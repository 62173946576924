import $store from "@/store"

export const SESSION_CHECKER_INTERVAL_MS = 1000 * 30 // 30 seconds.
export const SESSION_CHECKER_INTERVAL_ID = "__session_checker_interval_id"

export async function userSessionValidator() {
  const storeState = $store.getState()
  //>> Shallow check if user is a guest <<//
  const isLikelyGuest = (() => {
    const permissionIsGuest = storeState.permission.level === "guest"
    const hasGuestSessionStorage = window.sessionStorage.getItem("guestToken")
    return permissionIsGuest || hasGuestSessionStorage
  })()
  if (isLikelyGuest) return Promise.resolve(true)

  const env = storeState.env
  const REACT_APP_API_SERVER =
    window.env?.REACT_APP_API_SERVER ||
    window._env_?.REACT_APP_API_SERVER ||
    env.REACT_APP_API_SERVER ||
    import.meta.env.REACT_APP_API_SERVER

  const fetchURL = `${REACT_APP_API_SERVER}/v2/user/session/status`

  try {
    const response = await fetch(fetchURL, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
    const statusCode = response.status
    const statusText = response.statusText?.toLowerCase?.()
    const result = await response.json()

    const isUnauthorized = statusCode === 401 || statusText === "unauthorized"

    if (isUnauthorized) {
      const alertMsg = `Looks like your login session has expired. Please login again.`
      const spaceId = storeState.room?.data?._id
      const origin = window.location.origin
      const redirectUrl = spaceId ? `${origin}/space/${spaceId}` : origin
      window.alert(alertMsg)
      window.location.href = redirectUrl
      return Promise.resolve(result)
    }
    return Promise.resolve(result)
  } catch (error) {
    // Catch block triggers only when there is network error or if the request is blocked by CORS, NOT when the server returns certain status code like 401.
    const msg = "Server might be down. Please try again later."
    console.error(msg)
    return Promise.reject(error)
  }
}

export async function startUserSessionValidationLoop() {
  window.clearInterval(window[SESSION_CHECKER_INTERVAL_ID])
  userSessionValidator()
  window[SESSION_CHECKER_INTERVAL_ID] = window.setInterval(
    userSessionValidator,
    SESSION_CHECKER_INTERVAL_MS,
  )
}

export async function endUserSessionValidationLoop() {
  window.clearInterval(window[SESSION_CHECKER_INTERVAL_ID])
}
