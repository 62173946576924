import { AnnotationModeEnum } from "@/TYPES/common"
import { AnnotationState } from "@/TYPES/redux"
import { createSlice } from "@reduxjs/toolkit"

const initialState: AnnotationState = {
  showAnnotation: false,
  showMenu: false,
  mode: AnnotationModeEnum.MAGIC,
  penTimeoutMS: 0,
  penColor: "#fdff32",
  lineWidth: 3,
  parentElRect: null,
  isDrawing: false,
  currentDrawer: {
    _id: "",
    email: "",
    name: "",
  },
  volatileMarker: {
    enabled: true,
    color: "#d5fe00",
    width: 1,
    timeoutMS: 5000,
  },
  drawingChunks: [],
  clearChunkDataKey: 0,
}

export const annotationSlice = createSlice({
  name: "annotation",
  initialState,
  reducers: {
    clearChunkData: (state) => {
      return {
        ...state,
        clearChunkDataKey: state.clearChunkDataKey + 1,
      }
    },
    assignDrawingChunks: (state, action) => {
      const payload = action.payload
      return {
        ...state,
        drawingChunks: payload,
      }
    },
    assignShowAnnotation: (state, action) => {
      const payload = action.payload
      return {
        ...state,
        showAnnotation: payload,
        //>> Default to magic marker mode <<//
        mode: payload === false ? AnnotationModeEnum.MAGIC : state.mode,
        //>> Reset magic marker drawing user info <<//
        ...(payload === false
          ? { currentDrawer: initialState.currentDrawer }
          : {}),
      }
    },
    assignShowMenu: (state, action) => {
      const payload = action.payload
      return {
        ...state,
        showMenu: payload,
      }
    },
    assignAnnotationMode: (state, action) => {
      const payload = action.payload
      return {
        ...state,
        mode: payload,
      }
    },
    assignPenTimeoutMS: (state, action) => {
      const payload = action.payload
      let ms = Number.isNaN(+payload) ? 5000 : +payload
      if (ms < 1000) ms = ms * 1000
      return {
        ...state,
        penTimeoutMS: ms,
      }
    },
    assignPenColor: (state, action) => {
      return {
        ...state,
        penColor: action.payload,
      }
    },
    assignLineWidth: (state, action) => {
      return {
        ...state,
        lineWidth: action.payload,
      }
    },
    assignVolatileMarker: (state, action) => {
      return {
        ...state,
        volatileMarker: {
          ...state.volatileMarker,
          ...action.payload,
        },
      }
    },
    assignParentElRect: (state, action) => {
      return {
        ...state,
        parentElRect: action.payload,
      }
    },
    assignIsDrawing: (state, action) => {
      return {
        ...state,
        isDrawing: action.payload,
      }
    },
    assignCurrentDrawer: (state, action) => {
      const payload = action.payload
      if (!payload)
        return {
          ...state,
          currentDrawer: initialState.currentDrawer,
        }
      return {
        ...state,
        currentDrawer: {
          ...state.currentDrawer,
          ...payload,
        },
      }
    },
  },
})

export const {
  assignVolatileMarker,
  assignShowAnnotation,
  assignShowMenu,
  assignPenColor,
  assignParentElRect,
  assignIsDrawing,
  assignCurrentDrawer,
  assignLineWidth,
  assignPenTimeoutMS,
  assignAnnotationMode,
  assignDrawingChunks,
  clearChunkData,
} = annotationSlice.actions
export default annotationSlice.reducer
