import { useState } from "react"
import PlatformLayout from "../PlatformLayout"
import Chat from "@/components/Chat"

export default function Chats() {
  const [query, setQuery] = useState("")
  return (
    <PlatformLayout title="Chat" query={query} onSearch={setQuery}>
      <Chat />
    </PlatformLayout>
  )
}
