import { AppType, ChildrenType } from "@/TYPES/common"
import cn from "classnames"

type Props = {
  title: string
  apps: AppType[]
  children?: ChildrenType
}

export default function AppList({ title, apps, children }: Props) {
  return (
    <div className="flex flex-col">
      <div className={cn("flex justify-between mb-[21px]", "sm:mb-[10px]")}>
        <span className="text-18">{title}</span>
        <div>{children}</div>
      </div>
      <div className={cn("flex flex-wrap gap-[28px]", "sm:gap-[10px]")}>
        {apps.map((app) => (
          <div
            key={app.name}
            className={cn(
              "w-[305px] h-[205px] overflow-hidden",
              "flex flex-col px-[8px] rounded-[10px] bg-white shadow",
              "sm:w-[180px] sm:h-[140px]",
            )}
          >
            <div
              className={cn(
                "h-[50px] flex items-center text-18 font-bold leading-none",
                "sm:h-[30px] sm:text-12",
              )}
            >
              {app.name}
            </div>
            <div
              className={cn("h-[135px] flex bg-smoke", "sm:h-[100px]")}
            ></div>
          </div>
        ))}
      </div>
    </div>
  )
}
