import { RootState } from "@/TYPES/redux"
import { FileFolderManager, FileManager } from "@/components/File"
import { useSelector } from "react-redux"

export default function PhonePanel() {
  const selectedIds = useSelector((state: RootState) => state.files.selectedIds)

  const selected = selectedIds.length > 0

  return (
    <div className="w-full relative bg-white">
      {!selected && <FileFolderManager narrow />}
      {selected && <FileManager narrow managable />}
    </div>
  )
}
