import Icon, { IconNameProp } from "@/components/Icon"
import cn from "classnames"

type Props = {
  file: any
  selected: boolean
  narrow?: boolean
  darkMode?: boolean
  onSelect: () => void
  onOpen: () => void
}

const icons = {
  folder: { name: IconNameProp.Folder, size: 33, phoneSize: 27 },
  pdf: { name: IconNameProp.Pdf, size: 36, phoneSize: 30 },
  csv: { name: IconNameProp.Csv, size: 36, phoneSize: 28 },
  image: { name: IconNameProp.Image, size: 36, phoneSize: 29 },
  glb: { name: IconNameProp.ThreeD, size: 39, phoneSize: 31 },
  default: { name: IconNameProp.Files, size: 36, phoneSize: 30 },
}

export default function FileItem({
  file,
  selected,
  narrow,
  darkMode,
  onSelect,
  onOpen,
}: Props) {
  const icon = icons[file.type] || icons.default

  const modifiedAt = () => {
    if (file.modifiedAt) {
      const timestamp = file.modifiedAt;
      const parsedDate = new Date(timestamp);
      const options: Intl.DateTimeFormatOptions = {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      };

      const formattedDate = parsedDate.toLocaleDateString('en-US', options);
      return formattedDate;
    }
    return;
  }

  const handleClick = (e: any) => {
    if (e.detail === 1) { // Single click
      onSelect()
    } else if (e.detail === 2) { // Double click
      onOpen()
    }
  }

  return (
    <div
      className={cn("h-[70px] flex items-center text-22 select-none", {
        "border-b border-lightgray": !selected,
        "border-y border-gravel": selected,
        "py-4": !narrow,
        "py-2 px-2 border-none": narrow,
        "text-concord": !darkMode && !selected,
        "text-carbon": !darkMode && selected,
        "text-lavender": darkMode,
      })}
      onClick={handleClick}
    >
      {!narrow && (
        <div className="h-full min-w-[5%] max-w-[5%] flex items-center justify-center px-2 cursor-pointer">
          {selected && (
            <Icon
              className="fill-carbon"
              iconName={IconNameProp.Check}
              size={18}
            />
          )}
        </div>
      )}

      <div
        className={cn(
          {
            "min-w-[8%] max-w-[8%] px-2": !narrow,
            "min-w-[40px] max-w-[40px]": narrow,
          },
          "flex items-center",
        )}
      >
        <Icon
          className={cn({
            "fill-carbon": selected && !darkMode,
            "fill-concord": !selected && !darkMode,
            "fill-lavender": darkMode,
          })}
          iconName={icon.name}
          size={narrow ? icon.phoneSize : icon.size}
        />
      </div>

      <div
        className={cn(
          {
            "min-w-[35%] max-w-[35%] items-center": !narrow,
            "flex-1 flex-col items-start": narrow,
            "font-bold": selected,
          },
          "flex px-2 leading-none",
        )}
      >
        <span className={cn({ "text-18": narrow })}>{file.name}</span>
        {narrow && <span className="text-15">Modified {modifiedAt()}</span>}
      </div>

      {!narrow && (
        <div className="min-w-[25%] max-w-[25%] flex items-center px-2">
          {modifiedAt()}
        </div>
      )}

      {!narrow && (
        <div className="flex-1 flex items-center px-2">Wallon W.</div>
      )}
    </div>
  )
}
