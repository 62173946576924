import cn from "classnames"
import AppTile from "../../AppTile"

export default function MainTile({ active, tile }) {
  return (
    <div
      key={tile._id}
      className={cn(
        "absolute inset-0",
        "transition-opacity duration-1000 ease-in-out",
      )}
    >
      <AppTile isMain={true} active={active} tile={tile} />
    </div>
  )
}
