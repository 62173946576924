import { EventParticipant } from "@/TYPES/redux"
import User from "./User"
import cn from "classnames"

interface UserType {
  name: string
  title?: string
}

interface Props {
  users: EventParticipant[]
  organizer: string
  isDarkMode: boolean
}

export default function UserList({ users, organizer, isDarkMode }: Props) {
  return (
    <div className="flex flex-col pl-[35px] gap-2">
      {users?.map((userObj: EventParticipant) => (
        <div key={userObj._id} className="flex flex-row items-center">
          <div className="flex flex-col">
            <User
              textClassName={cn("font-bold text-[12pt]", {
                "text-carbon": !isDarkMode,
                "text-smoke": isDarkMode,
              })}
              bgColor={isDarkMode ? "carbon" : "smoke"}
              name={userObj.name}
              isOrganizer={organizer === userObj.name}
            />
          </div>
        </div>
      ))}
    </div>
  )
}
