import type { ReactNode } from "react"
import Button from "@mui/material/Button"

interface GuacConnInfoProps {
  show?: boolean
  guacURL?: string
  guacConnInfo: GuacAssetData
  children?: ReactNode
  isLargeTile?: boolean
  isOriginalOwner?: boolean
}

export default function GuacConnInfo(props: GuacConnInfoProps) {
  const show = props.show ?? true
  const guacConnInfo = props.guacConnInfo
  const guacURL = props.guacURL
  const isOriginalOwner = props.isOriginalOwner || false
  const children = props.children

  if (show === false) return <></>
  return (
    <div
      style={{
        display: guacURL ? "none" : "flex",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(4, 59, 92, 1)",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div>
        <u>
          <b>RDP INFO</b>
        </u>
      </div>
      <div>NAME: {guacConnInfo.name}</div>
      <div>HOST: {guacConnInfo.host}</div>
      <div
        style={{
          display: guacConnInfo.domain ? "block" : "none",
        }}
      >
        DOMAIN: {guacConnInfo.domain}
      </div>
      <br />
      {children}
    </div>
  )
}
