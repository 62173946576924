import { ChildrenType } from "@/TYPES/common"
import { Menu, MenuItem } from "@mui/material"
import cn from "classnames"
import { useRef, useState } from "react"

type MenuItemType = { label?: string | JSX.Element; onClick: () => void }

type Props = {
  className?: string
  menuClassName?: string
  children: ChildrenType
  menuItems: MenuItemType[]
  keepAfterSelect?: boolean
  position?: string
  anchorProps?: any
  transformProps?: any
  styleOverrides?: any
}

export default function DropdownMenu({
  className,
  menuClassName,
  menuItems,
  children,
  anchorProps,
  transformProps,
  styleOverrides,
  keepAfterSelect = false,
}: Props) {
  const ref = useRef<HTMLDivElement>(null)
  const [shown, setShown] = useState(false)

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setShown(!shown)
  }

  const handleItemClick = (e: any, item: MenuItemType) => {
    e.stopPropagation()

    item.onClick?.()

    setShown(keepAfterSelect)
  }

  return (
    <div
      ref={ref}
      className={cn("relative cursor-pointer", className)}
      onClick={handleClick}
    >
      {children}

      <Menu
        MenuListProps={{ dense: true, disablePadding: true }}
        className={menuClassName}
        anchorEl={ref?.current}
        open={shown}
        anchorOrigin={anchorProps}
        transformOrigin={transformProps}
        sx={{
          "& .MuiMenu-paper": {
            bgcolor: "rgb(40, 40, 40, 0.9)",
            color: "#ebebef",
            border: "0.5px solid rgb(40, 40, 40)",
          },
          "& .MuiCheckbox-root": {
            color: "#c5c9d2",
          },
          "& .Mui-checked": {
            color: "#fcd000 !important",
          },
          ...styleOverrides,
        }}
      >
        {menuItems.length > 0 ? (
          <div>
            {menuItems.map((item, index) => (
              <MenuItem key={index} onClick={(e) => handleItemClick(e, item)}>
                {item.label}
              </MenuItem>
            ))}
          </div>
        ) : (
          <div className="w-full h-[40px] flex-center text-pearl cursor-not-allowed">
            Empty
          </div>
        )}
      </Menu>
    </div>
  )
}
