import { createSlice } from "@reduxjs/toolkit"

export const appSlice = createSlice({
  name: "app",
  initialState: {
    isAddingVNC: false,
  },
  reducers: {
    assignIsAddingVNC: (state, action) => ({
      ...state,
      isAddingVNC: !!action.payload,
    })
  },
})

export const { assignIsAddingVNC } = appSlice.actions
export default appSlice.reducer
