import { createSlice } from "@reduxjs/toolkit"

export const chatSlice = createSlice({
  name: "chat",
  initialState: {
    user: null,
  },
  reducers: {
    assignChatUser: (state, action) => ({
      ...state,
      user: action.payload,
    }),
  },
})

export const { assignChatUser } = chatSlice.actions
export default chatSlice.reducer
