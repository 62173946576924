import { ChildrenType } from "@/TYPES/common"
import Icon, { IconNameProp } from "@/components/Icon"
import { defaultPopperProps } from "@/helpers"
import { removeView } from "@/store/slices/spaceSlice"
import Menu from "@mui/material/Menu"
import Paper from "@mui/material/Paper"
import Popper from "@mui/material/Popper"
import Tooltip from "@mui/material/Tooltip"
import cn from "classnames"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import type { NavItemType } from "./NavItems"

type Props = {
  iconClassName?: string
  className?: string
  selected: boolean
  disabled?: boolean
  label?: string
  tooltip?: string
  iconName: IconNameProp
  iconSize: number
  onClick: () => void
  children?: ChildrenType
  navItem?: NavItemType
}

export default function NavItem({
  iconClassName,
  className,
  selected,
  disabled = false,
  iconName,
  iconSize,
  label,
  tooltip = "",
  children,
  onClick,
  navItem,
}: Props) {
  const handleClick = (evt) => !disabled && onClick?.()
  const dispatch = useDispatch()
  const anchor_ref = useRef<HTMLDivElement | null>(null)
  const navItemRef = useRef<HTMLDivElement | null>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const annotationShow = useSelector(
    (state: any) => state.annotation.showAnnotation,
  )
  const showMore = useSelector((state: any) =>
    state.space.views.includes("more"),
  )
  const isPresenterMode = useSelector(
    (state: any) => state.space.isPresenterMode,
  )

  const hasDropdown = navItem && navItem.dropdown && navItem.dropdown.enabled
  const dropdownOpen = hasDropdown && navItem!.dropdown!.isOpen // TODO: Fix
  const hideChevron =
    navItem && (navItem.key === "annotate" || navItem.key === "more") // TODO: Ugh
  const highlighted =
    navItem && !navItem.disabledInPresenterMode && isPresenterMode

  const toggleMenu = (event) => {
    event.stopPropagation()
    event.preventDefault()
    console.log("hover")
    if (anchorEl) {
      if (showMore && navItem?.key === "more") {
        dispatch(removeView("more"))
      }
      return setAnchorEl(null)
    }
    setAnchorEl(anchor_ref.current)
  }

  const closeMenu = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(null)
  }

  useEffect(() => {
    if (!showMore) setAnchorEl(null)
    if (showMore) setAnchorEl(navItemRef.current)
  }, [showMore])

  return (
    <>
      <div
        ref={navItemRef}
        className={cn(
          className,
          "transition-color duration-300",
          "relative h-full min-w-[50px] w-fit shrink-0 flex items-stretch justify-between",
          { "z-50 opacity-80": highlighted },
          // { "mr-2": hasDropdown && !hideChevron },
          { "cursor-pointer": !disabled },
          { "cursor-not-allowed": disabled },
        )}
        onClick={handleClick}
      >
        <Tooltip title={tooltip} PopperProps={defaultPopperProps}>
          <span>
            <div
              className={cn(
                "group relative w-full flex flex-col justify-between items-center pt-[4px]",
                {
                  "text-accentyellow": selected,
                  "hover:text-white": !selected,
                },
              )}
            >
              {!navItem?.customJSX ? (
                <>
                  <Icon
                    className={cn(
                      iconClassName
                        ? iconClassName
                        : "transition-color duration-300 mb-3",
                      {
                        "fill-accentyellow group-hover:opacity-60":
                          selected && !disabled,
                        "fill-ghostgray group-hover:fill-accentgreen":
                          !selected && !disabled,
                        "fill-ghostgray": disabled,
                      },
                    )}
                    iconName={iconName}
                    size={iconSize}
                  />
                  <span
                    className={cn(
                      "w-full text-center text-9 leading-none whitespace-nowrap",
                    )}
                  >
                    {label}
                  </span>
                </>
              ) : (
                navItem.customJSX
              )}
            </div>
          </span>
        </Tooltip>
        {children}
        {hasDropdown && (
          <>
            <div
              ref={anchor_ref}
              className="group absolute top-0 left-6 h-full flex items-center z-10 pb-1 cursor-pointer"
              onClick={toggleMenu}
            >
              {!hideChevron && (
                <Icon
                  className="rotate-90 fill-ghostgray group-hover:fill-white"
                  iconName={IconNameProp.Chevron}
                  size={10}
                />
              )}
            </div>
            <NavItemDropdownMenuComp
              navItem={navItem}
              anchorEl={anchorEl}
              toggleMenu={toggleMenu}
            />
          </>
        )}
      </div>
    </>
  )
}

function NavItemDropdownMenuComp({ navItem, anchorEl, toggleMenu }) {
  if (!navItem || !anchorEl) return <></>
  const dropdown = navItem.dropdown
  const isAnnotationMenu = navItem.key === "annotate"
  if (!dropdown || !dropdown.enabled || !dropdown.menuContentJSX) return <></>
  const open = Boolean(anchorEl)

  return isAnnotationMenu ? (
    <AnnotationDropdownMenuComp anchorEl={anchorEl} dropdown={dropdown} />
  ) : (
    <Menu
      open={open}
      anchorEl={anchorEl}
      elevation={24}
      onClose={toggleMenu}
      variant="menu"
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      MenuListProps={{ dense: true, disablePadding: true }}
      anchorOrigin={{ vertical: -27, horizontal: "center" }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      sx={{
        "& .MuiMenu-paper": {
          bgcolor: "rgb(40, 40, 40, 0.9)",
          color: "#ebebef",
          border: "0.5px solid rgb(40, 40, 40)",
        },
        "& .MuiCheckbox-root": {
          color: "#c5c9d2",
        },
        "& .Mui-checked": {
          color: "#fcd000 !important",
        },
      }}
    >
      {dropdown.menuContentJSX}
    </Menu>
  )
}

function AnnotationDropdownMenuComp({ anchorEl, dropdown }) {
  const showAnnotation = useSelector(
    (state: any) => state.annotation.showAnnotation,
  )
  const showMenu = useSelector((state: any) => state.annotation.showMenu)
  const open = Boolean(anchorEl) && showAnnotation && showMenu

  return (
    <Popper
      sx={{ zIndex: 101 }}
      keepMounted
      open={open}
      anchorEl={anchorEl}
      placement="left-end"
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <Paper
        variant="outlined"
        sx={{
          backgroundColor: "transparent",
          border: "none",
        }}
      >
        {dropdown.menuContentJSX}
      </Paper>
    </Popper>
  )
}
