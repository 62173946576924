import Modal from "./index"

type Props = {
  message: string
  onClose: () => void
  onConfirm: () => void
}

export default function ConfirmationModal({
  message,
  onClose,
  onConfirm,
}: Props) {
  const handleConfirm = (e: any) => {
    e.stopPropagation()
    onConfirm()
  }

  const handleClose = (e: any) => {
    e.stopPropagation()
    onClose()
  }

  return (
    <Modal className="cursor-default" onClose={onClose} noCloseButton>
      <div className="text-16 w-[500px] break-words mb-4">{message}</div>
      <div className="flex justify-between items-center">
        <button
          className="text-white text-16 bg-carbon w-[150px] h-[40px] rounded hover:opacity-80"
          onClick={handleConfirm}
        >
          Confirm
        </button>
        <button
          className="text-white text-16 bg-accentred w-[150px] h-[40px] rounded hover:opacity-80"
          onClick={handleClose}
        >
          Cancel
        </button>
      </div>
    </Modal>
  )
}
