import { RootState } from "@/TYPES/redux"
import CenteredLoader from "@/components/CenteredLoader"
import { useSelectViewport } from "@/utils/hooks/viewport"
import { useSelector } from "react-redux"
import DesktopTileList from "./DesktopTileList"
import PhoneTileList from "./PhoneTileList"

export default function TileList() {
  const { isPhone } = useSelectViewport()

  const roomData = useSelector((state: RootState) => state.room.data)
  const views = useSelector((state: RootState) => state.space.views)

  const isFile = views.includes("files")

  if (!Array.isArray(roomData?.scene2d?.dataTile01))
    return (
      <div className="relative w-[240px] h-full">
        <CenteredLoader />
      </div>
    )

  const apps = roomData!.scene2d.dataTile01.filter(Boolean)

  // const filteredApps = apps.filter((app: any) => {
  //   const appIsFile = app.type === "file"
  //   const appIsRDP = app.fileName?.endsWith?.(".rdp")

  //   if (isFile) return appIsFile && !appIsRDP
  //   return !appIsFile || appIsRDP
  //   return app
  // })

  return isPhone ? (
    <PhoneTileList apps={apps} isFile={isFile} />
  ) : (
    <DesktopTileList apps={apps} isFile={isFile} />
  )
}
