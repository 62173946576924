import { createSlice } from "@reduxjs/toolkit"

export const mmSlice = createSlice({
  name: "mm",
  initialState: {
    loggedInMMUser: null,
    mmTeamName: null,
    mmConnectGuest: false,
  },
  reducers: {
    assignLoggedInMMUser: (state, action) => {
      return {
        ...state,
        loggedInMMUser: action.payload,
      }
    },
    assignMMTeamName: (state, action) => {
      return {
        ...state,
        mmTeamName: action.payload,
      }
    },
    assignMMConnectGuest: (state, action) => {
      return {
        ...state,
        mmConnectGuest: action.payload,
      }
    },
  },
})

export const { assignLoggedInMMUser, assignMMTeamName, assignMMConnectGuest } =
  mmSlice.actions
export default mmSlice.reducer
