import Icon, { IconNameProp } from "@/components/Icon"
import { useSelectViewport } from "@/utils/hooks/viewport"
import cn from "classnames"
import { Link, matchPath, useLocation } from "react-router-dom"

const navItems = [
  {
    key: "chat",
    label: "Chat",
    iconName: IconNameProp.Chat,
    iconSize: 28,
    path: "/chats",
  },
  {
    key: "spaces",
    label: "Spaces",
    iconName: IconNameProp.Spaces,
    iconSize: 41,
    path: "/spaces",
  },
  {
    key: "calendar",
    label: "Calendar",
    iconName: IconNameProp.Calendar,
    iconSize: 26,
    path: "/calendar",
  },
  {
    key: "apps",
    label: "Apps",
    iconName: IconNameProp.Apps,
    iconSize: 28,
    path: "/apps",
  },
  {
    key: "files",
    label: "Files",
    iconName: IconNameProp.Files,
    iconSize: 22,
    path: "/files",
  },
]

export default function Navbar() {
  const { isPhone } = useSelectViewport()
  const location = useLocation()

  return (
    <div
      className={cn(
        "flex flex-col w-[78px] bg-carbon",
        "sm:h-[62px] sm:w-full sm:flex-row sm:item-stretch sm:justify-between sm:px-[10px]",
      )}
    >
      {navItems.map((navItem) => {
        const selected = !!matchPath(location.pathname, navItem.path)

        return (
          <Link
            key={navItem.key}
            className={cn(
              "group",
              "relative h-[78px] overflow-hidden",
              "flex flex-col items-center justify-between pt-[11px] pb-[15px]",
              "sm:h-full sm:w-[62px] sm:pt-[8px] sm:pb-[10px] sm:px-[10px]",
              {
                "bg-gradient-gray3": !isPhone && selected,
                "text-accentyellow": selected,
                "hover:text-white": !selected,
              },
            )}
            to={navItem.path}
          >
            <div className="w-[32px] h-[32px] flex-center sm:w-[24px] sm:h-[24px]">
              <Icon
                className={cn({
                  "fill-accentyellow": selected,
                  "fill-typegray group-hover:fill-white": !selected,
                })}
                iconName={navItem.iconName}
                size={navItem.iconSize}
              />
            </div>
            <span
              className={cn(
                "w-full text-center text-11 leading-none",
                "sm:text-9",
              )}
            >
              {navItem.label}
            </span>

            {selected && (
              <div
                className={cn(
                  "absolute left-0 bottom-0 w-[4px] h-full bg-accentyellow",
                  "sm:h-[4px] sm:w-full",
                )}
              />
            )}
          </Link>
        )
      })}
    </div>
  )
}
