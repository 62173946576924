import Icon, { IconNameProp } from "@/components/Icon"

type Props = {
  selected?: number
}

export default function MainTitle({ selected = 0 }: Props) {
  return (
    <div className="h-[70px] flex items-stretch justify-between pl-[12px] shadow">
      <div className="flex items-center gap-[12px] text-concord">
        <span className="text-16 font-bold">{selected} apps selected</span>
        <button className="w-[228px] font-bold text-20 rounded-sm bg-accentyellow">
          Set as Tile(s)
        </button>
        <div className="w-[175px] h-[38px] flex-center text-18 border border-ghostgray rounded-sm">
          Manage apps
        </div>
      </div>
      <div className="flex items-center">
        <div className="p-4">
          <Icon
            className="fill-carbon"
            iconName={IconNameProp.Trash}
            size={22}
          />
        </div>
        <div className="p-4">
          <Icon
            className="fill-carbon"
            iconName={IconNameProp.Open}
            size={21}
          />
        </div>
      </div>
    </div>
  )
}
