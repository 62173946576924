import Icon, { IconNameProp } from "@/components/Icon"
import Topbar from "@/components/Topbar"
import cn from "classnames"

type Props = {
  isOwner: boolean
  isGuest: boolean
  title: string
  redirectToAfterSignIn: string
}

export default function TitleBar({
  redirectToAfterSignIn,
  title,
  isOwner,
  isGuest,
}: Props) {
  let role = "Standard"
  if (isOwner) {
    role = "Owner"
  } else if (isGuest) {
    role = "Guest"
  }

  //   const logoutTest = async () => {
  //     const response = await fetch("http://localhost:8082/v2/signout", {
  //       credentials: "include",
  //     })
  //     const data = await response.json()
  //     console.log(data)
  //   }

  return (
    <Topbar redirectToAfterSignIn={redirectToAfterSignIn}>
      <h2
        className={cn(
          "text-white text-18 font-light truncate mx-4",
          "sm:text-21",
        )}
      >
        {title}
      </h2>

      <div className="relative flex-center text-accentyellow gap-1 sm:hidden">
        <Icon
          className="fill-accentyellow"
          iconName={IconNameProp.User}
          size={26}
        />
        <span className="font-light">{role}</span>
      </div>
      {/* <button onClick={logoutTest}>logout testt</button> */}
    </Topbar>
  )
}
