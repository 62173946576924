import Component, { ActionMeta, GroupBase, OptionsOrGroups } from "react-select"
import cn from "classnames"

interface Props {
  placeholder?: string
  value: any
  options: OptionsOrGroups<any, GroupBase<any>>
  onChange: (newValue: any, actionMeta: ActionMeta<any>) => void
  isMulti: boolean
  closeMenuOnSelect?: boolean
  className?: string
}

export default function Select({
  placeholder,
  options,
  value,
  onChange,
  closeMenuOnSelect,
  isMulti,
  className,
}: Props) {
  const carbon = "#5b5b5b"
  const typegray = "#c6cad3"
  return (
    <Component
      className={cn("text-carbon", className)}
      placeholder={placeholder}
      value={value}
      isMulti={isMulti}
      options={options}
      closeMenuOnSelect={closeMenuOnSelect}
      menuPlacement="auto"
      onChange={onChange}
      styles={{
        singleValue: (provided, state) => ({
          ...provided,
          color: carbon,
        }),
        control: (provided, state) => ({
          ...provided,
          borderRadius: "2px",
          boxShadow: "none",
          color: carbon,
          border: "1px solid #5b5b5b",
          "&:hover": {
            border: "1px solid #5b5b5b",
          },
        }),
        menuList: (provided, state) => ({
          ...provided,
          color: carbon,
          border: `1px solid ${typegray}`,
          height: 170,
          overflowY: "auto",
        }),
        indicatorSeparator: (provided, state) => ({
          ...provided,
          backgroundColor: typegray,
        }),
      }}
    />
  )
}
