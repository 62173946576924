import { ChildrenType } from "@/TYPES/common"
import cn from "classnames"

type BgColors =
  | "gray"
  | "gray2"
  | "white"
  | "gradient-white"
  | "smoke"
  | "carbon"
  | "charcoal"
  | "cloudy"
  | "cobrowse"
  | "black"
  | "secondary"

type Props = {
  className?: string
  bgColor?: BgColors
  children?: ChildrenType
}

export default function TitleBar({
  className = "",
  bgColor = "gray",
  children,
}: Props) {
  return (
    <div
      className={cn(
        className,
        "relative h-[45px] flex items-center px-3 flex-none",
        {
          "bg-gradient-black": bgColor === "black",
          "bg-gradient-gray1": bgColor === "gray",
          "bg-gradient-gray2": bgColor === "gray2",
          "bg-gradient-white": bgColor === "gradient-white",
          "bg-white": bgColor === "white",
          "bg-smoke": bgColor === "smoke",
          "bg-carbon": bgColor === "carbon",
          "bg-charcoal": bgColor === "charcoal",
          "bg-cloudy": bgColor === "cloudy",
          "bg-cobrowsegray": bgColor === "cobrowse",
          "bg-secondarygray": bgColor === "secondary",
        },
      )}
    >
      {children}
    </div>
  )
}
