import { SocketContext } from "@/context/socket"
import { SocketMessageType } from "@/TYPES/common"
import { RootState } from "@/TYPES/redux"
import { updateGuestQueue } from "@/store/slices/spaceSlice"
import { useCallback, useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { v4 as uuidv4 } from "uuid"
import Icon, { IconNameProp } from "@/components/Icon"
import guestJoinAudio from "/assets/sounds/guest-join.mp3"

export default function GuestQueue() {
  const socket = useContext(SocketContext)
  const dispatch = useDispatch()

  const REACT_APP_API_SERVER =
    useSelector((state: any) => state.env.REACT_APP_API_SERVER) ||
    import.meta.env.REACT_APP_API_SERVER
  const roomId = useSelector(
    (state: RootState) => state.room.data?._id || state.space.spaceId,
  )
  const isOwner = useSelector((state: RootState) => state.user.owner)
  const [inProcess, setProcess] = useState(false)
  const [guestList, setGuestList] = useState<any[]>([])

  const guestLoginFunc = useCallback(() => {
    if (!guestList.length || inProcess) {
      return
    }
    setProcess(true)
  }, [guestList, inProcess])

  useEffect(() => {
    if (!isOwner) return
    socket.on(SocketMessageType.GuestLogin, (comms) => {
      console.warn(SocketMessageType.GuestLogin, comms)

      const duplicatedLogin = guestList.find(
        (guest) => guest.socketId === comms.socketId,
      )
      if (!duplicatedLogin) {
        setGuestList((prevState) => [...prevState, { ...comms }])
        playSound()
      }
      guestLoginFunc()
    })

    socket.on(SocketMessageType.LeaveGuestQueue, (comms) => {
      console.warn(SocketMessageType.LeaveGuestQueue, comms)

      const { message } = comms
      const guestObj = {
        socketId: message.socketId,
      }
      removeFromQueue(guestObj)
    })

    return () => {
      socket.off(SocketMessageType.GuestLogin)
      socket.off(SocketMessageType.LeaveGuestQueue)
    }
  }, [socket, isOwner, guestLoginFunc])

  useEffect(() => {
    dispatch(updateGuestQueue(guestList))
  }, [guestList])

  const handleAdmit = async (guestObj) => {
    const guestData = {
      name: guestObj.message.name,
      roomId,
      id: uuidv4(),
    }
    try {
      const response = await fetch(
        `${REACT_APP_API_SERVER}/api/auth/generateGuestToken`,
        {
          method: "POST",
          body: JSON.stringify(guestData),
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      )
      const data = await response.json()
      socket.emit(SocketMessageType.GuestToken, {
        admit: true,
        guestToken: data.guestUserSession[0].guestToken,
        socketId: guestObj.socketId,
      })
    } catch (err) {
      console.error("Error requesting guest ID:", err)
    } finally {
      removeFromQueue(guestObj)
    }
  }

  const handleDeny = (guestObj) => {
    socket.emit(SocketMessageType.GuestToken, {
      admit: false,
      socketId: guestObj.socketId,
    })
    removeFromQueue(guestObj)
  }

  const removeFromQueue = (guestObj) => {
    setGuestList((prevState) =>
      prevState.filter((guest) => guest.socketId !== guestObj.socketId),
    )
    if (guestList.length) {
      setProcess(false)
      guestLoginFunc()
    } else {
      setProcess(false)
    }
  }

  const playSound = () => {
    const sound = new Audio(guestJoinAudio)
    sound
      .play()
      .catch((err) => console.error("Failed to play guest-join.mp3", err))
  }

  return (
    <div className="relative flex flex-col items-center block overflow-y-auto minimal-scrollbar">
      <div className="flex flex-col w-full px-2">
        {guestList.map((guest) => (
          <div
            key={guest.socketId}
            className="flex flex-row justify-between items-center gap-2 p-2 sm:pt-0 sm:px-0"
          >
            <div className="text-13 sm:text-sm sm:truncate">
              {guest.message.name}
            </div>
            <div className="flex flex-row gap-2">
              <div className="group bg-accentgreen rounded-full button-effect p-2">
                <Icon
                  className="cursor-pointer fill-typegray group-hover:fill-white"
                  iconName={IconNameProp.Invite}
                  size={14}
                  onClick={() => handleAdmit(guest)}
                />
              </div>
              <div className="group bg-accentred rounded-full button-effect p-2">
                <Icon
                  className="cursor-pointer fill-typegray group-hover:fill-white"
                  iconName={IconNameProp.HangUp}
                  size={14}
                  onClick={() => handleDeny(guest)}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
