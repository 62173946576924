import { createSlice } from "@reduxjs/toolkit"

const initialState: any = {
  visible: false,
  content: "",
  placement: "bottom-end",
  type: "",
}

export const tippySlice = createSlice({
  name: "tippy",
  initialState,
  reducers: {
    assignTippy: (state, action) => {
      const payload = action.payload
      if (!payload) return initialState

      return {
        ...state,
        ...payload,
        //   visible: payload.visible ?? state.visible,
        //   content: payload.content ?? state.content,
      }
    },
    //  assignVisible: (state, action) => {
    //    return {
    //      ...state,
    //      visible: action.payload,
    //    }
    //  },
    //  assignContent: (state, action) => {
    //    return {
    //      ...state,
    //      content: action.payload,
    //    }
    //  },
  },
})

export const { assignTippy } = tippySlice.actions
export default tippySlice.reducer
