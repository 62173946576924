import "@/assets/styles/App.scss"
import "@/assets/styles/excalidraw.scss"
import "@/assets/styles/tailwind.css"
import TippyComp from "@/components/Tippy/TippyComp"
import {
  appendDocumentTitleVersion,
  generateToken,
  getGuacConnections,
  useMyUser,
} from "@/helpers"
import $store from "@/store"
import { SessionContext } from "@/utils/context"
import { getDevices } from "@/utils/hooks/useDevices"
import { useViewport } from "@/utils/hooks/viewport"
import { library } from "@fortawesome/fontawesome-svg-core"
import { far } from "@fortawesome/free-regular-svg-icons"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import { useEffect, useState } from "react"
import { pdfjs } from "react-pdf"
import { useDispatch } from "react-redux"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import "sweetalert2/src/sweetalert2.scss"
import Loader from "./components/Loader"
import ToastNotification from "./components/ToastNotification/ToastNotification"
import Apps from "./pages/Apps"
import Calendars from "./pages/Calendars"
import Chats from "./pages/Chats"
import Files from "./pages/Files"
import Lobby from "./pages/Lobby"
import Login from "./pages/Login"
import Space from "./pages/Space"
import { assignBulkEnv } from "./store/slices/envSlice"

const REACT_APP_API_SERVER_OVERRIDE =
  window.env?.REACT_APP_API_SERVER || window._env_?.REACT_APP_API_SERVER

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url,
).toString()

const router = createBrowserRouter([
  { path: "/", element: <Login /> },
  { path: "/chats", element: <Chats /> },
  { path: "/calendar", element: <Calendars /> },
  { path: "/apps", element: <Apps /> },
  { path: "/files", element: <Files /> },
  { path: "/space/:id", element: <Space /> },
  { path: "/lobby/:id", element: <Lobby /> },
  { path: "*", element: <div>URL not found!</div> },
])
function repeatWrapper(handler, timeout = 3000) {
  if (!handler || typeof handler !== "function") return
  window.setTimeout(() => {
    handler()
  }, timeout)
}
//@@ Check if first admin account needs to be created @@//
async function checkAppInitStatus() {
  try {
    const storeState = $store.getState()
    const env = storeState.env
    //  const portalPageURL =
    // env.REACT_APP_PORTAL || import.meta.env.REACT_APP_PORTAL
    const portalPageURL =
      env.REACT_APP_PORTAL || window.location.origin + "/portal"
    const REACT_APP_API_SERVER =
      REACT_APP_API_SERVER_OVERRIDE ||
      window.REACT_APP_API_SERVER ||
      window._env_?.REACT_APP_API_SERVER ||
      import.meta.env.REACT_APP_API_SERVER
    const initedFetchURL = REACT_APP_API_SERVER + "/v1/inited"
    const response = await fetch(initedFetchURL)
    //@@ ??? just repeat to check @@//
    if (!response.ok) {
      repeatWrapper(checkAppInitStatus)
      return
    }

    //@@ Check the result @@//
    const result = await response.json()
    console.log("Is app inited?", result)
    if (!result.status) {
      window.location.href = portalPageURL
      return
    }

    //@@ If app was never inited @@//
    const isInited = result.data
    if (!isInited) {
      window.location.href = portalPageURL
      return
    }

    //@@ If app was inited @@//
    // setIsForceLoading(false);
  } catch (error) {
    console.error(error)
  } finally {
    // setIsForceLoading(false);
  }
}

//@@ Fetch updated env variables from server @@//
async function checkEnv() {
  const REACT_APP_API_SERVER =
    REACT_APP_API_SERVER_OVERRIDE ||
    window.REACT_APP_API_SERVER ||
    window._env_?.REACT_APP_API_SERVER ||
    import.meta.env.REACT_APP_API_SERVER
  const url = `${REACT_APP_API_SERVER}/v1/env/cc-frontend`
  try {
    const response = await fetch(url)
    const result = await response.json()
    if (!response.ok || !result.status) throw result
    window.log(`[checkEnv()]`, "highlight", result)
    return Promise.resolve(result)
  } catch (err: any) {
    window.log(`[checkEnv()] Failed to check server ENV vars`, "error", err)
    return Promise.reject(err.message || err)
  }
}

const DEFAULT_MUI_THEME = createTheme({
  palette: {
    mode: "light",
  },
})

function App() {
  library.add(fas, far)

  const dispatch = useDispatch()
  const viewport = useViewport()

  const { isLoading } = useMyUser(true)
  const [dataLoading, setDataLoading] = useState(true)

  useEffect(() => {
    const fetchInitData = async () => {
      try {
        const envResult = await checkEnv()
        const envData = envResult?.data || null
        const updatedEnv = {
          ...envData,
          ...(REACT_APP_API_SERVER_OVERRIDE
            ? { REACT_APP_API_SERVER: REACT_APP_API_SERVER_OVERRIDE }
            : {}),
        }
        dispatch(assignBulkEnv(updatedEnv))
        await checkAppInitStatus()
      } catch (err) {
        console.error("Error rendering init page or fetching env vars:", err)
        $store.dispatch({
          type: 'init/assignServerError',
          payload: true,
        })
      } finally {
        setDataLoading(false)
        appendDocumentTitleVersion()
      }
    }
    fetchInitData()

    window.navigator.mediaDevices.addEventListener("devicechange", getDevices)
    return () => {
      window.navigator.mediaDevices.removeEventListener(
        "devicechange",
        getDevices,
      )
    }
    //  guacInitToken()
  }, [])

  async function guacInitToken() {
    try {
      const guacTokenResult = await generateToken()
      const guacConnResult = await getGuacConnections()
      console.log({ guacTokenResult, guacConnResult })
      return Promise.resolve(true)
    } catch (error) {
      console.error(`<guacInitToken()> ERROR:`, error)
      return Promise.resolve(true)
    }
  }

  return (
    <SessionContext.Provider value={{ viewport }}>
      <div className="relative w-full h-screen flex flex-col bg-cobrowsegray overflow-hidden">
        <ToastNotification />
        <TippyComp />
        {isLoading || dataLoading ? (
          <Loader size={100} />
        ) : (
          <ThemeProvider theme={DEFAULT_MUI_THEME}>
            <RouterProvider router={router} />
          </ThemeProvider>
        )}
      </div>
    </SessionContext.Provider>
  )
}

export default App
