import { RootState } from "@/TYPES/redux"
import { IconNameProp } from "@/components/Icon"
import { assignAudioMuted, assignVideoMuted } from "@/store/slices/callSlice"
import { useDevices } from "@/utils/hooks/useDevices"
import cn from "classnames"
import { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SelectDevice } from "./SelectDevice"
import ToggleButton from "./ToggleButton"

export default function PreviewVideo() {
  const selfPreview = useRef<any>(null)

  const dispatch = useDispatch()
  const {
    videoDevice,
    audioDevice,
    filterDevicesByType,
    onChangeAudioDevice,
    onChangeVideoDevice,
  } = useDevices()

  const videoMuted = useSelector((state: RootState) => state.call.videoMuted)
  const audioMuted = useSelector((state: RootState) => state.call.audioMuted)

  function handleToggleMedia(type: "audio" | "video") {
    console.log("handleToggleMedia() type", type)
    if (!type) return
    if (type === "audio") {
      dispatch(assignAudioMuted(!audioMuted))
    }
    if (type === "video") {
      dispatch(assignVideoMuted(!videoMuted))
    }
  }

  useEffect(() => {
    async function getMedia() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: videoDevice.id
            ? { deviceId: { exact: videoDevice.id } }
            : true,
          audio:
            !audioMuted && audioDevice.id
              ? { deviceId: { exact: audioDevice.id } }
              : !audioMuted,
        })

        selfPreview.current.srcObject = stream
      } catch (err) {
        console.error("Error accessing media devices.", err)
      }
    }

    if (!videoMuted) {
      getMedia()
    }

    return () => {
      if (!selfPreview.current?.srcObject) {
        return
      }

      const tracks = selfPreview.current.srcObject.getTracks()
      tracks.forEach((track) => track.stop())
      selfPreview.current.srcObject = null
    }
  }, [videoDevice, audioDevice, videoMuted, audioMuted])

  return (
    <div className="relative h-full w-full flex flex-col">
      <div className="h-full w-full overflow-hidden flex-center bg-gradient-gray1 rounded-sm">
        {videoMuted && <span className="font-light">Your video is off</span>}
        {!videoMuted && (
          <video
            id="self-view-preview"
            className="w-full h-full object-cover"
            ref={selfPreview}
            autoPlay
            muted
          />
        )}
      </div>

      <div
        className={cn(
          "absolute bottom-0 left-0 w-full flex justify-center p-4 gap-6",
          "sm:relative",
        )}
      >
        <ToggleButton
          active={!audioMuted}
          activeIcon={IconNameProp.Unmute}
          inactiveIcon={IconNameProp.Mute}
          onClick={() => handleToggleMedia("audio")}
        />
        <ToggleButton
          active={!videoMuted}
          activeIcon={IconNameProp.VideoOn}
          inactiveIcon={IconNameProp.Video}
          onClick={() => handleToggleMedia("video")}
        />
      </div>

      <div className="absolute top-full w-full flex items-center gap-4 py-2 px-4">
        <SelectDevice
          devices={filterDevicesByType("audioinput")}
          value={audioDevice}
          icon={IconNameProp.Unmute}
          onChange={onChangeAudioDevice}
        />
        <SelectDevice
          devices={filterDevicesByType("videoinput")}
          value={videoDevice}
          icon={IconNameProp.VideoOn}
          onChange={onChangeVideoDevice}
        />
      </div>
    </div>
  )
}
