import { faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { setActiveEvent, setEditEvent } from "@/store/slices/eventsSlice"
import { useDispatch } from "react-redux"
import cn from "classnames"

interface Props {
  isDarkMode: boolean
}

export default function Header({ isDarkMode }: Props) {
  const dispatch = useDispatch()

  const handleExitClick = () => {
    dispatch(setActiveEvent(undefined))
    dispatch(setEditEvent(undefined))
  }

  return (
    <div className="flex justify-between items-center">
      <div
        className={cn("font-bold text-[12pt]", {
          "text-cloudy": !isDarkMode,
          "text-typegray": isDarkMode,
        })}
      >
        Event Details
      </div>
      <button
        className="flex items-center justify-center w-5 h-5 overflow-hidden"
        onClick={() => handleExitClick()}
      >
        <FontAwesomeIcon
          icon={faXmark}
          className={cn({
            "text-carbon": !isDarkMode,
            "text-smoke": isDarkMode,
          })}
          size="xl"
        />
      </button>
    </div>
  )
}
