export type ViewportType = {
  isPhone?: boolean
  isPad?: boolean
  isDesktop?: boolean
}

type BaseAppType = {
  _id: string
  title?: string
  name?: string
  type?: "cobrowse" | "file"
  fileName?: string
  folderId?: string
  whiteboard?: any
}

export type CobrowseType = BaseAppType & {
  src: string
  workaround: boolean
  permit?: any[]
  access?: any[]
}

export type PdfType = BaseAppType & {
  currPage: number
}

export type DeviceType = {
  id?: string
  kind?: string
  label?: string
}

export type AppType = BaseAppType | CobrowseType | PdfType // AppType will be union type of apps

export type ChildrenType =
  | null
  | string
  | boolean
  | JSX.Element
  | undefined
  | (string | false | JSX.Element | undefined)[]

export enum DraggingType {
  App = "app",
  Tile = "tile",
}

export enum SocketMessageType {
  GuestLogin = "guest-login",
  GuestCallIn = "guest-call-in",
  GuestToken = "guest-token",

  JoinUser = "join-user",
  JoinGuest = "join-guest",
  KeepAlive = "keep-alive",
  LeavePerson = "leave-person",
  LeaveGuestQueue = "leave-guest-queue",

  SyncSpace = "sync-space",
  GetRoom = "get-room",

  ToggleIsLocked = "toggle-isLocked",
  ToogleAppsPanel = "toggle-apps-panel",

  ShowCursor = "show-cursor",
  CursorMovement = "cursor-movement",
  HideCursor = "hide-cursor",

  VideShareScreen = "video-share-screen",
  Fullscreen = "large-tile-fullscreen",
  PresenterMode = "toggle-presenter-mode",
  DemoMode = "toggle-demo-mode",

  SceneString = "scene-string",
  PdfPageChange = "pdf-page-change",

  AnnotationCanvas = "annotation-canvas",
  AnnotationNotif = "annotation-notif",

  TriggerToast = "trigger-toast",

  PresenterVideo = "presenter-video",
}

export enum AnnotationModeEnum {
  MAGIC = "magic",
  WHITEBOARD = "whiteboard",
}
