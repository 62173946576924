import { FileManager } from "@/components/File"
import PlatformMain from "@/components/PlatformMain"

export default function MainPanel() {
  return (
    <PlatformMain className="flex flex-col bg-white">
      <div className="relative flex-1 overflow-hidden">
        <FileManager managable />
      </div>
    </PlatformMain>
  )
}
