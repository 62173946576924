import { ChildrenType } from "@/TYPES/common"
import cn from "classnames"
import PlatformSidebarHeader from "./PlatformSidebarHeader"

type Props = {
  className?: string
  title: string
  headerActions?: ChildrenType
  children?: ChildrenType
}

export default function PlatformSidebar({
  className,
  title,
  headerActions,
  children,
}: Props) {
  return (
    <div className="w-[395px] flex flex-col bg-white shadow">
      <PlatformSidebarHeader title={title} actions={headerActions} />

      <div className={cn(className, "relative flex-1 flex flex-col")}>
        {children}
      </div>
    </div>
  )
}
