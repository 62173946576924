import { fetcher } from "@/helpers/fetcher"
import { DeviceType } from "@/TYPES/common"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

//>> This is just to auto trigger state update after fetching <<//
export const getUserListAsyncThunk = createAsyncThunk("user/getUserList", () =>
  fetcher("/user-list"),
)

export const userSlice = createSlice({
  name: "user",
  initialState: {
    _id: undefined,
    email: undefined,
    name: undefined,
    guest: true,
    owner: false,
    cursorColor: "black",
    availableDevices: [] as DeviceType[],
    audioDevice: {} as DeviceType,
    videoDevice: {} as DeviceType,
    userList: [],
    displayName: null,
    isOwner: false, // Same as: props.accessLevel === 'owner'.
    calendar_id: undefined,
    mmPassword: null,
    mmTeamName: null,
    isMMRegistered: true,
    orgId: null,
    teams: [],
    data: null,
    accessLevel: null,
  },
  reducers: {
    assignId: (state, action) => ({
      ...state,
      _id: action.payload,
    }),
    assignCalendarId: (state, action) => ({
      ...state,
      calendar_id: action.payload,
    }),
    assignEmail: (state, action) => ({
      ...state,
      email: action.payload,
    }),
    assignName: (state, action) => ({
      ...state,
      name: action.payload,
    }),
    assignGuest: (state, action) => ({
      ...state,
      guest: action.payload,
    }),
    assignOwner: (state, action) => ({
      ...state,
      owner: action.payload,
    }),
    assignWebexSelf: (state, action) => ({
      ...state,
      webex: { ...action.payload },
    }),
    assignCursorColor: (state, action) => ({
      ...state,
      cursorColor: action.payload,
    }),
    assignDevices: (state, action) => {
      const audioDevice =
        action.payload.find((item) => item.id === state.audioDevice?.id) ||
        action.payload.find((item) => item.kind === "audioinput") ||
        {}
      const videoDevice =
        action.payload.find((item) => item.id === state.videoDevice?.id) ||
        action.payload.find((item) => item.kind === "videoinput") ||
        {}

      console.warn(
        "assignDevices??",
        action.payload.filter((item) => item.kind === "videoinput"),
        videoDevice,
      )
      return {
        ...state,
        availableDevices: [...action.payload],
        audioDevice,
        videoDevice,
      }
    },
    assignAudioDevice: (state, action) => ({
      ...state,
      audioDevice: {
        ...state.audioDevice,
        ...action.payload,
      },
    }),
    assignVideoDevice: (state, action) => ({
      ...state,
      videoDevice: {
        ...state.videoDevice,
        ...action.payload,
      },
    }),
    updateUserList: (state, action) => ({
      ...state,
      userList: action.payload,
    }),
    updateDisplayName: (state, action) => ({
      ...state,
      displayName: action.payload,
    }),
    updateIsOwner: (state, action) => ({
      ...state,
      isOwner: action.payload,
    }),
    assignMMPassword: (state, action) => ({
      ...state,
      mmPassword: action.payload,
    }),
    assignMMTeamName: (state, action) => ({
      ...state,
      mmTeamName: action.payload,
    }),
    assignIsMMRegistered: (state, action) => ({
      ...state,
      isMMRegistered: action.payload,
    }),
    assignOrgId: (state, action) => ({
      ...state,
      orgId: action.payload,
    }),
    assignTeams: (state, action) => ({
      ...state,
      teams: action.payload,
    }),
    assignUserData: (state, action) => {
      const userData = action.payload
      if (!userData || !userData._id) {
        return { ...state }
      }

      return {
        ...state,
        guest: false,
        data: userData,
      }
    },
    assignAccessLevel: (state, action) => ({
      ...state,
      accessLevel: action.payload,
    }),
  },
  extraReducers: (builder) => {
    const { addCase } = builder
    const { fulfilled, pending, rejected } = getUserListAsyncThunk

    addCase(fulfilled, (state, action) => {
      const response: any = action.payload
      if (!response || !response.status || !response.data) {
        return
      }

      state.userList = response.data
    })
  },
})

export const {
  assignId,
  assignName,
  assignOwner,
  assignWebexSelf,
  assignCursorColor,
  assignCalendarId,
  assignDevices,
  assignAudioDevice,
  assignVideoDevice,
  assignEmail,
  assignGuest,
  updateUserList,
  updateDisplayName,
  updateIsOwner,
  assignMMPassword,
  assignMMTeamName,
  assignIsMMRegistered,
  assignOrgId,
  assignTeams,
  assignUserData,
} = userSlice.actions
export default userSlice.reducer
