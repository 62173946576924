import { Event } from "@/TYPES/redux"
import EventInformation from "./EventInformation"
import GuestInformation from "./GuestInformation"
import cn from "classnames"

interface Props {
  //   event: Event
  event: any
  isDarkMode: boolean
}

export default function ActivePanelInformation({ event, isDarkMode }: Props) {
  return (
    <div
      className={cn(
        "flex flex-col justify-between w-full h-full p-4 overflow-y-auto",
        {
          "bg-white": !isDarkMode,
          "bg-cobrowsegray": isDarkMode,
        },
      )}
    >
      <div>
        <EventInformation event={event} isDarkMode={isDarkMode} />
        <GuestInformation event={event} isDarkMode={isDarkMode} />
      </div>

      {/* Not sure on functionality yet. Will turn back on when functionality is determined. */}
      {/* <div className="flex justify-center items-center p-3 bg-white">
        <button className="bottom-0 bg-transparent border-2 border-accentyellow w-[170px] h-[38px] rounded-sm text-carbon font-bold hover:bg-accentyellow transition-color duration-300">
          Set Tiles
        </button>
      </div> */}
    </div>
  )
}
