import { RootState } from "@/TYPES/redux"
import { selectFolder } from "@/store/slices/filesSlice"
import cn from "classnames"
import { useDispatch, useSelector } from "react-redux"

type Props = {
  narrow?: boolean
  darkMode?: boolean
}

export default function UpItem({ narrow, darkMode }: Props) {
  const dispatch = useDispatch()

  const selectedIds: string[] = useSelector(
    (state: RootState) => state.files.selectedIds,
  )

  const handleClick = () => {
    const length = selectedIds.length
    if (length === 0) {
      return
    }

    dispatch(selectFolder(selectedIds.slice(0, length - 1)))
  }

  return (
    <div
      className={cn("flex items-center text-22 select-none", {
        "py-4 border-b border-lightgray": !narrow,
        "py-2 px-2 border-none": narrow,
        "text-concord": !darkMode,
        "text-lavender": darkMode,
      })}
      onClick={handleClick}
    >
      {!narrow && <div className="h-full min-w-[5%] max-w-[5%] px-2" />}

      <div
        className={cn(
          {
            "min-w-[8%] max-w-[8%] px-2": !narrow,
            "min-w-[40px] max-w-[40px]": narrow,
          },
          "flex items-center",
        )}
      >
        &#9166;
      </div>

      <div
        className={cn(
          {
            "min-w-[35%] max-w-[35%] items-center": !narrow,
            "flex-1 flex-col items-start": narrow,
          },
          "flex px-2 leading-none",
        )}
      >
        ..
      </div>
    </div>
  )
}
