import Icon, { IconNameProp } from "@/components/Icon"
import { getPopperProps } from "@/helpers"
import cn from "classnames"
import Tooltip from "@mui/material/Tooltip"

export default function Fullscreen() {
  const doc = document as DocumentWithFullscreen
  const mainTile = document.getElementById("main-tile")
  const isInFullscreen =
    (doc.fullscreenElement && doc.fullscreenElement) ||
    (doc.webkitFullscreenElement && doc.webkitFullscreenElement) ||
    (doc.mozFullScreenElement && doc.mozFullScreenElement) ||
    (doc.msFullscreenElement && doc.msFullscreenElement)

  function enterFullscreen(tile: DocumentElementWithFullscreen) {
    const requestFullScreen =
      tile.requestFullscreen ||
      tile.webkitRequestFullscreen ||
      tile.mozRequestFullScreen ||
      tile.msRequestFullscreen

    if (requestFullScreen) {
      requestFullScreen.call(tile)
    } else {
      console.error("Fullscreen API not supported in current browser")
    }
  }

  function exitFullscreen() {
    const exitFullscreen =
      doc.exitFullscreen ||
      doc.webkitExitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.msExitFullscreen

    if (exitFullscreen) {
      exitFullscreen.call(doc)
    } else {
      console.error("Fullscreen API not supported in current browser")
    }
  }

  function toggleFullscreen() {
    if (!mainTile) return

    if (isInFullscreen) {
      exitFullscreen()
    } else {
      enterFullscreen(mainTile)
    }
  }

  return (
    <div>
      <Tooltip
        title={mainTile ? "Fullscreen" : ""}
        placement="right"
        PopperProps={popperProps}
      >
        <div>
          <Icon
            className={cn("transition-color duration-300 cursor-pointer", {
              "fill-smoke hover:fill-accentgreen": !isInFullscreen,
              "fill-accentyellow hover:opacity-60": isInFullscreen,
            })}
            iconName={IconNameProp.FullScreen}
            size={20}
            onClick={() => toggleFullscreen()}
          />
        </div>
      </Tooltip>
    </div>
  )
}

const popperProps = getPopperProps({ offset: [0, -2] })
