import $store from "@/store"

// Helper function for error handling
export const throwError = (description: string, err: any) => {
  if (err instanceof Response) {
    // This should cover most errors
    const { status, statusText } = err
    console.error(`${description}: ${status} ${statusText}`)
  } else {
    // More general message for edge cases
    console.error(`${description}: ${err}`)
  }
}

// For JSON data
// export async function fetcher(endpoint: string) {
//   const url = `${REACT_APP_API_SERVER}${endpoint}`

//   if (guestToken) {
//     // If guestToken exists, send headers in request
//     // console.log('fetcher guestToken found');
//     try {
//       const response = await fetch(url, {
//         method: "GET",
//         headers,
//         credentials: "include",
//       })
//       if (!response.ok) throw response
//       const data = await response.json()
//       return data
//     } catch (err) {
//       throwError("Failed to GET JSON data", err)
//       throw err
//     }
//   } else {
//     // Same request, no headers
//     try {
//       console.log("fetcher guestToken NOT found")
//       const response = await fetch(url, {
//         method: "GET",
//         credentials: "include",
//       })
//       if (!response.ok) throw response
//       const data = await response.json()
//       // console.log('fetcher data:', data);
//       return data
//     } catch (err) {
//       throwError("Failed to GET JSON data", err)
//       throw err
//     }
//   }
// }

export async function fetcher(endpoint: string) {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    window.env?.REACT_APP_API_SERVER ||
    window._env_?.REACT_APP_API_SERVER ||
    env.REACT_APP_API_SERVER ||
    import.meta.env.REACT_APP_API_SERVER
  const guestToken = window.sessionStorage.getItem("guestToken")
  const roomId = window.sessionStorage.getItem("roomId")
  const fetchURL = `${REACT_APP_API_SERVER}${endpoint}`

  const headers: any = guestToken
    ? {
        "x-access-token": guestToken,
        "x-room-id": roomId,
      }
    : null

  try {
    const response = await fetch(fetchURL, {
      method: "GET",
      ...(headers ? { headers } : {}), // spread operator to add headers if they exist (otherwise, empty object
      credentials: "include",
    })
    if (!response.ok) return Promise.reject(response)
    const data = await response.json()
    return Promise.resolve(data)
  } catch (error) {
    console.error(error)
    return Promise.reject(error)
  }
}

// For image files
export async function blobFetcher(endpoint: string) {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER
  const guestToken = window.sessionStorage.getItem("guestToken")
  const roomId = window.sessionStorage.getItem("roomId")

  const url = `${REACT_APP_API_SERVER}${endpoint}`
  const headers: any = guestToken
    ? {
        "x-access-token": guestToken,
        "x-room-id": roomId,
      }
    : null

  if (guestToken) {
    try {
      const response = await fetch(url, {
        method: "GET",
        ...(headers ? { headers } : {}),
        credentials: "include",
      })
      if (!response.ok) throw response
      const data = await response.blob()
      return data
    } catch (err) {
      throwError("Failed to GET image data", err)
    }
  } else {
    try {
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      })
      if (!response.ok) throw response
      const data = await response.blob()
      return data
    } catch (err) {
      throwError("Failed to GET image data", err)
    }
  }
}

// For PDF files
export async function arrayFetcher(endpoint: string) {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER
  const guestToken = window.sessionStorage.getItem("guestToken")
  const roomId = window.sessionStorage.getItem("roomId")

  const url = `${REACT_APP_API_SERVER}${endpoint}`
  const headers: any = guestToken
    ? {
        "x-access-token": guestToken,
        "x-room-id": roomId,
      }
    : null

  if (guestToken) {
    try {
      const response = await fetch(url, {
        method: "GET",
        ...(headers ? { headers } : {}),
        credentials: "include",
      })
      if (!response.ok) throw response
      const data = await response.arrayBuffer()
      return data
    } catch (err) {
      throwError("Failed to GET PDF data", err)
    }
  } else {
    try {
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      })
      if (!response.ok) throw response
      const data = await response.arrayBuffer()
      return data
    } catch (err) {
      throwError("Failed to GET PDF data", err)
    }
  }
}

// export async function arrayFetcher(endpoint) {
//   if (window.sessionStorage.getItem("guestToken") !== null) {
//     let headers: any = {
//       "x-access-token": sessionStorage.getItem("guestToken"),
//       "x-room-id": sessionStorage.getItem("roomId"),
//     }
//     return new Promise((resolve, reject) => {
//       fetch(`${import.meta.env.REACT_APP_API_SERVER}${endpoint}`, {
//         method: "GET",
//         headers: headers,
//         credentials: "include",
//       })
//         .then((res) => {
//           const data = res.arrayBuffer()
//           if (res.ok) {
//             resolve(data)
//           }
//           //but we also should reject if network
//           //succeeds but api returns >400 code
//           reject(data)
//         })
//         .catch((err) => {
//           // fetch api will error on network problems
//           reject(err)
//         })
//     })
//   } else {
//     return new Promise((resolve, reject) => {
//       fetch(`${window._env_.REACT_APP_API_SERVER}${endpoint}`, {
//         method: "GET",
//         credentials: "include",
//       })
//         .then((res) => {
//           const data = res.arrayBuffer()
//           if (res.ok) {
//             resolve(data)
//           }
//           //but we also should reject if network
//           //succeeds but api returns >400 code
//           reject(data)
//         })
//         .catch((err) => {
//           // fetch api will error on network problems
//           reject(err)
//         })
//     })
//   }
// }

// export async function poster(endpoint: string, body: {}) {
//   const url = `${REACT_APP_API_SERVER}${endpoint}`;
//   if (guestToken) {
//     try {
//       const response = await fetch(
//         url,
//         {
//           method: 'POST',
//           body: JSON.stringify(body),
//           headers,
//           credentials: 'include'
//         }
//       );
//       if (!response.ok) throw response;
//       const data = await response.json();
//       return data;
//     } catch (err) {
//       throwError('Failed to POST data', err);
//     }
//   } else {
//     try {
//       const response = await fetch(
//         url,
//         {
//           method: 'POST',
//           body: JSON.stringify(body),
//           credentials: 'include'
//         }
//       );
//       if (!response.ok) throw response;
//       const data = await response.json();
//       return data;
//     } catch (err) {
//       throwError('Failed to POST data', err);
//     }
//   }
// }

export async function poster(endpoint, body) {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER

  if (sessionStorage.getItem("guestToken")) {
    let headers: any = {
      "x-access-token": sessionStorage.getItem("guestToken"),
      "x-room-id": sessionStorage.getItem("roomId"),
      "Content-Type": "application/json",
    }
    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_API_SERVER}${endpoint}`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: headers,
        credentials: "include",
      })
        .then((res) => {
          const data = res.json()
          if (res.ok) {
            resolve(data)
          }
          //but we also should reject if network
          //succeeds but api returns >400 code
          reject(data)
        })
        .catch((err) => {
          // fetch api will error on network problems
          reject(err)
        })
    })
  } else {
    return new Promise((resolve, reject) => {
      fetch(`${REACT_APP_API_SERVER}${endpoint}`, {
        method: "POST",
        body: JSON.stringify(body),
        credentials: "include",
      })
        .then((res) => {
          const data = res.json()
          if (res.ok) {
            resolve(data)
          }
          //but we also should reject if network
          //succeeds but api returns >400 code
          reject(data)
        })
        .catch((err) => {
          // fetch api will error on network problems
          reject(err)
        })
    })
  }
}

// This is the func formerly known as regPoster
// Currently it is only being called to create an event in calendar.js
// So this name made more sense to me, but we can edit as needed
export async function calendarEventPoster(endpoint: string, body: {}) {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER
  const url = `${REACT_APP_API_SERVER}${endpoint}`

  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    if (!response.ok) throw response
    const data = await response.json()
    return data
  } catch (err) {
    throwError("Failed to POST event", err)
  }
}
