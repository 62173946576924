import { Event } from "@/TYPES/redux"
import moment from "moment"

export const getEventIsValid = (form: Event) => {
  const hasParticipants = form.participants?.length > 0
  const hasTitle = form.title?.length > 0
  const validTime = form.startTime < form.endTime
  const hasSpaceId = form.spaceId !== undefined

  return hasParticipants && hasTitle && validTime && hasSpaceId
}

export const getDateFromTime = (time: string, date: string) => {
  const hours = parseFloat(time.slice(0, time.indexOf(":")))
  const minutes = parseFloat(
    time.slice(time.indexOf(":") + 1, time.indexOf(" ")),
  )
  const meridiem = time.slice(time.indexOf(" ") + 1)

  return moment(date)
    .hours(meridiem === "AM" ? hours : hours + 12)
    .minutes(minutes)
    .toDate()
}
