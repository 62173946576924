import type { RootState } from "@/TYPES/redux"
import { SocketMessageType } from "@/TYPES/common"
import * as tf from "@tensorflow/tfjs"
import * as bodyPix from "@tensorflow-models/body-pix"
import { memo, useContext, useEffect, useState, useRef } from "react"
import { useSelector } from "react-redux"
import { SocketContext } from "@/context/socket"
import IconButton from "@mui/material/IconButton"
import Close from "@mui/icons-material/Close"
import Tooltip from "@mui/material/Tooltip"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"

const MAX_VIDEO_SIZE = 400
const MIN_VIDEO_SIZE = 200

export default function CanvasVideo(props: any) {
  const vidSocket = props.socket
  const socket = useContext(SocketContext)
  const canvas_ref = useRef<HTMLCanvasElement>(null)
  const [net, setNet] = useState<bodyPix.BodyPix>()
  const [status, setStatus] = useState({
    inited: false,
    loading: false,
    error: false,
    msg: "Initializing...",
  })
  const [videoSize, setVideoSize] = useState({
    width: MIN_VIDEO_SIZE,
    height: MIN_VIDEO_SIZE,
  })
  const [canvasStyleSize, setCanvasStyleSize] = useState({
    width: MIN_VIDEO_SIZE,
    height: MIN_VIDEO_SIZE,
  })
  const [forceHideOverlay, setForceHideOverlay] = useState(false)
  const [mouseEntered, setMouseEntered] = useState(false)
  const [videoMinimized, setVideoMinimized] = useState(false)
  const room_id = useSelector(
    (state: RootState) => state.room.data?._id || state.space.spaceId,
  )
  const user_id = useSelector((state: RootState) => state.user.data?._id)
  // As of 1/23/2024, only oroiginal owner can "present" which means this video is only triggered by original owner
  const isOriginalOwner = room_id === user_id

  async function initComp() {
    window.log("initComp()", "highlight")
    setForceHideOverlay(false)

    setStatus((prev) => ({
      ...prev,
      inited: false,
      loading: true,
      error: false,
      msg: "Initializing...",
    }))

    try {
      // socket.on(SocketMessageType.PresenterVideo, (payload) => {
      //   console.log(SocketMessageType.PresenterVideo, payload)
      //   renderCanvas(payload)
      // })
      // vidSocket.on(SocketMessageType.PresenterVideo, (payload) => {
      //   console.log(SocketMessageType.PresenterVideo, payload)
      //   renderCanvas(payload)
      // })
      vidSocket.on(SocketMessageType.PresenterVideo, (payload) => {
        //   setTimeout(() => {
        //     console.log(SocketMessageType.PresenterVideo, payload)
        //     renderCanvas(payload)
        //   }, 1)
        //   window.requestAnimationFrame(() => {
        //     console.log(SocketMessageType.PresenterVideo, payload)
        //     renderCanvas(payload)
        //   })
        window.requestIdleCallback(() => {
          console.log(SocketMessageType.PresenterVideo, payload)
          renderCanvas(payload)
        })
      })

      setStatus((prev) => ({
        ...prev,
        inited: true,
        loading: false,
        error: false,
      }))
    } catch (err: any) {
      console.error(err)
      setStatus({
        inited: false,
        loading: false,
        error: true,
        msg: err.message || err,
      })
    }
  }

  function renderCanvas(payload: any) {
    if (!payload || !payload.imageData) {
      console.error("SOMETHING VERY WRONG!")
      return
    }
    const canvas_el = canvas_ref.current
    const ctx = canvas_el?.getContext("2d") as any
    if (!canvas_el || !ctx) {
      console.error("CanvasVideo: Canvas not found", { canvas_el, ctx })
      return
    }

    const payloadRoom_id = payload.room_id
    const payloadUser_id = payload.user_id
    const payloadImageData = payload.imageData
    const payloadVideoSize = payload.videoSize
    const payloadCanvasStyleSize = payload.canvasStyleSize
    if (payloadRoom_id !== room_id) {
      console.error(
        `CanvasVideo: payloadRoom_id (${payloadRoom_id}) !== room_id (${room_id})`,
      )
      return
    }
    setVideoSize(payloadVideoSize)
    setCanvasStyleSize(payloadCanvasStyleSize)

    // Convert ArrayBuffer back to Uint8ClampedArray.
    const imageDataArrayBuffer = payloadImageData.data
    const imageDataArray = new Uint8ClampedArray(imageDataArrayBuffer)
    const imageData = new ImageData(
      imageDataArray,
      payloadVideoSize.width,
      payloadVideoSize.height,
    )
    ctx.putImageData(imageData, 0, 0)
  }

  function onMouseEnter(evt: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    setMouseEntered(true)
  }

  function onMouseLeave(evt: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    setMouseEntered(false)
  }

  function toggleVideoContainer() {
    setVideoMinimized((prev) => !prev)
  }

  function continueWithoutVideo() {
    setForceHideOverlay(true)
  }

  //   useEffect(() => {
  //     initComp()
  //     return () => {
  //       socket.off(SocketMessageType.PresenterVideo)
  //     }
  //   }, [])
  //   useEffect(() => {
  //     if (videoMinimized || !canvasLoaded) {
  //       socket.off(SocketMessageType.PresenterVideo)
  //       return
  //     }
  //     initComp()
  //   }, [videoMinimized, canvasLoaded])
  useEffect(() => {
    if (!videoMinimized && canvas_ref.current) {
      initComp()
    }
    return () => {
      vidSocket.off(SocketMessageType.PresenterVideo)
    }
  }, [videoMinimized, canvas_ref.current]) // Include canvas_ref.current in dependencies

  const showOverlay =
    (!status.inited || status.loading || status.error) &&
    !forceHideOverlay &&
    !videoMinimized
  const showHeader =
    !forceHideOverlay && ((!showOverlay && mouseEntered) || videoMinimized)

  return (
    <>
      <div
        className="presenter-video-overlay"
        style={{
          display: showOverlay ? "flex" : "none",
          position: "absolute",
          width: "100%",
          height: "100%",
          top: "0",
          left: "0",
          zIndex: 20,
          backgroundColor: "#181818",
          justifyContent: "center",
          alignItems: "center",
          color: status.error ? "red" : "white",
          userSelect: "none",
        }}
      >
        <div
          className="presenter-video-overlay-close-btn"
          style={{
            display: status.error ? "flex" : "none",
            position: "absolute",
            top: "0",
            right: "0",
            width: "min-content",
            height: "min-content",
            backgroundColor: "transparent",
            zIndex: 1,
            userSelect: "none",
          }}
          onClick={continueWithoutVideo}
        >
          <IconButton color="error">
            <Close />
          </IconButton>
        </div>
        <div>{status.msg}</div>
      </div>

      <div
        className="presenter-video-container"
        style={{
          width: videoMinimized ? "40px" : "min-content",
          height: "auto",
          backgroundColor: "transparent",
          userSelect: "none",
          border: showHeader ? "1px solid rgba(108, 122, 137, 0.9)" : "none",
          borderRadius: "2px",
          zIndex: videoMinimized ? 1000 : 0,
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div
          className="presenter-video-container-header"
          style={{
            display: showHeader ? "flex" : "none",
            flexDirection: "row",
            width: "100%",
            height: "min-content",
            backgroundColor: "rgba(20, 20, 20, 0.9)",
            justifyContent: "flex-end",
          }}
        >
          <Tooltip
            title={
              videoMinimized
                ? "Show presentor's video"
                : "Hide presenter's video"
            }
            placement="top"
            color="info"
          >
            <IconButton
              color="info"
              size="small"
              onClick={toggleVideoContainer}
            >
              {videoMinimized ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Tooltip>
        </div>
        <canvas
          ref={canvas_ref}
          className="presenter-video-canvas"
          width={videoSize.width}
          height={videoSize.height}
          style={{
            width: `${canvasStyleSize.width}px`,
            height: videoMinimized ? `0px` : `${canvasStyleSize.height}px`,
            // userSelect: "none",
            // transition: "all 0.2s ease-in-out",
          }}
        />
      </div>
    </>
  )
}

//////////////////////////////////
//////////////////////////////////
//////////////////////////////////
