import { RootState } from "@/TYPES/redux"
import { depositAssetLocker, updateTiles, uploadAsset } from "@/helpers"
import { assignIsAddingFile } from "@/store/slices/spaceSlice"
import { useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"

type Props = {
  children: (upload: (files: any[]) => void, uploading: boolean) => JSX.Element
}

export default function AddFileWrapper({ children }: Props) {
  const dispatch = useDispatch()

  const teamId = useSelector((state: RootState) => state.room.data?.teamId)
  const roomId = useSelector(
    (state: RootState) => state.room.data?._id || state.space.spaceId || "",
  )

  const largeTiles = useSelector(
    (state: RootState) => state.room.data?.scene2d?.dataTile00,
  )
  const smallTiles = useSelector(
    (state: RootState) => state.room.data?.scene2d?.dataTile01,
  )

  const [uploading, setUploading] = useState(false)

  const throwError = useCallback((message: string) => {
    throw new Error(message)
  }, [])

  async function handleFileUpload(files: any[]) {
    if (!largeTiles && !smallTiles) {
      throwError("Something went wrong")
    }

    if (!teamId) {
      throwError("No associated team found")
    }

    if (files.length > 1) {
      throwError("Only one file can be uploaded at a time")
    }

    const allowedFileTypes = [
      "application/pdf",
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/gif",
    ]

    const file = files[0]
    if (!file) {
      throwError("No file found to upload")
    }
    // Validate file extension against its MIME type
    if (!allowedFileTypes.includes(file.type)) {
      throwError("Please upload a valid PDF, GIF, or image file")
    }

    const addingToast = toast(`Adding a file, "${file.name}"`, {
      toastId: `adding-file-${file.name}`,
      autoClose: false,
      closeOnClick: false,
      theme: "dark",
    })

    try {
      dispatch(assignIsAddingFile(true))
      setUploading(true)
      console.log(`Uploading file ${file.name} to SFTP...`)
      const uploadResult: any = await uploadAsset(teamId, file)
      if (!uploadResult || !uploadResult.status) {
        const msg = `Error uploading file to SFTP: ${uploadResult?.msg}`
        console.error(msg)
        throwError("Error uploading file to SFTP")
      }

      console.log(`Adding file record to database...`)
      const addDbResult: any = await depositAssetLocker(
        teamId,
        roomId,
        file.name,
      )
      if (!addDbResult || !addDbResult.data) {
        console.warn("addDbResult", addDbResult)
        throwError("Error adding file record to database")
      }

      const addedFileTile = addDbResult.data
      const newSmallTiles = Array.isArray(addedFileTile)
        ? [...addedFileTile, ...(smallTiles || [])]
        : [addedFileTile, ...(smallTiles || [])]

      await updateTiles(roomId, newSmallTiles, largeTiles)
    } catch (error: any) {
      toast.error(
        `Failed to add file: ${error.msg || error.message || "Server Error"}`,
        {
          autoClose: 4000,
          style: {
            width: "fit-content",
            whiteSpace: "pre-wrap",
          },
        },
      )
      throwError("500")
    } finally {
      dispatch(assignIsAddingFile(false))
      setUploading(false)
    }

    toast.dismiss(addingToast)
  }

  return <>{children(handleFileUpload, uploading)}</>
}
