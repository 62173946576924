import { IconNameProp } from "@/components/Icon/types"
import { SocketMessageType } from "@/TYPES/common"
import { socketConnection as socket } from "@/context/socket"
import { getRoom } from "@/helpers"
import $store from "@/store"
import { toast } from "react-toastify"
import useSWR from "swr"
import { v4 as uuidv4 } from "uuid"
import {
  arrayFetcher,
  blobFetcher,
  fetcher,
  poster,
  throwError,
} from "./fetcher"
import { SWRHelper } from "./swr"
import validator from "validator"
const { isURL } = validator

export async function updateTiles(
  roomId: string,
  newSmall: { [key: string]: any }[],
  newLarge?: { [key: string]: any }[],
) {
  console.log("updateTiles()", { roomId, newSmall, newLarge })

  newSmall = newSmall.map((tile) => {
    if (!tile._id) {
      const tileClone = JSON.parse(JSON.stringify({ ...tile }))
      tileClone._id = uuidv4()
      return tileClone
    }
    return tile
  })
  newLarge = newLarge?.map((tile) => {
    if (!tile._id) {
      const tileClone = JSON.parse(JSON.stringify({ ...tile }))
      tileClone._id = uuidv4()
      return tileClone
    }
    return tile
  })

  const endpoint = `/api/me/tiles/${roomId}/updateTiles`
  const body = {
    dataTile00: newLarge || [],
    dataTile01: newSmall || [],
  }
  console.log(
    "updateTiles roomId:",
    roomId,
    "newLarge:",
    newLarge,
    "newSmall:",
    newSmall,
  )

  try {
    $store.dispatch({
      type: "room/assignScene2d",
      payload: body,
    })
    const result = await poster(endpoint, body)
    console.log("updateTiles result:", result)

    const userId = $store.getState?.().user?.data?._id
    if (userId) {
      socket?.emit?.(SocketMessageType.GetRoom, {
        senderId: userId,
        roomId,
      })
    }

    return Promise.resolve(body)
  } catch (err: any) {
    console.error("updateTiles err:", err)
    throwError("Failed to update tiles", err)
    toast.error(
      `Failed to update tiles: ${err.msg || err.message || "Server Error"}`,
      {
        toastId: "update-tiles",
        autoClose: 4000,
        style: {
          width: "fit-content",
          whiteSpace: "pre-wrap",
        },
      },
    )
    return Promise.reject(err)
  } finally {
    //  window.setTimeout(() => {
    getRoom(roomId)
    //  console.log("$store", $store)
    //  const userId = $store.getState?.().user?.data?._id
    //  if (!userId) return
    //  socket?.emit?.("get-room", {
    //    senderId: userId,
    //    roomId,
    //  })
    //  }, 1000)
  }
}

// export function updateTiles(roomId: any, newSmall: any, newLarge: any) {
//   const body = {
//     dataTile00: newLarge,
//     dataTile01: newSmall,
//   }
//   poster(`/api/me/tiles/${roomId}/updateTiles`, body).then(() => {
//     return
//   })
// }

// export async function updatePdfPage(
//   spaceId: string,
//   roomId: string,
//   filename: string,
//   newPageNum: number,
// ) {
//   const url = `/api/teams/assetLocker/${spaceId}/${roomId}/${filename}/updatePage`
//   const body = {
//     newPage: newPageNum,
//   }

//   try {
//     await poster(url, body)
//     getRoom(roomId)
//   } catch (err) {
//     throwError("Failed to update PDF page #", err)
//   }
// }
export function updatePdfPage(teamId, roomId, fileName, newPage) {
  const body = {
    newPage: newPage,
  }

  poster(
    `/api/teams/assetLocker/${teamId}/${roomId}/${fileName}/updatePage`,
    body,
  ).then(() => {
    return
  })
}

interface UpdatePDFPageParam {
  roomId: string
  teamId: string
  tileId: string
  pageNum: number
}
// export async function updatePDFPageNum(param: UpdatePDFPageParam) {
//   if (
//     !param ||
//     !param.roomId ||
//     !param.teamId ||
//     !param.tileId ||
//     !param.pageNum
//   ) {
//     const msg = `No param or param.roomId or param.teamId or param.tileId or param.pageNum found`
//     console.error(msg)
//     return
//   }
//   const roomId = param.roomId
//   const teamId = param.teamId
//   const tileId = param.tileId
//   const pageNum = param.pageNum

//   try {
//     const resopnse = await fetch(
//       `${
//         import.meta.env.REACT_APP_API_SERVER
//       }/api/teams/assetlocker/${teamId}/${roomId}/${tileId}/update-page`,
//       {
//         method: "POST",
//         credentials: "include",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           pageNum,
//           roomId,
//           teamId,
//           tileId,
//         }),
//       },
//     )
//   } catch (error) {
//     console.error(error)
//   }
// }

// export function useDefaultAsset(
//   spaceId: string,
//   roomId: string,
//   filename: string,
// ) {
//   const url = `/api/teams/assetlocker/${spaceId}/${roomId}/${filename}/withdraw`
//   SWRHelper(url, fetcher)
// }

export function useDefaultAsset(teamId, roomId, fileName) {
  const { data, error } = useSWR(
    `/api/teams/assetlocker/${teamId}/${roomId}/${fileName}/withdraw`,
    fetcher,
  )
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  }
}

interface GetAssetParam {
  teamId: string
  roomId: string
  fileName: string
}
export async function getAsset(param: GetAssetParam): Promise<any> {
  console.log("[getAsset()]", param)
  const teamId = param.teamId
  const roomId = param.roomId
  const fileName = param.fileName
  if (!teamId || !roomId || !fileName) {
    const msg = `No teamId or roomId or fileName found`
    console.error(msg)
    return Promise.reject(msg)
  }

  const fetchEndpoint = `/api/teams/assetlocker/${teamId}/${roomId}/${fileName}/withdraw`

  try {
    const fetcherResult = await fetcher(fetchEndpoint)
    console.log("getAsset() fetcherResult:", fetcherResult)
    return Promise.resolve(fetcherResult)
  } catch (error) {
    console.error(`getAsset() ERROR - ${error}`)
    return Promise.reject(error)
  }
}

// export function useImageAsset(
//   spaceId: string,
//   roomId: string,
//   filename: string,
// ) {
//   const url = `/api/teams/assetlocker/${spaceId}/${roomId}/${filename}/withdraw`
//   SWRHelper(url, blobFetcher)
// }

export function useImageAsset(teamId, roomId, fileName) {
  const { data, error } = useSWR(
    `/api/teams/assetlocker/${teamId}/${roomId}/${fileName}/withdraw`,
    blobFetcher,
  )
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  }
}

// Formerly known as usePortableDocumentAsset
export function usePDFAsset(teamId: string, roomId: string, filename: string) {
  const url = `/api/teams/assetlocker/${teamId}/${roomId}/${filename}/withdraw`
  SWRHelper(url, arrayFetcher)
}

export function usePortableDocumentAsset(
  teamId: any,
  roomId: any,
  fileName: any,
) {
  const { data, error } = useSWR(
    `/api/teams/assetlocker/${teamId}/${roomId}/${fileName}/withdraw`,
    arrayFetcher,
  )
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  }
}

interface GetPDFDataParam {
  teamId: string
  roomId: string
  fileName: string
}
export async function getPDFData(param: GetPDFDataParam): Promise<any> {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER

  console.log("getPDFData()", { param })
  if (!param || !param.teamId || !param.roomId || !param.fileName)
    return Promise.reject(
      "No param or param.teamId or param.roomId or param.fileName found",
    )

  const fetchURL = `${REACT_APP_API_SERVER}/api/teams/assetlocker/${param.teamId}/${param.roomId}/${param.fileName}/withdraw`

  try {
    const guestToken = window.sessionStorage.getItem("guestToken")
    const roomId = param.roomId
    const response = await fetch(fetchURL, {
      method: "GET",
      credentials: "include",
      headers: {
        //   "Content-Type": "application/json",
        "x-access-token": guestToken || "",
        "x-room-id": roomId || "",
      },
    })

    const data = await response.arrayBuffer()
    console.log("getPDFData() data:", data)
    return Promise.resolve(data)
  } catch (err: any) {
    console.error(err)
    return Promise.reject(err.toString())
  }
}

export async function getVNCAsset(roomId: string, requestedUrl: string) {
  const url = "/api/cobrowse/init"
  const body = {
    roomId,
    requestedUrl,
  }

  try {
    const response: any = await poster(url, body)
    if (!response.ok) throw response
    return response
  } catch (err) {
    throwError("Failed to get VNC asset", err)
  }
}

export async function destroyVNCAsset(roomId: string, horusSerial: string) {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER
  const url = `${REACT_APP_API_SERVER}/api/cobrowse/stop/${roomId}/${horusSerial}`
  const body: RequestInit = {
    method: "DELETE",
    credentials: "include",
  }

  try {
    const response = await fetch(url, body)
    if (!response.ok) throw response
    const data = await response.json()
    return data
  } catch (err) {
    throwError("Failed to destroy VNC asset", err)
  }
}

interface DeleteTileParams {
  roomId?: string
  tileId: string
}
export async function deleteTile(param: DeleteTileParams) {
  console.log("deleteTile()", { param })
  const roomData = $store.getState()?.room?.data
  const spaceId = $store.getState()?.space?.spaceId
  const scene2d = roomData?.scene2d
  if (!scene2d || !param) return Promise.reject("No param or scene2d found")

  const tileId = param?.tileId
  const roomId = param?.roomId || roomData?._id || spaceId
  if (!tileId || !roomId) return Promise.reject("No tileId or roomId found")

  const largeTiles = scene2d.dataTile00
  const smallTiles = scene2d.dataTile01

  const newScene2d = {
    largeTiles,
    smallTiles,
  }

  let foundTile = null
  //@@ Check if deleting tile is from large @@//
  if (largeTiles && largeTiles.length > 0 && largeTiles[0]?._id === tileId) {
    foundTile = JSON.parse(JSON.stringify(largeTiles[0]))
    //  newScene2d.largeTiles = largeTiles.slice(1)
    newScene2d.largeTiles = []
  }

  //@@ Check if deleting tile is from small @@//
  const foundSmallTile = smallTiles.find((tile) => tile._id === tileId)
  if (foundSmallTile) {
    foundTile = JSON.parse(JSON.stringify(foundSmallTile))
    newScene2d.smallTiles = smallTiles.filter((tile) => tile._id !== tileId)
    console.log(foundTile)
  }

  if (!foundTile) return Promise.reject("No target tile found")

  try {
    const result = await updateTiles(
      roomId,
      newScene2d.smallTiles,
      newScene2d.largeTiles,
    )
    return Promise.resolve(result)
  } catch (err: any) {
    console.error("Failed to delete tile", err.message || err)
    return Promise.reject(err.message || err)
  }
}

export async function checkGoogleAvailability(): Promise<boolean> {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    window.env?.REACT_APP_API_SERVER ||
    window._env_?.REACT_APP_API_SERVER ||
    env.REACT_APP_API_SERVER ||
    import.meta.env.REACT_APP_API_SERVER

  const fetchURL = `${REACT_APP_API_SERVER}/v2/google/status`
  try {
    const response = await fetch(fetchURL, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
    const result = await response.json()
    if (!result.status) return Promise.resolve(false)
    return Promise.resolve(true)
  } catch (err) {
    return Promise.resolve(false)
  }
}

export async function getTileFaviconURL(tile: any): Promise<any> {
  const GOOGLE_FAVICON_API = "https://www.google.com/s2/favicons"
  const GOOGLE_FAVICON_SIZE = 16
  const FALLBACK_FAVICON_URL = "/assets/favicon.ico"

  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    window.env?.REACT_APP_API_SERVER ||
    window._env_?.REACT_APP_API_SERVER ||
    env.REACT_APP_API_SERVER ||
    import.meta.env.REACT_APP_API_SERVER

  if (!tile || !tile.src) {
    return Promise.resolve(FALLBACK_FAVICON_URL)
  }
  try {
    const src = tile.cobrowseSrc ?? tile.src
    const isGoogleAvailable = await checkGoogleAvailability()
    if (isGoogleAvailable && GOOGLE_FAVICON_API) {
      const faviconURL = `${GOOGLE_FAVICON_API}?domain=${src}&sz=${
        GOOGLE_FAVICON_SIZE || 16
      }`
      return Promise.resolve(faviconURL)
    }

    const fetchURL = `${REACT_APP_API_SERVER}/v2/site/favicon`
    const response = await fetch(fetchURL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ url: src }),
    })
    const result = await response.json()
    if (!result.status) return Promise.resolve(FALLBACK_FAVICON_URL)

    const hrefURL = result.data
    return Promise.resolve(hrefURL)
  } catch (err: any) {
    console.error(err.message || err)
    return Promise.reject(FALLBACK_FAVICON_URL)
  }
}

interface GetSiteResponseParam {
  url: string
}
export async function getSiteResponse(param: GetSiteResponseParam) {
  const url = param?.url
  if (!url) return Promise.reject("No URL passed as param")

  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    window.env?.REACT_APP_API_SERVER ||
    window._env_?.REACT_APP_API_SERVER ||
    env.REACT_APP_API_SERVER ||
    import.meta.env.REACT_APP_API_SERVER

  const fetchURL = `${REACT_APP_API_SERVER}/v2/site/response`
  try {
    const response = await fetch(fetchURL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ url }),
    })
    const result = await response.json()
    console.log("result", result)
    return Promise.resolve(result)
  } catch (err) {
    return Promise.reject(err)
  }
}
export const getIconNameProp = (fileName: string | undefined) => {
  const isIframe = fileName === "iframe"
  const isImg =
    fileName?.endsWith(".png") ||
    fileName?.endsWith(".jpg") ||
    fileName?.endsWith(".jpeg") ||
    fileName?.endsWith(".gif")
  const isPdf = fileName?.endsWith(".pdf")
  const isRdp = fileName?.endsWith(".rdp")

  let iconName = IconNameProp.FileApp
  if (isIframe || isRdp) {
    iconName = IconNameProp.Internet
  }
  if (isImg) {
    iconName = IconNameProp.FileJpg
  }
  if (isPdf) {
    iconName = IconNameProp.FilePdf
  }

  return iconName
}
