import { ChildrenType } from "@/TYPES/common"
import Icon, { IconNameProp } from "@/components/Icon"
import Topbar from "@/components/Topbar"
import { useMyUser } from "@/helpers/user"
import { useSelectViewport } from "@/utils/hooks/viewport"
import cn from "classnames"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Navbar from "./Navbar"
import Searchbox from "./Searchbox"

interface Props {
  query?: string
  title: ChildrenType
  onSearch: (value: string) => void
  onBack?: () => void
  children: ChildrenType
}

export default function PlatformLayout({
  query,
  title,
  onSearch,
  onBack,
  children,
}: Props) {
  const navigate = useNavigate()
  const { isPhone } = useSelectViewport()

  const { data } = useMyUser(true)

  useEffect(() => {
    if (!data) {
      navigate("/")
    }
  }, [data])

  return (
    <>
      <Topbar>
        <>
          {isPhone && (
            <div className="flex items-center">
              {onBack && (
                <Icon
                  className="transform rotate-180 fill-typegray"
                  iconName={IconNameProp.Chevron}
                  onClick={onBack}
                  size={48}
                />
              )}
              {title}
            </div>
          )}

          <Searchbox value={query} onChange={onSearch} />
        </>
      </Topbar>
      <div
        className={cn(
          "relative flex-1",
          "flex items-stretch",
          "sm:flex-col-reverse",
        )}
      >
        <Navbar />
        <div className="relative flex-1 flex items-strech gap-px">
          {children}
        </div>
      </div>
    </>
  )
}
