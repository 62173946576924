import { useSelector } from "react-redux"
import SvgArrow from "../../assets/cursors/SvgArrow"
import { RootState } from "@/TYPES/redux"

const defaultSize = 30

// type to prevent error for props
type CursorProps = {
  color?: string
  icon?: string
  width?: string
  height?: string
  fill?: string
  type?: string
  size?: number
}

export default function CursorSVG(props: CursorProps = {}) {
  const ENV = import.meta.env
  const CURSOR_ICON = useSelector((state: RootState) => state.env.REACT_APP_CURSOR_ICON) || ENV.CURSOR_ICON
  const CURSOR_SIZE = useSelector((state: RootState) => state.env.REACT_APP_CURSOR_SIZE) || ENV.CURSOR_SIZE
  const CURSOR_WIDTH = useSelector((state: RootState) => state.env.REACT_APP_CURSOR_WIDTH) || ENV.CURSOR_WIDTH
  const CURSOR_HEIGHT = useSelector((state: RootState) => state.env.REACT_APP_CURSOR_HEIGHT) || ENV.CURSOR_HEIGHT
  const color = props.color || props.fill || "#000000"
  const icon = (
    props.icon ||
    props.type ||
    CURSOR_ICON ||
    "arrow"
  ).toLowerCase()
  const width =
    props.width ||
    parseSize(CURSOR_WIDTH) ||
    parseSize(CURSOR_SIZE) ||
    defaultSize
  const height =
    props.height ||
    parseSize(CURSOR_HEIGHT) ||
    parseSize(CURSOR_SIZE) ||
    defaultSize

  if (icon === "arrow") return <SvgArrow {...props} />

  // fallback to arrow
  return <SvgArrow {...props} />
}

function parseSize(val) {
  if (!val) return
  const num = +val
  if (isNaN(num)) return
  if (num < 1) return
  return num
}
