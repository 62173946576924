export enum IconNameProp {
  AddFile = "add-file",
  Add = "add",
  Annotate = "annotate",
  Apps = "apps",
  Calendar = "calendar",
  Channel = "channel",
  Chat = "chat",
  Check = "check",
  Chevron = "chevron",
  Close = "close",
  Cog = "cog",
  Copy = "copy",
  Csv = "csv",
  Edit = "edit",
  Eraser = "eraser",
  Files = "files",
  FileApp = "file-app",
  FileJpg = "file-jpg",
  FilePdf = "file-pdf",
  Folder = "folder",
  FullScreen = "fullscreen",
  HangUp = "hang-up",
  Home = "home",
  Hotkeys = "hotkeys",
  Internet = "internet",
  Invite = "invite",
  Image = "img",
  Info = "info",
  Leave = "leave",
  Link = "link",
  Lock = "lock",
  Magic = "magic",
  Mute = "mute",
  NewChat = "new-chat",
  Open = "open",
  Options = "options",
  Portal = "portal",
  Pdf = "pdf",
  Presentation = "presentation",
  Provisions = "provisions",
  Record = "record",
  Send = "send",
  Settings = "settings",
  Share = "share",
  ShareCobrowseControl = "sharecobrowsecontrol",
  ShareControl = "sharecontrol",
  Shield = "shield",
  Spaces = "spaces",
  ThreeD = "3d",
  Trash = "trash",
  Unmute = "unmute",
  User = "user",
  VideoOn = "video-on",
  Video = "video",
}
