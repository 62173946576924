import Calendar from "@/components/Calendar"
import PlatformLayout from "../PlatformLayout"

export default function Calendars() {
  return (
    <PlatformLayout title="Calendar" query="" onSearch={() => {}}>
      <Calendar />
    </PlatformLayout>
  )
}
