import { SocketContext } from "@/context/socket"
import { updateDisplayName } from "@/store/slices/userSlice"
import { SocketMessageType } from "@/TYPES/common"
import { RootState } from "@/TYPES/redux"
import cn from "classnames"
import { useContext, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"

type Props = {
  spaceId: string
  videoOff: boolean
  audioOff: boolean
  redirectToAfterSignIn: string
  setLoadingMessage: (value: string) => void
}

export default function GuestJoinSpace({
  spaceId,
  videoOff,
  audioOff,
  redirectToAfterSignIn,
  setLoadingMessage,
}: Props) {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")

  const intervalRef = useRef<NodeJS.Timeout>()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const socket = useContext(SocketContext)

  const serverError = useSelector((state: RootState) => state.init.serverError)
  const user = useSelector((state: RootState) => state.user)

  const fullName = `${firstName} ${lastName}`
  const disabled = !firstName?.trim() || !lastName?.trim()

  useEffect(() => {
    //@@ Will trigger for guest after allowing to join room @@//
    socket.on(SocketMessageType.GuestToken, function (message) {
      clearInterval(intervalRef.current)
      if (message.message.admit === false) {
        setLoadingMessage("Denied")
        intervalRef.current = undefined

        setTimeout(() => {
          setLoadingMessage("")
        }, 1500)
      } else {
        setLoadingMessage("Admitted")
        window.sessionStorage.setItem("guestToken", message.message.guestToken)
        console.log(
          "sessionStorage guestToken set!",
          window.sessionStorage.getItem("guestToken"),
        )
        window.sessionStorage.setItem("roomId", spaceId)
        const queryParams = new URLSearchParams({
          audio: (!audioOff).toString(),
          video: (!videoOff).toString(),
          audioSource: user.audioDevice.id ?? "default",
          videoSource: user.videoDevice.id ?? "default",
          displayName: fullName,
        })
        navigate(
          {
            pathname: `/space/${spaceId}`,
            search: queryParams.toString(),
          },
          { state: { skipRedirect: true } },
        )
      }
    })

    window.addEventListener("beforeunload", handleLeave)

    return () => {
      socket.off(SocketMessageType.GuestToken)
      window.removeEventListener("beforeunload", handleLeave)
    }
  }, [])

  const handleSubmit = (e: any) => {
    e.preventDefault()

    if (disabled) {
      console.error(`GUEST MUST HAVE BOTH FIRST AND LAST NAME`)
      return
    }

    dispatch(updateDisplayName(fullName))

    window.sessionStorage.setItem("guestName", fullName)
    window.sessionStorage.setItem("guestFirstName", firstName)
    window.sessionStorage.setItem("guestLastName", lastName)

    socket.emit(SocketMessageType.GuestLogin, { name: fullName })
    intervalRef.current = setInterval(() => {
      socket.emit(SocketMessageType.GuestLogin, { name: fullName })
    }, 7500)

    setLoadingMessage("Waiting to be admitted...")
  }

  const handleLeave = () => {
    const name = window.sessionStorage.getItem("guestName")
    socket.emit(SocketMessageType.LeaveGuestQueue, {
      name,
      socketId: socket.id,
    })
  }

  return (
    <div className="w-full h-full">
      <form className="w-full flex flex-col" onSubmit={handleSubmit}>
        <h3 className="text-28 font-light text-center mb-8">Join as Guest</h3>

        <div
          className={cn(
            "h-[54px] flex items-stretch mb-4 gap-2",
            "sm:h-[46px]",
          )}
        >
          <input
            className={cn(
              "flex-grow w-0 outline-none bg-transparent px-4",
              "border-b-[0.5px] border-carbon focus:border-white focus:placeholder-white",
              "text-20 text-white font-light opacity-80",
            )}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="First Name"
          />
          <input
            className={cn(
              "flex-grow w-0 outline-none bg-transparent px-4",
              "border-b-[0.5px] border-carbon focus:border-white focus:placeholder-white",
              "text-20 text-white font-light opacity-80",
            )}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Last Name"
          />
        </div>

        <button
          type="submit"
          className={cn(
            "h-[54px] text-20 rounded-sm px-16 py-2",
            "disabled:bg-transparent disabled:opacity-100 disabled:cursor-not-allowed disabled:text-carbon disabled:border-[0.5px]",
            "sm:h-[46px] sm:text-16",
            {
              "text-carbon bg-accentyellow hover:opacity-80": !serverError,
              "text-ghostgray bg-accentred cursor-not-allowed opacity-80":
                serverError,
            },
          )}
          disabled={disabled}
        >
          Ask To Join
        </button>
      </form>
      {serverError && (
        <div className="self-center text-center pt-4">
          Internal Server Error: Try refreshing the page.
          <br />
          If the problem persists, contact your administrator.
        </div>
      )}
    </div>
  )
}
