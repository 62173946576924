import { RootState } from "@/TYPES/redux"
import Icon, { IconNameProp } from "@/components/Icon"
import {
  closeFolder,
  openFolder,
  selectFolder,
} from "@/store/slices/filesSlice"
import { useSelectViewport } from "@/utils/hooks/viewport"
import cn from "classnames"
import { useDispatch, useSelector } from "react-redux"

type Props = {
  className?: string
  folder: any
  viewMode: string
  narrow?: boolean
  darkMode?: boolean
  parentIds?: string[]
}

export default function FolderItem({
  folder,
  parentIds = [],
  viewMode,
  narrow,
  darkMode,
}: Props) {
  const { isPhone } = useSelectViewport()
  const dispatch = useDispatch()

  const { openFolderIds, selectedIds } = useSelector(
    (state: RootState) => state.files,
  )

  const isOpened = openFolderIds.includes(folder.id)

  const subs = folder.files.filter((item) => item.type === "folder")

  const toggleFolder = (e: any) => {
    e.stopPropagation()

    if (subs.length === 0) {
      return
    } else if (isOpened) {
      dispatch(closeFolder(folder.id))
    } else {
      dispatch(openFolder(folder.id))
    }
  }

  const handleSelect = () => {
    dispatch(selectFolder([...parentIds, folder.id]))
  }

  const isSelected = selectedIds.includes(folder.id)
  const isTileMode = viewMode === "tile"
  return (
    <>
      <div
        className={cn("flex items-center px-[22px] cursor-default", {
          "h-[70px]": !isTileMode && !narrow,
          "h-[48px]": !isTileMode && narrow,
          "h-[120px] w-1/2 justify-center": isTileMode,
          "bg-whitegray": isSelected,
        })}
        onClick={handleSelect}
      >
        {!isTileMode && <div style={{ minWidth: 30 * parentIds.length }} />}

        <div
          className={cn("flex items-center gap-[11px]", {
            "flex-col": isTileMode,
          })}
        >
          {!isPhone && (
            <>
              {subs.length > 0 ? (
                <Icon
                  className={cn("cursor-pointer", {
                    "rotate-90": isOpened,
                    "fill-pearl": darkMode,
                  })}
                  iconName={IconNameProp.Chevron}
                  size={10}
                  onClick={toggleFolder}
                />
              ) : (
                <div className="min-w-[10px]" />
              )}
            </>
          )}

          <Icon iconName={IconNameProp.Folder} size={!narrow ? 60 : 30} />

          <span
            className={cn("whitespace-nowrap", {
              "font-bold text-20": !narrow,
              "text-14": narrow,
              "text-pearl": darkMode,
            })}
          >
            {folder.name}
          </span>
        </div>
      </div>

      {isOpened &&
        subs.map((item) => (
          <FolderItem
            key={item.id}
            folder={item}
            parentIds={[...parentIds, folder.id]}
            viewMode={viewMode}
            narrow={narrow}
            darkMode={darkMode}
          />
        ))}
    </>
  )
}
