import { CurrentJoinType, SocketState } from "@/TYPES/redux"
import { createSlice } from "@reduxjs/toolkit"

const INITIAL_STATE: SocketState = {
  mySocketId: "",
  socketJoinPayload: null,
  currentJoins: null,
  guestJoins: [],
  userJoins: [],
}

export const socketSlice = createSlice({
  name: "socket",
  initialState: INITIAL_STATE,
  reducers: {
    assignMySocketId: (state, action) => {
      return {
        ...state,
        mySocketId: action.payload,
      }
    },
    assignSocketJoinPayload: (state, action) => {
      return {
        ...state,
        socketJoinPayload: action.payload,
      }
    },
    assignCurrentJoins: (state, action: { payload: CurrentJoinType }) => {
      //!! do not use !!//
      const payload: CurrentJoinType = action.payload
      if (!payload) return state
      return {
        ...state,
        currentJoins: {
          ...payload,
        } as CurrentJoinType,
      } as SocketState
    },
    addCurrentJoin: (state, action: { payload: SocketJoinEventPayload }) => {
      const payload: SocketJoinEventPayload = action.payload
      if (!payload.socketId) return state

      const newCurrentJoins = {
        ...state.currentJoins,
        [payload.socketId]: { ...payload },
      }

      const newGuestJoins = Object.values(newCurrentJoins).filter(
        (join: any) =>
          join.permissionLevel === "guest" || !join.permissionLevel,
      )
      const newUserJoins = Object.values(newCurrentJoins).filter(
        (join: any) => join.permissionLevel !== "guest",
      )

      const finalState: SocketState = {
        ...state,
        currentJoins: newCurrentJoins,
        userJoins: newUserJoins,
        guestJoins: newGuestJoins,
      }
      return finalState
    },
    removeCurrentJoin: (state, action: { payload: { socketId: string } }) => {
      const payload = action.payload
      if (!payload.socketId || !state.currentJoins) return state
      const socketId = payload.socketId

      // Destructure the currentJoins object to separate the entry to remove and the rest.
      const { [socketId]: _, ...restJoins } = state.currentJoins
      const newCurrentJoins = restJoins

      const newGuestJoins = Object.values(newCurrentJoins).filter(
        (join: any) =>
          join.permissionLevel === "guest" || !join.permissionLevel,
      )
      const newUserJoins = Object.values(newCurrentJoins).filter(
        (join: any) => join.permissionLevel !== "guest",
      )

      // Update the state with the remaining joins.

      const finalState: SocketState = {
        ...state,
        currentJoins: newCurrentJoins,
        userJoins: newUserJoins,
        guestJoins: newGuestJoins,
      }
      return finalState
    },
  },
})

export const {
  assignMySocketId,
  assignSocketJoinPayload,
  assignCurrentJoins,
  addCurrentJoin,
  removeCurrentJoin,
} = socketSlice.actions
export default socketSlice.reducer
