import { SocketContext } from "@/context/socket"
import { SocketMessageType } from "@/TYPES/common"
import { RootState } from "@/TYPES/redux"
import { Excalidraw, getSceneVersion, MainMenu } from "@excalidraw/excalidraw"
import { useContext, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"

function AnnotationCanvas(props) {
  // Listen to annotations channel for sockets
  const socket = useContext(SocketContext)

  // Manage the local canvas state
  const [canvasState, setCanvasState] = useState([])
  const canvasStateRef = useRef(canvasState)

  // Set the initial state for the canvas
  const initialState = useRef(canvasState)

  // Set the scene version and drag state
  const [sceneVer, setSceneVer] = useState(0)
  const [dragState, setDragState] = useState(false)

  // DEMO workarounds
  const [action, setAction] = useState("")
  const [curEditor, setCurEditor] = useState("")
  const [lastShown, setLastShown] = useState("")

  // tie user data to annotations
  const user = useSelector((state: RootState) => state.user)

  const guestFullName = window.sessionStorage.getItem("guestName")

  const excalidrawRef = useRef()
  canvasStateRef.current = canvasState

  // Handle broadcasting to the canvas
  useEffect(() => {
    socket.on(SocketMessageType.AnnotationCanvas, (data) => {
      const timeout = setTimeout(() => {
        const curUser = user.guest ? guestFullName : user?.name
        const { e, action, editor } = data.message

        if (action === "draw" && editor !== curUser) {
          // @ts-ignore
          excalidrawRef.current.updateScene({ elements: e })

          // @ts-ignore
          setCanvasState(excalidrawRef.current)
        } else {
          setCurEditor(editor)
        }
        clearTimeout(timeout)
      }, 66)
      // cap to ~30 FPS
    })

    return () => {
      socket.off(SocketMessageType.AnnotationCanvas)
    }
  }, [excalidrawRef.current, canvasState, sceneVer])

  useEffect(() => {
    socket.on(SocketMessageType.AnnotationNotif, (data) => {
      props.annotationNotify(data.message)
    })
    return () => {
      socket.off(SocketMessageType.AnnotationNotif)
    }
  }, [props.annotationNotify])

  return (
    <div
      id="annotation-canvas"
      className="relative flex flex-col w-full h-full"
    >
      <Excalidraw
        ref={excalidrawRef as any}
        onPointerUpdate={(e) => {
          if (e.button === "down") {
            setDragState(true)
            setAction("draw")
          } else {
            setDragState(false)
          }
        }}
        onChange={(e, appState) => {
          // When Excalidraw is instantiated, the canvas runs the
          // onChange function; if it's at 0, broadcast an empty
          // message to indicate that the client needs an update
          if (sceneVer === 0) {
            socket.emit(SocketMessageType.AnnotationCanvas, {
              state: {},
            })
          }

          if (dragState === false) {
            // Broadcast updated scene to all clients
            if (sceneVer < getSceneVersion(e)) {
              setSceneVer(getSceneVersion(e))
              // @ts-ignore
              if (action === "draw") {
                const editor = user.guest ? `${guestFullName}` : user.data.name
                socket.emit(SocketMessageType.AnnotationCanvas, {
                  state: { e, appState, editor, action: "draw" },
                })
              }
              setAction("")
            }
          }
        }}
        initialData={{
          ...initialState,
          // appState: { viewBackgroundColor: "#ffffff44" },
          appState: { viewBackgroundColor: "#00000000" },
        }}
        UIOptions={{
          welcomeScreen: false,
        }}
      >
        <MainMenu>
          <MainMenu.DefaultItems.ToggleTheme />
          <MainMenu.DefaultItems.ChangeCanvasBackground />
        </MainMenu>
      </Excalidraw>
    </div>
  )
}

export default AnnotationCanvas
