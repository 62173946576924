import { AppType, SocketMessageType } from "@/TYPES/common"
import { RootState } from "@/TYPES/redux"
import { SocketContext } from "@/context/socket"
import {
  deleteGuacConnection,
  deleteTile,
  removeVNC,
  TEMP_SHAREPOINT_TILE_ID,
  unlinkAsset,
  updateTiles,
} from "@/helpers"
import AddCobrowseModal from "@/pages/Space/Modals/AddCobrowseModal"
import AddFileModal from "@/pages/Space/Modals/AddFileModal"
import $store from "@/store"
import {
  assignCurrentDrawer,
  assignShowAnnotation,
} from "@/store/slices/annotationSlice"
import { assignLargeTile, removeLargeTile } from "@/store/slices/roomSlice"
import { assignIsAddingTile, removeView } from "@/store/slices/spaceSlice"
import cn from "classnames"
import { useContext, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import AddAppModal from "../Modals/AddAppModal"
import AddFileWrapper from "../Modals/AddFileWrapper"
import AddRDPModal from "../Modals/AddRDPModal"
import AddVNCModal from "../Modals/AddVNCModal"
import AppList from "./AppList"
import DesktopTitleBar from "./DesktopTitleBar"
import TileList from "./TileList"

type Props = {
  apps: AppType[]
  isFile: boolean
}

export default function DesktopTileList({ apps, isFile }: Props) {
  const dispatch = useDispatch()

  const roomId = useSelector(
    (state: RootState) => state.room.data?._id || state.space.spaceId,
  )
  const mainAppId = useSelector((state: RootState) => {
    const roomData = state.room.data
    if (!roomData) return null
    const scene2d = roomData.scene2d
    const dataTile00 = scene2d?.dataTile00
    return Array.isArray(dataTile00) ? dataTile00[0]?._id : null
  })
  const teamId = useSelector((state: RootState) => state.room.data?.teamId)
  const isAdmin = useSelector(
    (state: RootState) => state.user.data?.role === "super-admin",
  )
  const hotkeysEnabled = useSelector(
    (state: RootState) => state.space.hotkeysEnabled,
  )
  const rdpEnabled = useSelector(
    (state: RootState) => state.rdp?.rdpIsAvailable,
  )
  const showAnnotation = useSelector(
    (state: RootState) => state.annotation.showAnnotation,
  )
  const socket = useContext(SocketContext)

  const [adding, setAdding] = useState<string>("")

  const handleRemoveApp = async (app: any) => {
    console.warn("handleRemoveApp", app)

    let type = app.type || app.fileName
    if (app.fileName?.endsWith(".rdp")) type = "rdp"
    if (!app || !app._id || !type) {
      console.error(`No app or _id or type found`)
      return
    }

    const removingToast = toast(`Removing ${type}`, {
      toastId: `removing-app-${app._id}`,
      autoClose: false,
      closeOnClick: false,
      theme: "dark",
    })

    try {
      if (type === "cobrowse" && app.workaround) {
        const param = {
          roomId: roomId || "",
          tileId: app._id,
          url: app.src,
        }
        console.log("Deleting cobrowse", param)

        const result = await removeVNC(param, {
          onSuccess: () => {
            console.log("onSuccess")
            socket.emit(SocketMessageType.GetRoom, { senderId: null, roomId })
          },
          onError: (param: any) => {
            console.error("onError")
            const msg = param?.msg || `Error removing cobrowse`
            toast.error(msg)
          },
        })
      } else if (type === "rdp") {
        // const teamId = app.teamId
        // const roomId = app.roomId
        // const fileName = app.fileName
        // const _id = app._id
        // const type = app.type

        const result = await deleteGuacConnection(app)
      } else if (type === "file") {
        // socket.emit("close-tile", { _id: app._id })
        const fileName = app.fileName
        if (!teamId || !roomId || !fileName) {
          console.error("No teamId or roomId or fileName found to delete file")
          return
        }
        const result = await unlinkAsset(teamId, roomId, fileName)
        socket.emit(SocketMessageType.GetRoom, { senderId: null, roomId })
      } else if (type === "iframe") {
        console.log("Deleting regular iframe")
        const result = await deleteTile({
          tileId: app._id,
        })
      } else {
        console.error("not sure what type it is", type)
      }
    } catch (error) {
      console.error(error)
    }

    toast.dismiss(removingToast)
  }

  const handleAddAppClick = (adding: string) => {
    setAdding(adding)
    dispatch(assignIsAddingTile(!!adding))
  }

  const handleSetMainApp = (app: AppType) => {
    console.log("handleSetMainApp()")
    if (!app._id || !roomId) return
    if (!hotkeysEnabled && !showAnnotation) return
    //@@ Ensure annotation ends before changing main tile @@//
    dispatch(assignShowAnnotation(false))
    dispatch(removeView("annotate"))

    if (app._id === TEMP_SHAREPOINT_TILE_ID) {
      handleAddAppClick("sharepoint")
      return
    }

    dispatch(assignLargeTile(app._id))

    const scene2d = $store.getState().room.data?.scene2d
    if (!scene2d) return

    const newSmallTiles = scene2d.dataTile01
    const newLargeTiles = scene2d.dataTile00

    updateTiles(
      roomId,
      JSON.parse(JSON.stringify(newSmallTiles)),
      JSON.parse(JSON.stringify(newLargeTiles)),
    )
  }

  const handleDropMainApp = (source: any) => {
    if (!source?._id || !roomId) return

    dispatch(removeLargeTile())

    const scene2d = $store.getState().room.data?.scene2d
    if (!scene2d) return

    const newSmallTiles = scene2d.dataTile01
    const newLargeTiles = scene2d.dataTile00

    updateTiles(
      roomId,
      [
        ...JSON.parse(JSON.stringify(newSmallTiles)),
        ...JSON.parse(JSON.stringify(newLargeTiles)),
      ],
      [],
    )
  }

  const annotationMode = useSelector(
    (state: RootState) => state.annotation.annotationMode,
  )
  const user_id = useSelector((state: RootState) => state.user.data?._id)

  const handleAppClick = (evt: MouseEvent, app: AppType) => {
    console.log("handleAppClick", app)
    if (app._id === mainAppId) {
      handleDropMainApp(app)
    } else {
      handleSetMainApp(app)
    }
    socket.emit("magic-marker-toggle", {
      user_id,
      show: false,
      mode: annotationMode,
    })
    dispatch(assignShowAnnotation(false))
    dispatch(removeView("annotate"))
    dispatch(assignCurrentDrawer(null))
  }

  // TODO: If needed, implement more robust system for identifying demo users
  // Right now, we're just checking if the user is an admin
  // and if not, limiting the number of cobrowse apps to 3
  const addCobrowseDisabled =
    !isAdmin && apps.filter((app) => app.type === "cobrowse").length >= 3
  const addRdpDisabled = !rdpEnabled
  const addVncDisabled = true // TODO: Fix this

  return (
    <div
      className={cn(
        "h-full flex flex-col overflow-hidden bg-cobrowsegray",
        "inactive-iframes",
      )}
    >
      <DesktopTitleBar
        isFile={isFile}
        hotkeysEnabled={hotkeysEnabled}
        onAdd={handleAddAppClick}
      />

      <div className="relative flex-1 flex flex-col py-[10px] px-[7px] gap-[10px] overflow-y-auto minimal-scrollbar">
        <AddFileWrapper>
          {(upload, uploading) => (
            <TileList
              apps={apps}
              uploading={uploading}
              onClickHandler={handleAppClick}
              onRemove={handleRemoveApp}
              onUpload={upload}
            />
          )}
        </AddFileWrapper>
      </div>

      {adding === "app" && (
        <AddAppModal onClose={() => handleAddAppClick("")} />
      )}
      {adding === "cobrowse" && (
        <AddCobrowseModal
          addDisabled={addCobrowseDisabled}
          onClose={() => handleAddAppClick("")}
        />
      )}
      {adding === "file" && (
        <AddFileModal
          onClose={(file_input_ref) => {
            handleAddAppClick("")
            const fileInput = file_input_ref?.current
            if (!fileInput) return
            fileInput.value = null
          }}
        />
      )}
      {adding === "rdp" && (
        <AddRDPModal
          addDisabled={addRdpDisabled}
          isSharepoint={false}
          onClose={() => handleAddAppClick("")}
        />
      )}
      {adding === "sharepoint" && (
        <AddRDPModal
          isSharepoint={true}
          onClose={() => handleAddAppClick("")}
        />
      )}
      {adding === "vnc" && (
        <AddVNCModal
          addDisabled={addVncDisabled}
          onClose={() => handleAddAppClick("")}
        />
      )}
    </div>
  )
}
