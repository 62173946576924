import { RootState } from "@/TYPES/redux"
import Loader from "@/components/Loader"
import { useRoom } from "@/helpers"
import { useMyUser } from "@/helpers/user"
import cn from "classnames"
import { useState } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import GuestJoinSpace from "./GuestJoinSpace"
import JoinSpace from "./JoinSpace"
import PreviewVideo from "./PreviewVideo"
import TitleBar from "./TitleBar"

export default function Preview() {
  const params = useParams()

  const [loadingMessage, setLoadingMessage] = useState("")

  const { data, isLoading } = useMyUser()

  const audioOff: boolean = useSelector(
    (state: RootState) => state.call.audioMuted,
  )
  const videoOff: boolean = useSelector(
    (state: RootState) => state.call.videoMuted,
  )

  const room = useRoom(`${params.id}`)
  const isOwnRoom = params?.id === data?._id
  const redirectToAfterSignIn = `${window.location.origin}/space/${params.id}`
  // note that props grayed out now. Theia passed props.videoSize to video preview page

  return (
    <>
      <TitleBar
        redirectToAfterSignIn={redirectToAfterSignIn}
        title={room.data?.name || ""}
        isOwner={isOwnRoom}
        isGuest={!data}
      />
      <div
        className={cn(
          "relative flex-1 flex items-center justify-center",
          "sm:flex-col sm:justify-start",
        )}
      >
        <div
          className={cn(
            "relative h-[450px] w-[800px]",
            "sm:w-full sm:h-[350px]",
          )}
        >
          <PreviewVideo />
        </div>
        <div className="w-[6%] h-[10%]" />
        <div className={cn("w-[370px]", "sm:w-[320px]")}>
          {!data ? (
            <GuestJoinSpace
              spaceId={params.id!}
              audioOff={audioOff}
              videoOff={videoOff}
              redirectToAfterSignIn={redirectToAfterSignIn}
              setLoadingMessage={setLoadingMessage}
            />
          ) : (
            <JoinSpace
              spaceId={params.id!}
              audioOff={audioOff}
              videoOff={videoOff}
            />
          )}
        </div>

        {(isLoading || !!loadingMessage) && (
          <div className="absolute inset-0 bg-cobrowsegray">
            <Loader message={loadingMessage} />
          </div>
        )}
      </div>
    </>
  )
}
