import { getInitials } from "@/utils/functions"
import cn from "classnames"

interface Props {
  name: string
  textClassName?: string
  bgColor?: "carbon" | "smoke"
  isOrganizer?: boolean
}

export default function User({
  textClassName,
  bgColor = "carbon",
  name,
}: Props) {
  return (
    <div className="flex flex-row items-center">
      <div
        className={cn("flex items-center justify-center h-8 w-8 rounded-full", {
          "bg-carbon text-white": bgColor === "carbon",
          "bg-smoke text-carbon": bgColor === "smoke",
        })}
      >
        {getInitials(name)}
      </div>
      <div className="flex flex-col ml-2 leading-4">
        <div className={cn("flex items-center", textClassName)}>{name}</div>
      </div>
    </div>
  )
}
