import {
  PermissionCobrowse,
  PermissionLevel,
  PermissionState,
} from "@/TYPES/redux"
import { createSlice } from "@reduxjs/toolkit"

const permissionSlice = createSlice({
  name: "permission",
  initialState: {
    level: "guest",
    cobrowse: "",
  } as PermissionState,
  reducers: {
    assignPermissionLevel(state, action) {
      const payload: PermissionLevel = action.payload
      return {
        ...state,
        level: payload,
      }
    },
    assignPermissionCobrowse(state, action) {
      const payload: PermissionCobrowse = action.payload || "deny"
      return {
        ...state,
        cobrowse: payload,
      }
    },
  },
})

export const { assignPermissionLevel, assignPermissionCobrowse } =
  permissionSlice.actions
export default permissionSlice.reducer
