import { RootState } from "@/TYPES/redux"
import LoaderComp from "@/components/Loader/LoaderComp"
import { SocketContext } from "@/context/socket"
import {
  getAsset,
  getGuacActiveConnectionByName,
  getGuacIframeUrlByName,
  getGuacShareLinkByName,
} from "@/helpers"
import GuacConnInfo from "@/pages/Space/AppTile/GuacConnInfo"
import {
  addGuacConnInfo,
  assignGuacShareLink,
  assignRdpAsset,
} from "@/store/slices/rdpSlice"
import Button from "@mui/material/Button"
import cn from "classnames"
import { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import validator from "validator"

const { isURL } = validator

export default function RDPTile(props: any) {
  const isMain = props?.isMain
  const dispatch = useDispatch()
  const GUACAMOLE_URL =
    useSelector(
      (state: RootState) =>
        state.env.REACT_APP_GUACAMOLE_URL || state.env.GUACAMOLE_URL,
    ) || import.meta.env.REACT_APP_GUACAMOLE_URL
  const socket = useContext(SocketContext)
  const item = props?.tile || props?.app
  const teamId = item?.teamId
  const roomId = item?.roomId
  const fileName = item?.fileName
  const isSharepoint = item?.title === 'SharePoint'
  const permissionLevel = useSelector(
    (state: RootState) => state.permission.level,
  )
  const myUserId = useSelector((state: RootState) => state.user?.data?._id)
  const isGuest = permissionLevel === "guest"
  const isOwner = permissionLevel === "owner"
  const isOriginalOwner = (() => {
    if (!myUserId) return isOwner
    return myUserId === roomId
  })()
  const isLargeTile =
    useSelector((state: RootState) => {
      const largeTiles = state.room.data?.scene2d?.dataTile00
      if (!largeTiles || !item) return false
      const found = largeTiles.find((tile: any) => tile._id === item._id)
      return !!found
    }) && isMain
  const guacConnInfoDatas = useSelector(
    (state: RootState) => state.rdp.guacConnInfoCache,
  )
  const guacConnInfoData = guacConnInfoDatas?.[fileName]

  const [guacURL, setGuacURL] = useState("")
  const [guacConnInfo, setGuacConnInfo] = useState<GuacAssetData | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [guacShareLink, setGuacShareLink] = useState("")

  //   const guacConnInfoCached = useSelector((state: any) => {
  //     const found = state.rdp.guacConnInfoCache?.[uuid]
  //     return found
  //   })

  //!! Slowly transitioning to using Redux for guacConnInfo !!//
  //   useEffect(() => {
  //     return () => {
  //       dispatch(assignGuacShareLink(""))
  //       dispatch(assignRdpAsset(null))
  //     }
  //   }, [])
  //!! !!//

  useEffect(() => {
    onMount()
    socketEvt(true)

    return () => {
      dispatch(assignGuacShareLink(""))
      dispatch(assignRdpAsset(null))
      window.clearInterval(window.__connectGuacReqInterval)
      window.clearInterval(window.__shareLinkInterval)
      socketEvt(false)
    }
  }, [isLargeTile])

  useEffect(() => {
    socketEvt(false)
    socketEvt(true)
  }, [guacConnInfo, guacShareLink])

  async function onMount() {
    window.log(`[RDPTile] onMount()`, "highlight", {
      isLargeTile,
      guacConnInfo,
      guacURL,
    })

    try {
      // socketEvt()
      const rdpAsset = await getRDPAsset()

      if (!isOriginalOwner) {
        window.localStorage.removeItem("GUAC_AUTH")
        nonOwnerConnectReq()
        return
      }
      if (!guacConnInfo || isLargeTile) return

      connectGuac()
    } catch (error: any) {
      console.error("RDPTile.tsx onMount() - ERROR", error)
    } finally {
    }
  }

  async function getRDPAsset() {
    setIsLoading(true)
    try {
      const rdpData: GuacAssetData = await getAsset({
        teamId,
        roomId,
        fileName,
      })
      console.warn("getRDPAsset() received getAsset data:", rdpData)
      console.info(`rdpData type:`, typeof rdpData)
      const rdpDataClone = JSON.parse(JSON.stringify({ ...rdpData }))
      console.warn("rdpDataClone:", rdpDataClone)

      //!! Adding this bc useState is not working.. !!//
      dispatch(assignRdpAsset(rdpDataClone))
      dispatch(addGuacConnInfo(rdpDataClone))
      if (!window.__TEMP__) window.__TEMP__ = {}
      window.__TEMP__.rdpDataClone = rdpDataClone
      //!! !!//

      setGuacConnInfo(rdpDataClone)
      return Promise.resolve(rdpDataClone)
    } catch (e: any) {
      console.error(e)
      return Promise.reject(e)
    } finally {
      setIsLoading(false)
    }
  }

  function socketEvtOwner(register: boolean = true) {
    if (!register) {
      socket.off("connect-guac-req")
      return
    }
    if (!isOriginalOwner) return
    const alreadyRegistered = (socket as any).hasListeners("connect-guac-req")
    if (alreadyRegistered) return
    socket.on("connect-guac-req", (payload: any) => {
      const payloadRoomId = payload.roomId
      const payloadFileName = payload.fileName

      if (!payloadRoomId || !payloadFileName) {
        window.log(
          `<connect-guac-req> Missing 'roomId' or 'fileName' from payload`,
          "error",
          payload,
        )
        return
      }
      if (roomId !== payloadRoomId) {
        window.log(
          `<connect-guac-req> roomId mismatch. Expected ${roomId} but received ${payloadRoomId}`,
          "error",
          payload,
        )
        return
      }
      if (fileName !== payloadFileName) {
        window.log(
          `<connect-guac-req> fileName mismatch. Expected ${fileName} but received ${payloadFileName}`,
          "error",
          payload,
        )
        return
      }

      if (!guacShareLink) {
        return
      }

      socket.emit("connect-guac", {
        roomId,
        fileName,
        guacURL,
        rdpName: guacConnInfo?.name,
        guacShareLink,
      })
    })
  }

  function socketEvtParticipants(register: boolean = true) {
    if (!register) {
      socket.off("connect-guac")
      return
    }

    const alreadyRegistered = (socket as any).hasListeners("connect-guac")
    if (isOriginalOwner || alreadyRegistered) return
    socket.on("connect-guac", (payload: any) => {
      const payloadRoomId = payload.roomId
      const payloadFileName = payload.fileName
      const payloadGuacURL = payload.guacURL
      const payloadRdpName = payload.rdpName
      const payloadGuacShareLink = payload.guacShareLink
      window.log(
        "<connect-guac> Received 'connect-guac' socket evt from original owner!",
        "highlight",
        {
          payloadRoomId,
          payloadFileName,
          payloadGuacURL,
          payloadRdpName,
          roomId,
          fileName,
          guacURL,
          rdpName: guacConnInfo?.name,
          guacShareLink,
          payloadGuacShareLink,
        },
      )
      if (
        !payloadRoomId ||
        !payloadFileName ||
        !payloadGuacURL ||
        !payloadRdpName ||
        !payloadGuacShareLink
      )
        return

      if (roomId !== payloadRoomId) return
      if (fileName !== payloadFileName) return
      setIsLoading(false)
      console.log("Setting guac URL:", payloadGuacShareLink)
      setGuacURL(payloadGuacShareLink)
    })
  }

  function socketEvt(register: boolean = true) {
    console.log("socketEvt()")

    //@@ For original owner @@//
    if (isOriginalOwner) {
      socketEvtOwner(register)
      return
    }

    //@@ For all others @@//
    socketEvtParticipants(register)
  }

  async function connectGuac() {
    if (!guacConnInfo) {
      console.warn("connectGuac() - no guacConnInfo! Returning")
      return
    }
    setIsLoading(true)
    try {
      const rdpName = guacConnInfo.name
      const guacURL = await getGuacIframeUrlByName(rdpName)
      setGuacURL(guacURL)
      shareGuacLinkAfterOwnerConnects()
    } catch (error: any) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  async function checkOnwerConnectStatus() {
    if (!guacConnInfo || !isOriginalOwner) {
      console.warn(
        "checkOwnerConnectStatus() - no guacConnInfo OR not owner. guacConnInfo is:",
        guacConnInfo || null,
      )
      return Promise.reject("No guacConnInfo or not original owner")
    }
    try {
      const activeConn = await getGuacActiveConnectionByName(
        guacConnInfo?.name as string,
      )
      console.log("checkOwnerConnectStatus() received activeConn:", activeConn)
      return Promise.resolve(activeConn)
    } catch (error: any) {
      console.error(`checkOwnerConnectStatus() - ERROR: ${error}`)
      return Promise.reject(error)
    }
  }

  //## Upon guacShareLink value changes ##//
  useEffect(() => {
    window.log(`[RDPTile] guacShareLink set`, "highlight", guacShareLink)
    if (isOriginalOwner) {
      if (!guacShareLink) {
        console.log("RDPTile.tsx OWNER useEffect - no guacShareLink! Returning")
        return
      }
      socket.emit("connect-guac", {
        roomId,
        fileName,
        guacURL,
        rdpName: guacConnInfo?.name,
        guacShareLink,
      })
      window.clearInterval(window.__shareLinkInterval)
      return
    }
    if (!isOriginalOwner) {
      if (!guacShareLink)
        console.log(
          "RDPTile.tsx NON-OWNER useEffect - no guacShareLink! Returning",
        )
      if (!guacShareLink) return
      setIsLoading(false)
      setGuacURL(guacShareLink)
      window.clearInterval(window.__shareLinkInterval)
      return
    }
  }, [guacShareLink])

  async function shareGuacLinkAfterOwnerConnects() {
    if (!guacConnInfo || !isOriginalOwner || !isLargeTile) return
    try {
      window.clearInterval(window.__shareLinkInterval)
      window.__shareLinkInterval = window.setInterval(async () => {
        window.log(
          `[RDPTile Owner] Checking if RDP is connected..`,
          "highlight",
        )
        const ownerIsConnected = await checkOnwerConnectStatus()
        if (!ownerIsConnected) return
        console.warn("ownerIsConnected", ownerIsConnected)
        const shareLink = await getGuacShareLinkByName(guacConnInfo?.name)
        console.log("shareLink", shareLink)
        if (!shareLink || !isURL(shareLink)) return

        //!! Slowly transitioning to using Redux for guacShareLink !!//
        dispatch(assignGuacShareLink(shareLink))
        //!! !!//

        setGuacShareLink(shareLink)
      }, 2000)
    } catch (error: any) {
      console.error(`shareGuacLinkAfterOwnerConnects() ERROR - ${error}`)
    } finally {
    }
  }

  function nonOwnerConnectReq() {
    if (isOriginalOwner) return
    if (guacShareLink) return
    window.clearInterval(window.__connectGuacReqInterval)
    window.__connectGuacReqInterval = window.setInterval(() => {
      const emitPayload = {
        roomId,
        fileName,
        rdpName: guacConnInfo?.name,
      }
      // console.log('emitting "connect-guac-req"', emitPayload)
      socket.emit("connect-guac-req", emitPayload)
    }, 1000)
  }

  /////////////////
  ////COMPONENT////
  /////////////////

  if (!GUACAMOLE_URL) {
    return <div>ERROR - No Guacamole URL</div>
  }

  if (isLoading) {
    return (
      <div
        className="rdp-tile-wrapper"
        style={{
          display: "flex",
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        <LoaderComp />
      </div>
    )
  }

  if (isSharepoint && !isLargeTile)
    return (
      <div
        style={{
          display: "flex",
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        <img src="/assets/images/logo-sharepoint-blue.png" />
      </div>
    )

  //## No guacConnInfo ##//
  if (!guacConnInfo)
    return (
      <div
        className="no-conn-info"
        style={{
          display: "flex",
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        Retrieving RDP info..
      </div>
    )

  //## Has guacConnInfo ##//
  return (
    <div className={cn("rdp-tile-wrapper", "relative w-full h-full", "flex")}>
      {!isGuest && !isLargeTile && (
        <div
          className={cn(
            "read-only-overlay",
            "absolute inset-0",
            "flex flex-col justify-center items-center",
            "cursor-pointer z-[100] bg-whilte/30",
          )}
        />
      )}
      <div
        className={cn(
          "has-conn-info",
          "relative w-full h-full",
          guacConnInfo ? "flex" : "hidden",
        )}
      >
        {/*//### No guacURL ###//*/}
        <GuacConnInfo
          show={isLargeTile}
          guacURL={guacURL}
          guacConnInfo={guacConnInfo}
          isOriginalOwner={isOriginalOwner}
        >
          {isOriginalOwner && (
            <Button
              className="flex"
              variant="contained"
              onClick={connectGuac}
              disabled={isLoading}
            >
              CONNECT
            </Button>
          )}
          {!isOriginalOwner && (
            <div className="flex">Awaiting owner to connect..</div>
          )}
        </GuacConnInfo>

        {/*//### Has guacURL ###//*/}
        <iframe
          className={!guacURL ? "hidden" : "flex"}
          src={guacURL}
          //  onLoad={iframeOnLoad}
          height={"100%"}
          width={"100%"}
          title="RDP"
          allowFullScreen
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        />
      </div>
    </div>
  )
}
