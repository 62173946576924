import { EventsState } from "@/TYPES/redux"
import { createSlice } from "@reduxjs/toolkit"

// temporary until we implement backend for calendar
const initialState: EventsState = {
  activeId: undefined,
  editEvent: undefined,
  events: [],
}

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setEvents: (state, action) => {
      return {
        ...state,
        events: action.payload,
      }
    },
    addEvent: (state, action) => {
      return {
        ...state,
        events: [
          ...state.events.filter(
            (event) => event.eventUUID !== action.payload.eventUUID,
          ),
          action.payload,
        ],
      }
    },
    deleteEvent: (state, action) => {
      return {
        ...state,
        events: state.events.filter(
          (event) => event.eventUUID !== action.payload,
        ),
        activeId: undefined,
      }
    },
    updateDraggedEventTime: (state, action) => {
      const { newAllDay, newStartDate, newEndDate, draggedEventId } =
        action.payload
      const newEventData = state.events.map((event) => {
        return event.eventUUID === draggedEventId
          ? {
              ...event,
              allDay: newAllDay,
              startTime: newStartDate,
              endTime: newEndDate,
            }
          : event
      })

      return {
        ...state,
        events: newEventData,
      }
    },
    setActiveEvent: (state, action) => {
      return {
        ...state,
        activeId: action.payload,
      }
    },
    setEditEvent: (state, action) => {
      return {
        ...state,
        editEvent: action.payload,
      }
    },
  },
})

export const {
  setEvents,
  addEvent,
  deleteEvent,
  updateDraggedEventTime,
  setActiveEvent,
  setEditEvent,
} = eventsSlice.actions
export default eventsSlice.reducer
