import { CSSProperties } from "react"
import { SocketMessageType } from "@/TYPES/common"
import { RootState } from "@/TYPES/redux"
import CenteredLoader from "@/components/CenteredLoader"
import { SocketContext } from "@/context/socket"
import {
  CSS_COLOR_NAMES,
  getCobrowseBackendURL,
  getCobrowseIDFromURL,
  getCursorId,
  setCobrowsePodPhase,
  toggleCursorVisibility,
  debounce,
} from "@/helpers"
import cn from "classnames"
import { useContext, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  assignIsDrawing,
  assignCurrentDrawer,
  assignDrawingChunks,
  clearChunkData,
} from "@/store/slices/annotationSlice"
import { assignTippy } from "@/store/slices/tippySlice"
import { v4 as uuidV4 } from "uuid"

interface DrawingPoint {
  x: number // Will change! This is the actual x coordinate applied to the canvas!
  y: number // Will change! This is the actual y coordinate applied to the canvas!
  offsetX: number // This is same as `x` but from native mouse event! I didn't decide if this should also update when applying to another canvas size or not.
  offsetY: number // This is same as `y` but from native mouse event! I didn't decide if this should also update when applying to another canvas size or not.
  drawnCanvasSize: CanvasSize // Will change! This indicates the current applied canvas size!
  normalizedX: number // Will NOT change! This is the value of (offsetX / canvasSize.width) at the time of drawing
  normalizedY: number // Will NOT change! This is the value of (offsetY / canvasSize.height) at the time of drawing
}
interface ChunkData {
  id: string
  drawings: DrawingPoint[]
  show: boolean
  strokeStyle?: string
  lineWidth?: number
}

enum MagicMarkerActionEnum {
  DRAW_START = "draw_start",
  DRAW_END = "draw_end",
  RESET_DRAWER = "reset_drawer",
  DRAWING = "drawing",
}

interface CurrentDrawer {
  _id: string
  email: string
  name: string
}

type CanvasSize = { width: number; height: number }

interface MagicMarkerPayload {
  user_id: string
}

interface MagicMarkerSocketDrawingPayload extends MagicMarkerPayload {
  action: MagicMarkerActionEnum.DRAWING
  canvasRect: DOMRect
  x: number
  y: number
  normalizedX: number
  normalizedY: number
  ctx: CanvasRenderingContext2D
  strokeStyle: string
  lineWidth: number
  canvasSize: CanvasSize
  offsetX: number
  offsetY: number
}

interface MagicMarkerSocketDrawStartPayload extends MagicMarkerPayload {
  action: MagicMarkerActionEnum.DRAW_START
  strokeStyle: string
  ctx: CanvasRenderingContext2D
  lineWidth: number
  normalizedX: number
  normalizedY: number
  currentDrawer: CurrentDrawer
  chunkData: { id: string }
  canvasSize: CanvasSize
  offsetX: number
  offsetY: number
}

interface MagicMarkerSocketDrawEndPayload extends MagicMarkerPayload {
  action: MagicMarkerActionEnum.DRAW_END
  currentDrawer: CurrentDrawer
  chunkTimeoutMS: number
  chunkData: ChunkData
  chunkId: string
}

interface MagicMarkerSocketResetDrawerPayload extends MagicMarkerPayload {
  action: MagicMarkerActionEnum.RESET_DRAWER
}

const GUEST_TIPPY_MSG = "Guests are not allowed to annotate"
const LINE_WIDTH = 3

interface AnnotationCompProps {
  tile?: any
}

export default function AnnotationComp(props: AnnotationCompProps) {
  const tile = props?.tile
  const dispatch = useDispatch()
  const socket = useContext(SocketContext)
  const isGuest = useSelector(
    (state: RootState) => state.user.guest,
  )
  const views = useSelector((state: RootState) => state.space.views)
  const showAnnotation = useSelector(
    (state: RootState) => state.annotation.showAnnotation,
  )
  const annotationMode = useSelector(
    (state: RootState) => state.annotation.mode,
  )
  const my_id = useSelector((state: RootState) => state.user.data?._id)
  const myEmail = useSelector((state: RootState) => state.user.data?.email)
  const myName = useSelector((state: RootState) => state.user.data?.name)
  const penColor = useSelector((state: RootState) => state.annotation.penColor)
  const penTimeoutMS = useSelector(
    (state: RootState) => state.annotation.penTimeoutMS,
  )
  const lineWidth = useSelector(
    (state: RootState) => state.annotation.lineWidth,
  )
  const currentDrawer = useSelector(
    (state: RootState) => state.annotation.currentDrawer,
  )
  const cobrowseCanvasRect = useSelector(
    (state: RootState) => state.cobrowse.mainCanvasRect,
  )
  const mainTileType = useSelector(
    (state: RootState) => state.room.mainTileType,
  )
  const mainTileRect = useSelector(
    (state: RootState) => state.space.mainTileRect,
  )
  const mainPDFRect = useSelector((state: RootState) => state.pdf.mainPDFRect)
  const clearChunkDataKey = useSelector(
    (state: RootState) => state.annotation.clearChunkDataKey,
  )
  const enableAnnotation = views.includes("annotate") && showAnnotation
  const imDrawing = currentDrawer._id === my_id
  const noOneIsDrawing = !currentDrawer._id
  const someoneIsDrawing = !noOneIsDrawing && !imDrawing

  const comp_ref = useRef<HTMLDivElement>(null)
  const canvas_ref = useRef<HTMLCanvasElement>(null)
  const [canvasSize, setCanvasSize] = useState<CanvasSize>({
    width: 0,
    height: 0,
  })
  const [ctx, setCtx] = useState<CanvasRenderingContext2D | null>(null)
  const [drawingChunks, setDrawingChunks] = useState<ChunkData[]>([])
  //   const drawingChunks = useSelector(
  //     (state: RootState) => state.annotation.drawingChunks,
  //   )
  //   const setDrawingChunks = (payload: any) => {
  //     console.log("setDrawingChunks", drawingChunks)
  //     if (typeof payload === "function") {
  //       const newChunks = payload(drawingChunks)
  //       dispatch(assignDrawingChunks(newChunks))
  //       return
  //     }
  //     dispatch(assignDrawingChunks(payload))
  //   }
  const currentDrawing = useRef<DrawingPoint[]>([])
  const canvasRedrawn = useRef(false)
  const endDrawTimeoutId = useRef<any>(null)
  const currentDrawingChunkId = useRef<string | null>(null)
  const [dynamicCompStyle, setDynamicCompStyle] = useState<CSSProperties>({})
  //   const originalCanvasSize = useRef({
  //     width: canvasSize.width,
  //     height: canvasSize.height,
  //   })

  const onMouseDownHandler = (
    e: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
  ) => {
    //  dispatch(assignIsDrawing(true))
    if (someoneIsDrawing) return // Someone is already drawing.
    currentDrawingChunkId.current = uuidV4()

    const currentDrawerPayoad = {
      _id: my_id,
      email: myEmail,
      name: myName,
      socket_id: socket.id,
    }
    // Set myself as the current drawer
    dispatch(assignCurrentDrawer(currentDrawerPayoad))
    const drawStartPayload: MagicMarkerSocketDrawStartPayload = {
      user_id: my_id,
      action: MagicMarkerActionEnum.DRAW_START,
      strokeStyle: penColor,
      ctx: ctx!,
      lineWidth,
      normalizedX: e.nativeEvent.offsetX / canvasSize.width,
      normalizedY: e.nativeEvent.offsetY / canvasSize.height,
      currentDrawer: currentDrawerPayoad,
      chunkData: { id: currentDrawingChunkId.current },
      canvasSize,
      offsetX: e.nativeEvent.offsetX,
      offsetY: e.nativeEvent.offsetY,
    }
    socket.emit("magic-marker", drawStartPayload)
    currentDrawing.current = [] // Start a new path
    drawHandler(e, true)
  }

  const onMouseUpHandler = (
    e: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
  ) => {
    if (!imDrawing) return // If I'm not assigned as the current drawer, do nothing
    if (!ctx) return
    ctx.beginPath()

    //  const chunkId = uuidV4()
    const chunkId = currentDrawingChunkId.current || uuidV4()
    const chunkData: ChunkData = {
      id: chunkId,
      drawings: [...currentDrawing.current],
      show: true, // Initially, this chunk is visible
      strokeStyle: penColor,
      lineWidth,
    }
    setDrawingChunks((prevChunks) => [...prevChunks, chunkData])
    currentDrawing.current = []

    hideChunkById(chunkId, penTimeoutMS)

    dispatch(assignCurrentDrawer(null))

    const drawingPayload: MagicMarkerSocketDrawEndPayload = {
      user_id: my_id,
      action: MagicMarkerActionEnum.DRAW_END,
      currentDrawer,
      chunkTimeoutMS: penTimeoutMS,
      chunkData,
      chunkId,
    }

    socket.emit("magic-marker", drawingPayload)

    // Give some time for the current drawer to re-draw before ending the drawing term.
    endDrawTimeoutId.current = setTimeout(() => {
      const resetDrawerPayload: MagicMarkerSocketResetDrawerPayload = {
        user_id: my_id,
        action: MagicMarkerActionEnum.RESET_DRAWER,
      }
      socket.emit("magic-marker", resetDrawerPayload)
      endDrawTimeoutId.current = null
    }, 1000)

    currentDrawingChunkId.current = null
  }

  const onMouseLeaveHandler = (
    e: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
  ) => {
    onMouseUpHandler(e)
  }

  const onMouseMoveHandler = (
    e: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
  ) => {
    drawHandler(e)
  }

  const redrawCanvas = () => {
    if (!ctx || !canvas_ref.current) return
    ctx.clearRect(0, 0, canvasSize.width, canvasSize.height)

    let shouldRemoveCurrentDrawing = false // Should be true

    // Redraw all visible chunks
    drawingChunks.forEach((chunkData: ChunkData) => {
      // Skip if the chunk is not visible
      if (!chunkData.show) {
        // Remove the current drawing if its id matches the chunk id that is not visible (should only be applicable if someone else is drawing to remove very last chunk)
        shouldRemoveCurrentDrawing =
          chunkData.id === currentDrawingChunkId.current
        return
      }

      // To scale the drawing points to the current canvas size from previous size //
      // const scaledPoints = scaleDrawingPoints(
      //   chunkData.drawings,
      //   originalCanvasSize.current,
      //   canvasSize,
      // )
      const scaledChunkData = scaleChunkDrawing(chunkData)

      ctx.strokeStyle = chunkData.strokeStyle || penColor
      ctx.lineWidth = chunkData.lineWidth || lineWidth

      ctx.beginPath()
      // chunkData.drawings.forEach((point, index) => {
      scaledChunkData.drawings.forEach((point, index) => {
        const { x, y } = point
        if (index === 0) {
          ctx.moveTo(x, y)
          return
        }
        ctx.lineTo(x, y)
        ctx.stroke()
      })
      ctx.closePath()
    })
    if (!shouldRemoveCurrentDrawing) {
      // Draw the current drawing on top of other chunks to prevent currently drawing chunk from disappearing upon other chunks' disappearance
      if (currentDrawing.current.length > 0) {
        ctx.beginPath()
        currentDrawing.current.forEach(({ x, y }, index) => {
          if (index === 0) {
            ctx.moveTo(x, y)
          } else {
            ctx.lineTo(x, y)
          }
          ctx.stroke()
        })
        ctx.closePath()
      }
    }

    canvasRedrawn.current = true
  }

  const drawHandler = (
    e: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
    bypass = false,
  ) => {
    const canvasEl = canvas_ref.current
    if ((!imDrawing && !bypass) || !ctx || !canvasEl) return // bypass indicates starting new path

    window.clearTimeout(endDrawTimeoutId.current)
    endDrawTimeoutId.current = null

    //>> `x` and `offsetX` are basically the same <<//
    const canvasRect = canvasEl.getBoundingClientRect()
    const x = e.clientX - canvasRect.left
    const offsetX = e.nativeEvent.offsetX
    const y = e.clientY - canvasRect.top
    const offsetY = e.nativeEvent.offsetY
    //  console.log(`x: ${x}\noffsetX: ${e.nativeEvent.offsetX}`)

    ctx.strokeStyle = penColor
    ctx.lineWidth = lineWidth
    ctx.lineCap = "round"

    if (canvasRedrawn.current) {
      ctx.beginPath()
      canvasRedrawn.current = false // Reset the flag
    }

    //  if (!someoneIsDrawing && bypass) return // Do not draw if not actually drawing (bypass indicates starting new path)

    currentDrawing.current.push({
      x,
      y,
      offsetX,
      offsetY,
      normalizedX: x / canvasSize.width,
      normalizedY: y / canvasSize.height,
      drawnCanvasSize: { ...canvasSize },
    }) // Collect the drawing data.

    const drawingPayload: MagicMarkerSocketDrawingPayload = {
      user_id: my_id, // or use a dedicated drawing session ID
      action: MagicMarkerActionEnum.DRAWING,
      canvasRect,
      x,
      y,
      // normalizedX: x / canvasRect.width,
      normalizedX: x / canvasSize.width,
      // normalizedY: y / canvasRect.height,
      normalizedY: y / canvasSize.height,
      ctx,
      strokeStyle: penColor,
      lineWidth,
      canvasSize,
      offsetX,
      offsetY,
    }
    socket.emit("magic-marker", drawingPayload)

    // Start a new path for each mouse move when drawing to prevent connecting the dots between current chunk and previous chunk
    if (bypass || currentDrawing.current.length === 1) {
      ctx.beginPath() // This ensures the start of a new path
      ctx.moveTo(x, y)
    } else {
      ctx.lineTo(x, y)
      ctx.stroke()
    }
  }

  const hideChunkById = (chunkId: string, timeoutMS?: number) => {
    console.log("hideChunkById", { chunkId, timeoutMS })
    if (timeoutMS === 0) {
      return
    }

    setTimeout(() => {
      setDrawingChunks((prevChunks: ChunkData[]) =>
        prevChunks.map((chunk: ChunkData) =>
          chunk.id === chunkId ? { ...chunk, show: false } : chunk,
        ),
      )
      console.log(drawingChunks)
    }, timeoutMS || 0)
  }

  const scaleDrawingPoints = (points, originalSize, newSize) => {
    const scaleX = newSize.width / originalSize.width
    const scaleY = newSize.height / originalSize.height

    return points.map((point) => ({
      x: point.x * scaleX,
      y: point.y * scaleY,
    }))
  }

  const scaleChunkDrawing = (chunkData: ChunkData) => {
    const points = chunkData.drawings
    const scaledPoints = points.map((point: DrawingPoint) => {
      const drawnCanvasSize = point.drawnCanvasSize
      const myCanvasSize = canvasSize
      if (
        drawnCanvasSize.width === myCanvasSize.width &&
        drawnCanvasSize.height === myCanvasSize.height
      )
        return point

      const scaledX = point.normalizedX * myCanvasSize.width
      const scaledY = point.normalizedY * myCanvasSize.height
      return {
        ...point,
        x: scaledX,
        y: scaledY,
        drawnCanvasSize: myCanvasSize,
      }
    })
    chunkData.drawings = scaledPoints
    return chunkData
    //  //>> Just to avoid TS error <<//
    //  const chunkDataCopy = { ...chunkData }
    //  chunkDataCopy.drawings = scaledPoints
    //  return chunkDataCopy
  }

  const getCompRect = () => {
    const compEl = comp_ref.current
    if (!compEl) return
    return compEl.getBoundingClientRect()
  }

  useEffect(() => {
    const compEl = comp_ref.current
    const canvasEl = canvas_ref.current
    if (!compEl || !canvasEl) return
    const compRect = getCompRect()!
    setCanvasSize({
      width: compRect.width,
      height: compRect.height,
    })
    //  originalCanvasSize.current = {
    //    width: compRect.width,
    //    height: compRect.height,
    //  }
    const ctx = canvasEl.getContext("2d")
    setCtx(ctx)

    // if (isGuest) {
    //   dispatch(assignTippy({ visible: true, content: GUEST_TIPPY_MSG }))
    // }

    return () => {
      // Cleanup
      // if (ctx) ctx.clearRect(0, 0, compRect.width, compRect.height)
      if (ctx) ctx.clearRect(0, 0, canvasSize.width, canvasSize.height)
      dispatch(assignTippy({ visible: false, content: "" }))
    }
  }, [])

  useEffect(() => {
    redrawCanvas()
    //  originalCanvasSize.current = { ...canvasSize }
  }, [drawingChunks, canvasSize]) // Depend on drawingChunks and canvasSize to trigger redraws

  useEffect(() => {
    if (isGuest) return
    if (imDrawing || noOneIsDrawing) {
      dispatch(assignTippy(null))
      return
    }
    if (someoneIsDrawing) {
      dispatch(
        assignTippy({
          visible: true,
          content: `${currentDrawer.name} is drawing..`,
        }),
      )
      return
    }
  }, [currentDrawer])

  useEffect(() => {
    const socketEvtHandler = (payload: any) => {
      if (
        !payload ||
        payload.user_id === my_id ||
        !ctx ||
        !payload.action ||
        !payload.action.trim()
      )
        return

      const action = payload.action.trim()

      //## 'draw_start' ##//
      if (action === MagicMarkerActionEnum.DRAW_START) {
        const data: MagicMarkerSocketDrawStartPayload = payload
        const p_chunkData = data.chunkData
        const p_currentDrawer = data.currentDrawer
        const p_canvasSize = data.canvasSize
        const p_normalizedX = data.normalizedX
        const p_normalizedY = data.normalizedY
        const p_lineWidth = data.lineWidth
        const p_strokeStyle = data.strokeStyle
        const p_offsetX = data.offsetX
        const p_offsetY = data.offsetY

        currentDrawingChunkId.current = p_chunkData.id
        dispatch(assignCurrentDrawer(p_currentDrawer))
        const x = p_normalizedX * canvasSize.width
        const y = p_normalizedY * canvasSize.height

        currentDrawing.current = []
        currentDrawing.current.push({
          x,
          y,
          offsetX: p_offsetX,
          offsetY: p_offsetY,
          normalizedX: p_normalizedX,
          normalizedY: p_normalizedY,
          drawnCanvasSize: p_canvasSize,
        })

        ctx.lineWidth = p_lineWidth || LINE_WIDTH // Use provided lineWidth or a default
        ctx.strokeStyle = p_strokeStyle || penColor
        ctx.lineCap = "round"

        ctx.beginPath()
        ctx.moveTo(x, y)

        return
      }

      //## 'drawing' ##//
      if (action === MagicMarkerActionEnum.DRAWING) {
        const data: MagicMarkerSocketDrawingPayload = payload
        const p_lineWidth = data.lineWidth
        const p_strokeStyle = data.strokeStyle
        const p_normalizedX = data.normalizedX
        const p_normalizedY = data.normalizedY
        const p_offsetX = data.offsetX
        const p_offsetY = data.offsetY
        const p_canvasSize = data.canvasSize

        ctx.lineWidth = p_lineWidth || LINE_WIDTH // Use provided lineWidth or a default
        ctx.strokeStyle = p_strokeStyle || penColor
        ctx.lineCap = "round"

        // If something was just deleted, start a new path to prevent connecting line //
        if (canvasRedrawn.current) {
          ctx.beginPath()
          canvasRedrawn.current = false // Reset the flag
        }

        const x = p_normalizedX * canvasSize.width
        const y = p_normalizedY * canvasSize.height
        ctx.lineTo(x, y)
        ctx.stroke()
        currentDrawing.current.push({
          x,
          y,
          offsetX: p_offsetX,
          offsetY: p_offsetY,
          normalizedX: p_normalizedX,
          normalizedY: p_normalizedY,
          drawnCanvasSize: p_canvasSize,
        })
        return
      }

      //## 'draw_end' ##//
      if (action === MagicMarkerActionEnum.DRAW_END) {
        const data: MagicMarkerSocketDrawEndPayload = payload
        const p_chunkData = data.chunkData
        const p_chunkTimeoutMS = data.chunkTimeoutMS
        const p_chunkId = p_chunkData?.id || data.chunkId
        const p_currentDrawer = data.currentDrawer

        ctx.lineWidth = p_chunkData.lineWidth || LINE_WIDTH // Use provided lineWidth or a default
        ctx.strokeStyle = p_chunkData.strokeStyle || penColor
        ctx.lineCap = "round"

        setDrawingChunks((prevChunks) => [
          ...prevChunks,
          {
            id: p_chunkId,
            drawings: [...currentDrawing.current],
            show: true,
            strokeStyle: p_chunkData.strokeStyle,
            lineWidth: p_chunkData.lineWidth,
          },
        ])

        hideChunkById(p_chunkId, p_chunkTimeoutMS)
        return
      }

      //## 'reset_drawer' ##//
      if (action === MagicMarkerActionEnum.RESET_DRAWER) {
        dispatch(assignCurrentDrawer(null))
        return
      }

      window.log(`Unknown action type: ${action}`, "error")
    }

    socket.on("magic-marker", socketEvtHandler)

    return () => {
      socket.off("magic-marker", socketEvtHandler)
    }
  }, [socket, my_id, ctx, canvasSize])

  useEffect(() => {
    /// This is to set the appropriate `canvasSize` value ///
    if (mainTileType === "cobrowse") {
      const canvasWidth = cobrowseCanvasRect?.width || getCompRect()?.width
      const canvasHeight = cobrowseCanvasRect?.height || getCompRect()?.height
      setCanvasSize({ width: canvasWidth, height: canvasHeight })
      return
    }
    if (mainTileType === "pdf") {
      if (!mainPDFRect) return
      const canvasWidth = mainPDFRect.width || 0
      const canvasHeight = mainPDFRect.height || 0
      setCanvasSize({ width: canvasWidth, height: canvasHeight })
      return
    }

    const canvasWidth = mainTileRect?.width || 0
    const canvasHeight = mainTileRect?.height || 0
    setCanvasSize({ width: canvasWidth, height: canvasHeight })
  }, [cobrowseCanvasRect, mainTileType, mainTileRect, mainPDFRect])

  useEffect(() => {
    setDrawingChunks([])
    currentDrawing.current = []
    currentDrawingChunkId.current = null
  }, [clearChunkDataKey])

  useEffect(() => {
    if (!showAnnotation) {
      dispatch(clearChunkData())
      dispatch(assignTippy(null))
    }
  }, [showAnnotation])

  const compStyleDefault: CSSProperties = {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    backgroundColor: "transparent",
    zIndex: 100,
    boxSizing: "border-box",
  }

  const compStyle: CSSProperties = {
    ...compStyleDefault,
    cursor: (() => {
      if (!enableAnnotation) return "default"
      if (isGuest) return "not-allowed"
      return someoneIsDrawing ? "not-allowed" : "crosshair"
    })(),
    pointerEvents: !enableAnnotation ? "none" : "auto",
  }

  /// This is to set component CSS style when the mainTileType changes ///
  //(( cobrowse ))//
  if (mainTileType === "cobrowse") {
    //>> Cobrowse tile position does not need to be `fixed` because its rect value is respected to <canvas> INSDIE of the iframe <<//
    compStyle.top = cobrowseCanvasRect?.y || compStyleDefault.top
    compStyle.left = cobrowseCanvasRect?.x || compStyleDefault.left
    //  compStyle.width = cobrowseCanvasRect?.width || compStyleDefault.width
    compStyle.width = canvasSize.width || compStyleDefault.width
    //  compStyle.height = cobrowseCanvasRect?.height || compStyleDefault.height
    compStyle.height = canvasSize.height || compStyleDefault.height
  }
  //(( pdf ))//
  if (mainTileType === "pdf") {
    //>> Make component position `fixed` to accustom with PDF <canvas> rect value <<//
    compStyle.position = "fixed"
    compStyle.top = mainPDFRect?.y || compStyleDefault.top
    compStyle.left = mainPDFRect?.x || compStyleDefault.left
    compStyle.width = mainPDFRect?.width || compStyleDefault.width
    compStyle.height = mainPDFRect?.height || compStyleDefault.height
  }
  //(( iframe ))//
  if (mainTileType === "iframe") {
    //!! IFRAME MAGIC MARKER WILL NEVER BE ACCURATE BECAUSE WE HAVE NO CONTROL OVER THE IFRAME CONTENTS !!//
    //>> Make component position `fixed` to accustom with main (large) tile rect value <<//
    compStyle.position = "fixed"
    compStyle.top = mainTileRect?.y || compStyleDefault.top
    compStyle.left = mainTileRect?.x || compStyleDefault.left
    compStyle.width = mainTileRect?.width || compStyleDefault.width
    compStyle.height = mainTileRect?.height || compStyleDefault.height
  }

  return (
    <div ref={comp_ref} className="annotation-comp" style={compStyle}>
      <div
        className="annotation-comp-guest-blocker"
        style={{
          display: isGuest ? "flex" : "none",
          position: "absolute",
          width: "100%",
          height: "100%",
          top: "0",
          left: "0",
          zIndex: 200,
          backgroundColor: "rgba(255, 255, 255, 0)",
          justifyContent: "center",
          alignItems: "center",
          cursor: "not-allowed !important",
          boxSizing: "border-box",
        }}
        onMouseEnter={() => {
          dispatch(assignTippy({ visible: true, content: GUEST_TIPPY_MSG }))
        }}
        onMouseLeave={() => {
          dispatch(assignTippy(null))
        }}
      ></div>

      <canvas
        ref={canvas_ref}
        width={canvasSize.width || getCompRect()?.width}
        height={canvasSize.height || getCompRect()?.height}
        onMouseDown={onMouseDownHandler}
        onMouseMove={onMouseMoveHandler}
        onMouseUp={onMouseUpHandler}
        onMouseLeave={onMouseLeaveHandler}
        style={{
          boxShadow: enableAnnotation
            ? "0px 0px 5px 1px rgba(237, 233, 157, 0.5) inset"
            : "none",
        }}
      ></canvas>
    </div>
  )
}
