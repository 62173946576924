export default function VNCLoader(props) {
  const size = getSize(props.size)
  //   const msg = props.msg || props.message || "Loading..."
  const msg = (() => {
    if (props.msg === false || props.message === false) return false
    return props.msg || props.message || "Loading..."
  })()

  const show = props.show === false ? false : true

  return (
    <div
      className="vnc-circular-loader"
      style={{
        display: show ? "flex" : "none",
        flexDirection: "column",
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        zIndex: 100,
        borderRadius: "10px",
        border: `2px solid rgba(255, 255, 255, 0.3)`,
      }}
    >
      <div
        style={{
          display: msg ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: "2px",
          width: "100%",
          color: "rgba(255, 255, 255, 0.7)",
          // color: 'rgba(3, 140, 127, 0.7)',
        }}
      >
        {msg}
      </div>

      <div
        className="vnc-circular-loader__circle"
        style={{
          border: `10px solid rgba(255, 255, 255, 0.3)`,
          borderRadius: `50%`,
          width: size,
          height: size,
          boxSizing: `border-box`,
          borderTopColor: `rgba(90, 254, 239, 1)`,
          animation: `spin 1s linear infinite`,
        }}
      ></div>
    </div>
  )
}

////////////////////////////

function getSize(size) {
  const defaultSize = "50px"
  if (!size) return defaultSize
  const sizeRegex = /^(?:\d+(?:\.\d+)?)(px|em|rem|vh|vw|%)?$/
  const match = size.match(sizeRegex)
  if (match) {
    if (match[1]) {
      return size // if it has a valid unit already, return as is
    } else {
      return `${size}px` // if no unit, default to px
    }
  }
  return defaultSize // if not a valid size, default to 50px
}
