import cn from "classnames"

type Props = {
  className?: string
  message?: string
  size?: number
}

export default function Loader({
  className,
  message = "Loading",
  size = 200,
}: Props) {
  const indexes = Array.from(Array(8).keys())

  const width = size / 10
  const height = size * 0.3
  const innerRadius = size * 0.2
  const fontSize = size * 0.2

  return (
    <div
      className={cn(
        className,
        "absolute inset-0 flex flex-col justify-center items-center",
      )}
    >
      <div className="relative w-px h-px">
        {indexes.map((i) => (
          <div
            key={i}
            className="absolute flex origin-top"
            style={{
              top: 0,
              left: width * -0.5,
              paddingTop: innerRadius,
              transform: `rotate(${45 * i}deg)`,
            }}
          >
            <div
              className="rounded-full bg-accentyellow animation-spin-loading"
              style={{
                width,
                height,
                animationDelay: `${0.13 * i}s`,
              }}
            />
          </div>
        ))}
      </div>
      {!!message && (
        <div
          className="absolute-center text-accentyellow font-light animate-pulse"
          style={{ paddingTop: size * 2, fontSize }}
        >
          {message}
        </div>
      )}
    </div>
  )
}
