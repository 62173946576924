import DropdownMenu from "../DropdownMenu"
import Icon, { IconNameProp } from "../Icon"

export default function FileFolderMenu() {
  const menuItems = [
    { label: "Upload File", onClick: () => {} },
    { label: "New Folder", onClick: () => {} },
  ]

  return (
    <DropdownMenu menuItems={menuItems}>
      <Icon iconName={IconNameProp.Add} />
    </DropdownMenu>
  )
}
