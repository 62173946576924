import { RootState } from "@/TYPES/redux"
import { getPopperProps } from "@/helpers"
import Tooltip from "@mui/material/Tooltip"
import cn from "classnames"
import { useSelector } from "react-redux"
import DropdownMenu from "../DropdownMenu"
import Icon, { IconNameProp } from "../Icon"
import PermitCheck from "./PermitCheck"

export default function PermitCobrowse({ tile }) {
  const mySocketId = useSelector((state: RootState) => state.socket.mySocketId)
  const isOwner = useSelector((state: RootState) => state.user.owner)
  const spaceUsers = useSelector((state: RootState) => state.space.users || {})
  const guestUsers = useSelector((state: RootState) => state.space.guests || {})
  const spaceUsersList: SocketJoinEventPayload[] = Object.values(spaceUsers)
  const guestUsersList: SocketJoinEventPayload[] = Object.values(guestUsers)
  const spaceUsersListFiltered = [...spaceUsersList, ...guestUsersList].filter(
    (user: SocketJoinEventPayload) => {
      const isMyself = mySocketId === user.socketId
      const isGuest = user.permissionLevel === "guest"
      return !isMyself
    },
  )

  const menuItems = spaceUsersListFiltered.map(
    (user: SocketJoinEventPayload, index: number) => {
      const userSocketId = user.socketId
      const userData = user.data
      const userId = userData?._id || userData?.guestId

      const element = (
        <div
          key={index}
          className="w-full h-full flex items-center cursor-default select-none"
        >
          <div>
            <PermitCheck user={user} tile={tile} />
          </div>
          <div>{userData?.name}</div>
        </div>
      )

      return {
        label: element,
        onClick: () => null,
      }
    },
  )

  const disabled =
    !isOwner || !menuItems.length || !tile || tile?.type !== "cobrowse"
  const title = !disabled ? "Share Control" : ""

  const sharedUserIds = tile?.access
    ?.filter((item) => item.action === "control")
    ?.map((item) => item.userId)
  const hasSharedUsers =
    spaceUsersListFiltered.findIndex((user) =>
      sharedUserIds?.includes(user.data._id || user.data.guestId),
    ) >= 0

  const anchorProps = {
    vertical: "center",
    horizontal: 40,
  }
  const transformProps = {
    vertical: "center",
    horizontal: "left",
  }
  const styleOverrides = {
    "& .MuiMenu-paper": {
      bgcolor: "rgb(40, 40, 40, 0.8)",
      color: "#ebebef",
      border: "0.5px solid rgb(40, 40, 40)",
    },
  }

  return (
    <DropdownMenu
      menuItems={menuItems}
      keepAfterSelect={false}
      anchorProps={anchorProps}
      transformProps={transformProps}
      styleOverrides={styleOverrides}
    >
      <Tooltip title={title} placement="right" PopperProps={popperProps}>
        <span>
          <Icon
            className={cn("transition-color duration-300", {
              "fill-accentyellow hover:opacity-60 cursor-pointer":
                !disabled && hasSharedUsers,
              "fill-smoke hover:fill-accentgreen cursor-pointer":
                !disabled && !hasSharedUsers,
              "fill-smoke hover:fill-smoke cursor-not-allowed": disabled,
            })}
            iconName={IconNameProp.ShareCobrowseControl}
            size={24}
          />
        </span>
      </Tooltip>
    </DropdownMenu>
  )
}

const popperProps = getPopperProps({ offset: [0, -4] })
