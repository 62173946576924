import PlatformSidebar from "@/components/PlatformSidebar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import HeaderActions from "./HeaderActions"
import { apps } from "./utils"

export default function Sidebar() {
  const [query, setQuery] = useState("")

  const filteredApps = query
    ? apps.filter((app) =>
        app.title
          ? app.title.toLowerCase().includes(query.toLowerCase())
          : null,
      )
    : apps

  return (
    <PlatformSidebar
      className="py-[11px]"
      title="Apps"
      headerActions={<HeaderActions />}
    >
      <div className="px-[22px]">
        <div className="relative h-[38px] flex items-center border rounded-[5px] px-[10px] overflow-hidden">
          <input
            className="flex-1 outline-none text-carbon"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Find an app"
          />
          <div className="flex">
            <FontAwesomeIcon icon="search" />
          </div>
        </div>
      </div>
      <div className="p-[22px]">Popular</div>
      <div className="relative flex-1">
        <div className="absolute inset-0 flex flex-col px-[22px] gap-[22px] overflow-x-hidden overflow-y-auto">
          {filteredApps.map((app) => (
            <div key={app.title} className="flex items-center gap-[11px]">
              <div className="w-[52px] h-[52px] bg-carbon rounded-[10px]" />
              <div className="flex-1 text-22 font-bold">{app.title}</div>
              <button className="h-[38px] w-[100px] border border-accentyellow rounded-sm text-20 font-bold">
                Add
              </button>
            </div>
          ))}
        </div>
      </div>
    </PlatformSidebar>
  )
}
