import $store from "@/store"
import { fetcher, throwError } from "./fetcher"
import { SWRHelper } from "./swr"

export function useMyUser(autoUpdateStore = false) {
  const guestToken = window.sessionStorage.getItem("guestToken")
  if (!guestToken) {
    // If no guestToken, grab user data from server
    const result = SWRHelper("/api/me", fetcher)
    const data = result?.data
    if (autoUpdateStore && data && data._id) {
      window.sessionStorage.setItem("userId", data._id)
      window.sessionStorage.removeItem("guestToken")
      window.sessionStorage.removeItem("guestName")
      window.sessionStorage.removeItem("guestFirstName")
      window.sessionStorage.removeItem("guestLastName")
      $store.dispatch({
        type: "user/assignUserData",
        payload: result.data,
      })
    }

    return result
  }

  // If guestToken
  $store.dispatch({
    type: "permission/assignPermissionLevel",
    payload: "guest",
  })
  return SWRHelper("/api/auth/testGuestToken", fetcher)
}

export const getSyncableUsers = async () => {
  const FETCH_INTERVAL = 24 * 60 * 60 * 1000
  const FETCH_ID = "lastSyncFetch"
  const shouldFetch = checkLastFetchTime(FETCH_ID, FETCH_INTERVAL)
  if (!shouldFetch) return
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER
  const fetchURL = `${REACT_APP_API_SERVER}/v1/user/sync`
  const guestToken = window.sessionStorage.getItem("guestToken")
  const roomId = window.sessionStorage.getItem("roomId")
  const isGuest = !!guestToken

  const headers: any = isGuest
    ? {
        "x-access-token": guestToken,
        "x-room-id": roomId,
      }
    : null

  try {
    const response = await fetch(fetchURL, {
      method: "GET",
      credentials: "include",
      ...(headers ? { headers } : {}),
    })

    if (!response.ok) throw response
    const result = await response.json()
    const data = result?.data
    const syncableUsers = filterForRequiredFields(data)

    setLastFetchTime(FETCH_ID)
    return Promise.resolve(syncableUsers)
  } catch (err: any) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function getMyUser(callback?: () => void) {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER
  const fetchURL = `${REACT_APP_API_SERVER}/api/me`
  const guestToken = window.sessionStorage.getItem("guestToken")
  const roomId = window.sessionStorage.getItem("roomId")
  const isGuest = !!guestToken

  const headers: any = isGuest
    ? {
        "x-access-token": guestToken,
        "x-room-id": roomId,
      }
    : null
  try {
    const response = await fetch(fetchURL, {
      method: "GET",
      credentials: "include",
      ...(headers ? { headers } : {}),
    })

    if (!response.ok) return Promise.reject(response)
    const result = await response.json()

    const data = result?.data
    if (data && data._id && !isGuest) {
      window.sessionStorage.setItem("userId", data._id)
      $store.dispatch({
        type: "user/assignUserData",
        payload: result.data,
      })
    }

    return Promise.resolve(result)
  } catch (error: any) {
    console.error(error)
    return Promise.reject(error)
  } finally {
    callback?.()
  }
}

export async function permitted(roomId: any) {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER
  const url = `${REACT_APP_API_SERVER}/api/rooms/${roomId}/permits/accessLevel`

  try {
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
    })
    if (!response.ok) {
      throw response
    }

    const data = await response.json()

    $store.dispatch({
      type: "user/assignAccessLevel",
      payload: data.accessLevels[0],
    })

    return data
  } catch (err) {
    throwError("Failed to establish permissions", err)
  }
}

interface AccessLevelItem {
  accessLevel: string
  _id: string
  roomId: string
  userId: string
  userInfo: any
}
interface AcessLevelResultData {
  accessLevel: AccessLevelItem[]
}

export function usePermission(roomId: string, autoUpdateStore = false) {
  if (!roomId) return "guest"
  const result = SWRHelper(`/api/rooms/${roomId}/permits/accessLevel`, fetcher)
  const data: AcessLevelResultData | any = result?.data
  //   console.log("usePermission() data", data)
  if (autoUpdateStore && data?.accessLevel && Array.isArray(data.accessLevel)) {
    const aLevels = data.accessLevel
    const aLevelsFiltered = aLevels.filter((aLevelItem: AccessLevelItem) => {
      return aLevelItem.roomId === roomId
    })

    $store.dispatch({
      type: "user/assignAccessLevel",
      payload: data.accessLevels,
    })

    const finalPerm = resolvePermission(aLevelsFiltered)
    return finalPerm
  }

  return "guest"
}

// export async function permittedPromise(roomId: any): Promise<any> {
export async function getPermission(roomId: any): Promise<any> {
  if (!roomId) return Promise.reject("No roomId provided")

  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER
  const url = `${REACT_APP_API_SERVER}/api/rooms/${roomId}/permits/accessLevel`

  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      })

      const data = await response.json()
      const accessLevels = data.accessLevel

      const finalPerm = resolvePermission(accessLevels)

      resolve(finalPerm) // Fallback value
    } catch (error: any) {
      console.error(error.message || error)
      reject(error)
    }
  })
}

export function resolvePermission(accessLevels: AccessLevelItem[]) {
  const userData = $store.getState()?.user?.data
  const userId = userData?._id
  const roomId = $store.getState()?.room?.data?._id

  //   console.log("resolvePermission()", { accessLevels, userId, roomId })

  //@@ If it's a guest, assign "guest" @@//
  if (!userId) {
    const perm = "guest"
    $store.dispatch({
      type: "permission/assignPermissionLevel",
      payload: perm,
    })
    return perm
  }

  //@@ If it's a user, and is room original owner @@//
  if (userId === roomId) {
    const perm = "owner"
    $store.dispatch({
      type: "permission/assignPermissionLevel",
      payload: perm,
    })
    return perm
  }

  //@@ If user but invalid param or no accessLevels @@//
  if (!accessLevels || !Array.isArray(accessLevels) || !accessLevels.length) {
    const perm = "standard"
    $store.dispatch({
      type: "permission/assignPermissionLevel",
      payload: perm,
    })
    return perm
  }

  //@@ If it's a user @@//
  const userRole = $store.getState()?.user?.data?.role
  //@@ Check if user has accessLevel for this room @@//
  const accessLevelsFiltered = accessLevels.filter((level) => {
    return level.userId === userId && level.roomId === roomId
  })
  //@@ If there's no specific accessLevel for this user, assign "standard" @@//
  if (!accessLevelsFiltered || !accessLevelsFiltered.length) {
    const perm = userRole === "super-admin" ? "owner" : "standard"
    $store.dispatch({
      type: "permission/assignPermissionLevel",
      payload: userRole === "super-admin" ? "owner" : "standard",
    })
    return perm
  }

  //@@ If there are more than one accessLevel for this user, assign the highest @@//
  if (accessLevelsFiltered.length > 1) {
    let highestPerm = "standard"
    for (const level of accessLevelsFiltered) {
      if (level.accessLevel === "owner") {
        highestPerm = "owner"
        break
      }
    }
    return highestPerm
  }

  //@@ Check if user has one accessLevel for this room @@//
  const accessLevel = accessLevelsFiltered[0]

  //@@ If the user has specific accessLevel for this room, assign that accessLevel (fallback to "standard") @@//
  let perm = accessLevel.accessLevel || accessLevel
  if (
    typeof perm !== "string" ||
    !perm ||
    (perm !== "owner" && perm !== "standard" && perm !== "guest")
  )
    perm = "standard"

  $store.dispatch({
    type: "permission/assignPermissionLevel",
    payload: perm,
  })
  $store.dispatch({
    type: "user/assignAccessLevel",
    payload: perm,
  })

  return perm
}

export async function removeGuestToken() {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER
  const guestToken = window.sessionStorage.getItem("guestToken")
  const roomId = window.sessionStorage.getItem("roomId")

  const url = `${REACT_APP_API_SERVER}/api/auth/revokeGuestToken`
  const email = sessionStorage.getItem("guestEmail")
  const body = JSON.stringify({ email, roomId })

  const headers: any = guestToken
    ? {
        "x-access-token": guestToken,
        "x-room-id": roomId,
      }
    : null

  try {
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers,
      body,
    })
    if (!response.ok) throw response
    window.sessionStorage.clear()
    const data = await response.json()
    return data
  } catch (err) {
    throwError("Failed to remove guest token", err)
  }
}

export async function logoutUser() {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER
  const url = `${REACT_APP_API_SERVER}/v2/signout`

  try {
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
    })
    if (!response.ok) throw response
    window.sessionStorage.clear()
  } catch (err) {
    throwError("Failed to signout", err)
  }
}

const filterForRequiredFields = (users) =>
  users.filter(
    ({ firstName, lastName, email }) => firstName && lastName && email,
  )

const setLastFetchTime = (identifier: string) => {
  const now = Date.now();
  window.localStorage.setItem(identifier, now.toString());
}

const checkLastFetchTime = (identifier: string, interval: number) => {
  const now = Date.now();
  const storedTimestamp = window.localStorage.getItem(identifier) || '0';
  const lastFetchTime = parseInt(storedTimestamp);
  if (!lastFetchTime || (now - lastFetchTime) >= interval) {
    return true;
  }
  return false;
}
