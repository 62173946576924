import { SocketMessageType } from "@/TYPES/common"
import { RootState } from "@/TYPES/redux"
import Icon, { IconNameProp } from "@/components/Icon"
import { SocketContext } from "@/context/socket"
import { useMyUser } from "@/helpers"
import { togglePresenterMode } from "@/store/slices/spaceSlice"
import { Tooltip } from "@mui/material"
import cn from "classnames"
import { useContext, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

type Props = {
  isOwner: boolean
}

export default function LaunchPresenterMode({ isOwner }: Props) {
  const dispatch = useDispatch()
  const socket = useContext(SocketContext)
  const { data } = useMyUser()
  const name = data?.name
  const views = useSelector((state: RootState) => state.space.views)
  const isPresenting = views.includes("presentation") && isOwner
  const presenter_id = useSelector((state: RootState) => state.user.data?._id)

  function handlePresenterMode() {
    const toggle = !isPresenting
    // If not presenting, start. If already presenting, stop
    const payload = {
      toggle,
      name,
      triggerNotif: true,
      presenter_id,
    }
    console.log("handlePresenterMode()", payload)
    //!! <Experimental> For immediate animation loop termination for owner !!//
    dispatch(togglePresenterMode(toggle))
    //!! !!//
    socket.emit(SocketMessageType.PresenterMode, payload)
  }

  useEffect(() => {
    // The `isPresenting` can only be `true` for owner (presenter).
    if (isPresenting) {
      // If owner is presenting, toggle presenter mode for each new user that joins
      socket.on(SocketMessageType.SyncSpace, () => {
        socket.emit(SocketMessageType.PresenterMode, {
          toggle: true,
          name,
          presenter_id,
        })
      })

      // Disable presenter mode for all users when owner closes tab or refreshes
      const disableOnOwnerExit = () => {
        const toggle = false
        socket.emit(SocketMessageType.PresenterMode, {
          toggle,
          name,
          presenter_id,
        })
      }

      window.addEventListener("beforeunload", disableOnOwnerExit)

      return () => {
        window.removeEventListener("beforeunload", disableOnOwnerExit)
        socket.off(SocketMessageType.SyncSpace)
      }
    }
  }, [isPresenting])

  return (
    <Tooltip title="Presenter Mode" placement="top">
      <div
        className="cursor-pointer opacity-80 hover:opacity-100"
        onClick={() => handlePresenterMode()}
      >
        {isPresenting ? (
          <Icon
            className="fill-accentred cursor-pointer"
            iconName={IconNameProp.Close}
            size={16}
          />
        ) : (
          <Icon
            className="fill-ghostgray hover:fill-white cursor-pointer"
            iconName={IconNameProp.Presentation}
            size={24}
          />
        )}
      </div>
    </Tooltip>
  )
}

export function TheaterOverlay() {
  const socket = useContext(SocketContext)
  const views = useSelector((state: RootState) => state.space.views)
  const user = useSelector((state: RootState) => state.user.data)
  const name = user?.name
  const presenter_id = user?._id
  const isOwner = useSelector((state: RootState) => state.user.owner)
  const isPresenting = views.includes("presentation") && isOwner
  const isPresenterMode = useSelector(
    (state: RootState) => state.space.isPresenterMode,
  )

  useEffect(() => {
    // The `isPresenting` can only be `true` for owner (presenter).
    if (isPresenting) {
      // If owner is presenting, toggle presenter mode for each new user that joins
      socket.on(SocketMessageType.SyncSpace, () => {
        socket.emit(SocketMessageType.PresenterMode, {
          toggle: true,
          name,
          presenter_id,
        })
      })

      // Disable presenter mode for all users when owner closes tab or refreshes
      const disableOnOwnerExit = () => {
        const toggle = false
        socket.emit(SocketMessageType.PresenterMode, {
          toggle,
          name,
          presenter_id,
        })
      }

      window.addEventListener("beforeunload", disableOnOwnerExit)

      return () => {
        window.removeEventListener("beforeunload", disableOnOwnerExit)
        socket.off(SocketMessageType.SyncSpace)
      }
    }
  }, [isPresenting])

  return (
    <div
      className={cn(
        "theater-overlay pointer-events-none fixed inset-0 w-full h-full bg-cobrowsegray transition-bg-opacity",
        {
          "bg-opacity-0 z-0": !isPresenterMode,
          "bg-opacity-75 z-40 duration-[1500ms]": isPresenterMode,
        },
      )}
    />
  )
}
