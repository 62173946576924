import { Event } from "@/TYPES/redux"
import { useState } from "react"
import SectionHeader from "../components/SectionHeader"
import { useDispatch } from "react-redux"
import { setEditEvent } from "@/store/slices/eventsSlice"

interface Props {
  editEvent: Event
  isDarkMode: boolean
}

export default function AgendaInput({ editEvent, isDarkMode }: Props) {
  const dispatch = useDispatch()

  const handleAgendaType = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setEditEvent({ ...editEvent, agenda: e.target.value }))
  }

  return (
    <div className="w-full">
      <SectionHeader title="Agenda" isDarkMode={isDarkMode} />
      <textarea
        className="flex border-[1px] border-carbon rounded-sm outline-none w-full p-2 text-carbon resize-none"
        value={editEvent.agenda}
        onChange={handleAgendaType}
        placeholder="Add agenda"
        rows={4}
        maxLength={176} /** 44 characters per line */
      />
    </div>
  )
}
