import { CircularProgress } from "@mui/material"

export default function CenteredLoader() {
  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <CircularProgress
        className="z-50"
        sx={{
          //  color: "rgba(90, 254, 239, 1)",
          color: "rgba(252, 208, 0, 1)",
        }}
      />
    </div>
  )
}
