import { AppType } from "@/TYPES/common"
import { createSlice } from "@reduxjs/toolkit"

export const spaceSlice = createSlice({
  name: "space",
  initialState: {
    spaceId: undefined,
    views: ["jitsi"],
    mainApp: {} as AppType,
    apps: [] as AppType[],
    users: {},
    guests: {},
    guestQueue: [] as any[],
    syncableUserCount: 0,
    hotkeysEnabled: false,
    isAddingFile: false,
    isAddingTile: false,
    isDemoMode: false,
    isLocked: false,
    isPresenterMode: false,
    isSmallSidebar: false,
    isSharingControl: false,
    mainTileRect: null,
  },
  reducers: {
    syncSpace: (state, action: { payload: SyncSpacePayload }) => {
      if (!action.payload) return state
      const payload = action.payload

      const spaceId = payload.spaceId
      const users = payload.users
      const guests = payload.guests
      const showAppsPanel =
        typeof payload.showAppsPanel === "boolean"
          ? payload.showAppsPanel
          : payload.showAppsPanel?.show
      const shouldNetworkAppsPanel =
        state.isDemoMode && showAppsPanel !== undefined
      const newViewsWithNoDuplicates = [
        ...Array.from(new Set([...state.views, "apps"])),
      ]
      const newViews = !shouldNetworkAppsPanel
        ? [...state.views]
        : showAppsPanel
        ? newViewsWithNoDuplicates
        : state.views.filter((view) => view !== "apps")

      const finalState = {
        ...state,
        users,
        guests,
        views: newViews,
      }

      return finalState
    },
    assignSpaceId: (state, action) => {
      return {
        ...state,
        spaceId: action.payload,
      }
    },
    setSyncableUserCount: (state, action) => {
      return {
        ...state,
        syncableUserCount: action.payload,
      }
    },
    toggleDemoMode: (state, action) => {
      const { payload } = action
      return { ...state, isDemoMode: payload }
    },
    togglePresenterMode: (state, action) => {
      const { payload } = action
      const newViews = payload ? ["presentation"] : ["jitsi"]

      const finalState = {
        ...state,
        isPresenterMode: payload,
        views: newViews,
      }

      return finalState

      // if (!payload) return { ...state, views: ["apps", "jitsi"], isPresenterMode: payload}

      // return { ...state, views: ["presentation", "jitsi"], isPresenterMode: payload}
    },
    toggleHotkeysEnabled: (state, action) => {
      const { payload } = action
      return { ...state, hotkeysEnabled: payload }
    },
    switchViews: (state, action) => {
      const { payload } = action
      if (!payload) return state

      const currViews = state.views
      const filterViews = (keys: string[]) =>
        currViews.filter((view) => !keys.includes(view))

      const newViews = (() => {
        if (currViews.includes(payload)) {
          return currViews.filter((view) => view !== payload)
        }

        switch (payload) {
          case "jitsi":
            return [...filterViews(["chat"]), payload]
          case "chat":
            return [...filterViews(["jitsi"]), payload]
          case "calendar":
            return [...filterViews(["annotate"]), payload]
        }
        return [...currViews, payload]
      })()

      return { ...state, views: newViews }
    },
    removeView: (state, action) => {
      const payload = action.payload
      const currViews = state.views
      const newViews = currViews.filter((view) => view !== payload)
      console.warn("newViews:", newViews)
      return { ...state, views: newViews }
    },
    addView: (state, action) => {
      const payload = action.payload
      const currViews = state.views
      const newViews = Array.from(new Set([...currViews, payload]))
      return { ...state, views: newViews }
    },
    toggleAppsPanel: (state, action) => {
      const payload = action.payload
      const currViews = state.views
      const calendarOpen = currViews.includes("calendar")
      const chatOpen = currViews.includes("chat")
      const filesOpen = currViews.includes("files")
      const hideCalendar = currViews.filter((view) => view !== "calendar")

      if (typeof payload !== "boolean") {
        return state
      }
      if (filesOpen) {
        return {
          ...state,
          views: [...currViews.filter((view) => view !== "files"), "apps"],
        }
      }
      if (payload && chatOpen && calendarOpen) {
        return { ...state, views: [...hideCalendar, "apps"] }
      }

      return {
        ...state,
        views: payload
          ? [...state.views, "apps"]
          : state.views.filter((view) => view !== "apps"),
      }
    },
    changeMainApp: (state, action) => {
      const app = action.payload as AppType

      return {
        ...state,
        apps: [
          state.mainApp,
          ...state.apps.filter(
            (item: any) => item.name !== app.name || item.title !== app.title,
          ),
        ],
        mainApp: app,
      }
    },
    assignMainApp: (state, action) => {
      const payload = action.payload
      return {
        ...state,
        mainApp: payload,
      }
    },
    assignSmallApps: (state, action) => {
      const payload = action.payload
      return {
        ...state,
        apps: payload,
      }
    },
    setInitialApps: (state, action) => {
      const apps = action.payload
      return {
        ...state,
        apps,
      }
    },
    addApp: (state, action) => {
      console.log("addApp payload:", action.payload)
      return { ...state, apps: [...state.apps, action.payload] }
    },
    removeApp: (state, action) => {
      const app = action.payload
      return {
        ...state,
        apps: state.apps.filter(
          (item: any) => item.name !== app.name || item.title !== app.title,
        ),
      }
    },
    assignIsAddingFile: (state, action) => {
      const payload = action.payload
      return {
        ...state,
        isAddingFile: payload,
      }
    },
    assignIsAddingTile: (state, action) => {
      const payload = action.payload
      return {
        ...state,
        isAddingTile: payload,
      }
    },
    assignIsLocked: (state, action) => {
      const payload = action.payload
      return {
        ...state,
        isLocked: payload,
      }
    },
    assignIsSmallSidebar: (state, action) => {
      return {
        ...state,
        isSmallSidebar: action.payload,
      }
    },
    assignIsSharingControl: (state, action) => {
      return {
        ...state,
        isSharingControl: action.payload,
      }
    },
    assignMainTileRect: (state, action) => {
      return {
        ...state,
        mainTileRect: action.payload,
      }
    },
    updateGuestQueue: (state, action) => {
      const payload = action.payload
      return {
        ...state,
        guestQueue: payload,
      }
    },
  },
})

export const {
  assignSpaceId,
  switchViews,
  toggleDemoMode,
  togglePresenterMode,
  toggleHotkeysEnabled,
  changeMainApp,
  setInitialApps,
  setSyncableUserCount,
  addApp,
  removeApp,
  toggleAppsPanel,
  assignMainApp,
  assignSmallApps,
  assignIsAddingFile,
  assignIsAddingTile,
  assignIsLocked,
  assignIsSmallSidebar,
  assignIsSharingControl,
  removeView,
  syncSpace,
  assignMainTileRect,
  addView,
  updateGuestQueue,
} = spaceSlice.actions

export default spaceSlice.reducer
