import DropdownMenu from "@/components/DropdownMenu"
import Icon, { IconNameProp } from "@/components/Icon"

type Props = {
  devices: any[]
  value: any
  icon: IconNameProp
  onChange: (value: any) => void
}

export function SelectDevice({ devices, icon, value, onChange }: Props) {
  const menuItems = devices.map((item) => ({
    label: (
      <div className="w-full flex items-center justify-between">
        <span className="mr-4">{item.label}</span>
        {value?.id === item.id && <Icon iconName={IconNameProp.Check} size={12} />}
      </div>
    ),
    onClick: () => onChange(item),
  }))

  return (
    <DropdownMenu className="w-[200px] sm:w-1/2" menuItems={menuItems}>
      <div className="flex items-center">
        <Icon className="fill-white" iconName={icon} />
        <span className="ml-2 truncate">{value.label || "No device"}</span>
      </div>
    </DropdownMenu>
  )
}
