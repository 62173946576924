import { SocketMessageType } from "@/TYPES/common"
import { RootState } from "@/TYPES/redux"
import { SocketContext } from "@/context/socket"
import { assignCursors } from "@/store/slices/participantsSlice"
import { assignCursorColor } from "@/store/slices/userSlice"
import { useContext, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import CursorSVG from "./CursorSVG"

// generic type to get around TS throwing a fit about reference props
type cursorType = {
  cursorX: number
  cursorY: number
  time: any
  color: string
  id: number
  name: string
}

export default function Cursor({ tile }) {
  const ENV = import.meta.env
  const CURSOR_DEBUG =
    useSelector((state: RootState) => state.env.REACT_APP_CURSOR_DEBUG) ||
    ENV.CURSOR_DEBUG
  const CURSOR_TRACK_TIME =
    useSelector((state: RootState) => state.env.REACT_APP_CURSOR_TRACK_TIME) ||
    ENV.CURSOR_TRACK_TIME

  const socket = useContext(SocketContext)
  const user = useSelector((state: RootState) => state.user)
  const participants = useSelector(
    (state: RootState) => state.participants.participants,
  )
  const cursors = useSelector((state: RootState) => state.participants.cursors)
  const cursorsRef = useRef(cursors)
  const dispatch = useDispatch()
  const [allCursors, setAllCursors] = useState([])

  // figure out how to get color scheme later
  const colorTemp = user.cursorColor || "#" + Math.floor(Math.random() * 16777215).toString(16)

  // State, figure out later
  const [cursorColor, _] = useState([colorTemp])

  const myColor = user.cursorColor

  const broadcastPosition = (e) => {
    const { clientX, clientY } = e
    let propX = clientX === 0 ? 0 : clientX / window.innerWidth
    let propY = clientY === 0 ? 0 : clientY / window.innerHeight
    socket.emit(SocketMessageType.CursorMovement, {
      id: user._id,
      x: propX,
      y: propY,
      color: myColor || cursorColor,
    })
  }

  const vncBroadcastPosition = (e) => {
    const { cursorX, cursorY } = e.detail
    socket.emit(SocketMessageType.CursorMovement, {
      id: user._id,
      x: cursorX,
      y: cursorY,
      color: myColor || cursorColor,
    })
  }

  const debugTime = (against, compare) => {
    if (!compare || !compare.time || !compare.name) return
    const { time, name } = compare

    const convertFormat = (time) => {
      const now = new Date(time)
      const minutesNow = now.getMinutes().toString().padStart(2, "0")
      const secondsNow = now.getSeconds().toString().padStart(2, "0")
      const formatted = `${minutesNow}:${secondsNow}`
      return formatted
    }

    console.log()
    console.group(name)
    console.warn(`${convertFormat(against)} - NOW`)
    console.warn(`${convertFormat(time)} - ${name}`)
    console.warn(`${convertFormat(against - time)} - DIFF`)
    // console.groupEnd(name);
    console.groupEnd()
    console.log()
  }

  // console.log(participants);

  // TODO: Figure out why this isn't firing despite WS data
  // coming through.
  const renderCursors = (data) => {
    // console.log('DATA', data);
    let list: any[] = []
    const time = Date.now()

    for (let pId in participants) {
      // console.log('PARTICIPANT: ', participants[pId].id, 'DATA: ', data.id);
      const participant = participants[pId]
      const participantExists = participant.id === data.id
      const cursorIsNotMine = participant.id !== user._id
      const participantTime = participant.time
      const participantTimeIsRecent = CURSOR_TRACK_TIME
        ? participantTime >= time - 10000
        : true

      CURSOR_DEBUG &&
        debugTime(time, { name: "PARTICIPANT", time: participantTime })
      // console.log({ participantExists, cursorIsNotMine, participantTimeIsRecent });
      // console.log('1, 2, 3', participantExists, cursorIsNotMine, participantTimeIsRecent);
      // if (participants[pId].id === data.id && participants[pId].id !== user._id && participants[pId].time >= time - 10000) {
      if (participantExists && cursorIsNotMine && participantTimeIsRecent) {
        let cursor = {
          name: participants[pId].name,
          id: participants[pId].id,
          cursorX: data.x,
          cursorY: data.y,
          color: data.color,
          time: participants[pId].time,
          show: true,
        }
        dispatch(assignCursors(cursor))

        const curCursorRef = cursorsRef.current[pId] as cursorType

        const cursorExists = !!curCursorRef
        const cursorTime = curCursorRef.time
        const cursorTimeIsRecent = CURSOR_TRACK_TIME
          ? cursorTime >= time - 10000
          : true

        CURSOR_DEBUG && debugTime(time, { name: "CURSOR", time: cursorTime })

        // if (curCursorRef !== undefined && curCursorRef.time >= time - 10000) {
        if (cursorExists && cursorTimeIsRecent) {
          const left = curCursorRef.cursorX * window.innerWidth
          const top = curCursorRef.cursorY * window.innerHeight

          const cursorObj = curCursorRef
          const { color, name, id } = cursorObj
          //  const nameTrimmed = name.split(" ")[0]

          list.push(
            <div
              key={id}
              // className="fixed inset-0 z-[95] visible pointer-events-none"
              className="fixed inset-0 z-[95] visible pointer-events-none"
            >
              <div
                id={`cursor_${id}`}
                className="absolute visible pointer-events-none select-none flex justify-center items-center"
                style={{ left, top }}
              >
                <CursorSVG color={color} type="arrow" size={30} />
                <div
                  className="bottom-0 p-[0.2rem] rounded-[5px] font-poppins max-w-[100px] truncate text-[12px]"
                  style={{ backgroundColor: color }}
                >
                  {name}
                </div>
              </div>
            </div>,
          )

          // list.push(
          //    <div
          //       className="cursorWrapper"
          //       key={participants[pId].id}
          //       // style={{
          //       //    display: cursor.show === false ? 'none' : 'block',
          //       // }}
          //       style={{
          //          visibility: 'visible',
          //       }}
          //    >
          //       <div
          //          id={`cursor_${pId}`}
          //          className="flex-container-row center"
          //          style={{
          //             position: 'absolute',
          //             pointerEvents: 'none',
          //             left,
          //             top,
          //             transform: 'translate(-50%, -50%)'
          //          }}
          //       >
          //          <CursorSVG
          //             color={curCursorRef.color}
          //             type="arrow"
          //             size={30}
          //          />
          //       </div>
          //       <div
          //          id={`cursor_${pId}`}
          //          className="flex-container-row center"
          //          style={{
          //             position: 'absolute',
          //             pointerEvents: 'none',
          //             left,
          //             top,
          //             transform: 'translate(-50%, -50%)'
          //          }}
          //       >
          //          {curCursorRef.name !== undefined &&
          //             <p className="cursor-name" style={{ backgroundColor: curCursorRef.color }}>
          //                {curCursorRef.name.split(' (guest)')[0]}
          //             </p>
          //          }
          //       </div>
          //    </div>
          // )
        }
      }
    }

    // @ts-ignore
    setAllCursors(list)
  }

  useEffect(() => {
    cursorsRef.current = cursors
  })

  useEffect(() => {
    document
      // .querySelector(".flex-1.bg-gravel")
      .querySelector(".main-tile-body")
      ?.addEventListener("mousemove", broadcastPosition, false)
    document.addEventListener("vncmousemove", vncBroadcastPosition, false)
    return () => {
      // let el = document.querySelector(".flex-1.bg-gravel")
      let el = document.querySelector(".main-tile-body")
      if (el) el.removeEventListener("mousemove", broadcastPosition)
      document.removeEventListener("vncmousemove", vncBroadcastPosition)
    }
  }, [tile])

  useEffect(() => {
    dispatch(assignCursorColor(cursorColor))
  }, [])

  useEffect(() => {
    const handleCursorMovement = (message) => {
      // console.log('MESSAGE?', message);
      renderCursors(message.message)
    }

    socket.on(SocketMessageType.CursorMovement, (payload: any) => {
      // console.log('CURSOR MOVEMENT: ', payload)
      handleCursorMovement(payload)
    })

    return () => {
      socket.off(SocketMessageType.CursorMovement, handleCursorMovement)
    }
  }, [participants])

  return <>{allCursors}</>
}
