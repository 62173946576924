import type { RootState } from "@/TYPES/redux"
import { SocketMessageType } from "@/TYPES/common"
import * as tf from "@tensorflow/tfjs"
import * as bodyPix from "@tensorflow-models/body-pix"
import { memo, useContext, useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SocketContext } from "@/context/socket"
import { assignAudioMuted } from "@/store/slices/callSlice"
import IconButton from "@mui/material/IconButton"
import Close from "@mui/icons-material/Close"
import Refresh from "@mui/icons-material/Refresh"
import Tooltip from "@mui/material/Tooltip"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"

const MAX_VIDEO_SIZE = 400
const MIN_VIDEO_SIZE = 200

export default function CanvasVideo(props: any) {
  const vidSocket = props.socket
  const dispatch = useDispatch()
  const socket = useContext(SocketContext)
  const video_ref = useRef<HTMLVideoElement>(null)
  const canvas_ref = useRef<HTMLCanvasElement>(null)
  const [net, setNet] = useState<bodyPix.BodyPix>()
  const [status, setStatus] = useState({
    inited: false,
    loading: false,
    error: false,
    msg: "Initializing...",
  })
  const [videoSize, setVideoSize] = useState({
    width: MIN_VIDEO_SIZE,
    height: MIN_VIDEO_SIZE,
  })
  const [canvasStyleSize, setCanvasStyleSize] = useState({
    width: MIN_VIDEO_SIZE,
    //  width: MAX_VIDEO_SIZE,
    height: MIN_VIDEO_SIZE,
    //  height: MAX_VIDEO_SIZE,
  })
  const [forceHideOverlay, setForceHideOverlay] = useState(false)
  const [mouseEntered, setMouseEntered] = useState(false)
  const [videoMinimized, setVideoMinimized] = useState(false)
  const [noCameraMode, setNoCameraMode] = useState(false)
  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([])
  const room_id = useSelector(
    (state: RootState) => state.room.data?._id || state.space.spaceId,
  )
  const user_id = useSelector((state: RootState) => state.user.data?._id)
  const selectedJitsiCamera = useSelector(
    (state: RootState) => state.user.videoDevice,
  )
  const selectedJitsiCameraId = selectedJitsiCamera?.id
  // As of 1/23/2024, only oroiginal owner can "present" which means this video is only triggered by original owner
  const isOriginalOwner = room_id === user_id

  async function initComp() {
    window.log("initComp()", "highlight")
    reqAniFrameLoopIdHandler(false)
    setForceHideOverlay(false)
    setNoCameraMode(false)

    setStatus((prev) => ({
      ...prev,
      inited: false,
      loading: true,
      error: false,
      msg: "Initializing...",
    }))

    try {
      if (!selectedJitsiCameraId) {
         setStatus({
            inited: false,
            loading: false,
            error: true,
            msg: "No camera available",
         })
         return
      }

      await tf.setBackend("webgl")
      await tf.ready()
      const net = await bodyPix.load()
      setNet(net)

      console.log(selectedJitsiCamera)
      const videoStream = await window.navigator.mediaDevices.getUserMedia({
        video: { deviceId: { exact: selectedJitsiCameraId } },
      })
      const videoTrack = videoStream.getVideoTracks()[0]
      const videoSettings = videoTrack.getSettings()
      const video_el = video_ref.current
      const canvas_el = canvas_ref.current
      if (!video_el || !canvas_el) {
        setStatus({
          inited: false,
          loading: false,
          error: true,
          msg: "video or canvas element is not ready",
        })
        return
      }
      const videoResolutionWidth = videoSettings.width as number
      const videoResolutionHeight = videoSettings.height as number
      setVideoSize({
        width: videoResolutionWidth,
        height: videoResolutionHeight,
      })
      const canvasSize = computeCanvasSize(videoSettings)

      if (!canvasSize) {
        setStatus({
          inited: false,
          loading: false,
          error: true,
          msg: "Unable to compute canvas size",
        })
        return
      }

      video_el.srcObject = videoStream

      setCanvasStyleSize(canvasSize)
    } catch (err: any) {
      console.error(err)
      if (err.name === "NotAllowedError") {
        setStatus({
          inited: false,
          loading: false,
          error: true,
          msg: "Please allow camera access to use this feature",
        })
        return
      }
      if (err.name === "NotFoundError") {
        setStatus({
          inited: false,
          loading: false,
          error: true,
          msg: "No camera found",
        })
        return
      }
      setStatus({
        inited: false,
        loading: false,
        error: true,
        msg: err.message || err,
      })
    }
  }

  async function onLoadedMetadata() {
    // There should already be
    try {
      const video_el = video_ref.current
      if (!video_el) {
        setStatus((prev) => ({
          ...prev,
          loading: false,
          error: true,
          msg: "video_el is not ready",
        }))
        return
      }
      await video_el.play()
      dispatch(assignAudioMuted(false))
      renderMaskedVideo()
      setStatus((prev) => ({
        ...prev,
        inited: true,
        loading: false,
      }))
    } catch (err: any) {
      console.error(err)
      setStatus((prev) => ({
        ...prev,
        loading: false,
        error: true,
        msg: `${err.message || err}\n`,
      }))
    }
  }

  function doEmit(param?: boolean) {
    if (param === false) return (window.__do_emit_num = 0)
    if (!window.__do_emit_num) window.__do_emit_num = 0
    const num = window.__do_emit_num
    const isEven = num % 2 === 0
    window.__do_emit_num++
    return isEven
  }

  async function renderMaskedVideo() {
    const video_el = video_ref.current
    const canvas_el = canvas_ref.current
    if (!net || !video_el || !canvas_el) {
      setStatus((prev) => ({
        ...prev,
        loading: false,
        error: true,
        msg: "net, video or canvas_el is not ready",
      }))
      return
    }

    try {
      const segmentation = await net.segmentPerson(video_el, {
        maxDetections: 1, // Default is 10.
        scoreThreshold: 0.7, // Default is 0.4.
        nmsRadius: 30, // Default is 20.
      })

      // Get the mask from the segmentation data
      const coloredPartImage = bodyPix.toMask(segmentation)
      const opacity = 1
      const flipHorizontal = false
      const maskBlurAmount = 0

      // Draw the mask onto the canvas
      bodyPix.drawMask(
        canvas_el,
        video_el,
        coloredPartImage,
        opacity,
        maskBlurAmount,
        flipHorizontal,
      )

      // Modify canvas to make the black part transparent
      const ctx = canvas_el.getContext("2d") as any
      const imageData = ctx.getImageData(
        0,
        0,
        canvas_el.width,
        canvas_el.height,
      )

      //** Previous working code but slower. **/
      // const data = imageData.data
      // for (let i = 0; i < data.length; i += 4) {
      //   if (data[i] === 0 && data[i + 1] === 0 && data[i + 2] === 0) {
      //     data[i + 3] = 0
      //   }
      // }

      //** This is a more efficient way to do it. It's a lot faster than the above comented out code. Note that `new Unit32Array()` does not create new independent object but act as a pointer to `imageData`. **/
      const uint32Data = new Uint32Array(imageData.data.buffer)
      for (let i = 0; i < uint32Data.length; i++) {
        if (uint32Data[i] === 0xff000000) {
          uint32Data[i] = 0
        }
      }

      //** The `imageData` should've been updated from previous loop. **/
      ctx.putImageData(imageData, 0, 0)

      //** When transmitting imageData over Socket.io, it's converted to an ArrayBuffer because imageData.data is an instance of Uint8ClampedArray, which is a typed array. Typed arrays are transferred as ArrayBuffer over SocketIO. This is a standard behavior as the WebSocket protocol and Socket.io are optimized to send binary data efficiently in this format. **/
      // socket.emit(SocketMessageType.PresenterVideo, {
      //   room_id,
      //   user_id,
      //   imageData,
      //   videoSize,
      //   canvasStyleSize,
      // })
      if (doEmit()) {
        vidSocket.emit(SocketMessageType.PresenterVideo, {
          room_id,
          user_id,
          imageData,
          videoSize,
          canvasStyleSize,
        })
      }

      // const loopId = window.requestAnimationFrame(renderMaskedVideo)
      const loopId = window.requestIdleCallback(renderMaskedVideo, {
        timeout: 200,
      })
      reqAniFrameLoopIdHandler(loopId)

      setStatus((prev) => ({
        ...prev,
        loading: false,
        error: false,
      }))
    } catch (err: any) {
      console.error(err)
      setStatus((prev) => ({
        ...prev,
        loading: false,
        error: true,
        msg: err.message || err,
      }))
    }
  }

  function continueWithoutCamera() {
    //  setForceHideOverlay(true)
    setNoCameraMode(true)
    reqAniFrameLoopIdHandler(false)
  }

  function onMouseEnter(evt: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    setMouseEntered(true)
  }

  function onMouseLeave(evt: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    setMouseEntered(false)
  }

  function toggleVideoContainer() {
    setVideoMinimized((prev) => !prev)
  }

  useEffect(() => {
    initComp()

    return () => {
      reqAniFrameLoopIdHandler(false)
      doEmit(false)
    }
  }, [selectedJitsiCameraId])

  const showOverlay =
    (!status.inited || status.loading || status.error) &&
    !forceHideOverlay &&
    !videoMinimized
  const showHeader = (!showOverlay && mouseEntered) || videoMinimized

  return (
    <>
      <div
        className="presenter-video-overlay"
        style={{
          display: showOverlay && !noCameraMode ? "flex" : "none",
          //  display: "flex",
          flexDirection: "column",
          position: "absolute",
          width: "100%",
          height: "100%",
          top: "0",
          left: "0",
          zIndex: 20,
          backgroundColor: "#181818",
          justifyContent: "center",
          alignItems: "center",
          color: status.error ? "red" : "white",
          userSelect: "none",
          boxSizing: "border-box",
          //  border: "1px solid rgba(0, 255, 0, 0.2)",
          border: "1px solid rgba(108, 122, 137, 1)",
          borderRadius: "5px",
        }}
      >
        <div
          className="presenter-video-overlay-close-btn"
          style={{
            display: status.error ? "flex" : "none",
            position: "absolute",
            top: "0",
            right: "0",
            width: "min-content",
            height: "min-content",
            backgroundColor: "transparent",
            zIndex: 1,
            userSelect: "none",
          }}
          onClick={continueWithoutCamera}
        >
          <Tooltip title="Continue without camera" placement="top" color="info">
            <IconButton color="error">
              <Close />
            </IconButton>
          </Tooltip>
        </div>

        <div>{status.msg}</div>
        <div
          style={{
            display: status.error ? "flex" : "none",
            flexDirection: "column",
          }}
        >
          <IconButton color="info">
            <Refresh onClick={initComp} />
          </IconButton>
        </div>
      </div>

      <div
        className="presenter-video-container"
        style={{
          display: noCameraMode ? "none" : "block",
          width: videoMinimized ? "40px" : "min-content",
          height: "auto",
          backgroundColor: "transparent",
          userSelect: "none",
          //  pointerEvents: "none",
          border: showHeader ? "1px solid rgba(108, 122, 137, 0.9)" : "none",
          borderRadius: "2px",
          //  transition: "all 0.2s ease-in-out",
          zIndex: videoMinimized ? 1000 : 0,
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div
          className="presenter-video-container-header"
          style={{
            display: showHeader ? "flex" : "none",
            flexDirection: "row",
            width: "100%",
            height: "min-content",
            backgroundColor: "rgba(20, 20, 20, 0.9)",
            justifyContent: "flex-end",
          }}
        >
          <Tooltip
            title={
              videoMinimized
                ? "Show presentor's video"
                : "Hide presenter's video"
            }
            placement="top"
            color="info"
          >
            <IconButton
              color="info"
              size="small"
              onClick={toggleVideoContainer}
            >
              {videoMinimized ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Tooltip>
        </div>
        <video
          ref={video_ref}
          onLoadedMetadata={onLoadedMetadata}
          style={{
            width: "1px",
            height: "1px",
            pointerEvents: "none",
            userSelect: "none",
            visibility: "hidden",
            zIndex: "-1",
          }}
          width={videoSize.width}
          height={videoSize.height}
        ></video>
        <canvas
          ref={canvas_ref}
          className="presenter-video-canvas"
          width={videoSize.width}
          height={videoSize.height}
          style={{
            width: `${canvasStyleSize.width}px`,
            height: videoMinimized ? `0px` : `${canvasStyleSize.height}px`,
            // userSelect: "none",
            // transition: "all 0.2s ease-in-out",
          }}
        />
      </div>
    </>
  )
}

//////////////////////////////////
//////////////////////////////////
//////////////////////////////////

function computeCanvasSize(videoSettings: MediaTrackSettings) {
  // Shallow checks for now.. //
  if (!videoSettings || !videoSettings.width || !videoSettings.height) {
    console.log(
      `Parameter 'videoSettings' is not valid. Setting to default maximum values: { width: ${MIN_VIDEO_SIZE}, height: ${MIN_VIDEO_SIZE} }`,
    )
    return { width: MIN_VIDEO_SIZE, height: MIN_VIDEO_SIZE }
  }

  const aspectRatio = videoSettings.aspectRatio as number
  const width = videoSettings.width as number
  const height = videoSettings.height as number

  const isTooBig = width > MAX_VIDEO_SIZE || height > MAX_VIDEO_SIZE
  const isTooSmall = width < MIN_VIDEO_SIZE || height < MIN_VIDEO_SIZE

  if (isTooBig || isTooSmall) {
    //?? Can be better ??//
    if (isTooBig) {
      return { width: MAX_VIDEO_SIZE, height: MAX_VIDEO_SIZE / aspectRatio }
      // return { width: MIN_VIDEO_SIZE, height: MIN_VIDEO_SIZE / aspectRatio }
    } else if (isTooSmall) {
      return { width: MIN_VIDEO_SIZE, height: MIN_VIDEO_SIZE / aspectRatio }
    }
  }

  return { width, height }
}

/**
 * reqAniFrameLoopIdHandler() - Returns current loop id.
 * reqAniFrameLoopIdHandler(false) - Terminates current loop.
 * reqAniFrameLoopIdHandler(id: number) - Terminates current loop and starts new loop with new id.
 **/
function reqAniFrameLoopIdHandler(param?: number | false) {
  const loopId = window.__presenter_mode_req_ani_frame_id
  // If no param is passed //
  if (!param) {
    // Return current loopId if param is not boolean //
    if (param !== false) return loopId
    // If param is boolean `false`, terminate loop //
    //  window.cancelAnimationFrame(loopId)
    window.cancelIdleCallback(loopId)
    return
  }

  // If id param is passed (should be number) //
  //   window.cancelAnimationFrame(loopId)
  window.cancelIdleCallback(loopId)
  window.__presenter_mode_req_ani_frame_id = param
}
