import { AppType } from "@/TYPES/common"
import TileBody from "@/components/TileBody"
import AppTile from "../AppTile"

type Props = { app: AppType; isMain?: boolean }

export default function Tile({ app, isMain = false }: Props) {
  return (
    <TileBody>
      <AppTile tile={app} isMain={isMain} />
    </TileBody>
  )
}
