import { AppType } from "@/TYPES/common"

export const apps = [
  { title: "PowerBl" },
  { title: "Chat App" },
  { title: "App one" },
  { title: "App two" },
  { title: "App three" },
  { title: "App four" },
  { title: "App five" },
  { title: "App six" },
  { title: "App seven" },
  { title: "App eight" },
  { title: "App nine" },
  { title: "App ten" },
] as AppType[]

export const sorts = [{ label: "Last Opened" }, { label: "Name" }]
