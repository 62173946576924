export const getMessageTitle = (users: string[]) => {
  const title = users.join(", ")
  const titleLength = title.length
  const CHARACTER_LIMIT = 12

  if (titleLength <= CHARACTER_LIMIT) {
    return title
  } else {
    return title.slice(0, CHARACTER_LIMIT) + "..."
  }
}

export const getInitials = (name: string) => {
  const splitName = name?.split?.(" ")
  if (!splitName) return ""
  if (splitName.length === 1) {
    return splitName[0].charAt(0).toUpperCase()
  }
  const firstName = splitName[0]
  const lastName = splitName[1]

  return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()
}

export const getFirstName = (name: string) => {
  if (!name) return ""
  return name.split?.(" ")[0]
}
