import { RootState } from "@/TYPES/redux"
import Icon, { IconNameProp } from "@/components/Icon"
import { defaultPopperProps } from "@/helpers"
import { Tooltip } from "@mui/material"
import { useSelector } from "react-redux"

export default function VisitAdmin() {
  const hasSyncableUsers = useSelector(
    (state: RootState) => state.space.syncableUserCount > 0,
  )

  return (
    <Tooltip title="Visit Admin Portal" PopperProps={defaultPopperProps}>
      <a
        className="group relative flex flex-col items-center justify-between p-1"
        target="_blank"
        href={`${window.origin}/portal`}
      >
        <Icon
          className="fill-typegray group-hover:fill-smoke mb-2"
          iconName={IconNameProp.Portal}
          size={22}
        />

        {hasSyncableUsers && (
          <div className="absolute w-2 h-2 top-1 right-0 rounded-full">
            <div className="absolute inset-0 rounded-full bg-accentred animate-ping opacity-75" />
            <div className="relative w-full h-full rounded-full bg-accentred" />
          </div>
        )}
      </a>
    </Tooltip>
  )
}
