import { RootState } from "@/TYPES/redux"
import { useSelectViewport } from "@/utils/hooks/viewport"
import NavContent from "@/pages/Space/Navbar/NavContent"
import { useSelector } from "react-redux"
import cn from "classnames"

export default function Navbar() {
  const { isPad, isDesktop } = useSelectViewport()
  const views = useSelector((state: RootState) => state.space.views)
  const isAddingTile = useSelector(
    (state: RootState) => state.space.isAddingTile || state.space.isAddingTile,
  )
  const isPresenterMode = views.includes("presentation")

  if (!isPad && !isDesktop) return null

  return (
    <div
      className={cn(
        "absolute flex bottom-0 left-1/2 transform -translate-x-1/2 w-[50vw] min-h-fit max-h-[50px] pt-3 pr-2 rounded-sm bg-cobrowsegray transition-width",
        {
          "w-full justify-center": isPresenterMode,
          "z-100": isAddingTile,
          "z-[101]": !isAddingTile,
        },
      )}
    >
      <div className="overflow-auto hide-scrollbar h-full w-full">
        <NavContent />
      </div>
    </div>
  )
}
