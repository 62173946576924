import { RootState } from "@/TYPES/redux"
import $store from "@/store"
import {
  assignAudioDevice,
  assignDevices,
  assignVideoDevice,
} from "@/store/slices/userSlice"
import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

export function useDevices() {
  const dispatch = useDispatch()

  const availableDevices = useSelector(
    (state: RootState) => state.user.availableDevices,
  )
  const audioDevice = useSelector((state: RootState) => state.user.audioDevice)
  const videoDevice = useSelector((state: RootState) => state.user.videoDevice)

  const fetchDevices = useCallback(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const allDevices = devices.map((device) => ({
        label: device.label,
        id: device.deviceId,
        kind: device.kind,
      }))

      dispatch(assignDevices(allDevices))
    })
  }, [])

  useEffect(() => {
    navigator.mediaDevices.ondevicechange = (event) => {
      fetchDevices()
    }
    fetchDevices()
  }, [])

  const onChangeAudioDevice = useCallback(
    (device) => dispatch(assignAudioDevice(device)),
    [],
  )

  const onChangeVideoDevice = useCallback(
    (device) => dispatch(assignVideoDevice(device)),
    [],
  )

  const filterDevicesByType = useCallback(
    (devicetype) => availableDevices.filter((item) => item.kind === devicetype),
    [availableDevices],
  )

  return {
    availableDevices,
    audioDevice,
    videoDevice,
    filterDevicesByType,
    onChangeAudioDevice,
    onChangeVideoDevice,
  }
}

export async function getDevices(): Promise<any> {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices()
    if (!devices || !devices.length) return []
    const allDevices = devices.map((device) => ({
      ...device,
      id: device.deviceId,
    }))

    $store.dispatch({
      type: "user/assignDevices",
      payload: allDevices,
    })

    const filterDevicesByType = (devicetype) =>
      allDevices.filter((item) => item.kind === devicetype)

    const data = {
      availableDevices: allDevices,
      audioDevices: filterDevicesByType("audioinput"),
      videoDevices: filterDevicesByType("videoinput"),
    }

    return Promise.resolve(data)
  } catch (error) {
    console.error(error)
    return Promise.reject(error)
  }
}
