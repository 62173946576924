import { RootState } from "@/TYPES/redux"
import Icon, { IconNameProp } from "@/components/Icon"
import cn from "classnames"
import { useState } from "react"
import { useSelector } from "react-redux"
import FolderItem from "./FolderItem"

type Props = {
  narrow?: boolean
  darkMode?: boolean
}

export default function FileFolderManager({
  narrow = false,
  darkMode = false,
}: Props) {
  const [viewMode, setViewMode] = useState<string>("list")
  const folders = useSelector((state: RootState) => state.files.folders)

  return (
    // Everything under My Files header when not inside folder
    <div className="h-full flex flex-col text-carbon py-[22px] gap-[11px] overflow-auto">
      <div className="flex items-center px-[22px]">
        <Icon
          className={cn("rotate-90", { "fill-lavender": darkMode })}
          iconName={IconNameProp.Chevron}
          size={10}
        />
        <span
          className={cn("ml-[11px]", {
            "text-14": narrow,
            "text-lavender": darkMode,
          })}
        >
          Folders
        </span>
      </div>

      <div
        className={cn("flex flex-col", {
          "flex-row flex-wrap": viewMode === "tile",
        })}
      >
        {folders.map((item) => (
          <FolderItem
            key={item.id}
            folder={item}
            viewMode={viewMode}
            narrow={narrow}
            darkMode={darkMode}
          />
        ))}
      </div>
    </div>
  )
}
