/**
 * This file holds all the functions we need to make Webex work
 * the way it's supposed to
 */
import $store from '@/store'
import {
  cleanRoomMemberships,
  createRoomMembership,
  deleteRoomMembership,
  getAccessToken,
  getGuesUser,
  getJwt,
  getMeetingInfo,
} from "@/helpers/webex"
import io from "socket.io-client"
// import Webex from 'webex'

const storeState = $store.getState();
const env = storeState.env;

const PREVIEWSIZE = 0.65
const width = PREVIEWSIZE * window.screen.width
const height = PREVIEWSIZE * window.screen.height
const constraints = { audio: true, video: { width: width, height: height } }

let webex
let mediaStream
let localStream
let remoteVideo
let remoteAudio
let remoteShare
let localShare
let notifiedCallInUser = {}
let userId = undefined
const guestCallIn = "guest-call-in"
const screenShare = "screen-share"
const setWebexSelf = "set-webex-self"
const checkParticipants = "check-participants"
const baseSocketUrl = window._env_?.REACT_APP_SOCKET_SERVER ||
  env.REACT_APP_SOCKET_SERVER ||
  import.meta.env.REACT_APP_SOCKET_SERVER
const roomId = window.location.pathname.split("/")[2]
const socketGuestToken = io(baseSocketUrl + "/space-" + roomId, {
  transports: ["websocket"],
})

const setElmentSrc = (elementId: string, value: string | null) => {
  try {
    const element: any = document.getElementById("self-view-video")
    element.srcObject = value
  } catch (error) {
    console.log(error)
  }
}

const stopElementTracks = (elementId: string) => {
  try {
    const element: any = document.getElementById(elementId)

    element.srcObject.getTracks().forEach((track) => track.stop())
  } catch (e) {
    console.error("Error stopping mediaStream tracks:", e)
  }
}

// from https://github.com/webex/webex-js-sdk/blob/master/packages/node_modules/samples/browser-plugin-meetings/app.js
export function getCurrentMeeting() {
  try {
    const meetings = webex.meetings.getAllMeetings()
    return meetings[Object.keys(meetings)[0]]
  } catch (error) {
    console.error(error)
    return
  }
}

// from https://github.com/webex/webex-js-sdk/blob/master/packages/node_modules/samples/browser-plugin-meetings/app.js
function stopMediaTrack(type) {
  const meeting = getCurrentMeeting()

  if (!meeting) return
  const { audioTrack, videoTrack, shareTrack } = meeting.mediaProperties

  switch (type) {
    case "audio":
      try {
        audioTrack.stop()
        break
      } catch (e) {
        console.error(e)
      }
      break
    case "video":
      try {
        videoTrack.stop()
        break
      } catch (e) {
        console.error(e)
      }
      break
    case "share":
      try {
        shareTrack.stop()
        break
      } catch (e) {
        console.error(e)
      }
      break
    default:
      break
  }
}

function bindMeetingEvents(meeting) {
  meeting.on("media:ready", (media) => {
    if (!media) {
      return
    }
    if (media.type === "local") {
      setElmentSrc("self-view-video", media.stream)
      localStream = media.stream
    }
    if (media.type === "remoteVideo") {
      setElmentSrc("remote-view-video", media.stream)
      remoteVideo = media.stream
    }
    if (media.type === "remoteAudio") {
      setElmentSrc("remote-view-audio", media.stream)
      remoteAudio = media.stream
    }
    if (media.type === "remoteShare") {
      // Remote share streams become active immediately on join, even if nothing is being shared
      remoteShare = media.stream
    }
    if (media.type === "localShare") {
      localShare = media.stream
    }
  })

  meeting.on("meeting:media:remote:start", () => {
    addElementMedia(undefined)
    // we can now initialize the webex members list
    // meeting.changeVideoLayout('Single').catch((e) => {
    //   console.error(e)
    // })
    socketGuestToken.emit(setWebexSelf, { ready: true })
  })

  meeting.on("meeting:stoppedSharingLocal", () => {
    socketGuestToken.emit(screenShare, { sharing: false, id: userId })
  })

  meeting.on("meeting:stoppedSharingRemote", () => {
    socketGuestToken.emit(screenShare, { sharing: false, id: userId })
  })

  meeting.on("meeting:actionsUpdate", () => {
    if (userId === undefined) {
      let members = getMeetingMembers()
      for (let member in members) {
        if (members[member].isSelf) userId = members[member].id
      }
    }
    socketGuestToken.emit(checkParticipants)
  })

  meeting.on("all", (event) => {
    console.info(event)
  })
}

/**
 * Here we want our 3Data user to connect to webex so they can initialize the meeting.
 * Previously we were able to use the webex NPM package, however, it was causing
 * problems during development. Webex is currently included using a script tag and
 * and unpkg link that gets the most recent major version while avoiding
 * breaking changes.
 */
async function connect(accessToken) {
  webex = window.Webex.init({
    meetings: {
      reconnection: {
        enabled: true,
      },
    },
    credentials: {
      access_token: accessToken,
    },
  })
  if (!webex.meetings.registered) {
    await webex.meetings.register()
  }

  return
}

/**
 * @param meeting current meeting object as returned by Webex package
 *
 * Determining if we can update media for screen-sharing capability. Gives 15
 * seconds before timeout.
 */
function waitForMediaReady(meeting) {
  return new Promise<void>((resolve, reject) => {
    if (meeting.canUpdateMedia()) {
      resolve()
    } else {
      console.info("SHARE-SCREEN: Unable to update media, pausing to retry...")
      let retryAttempts = 0

      const retryInterval = setInterval(() => {
        retryAttempts += 1
        console.info("SHARE-SCREEN: Retry update media check")

        if (meeting.canUpdateMedia()) {
          console.info("SHARE-SCREEN: Able to update media, continuing")
          clearInterval(retryInterval)
          resolve()
        }
        // If we can't update our media after 15 seconds, something went wrong
        else if (retryAttempts > 15) {
          console.error(
            "SHARE-SCREEN: Unable to share screen, media was not able to update.",
          )
          clearInterval(retryInterval)
          reject()
        }
      }, 1000)
    }
  })
}

/**
 * We want to know if the user has the devices available
 * to request permissions from to avoid unnecessary errors
 */
async function checkForAvailableDevices(audioMute, videoMute) {
  let devices = { audio: false, video: false }
  let md = navigator.mediaDevices
  if (!md) {
    console.error("No audio or video devices detected.")
    return "No audio or video devices detected."
  }

  let media = await md.enumerateDevices()

  media.forEach((device) => {
    if (device.kind === "audioinput") devices.audio = true
    if (device.kind === "videoinput") devices.video = true
  })

  return {
    receiveVideo: true,
    receiveAudio: true,
    receiveShare: true,
    sendShare: false,
    sendVideo: videoMute !== true ? devices.video : false,
    sendAudio: audioMute !== true ? devices.audio : false,
  }
}

async function getSourceId(audioSource, videoSource) {
  let audioVideo = {}
  let devices = await navigator.mediaDevices.enumerateDevices()
  devices.forEach((device) => {
    // set proper device if able
    if (device.deviceId === audioSource) {
      audioVideo = Object.assign(audioVideo, {
        audio: { deviceId: device.deviceId },
      })
    }
    if (device.deviceId === videoSource) {
      audioVideo = Object.assign(audioVideo, {
        video: { deviceId: device.deviceId, aspectRatio: 1.7777777778 },
      })
    }
  })
  return audioVideo
}

export async function setupWebex(roomId) {
  let token
  const jwt: any = await getJwt()
  const authObj: any = await getAccessToken(jwt.token)
  token = authObj.token

  await connect(token)

  const meeting: any = await getMeetingInfo(roomId)
  await webex.meetings.create(meeting.sipAddress)

  const guestObj: any = await getGuesUser(authObj.token)
  await createRoomMembership(guestObj.id, roomId)
  return
}

export async function joinMeeting(
  audioSource,
  videoSource,
  audioMute,
  videoMute,
) {
  let activeMeeting = getCurrentMeeting()

  bindMeetingEvents(activeMeeting)

  let mediaSettings = await checkForAvailableDevices(audioMute, videoMute)
  let audioVideo = await getSourceId(audioSource, videoSource)

  let localStream
  let localShare

  try {
    ;[localStream, localShare] = await activeMeeting.getMediaStreams(
      mediaSettings,
      audioVideo,
    )
  } catch (e) {
    ;[localStream, localShare] = await activeMeeting.getMediaStreams(
      mediaSettings,
      {
        audio: true,
        video: { aspectRatio: 1.7777777778 },
      },
    )
  }

  await activeMeeting.join()
  await activeMeeting.addMedia({
    mediaSettings,
    localShare,
    localStream,
  })

  return localStream
}

export async function leaveCall(roomId, accessLevel) {
  let activeMeeting = getCurrentMeeting()

  if (activeMeeting) {
    // if we're using users audio/video devices
    if (mediaStream) {
      // stop using them
      mediaStream.getTracks().forEach((track) => {
        track.stop()
      })
    }
    activeMeeting.leave()
    await deleteRoomMembership()
    if (accessLevel === "owner") {
      await cleanRoomMemberships(roomId)
    }
    return
  } else {
    console.error("No active meeting!")
    return
  }
}

/**
 * We want to take action on the video
 * depending on the state of the button. If we're
 * re-adding video, we also want to check to see
 * if we previously had audio and keep that in there
 * too.
 *
 * @param isSelected whether the video mute button has been selected or not
 */
export async function muteVideoPreview(isSelected) {
  if (!mediaStream || mediaStream.error) return

  if (isSelected) {
    mediaStream.getTracks().forEach((track) => {
      if (track.readyState === "live" && track.kind === "video") {
        track.stop()
      }
    })
    setElmentSrc("self-view-preview", null)
    return
  } else {
    let options: any = { video: { width: width, height: height } }
    mediaStream.getTracks().forEach((track) => {
      if (track.readyState === "live" && track.kind === "audio")
        options.audio = true
    })
    mediaStream = await navigator.mediaDevices.getUserMedia(options)
    setElmentSrc("self-view-preview", mediaStream)

    return new Promise<void>((resolve) => resolve())
  }
}

/**
 * We want to take action on the audio
 * depending on the state of the button. If we're
 * re-adding audio, we also want to check to see
 * if we previously had video and keep that in there
 * too
 *
 * @param {*} isSelected whether the audio mute button has been selected or not
 */
export async function muteAudioPreview(isSelected) {
  if (mediaStream === undefined || mediaStream.error) return

  if (isSelected) {
    mediaStream.getTracks().forEach((track) => {
      if (track.readyState === "live" && track.kind === "audio") {
        track.stop()
      }
    })
    return
  } else {
    let options: any = { audio: true }
    mediaStream.getTracks().forEach((track) => {
      if (track.readyState === "live" && track.kind === "video")
        options.video = { width: width, height: height }
    })
    mediaStream = await navigator.mediaDevices.getUserMedia(options)
    setElmentSrc("self-view-preview", mediaStream)

    return
  }
}

export async function muteAudio(isSelected) {
  let activeMeeting = getCurrentMeeting()

  if (!isSelected && activeMeeting.mediaProperties.audioTrack === undefined) {
    const query = new URLSearchParams(window.location.search)
    const audioSource = query.get("audioSource")
    changeAudioDeviceCall(audioSource, true)
  } else if (!isSelected && activeMeeting.isAudioMuted()) {
    try {
      await activeMeeting.unmuteAudio()
    } catch (error) {
      console.error(error)
    }
  } else if (isSelected) {
    try {
      await activeMeeting.muteAudio()
    } catch (error) {
      console.error(error)
    }
  }
  return
}

export async function muteVideo(isSelected) {
  let activeMeeting = getCurrentMeeting()

  if (mediaStream === undefined) return

  if (!isSelected && activeMeeting.mediaProperties.videoTrack === undefined) {
    const query = new URLSearchParams(window.location.search)
    const videoSource = query.get("videoSource")
    changeVideoDeviceCall(videoSource)
  } else if (!isSelected && activeMeeting.isVideoMuted()) {
    try {
      await activeMeeting.unmuteVideo()
    } catch (error) {
      console.error(error)
    }
  } else if (isSelected) {
    try {
      await activeMeeting.muteVideo()
    } catch (error) {
      console.error(error)
    }
  }
  return
}

export async function shareScreenPress() {
  let activeMeeting = getCurrentMeeting()

  // if theres an active meeting
  if (activeMeeting) {
    // and we've pressed the button an no one is sharing
    if (!activeMeeting.isSharing) {
      // start the process of sharing
      await waitForMediaReady(activeMeeting)
      await activeMeeting.shareScreen({
        sendAudio: false,
        sendShare: true,
        sharePreferences: { highFrameRate: false },
      })
      socketGuestToken.emit(screenShare, { sharing: true, id: userId })
      return true
    } else {
      // otherwise stop sharing
      await waitForMediaReady(activeMeeting)
      await activeMeeting.stopShare()
      socketGuestToken.emit(screenShare, { sharing: false, id: userId })
      return false
    }
  } else {
    console.error("No active meeting available to share screen.")
    return false
  }
}

// export async function getUserMedia() {
//    try {
//       mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
//       document.getElementById('self-view-preview').srcObject = mediaStream;
//    } catch (error) {
//       console.error(error);
//    }
//    return mediaStream;
// }

export async function getUserMedia() {
  console.log("getUserMedia()")
  try {
    console.log("mediaDevices", window.navigator.mediaDevices)

    mediaStream = await window.navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    })
    // const videoTracks = mediaStream.getVideoTracks()[0];
    // const audioTracks = mediaStream.getAudioTracks();
    const videoEl: any = document.getElementById("self-view-preview")
    videoEl.srcObject = mediaStream

    // const videoSettings = videoTracks.getSettings();
    // console.warn({ videoSettings });
  } catch (error: any) {
    //>> https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#exceptions
    const { code, message, name } = error
    console.error({ error })
    mediaStream = { error: true, code, message, name }
  } finally {
    return mediaStream
  }
}

export async function changeVideoDevicePreview(deviceName, audioDevice) {
  let constraints: any = {
    audio: true,
    video: { width: width, height: height },
  }
  // stop all tracks (even audio) to change one of them
  stopElementTracks("self-view-preview")

  let devices = await navigator.mediaDevices.enumerateDevices()
  devices.forEach((device) => {
    if (device.deviceId === deviceName)
      constraints.video.deviceId = { exact: device.deviceId }
    // we want to continue using the same audio device
    if (device.label === audioDevice.label)
      constraints.audio = { deviceId: { exact: device.deviceId } }
  })
  try {
    mediaStream = await navigator.mediaDevices.getUserMedia(constraints)
  } catch (error) {
    console.error(error)
  }

  setElmentSrc("self-view-preview", mediaStream)

  return
}

export async function changeAudioDevicePreview(
  deviceName,
  videoDevice,
  videoMuted,
) {
  let constraints: any = {
    audio: true,
    video: videoMuted ? false : { width: width, height: height },
  }
  // stop all tracks (even video) to change one of them
  stopElementTracks("self-view-preview")

  let devices = await navigator.mediaDevices.enumerateDevices()
  devices.forEach((device) => {
    if (device.deviceId === deviceName)
      constraints.audio = { deviceId: { exact: device.deviceId } }
    // we want to continue using the same video device
    if (device.label === videoDevice.label && !videoMuted)
      constraints.video.deviceId = { exact: device.deviceId }
  })
  try {
    mediaStream = await navigator.mediaDevices.getUserMedia(constraints)
  } catch (error) {
    console.error(error)
  }

  setElmentSrc("self-view-preview", mediaStream)

  return
}

export async function changeVideoDeviceCall(deviceName) {
  let activeMeeting = getCurrentMeeting()
  let { video }: any = await getSourceId(undefined, deviceName)
  if (activeMeeting) {
    stopMediaTrack("video")

    let [localStream] = await activeMeeting.getMediaStreams(
      {
        sendAudio: true,
        receiveAudio: true,
        sendVideo: true,
        receiveVideo: true,
      },
      { video },
    )

    await activeMeeting.updateVideo({
      sendVideo: true,
      receiveVideo: true,
      stream: localStream,
    })

    return
  }
}

export async function changeAudioDeviceCall(deviceName, firstTime) {
  let activeMeeting = getCurrentMeeting()
  let { audio }: any = await getSourceId(deviceName, undefined)

  if (activeMeeting) {
    if (firstTime !== true) stopMediaTrack("audio")

    let [localStream] = await activeMeeting.getMediaStreams(
      {
        sendAudio: true,
        receiveAudio: true,
        sendVideo: true,
        receiveVideo: true,
      },
      { audio, video: true },
    )
    await activeMeeting.updateAudio({
      sendAudio: true,
      receiveAudio: true,
      stream: localStream,
    })
    return
  }
}

export function addElementMedia(sharing) {
  if (localStream && document.getElementById("self-view-video")) {
    setElmentSrc("self-view-video", localStream)
  }
  if (remoteVideo && document.getElementById("remote-view-video")) {
    setElmentSrc("remote-view-video", remoteVideo)
  }
  if (remoteAudio && document.getElementById("remote-view-audio")) {
    setElmentSrc("remote-view-audio", remoteAudio)
  }
  if (
    localShare &&
    document.getElementById("screen-share-video") &&
    sharing === "local"
  ) {
    setElmentSrc("screen-share-video", localShare)
  }
  if (
    remoteShare &&
    document.getElementById("screen-share-video") &&
    (sharing === "remote" || sharing === undefined)
  ) {
    setElmentSrc("screen-share-video", remoteShare)
  }
}

export function admitLobbyUser(id) {
  let activeMeeting = getCurrentMeeting()
  activeMeeting.admit(id)
  return
}

export function removeLobbyUser(id) {
  let activeMeeting = getCurrentMeeting()

  activeMeeting.remove(id)
  return
}

export function refreshLobby() {
  const refresLobbyInterval = setInterval(() => {
    try {
      const membersList = getMeetingMembers()

      for (let member in membersList) {
        if (
          membersList[member].isInLobby &&
          notifiedCallInUser[membersList[member]] === undefined &&
          membersList[member].name.includes("Call-in")
        ) {
          notifiedCallInUser[membersList[member]] = {
            id: membersList[member].id,
            name: membersList[member].name,
          }
          socketGuestToken.emit(
            guestCallIn,
            notifiedCallInUser[membersList[member]],
          )
        }
      }
    } catch (e) {
      console.error(e)
    }
  }, 5000)
}

// allows one user to mute another
export function muteUser(id) {
  const meeting = getCurrentMeeting()
  meeting.mute(id, true)
}

// allows one user to remove another
export function removeUser(id) {
  const meeting = getCurrentMeeting()
  meeting.remove(id)
}

// list of all users with space membership
export function getMeetingMembers() {
  const meeting = getCurrentMeeting()
  return meeting.members.membersCollection.members
}
