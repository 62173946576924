import { ChildrenType } from "@/TYPES/common"
import cn from "classnames"

type Props = {
  className?: string
  children?: ChildrenType
}

export default function TileHeader({ className, children }: Props) {
  return (
    <div
      className={cn(
        className,
        "relative h-[38px] flex items-center bg-gradient-gray2 px-3",
        "sm:h-[36px] sm:px-[10px] sm:border-y border-darkgray",
      )}
    >
      {children}
    </div>
  )
}
