import validator from "validator"
import $store from "@/store"

export * from "./assets"
export * from "./call"
export * from "./cobrowse"
export * from "./fetcher"
export * from "./guac"
export * from "./rooms"
export * from "./swr"
export * from "./tiles"
export * from "./user"
export * from "./cursor"
export * from "./env"
export * from "./annotation"
export * from "./session"

const { isURL } = validator

export function appendUrlPath(baseUrl: string, paths: string) {
  // Trim slashes at the ends of baseUrl and at the start of paths
  const sanitizedBaseUrl = baseUrl.replace(/\/+$/, "")
  const sanitizedPaths = paths.replace(/^\/+/, "")

  return `${sanitizedBaseUrl}/${sanitizedPaths}`
}

//!! INCOMPLETE - DO NOT USE !!//
export function appendSubpathToURL(url: string, subpath: string) {
  if (!url || !url.trim() || !subpath || !subpath.trim()) return
  let newUrl = url.trim()
  subpath = subpath.trim()
  const hasProtocol =
    newUrl.startsWith("http://") || newUrl.startsWith("https://")
  if (!hasProtocol) newUrl = `https://${newUrl}`
  newUrl = fixDoubleSlashInURL(newUrl)
  if (!newUrl) {
    window.log(`[appendSubpathToURL()] Missing url ${url}`, "error")
    return
  }
  const hasTrailingSlash = newUrl.endsWith?.("/")
  hasTrailingSlash && (newUrl = newUrl.slice(0, -1))
  const isUrl = isURL(newUrl, { require_protocol: true })
  if (!isUrl) return
  const normedSubpath = subpath.startsWith("/") ? subpath : `/${subpath}`
  newUrl = newUrl + normedSubpath
  if (!isURL(newUrl, { require_protocol: true })) return
  return newUrl
}

//!! INCOMPLETE - DO NOT USE !!//
export function fixDoubleSlashInURL(url) {
  const PROTOCOL_DETECTOR = "://"
  if (!url || !url.trim()) return
  url = url.trim()
  const splitUrl = url.split(PROTOCOL_DETECTOR)
  //@@ URL has multiple `PROTOCOL_DETECTOR` strings @@//
  if (splitUrl.length > 2) {
    window.log(
      `[fixDoubleSlashInURL()] URL has multiple "${PROTOCOL_DETECTOR}" strings`,
      "error",
    )
    return
  }
  const hasProtocol = splitUrl.length === 2
  if (!hasProtocol) {
    const origin = splitUrl[0]
    origin.replaceAll("//", "/")
    return origin
  }
  const protocol = splitUrl[0] + PROTOCOL_DETECTOR
  const urlWithoutProtocol = url.replace(protocol, "")
  console.log({ protocol, origin })
}

export const CSS_COLOR_NAMES = [
  "Blue",
  "BlueViolet",
  "Brown",
  "CadetBlue",
  "Chocolate",
  "Coral",
  "CornflowerBlue",
  "Crimson",
  "DarkBlue",
  "FireBrick",
  "ForestGreen",
  "Fuchsia",
  "GoldenRod",
  "Green",
  "IndianRed",
  "Indigo",
  "Magenta",
  "Maroon",
  "Navy",
  "Olive",
  "Orange",
  "Orchid",
  "Peru",
  "Purple",
  "RebeccaPurple",
  "Red",
  "RoyalBlue",
  "SaddleBrown",
  "SeaGreen",
  "Sienna",
  "SteelBlue",
  "Teal",
  "Tomato",
]

export const CSS_COLOR_HEX = [
  "#0000ff",
  "#8a2be2",
  "#a52a2a",
  "#5f9ea0",
  "#d2691e",
  "#ff7f50",
  "#6495ed",
  "#dc143c",
  "#00008b",
  "#b22222",
  "#228b22",
  "#ff00ff",
  "#daa520",
  "#008000",
  "#cd5c5c",
  "#4b0082",
  "#ff00ff",
  "#800000",
  "#000080",
  "#808000",
  "#ffa500",
  "#da70d6",
  "#cd853f",
  "#800080",
  "#663399",
  "#ff0000",
  "#4169e1",
  "#8b4513",
  "#2e8b57",
  "#a0522d",
  "#4682b4",
  "#008080",
  "#ff6347",
]

export function debounce(func: Function, wait: number) {
  let timeout
  return function () {
    //@ts-ignore
    const context = this,
      args = arguments
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(context, args), wait)
  }
}

export function resolveToValidHttpURL(url: string) {
  try {
    const isValid = isURL(url, {
      protocols: ["http", "https"],
    })
    if (!isValid) return false

    let finalURL = url
    if (!finalURL.startsWith("http://") && !finalURL.startsWith("https://")) {
      finalURL = `https://${finalURL}`
    }
    return finalURL
  } catch (err) {
    return false
  }
}

export const getPopperProps = (overrides = {}): PopperPropsType => ({
  modifiers: [
    {
      name: "offset",
      options: {
        offset: [0, -6],
        ...overrides,
      },
    },
  ],
})

export const defaultPopperProps = {
  modifiers: [
    {
      name: "offset",
      options: {
        offset: [0, -6],
      },
    },
  ],
}

interface PopperPropsType {
  modifiers: {
    name: string
    options: {
      offset: number[]
    }
  }[]
}
