import { createSlice } from "@reduxjs/toolkit"

export const guestSlice = createSlice({
  name: "guest",
  initialState: {
    _minDisplayNameLength: 1, // Minimum length of the guest's display name.
    displayName: "", // Display name of the guest.
    displayNameInput: "", // Input field for the guest's display name in preview screen.
    isValidDisplayNameInput: false,
    firstNameInput: "", // Input field for the guest's first name to be combined as display name.
    lastNameInput: "", // Input field for the guest's last name to be combined as display name.
    mmData: null, // Mattermost data of the guest.
    mmDesiredFormShow: false, // Show the form for the guest to enter their desired name and email.
    mmDesiredFirstName: null, // Desired first name of the guest.
    mmDesiredLastName: null, // Desired last name of the guest.
    mmDesiredEmail: null, // Desired email of the guest (temporary dummy email).
    mmDesiredTeamName: null, // Desired team name to join.
    fetching: false,
  },

  reducers: {
    assignFetching(state, action) {
      return {
        ...state,
        fetching: action.payload,
      }
    },
    updateGuestDisplayName(state, action) {
      return {
        ...state,
        displayName: action.payload,
      }
    },
    updateGuestDisplayNameInput(state, action) {
      return {
        ...state,
        displayNameInput: action.payload,
      }
    },
    updateGuestIsValidDisplayNameInput(state, action) {
      return {
        ...state,
        isValidDisplayNameInput: action.payload,
      }
    },
    updateGuestFirstNameInput(state, action) {
      return {
        ...state,
        firstNameInput: action.payload,
        displayNameInput:
          action.payload && state.lastNameInput
            ? `${action.payload} ${state.lastNameInput}`
            : "",
      }
    },
    updateGuestLastNameInput(state, action) {
      return {
        ...state,
        lastNameInput: action.payload,
        displayNameInput:
          action.payload && state.firstNameInput
            ? `${state.firstNameInput} ${action.payload}`
            : "",
      }
    },
    updateMMData(state, action) {
      return {
        ...state,
        mmData: action.payload,
      }
    },
    updateMMDesiredFormShow(state, action) {
      return {
        ...state,
        mmDesiredFormShow: action.payload,
      }
    },
    updateMMDesiredFirstName(state, action) {
      return {
        ...state,
        mmDesiredFirstName: action.payload,
      }
    },
    updateMMDesiredLastName(state, action) {
      return {
        ...state,
        mmDesiredLastName: action.payload,
      }
    },
    updateMMDesiredEmail(state, action) {
      return {
        ...state,
        mmDesiredEmail: action.payload,
      }
    },
    updateMMTeamName(state, action) {
      return {
        ...state,
        mmDesiredTeamName: action.payload,
      }
    },
  },
})

export const {
  updateGuestDisplayName,
  updateGuestDisplayNameInput,
  updateMMData,
  updateMMDesiredFormShow,
  updateMMDesiredFirstName,
  updateMMDesiredLastName,
  updateMMDesiredEmail,
  updateMMTeamName,
  updateGuestFirstNameInput,
  updateGuestLastNameInput,
  updateGuestIsValidDisplayNameInput,
  assignFetching,
} = guestSlice.actions

export default guestSlice.reducer
