import App from "@/App"
import "@/assets/styles/index.scss"
import store from "@/store"
import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"

window.log = log

window._env_ = window._env_ || {}
window.env = window.env || {}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
)

function log(msg, type = "info", data) {
  const logStyles = {
    info: `background:black;color:#87ceeb;border:1px solid #87ceeb;font-size:18px;padding:10px;`,
    error: `background:black;color:red;border:1px solid red;font-size:18px;padding:10px;`,
    warn: `background:black;color:orange;border:1px solid orange;font-size:18px;padding:10px;`,
    highlight: `background:black;color:#bada55;border:1px solid #bada55;font-size:18px;padding:10px;`,
  }
  if (data) return console.log(`%c[cc-frontend]: ${msg}`, logStyles[type], data)
  return console.log(`%c[cc-frontend]: ${msg}`, logStyles[type])
}
