import { RootState } from "@/TYPES/redux"
import Icon, { IconNameProp } from "@/components/Icon"
import TitleBar from "@/components/TitleBar"
import { setActiveEvent, setEditEvent } from "@/store/slices/eventsSlice"
import cn from "classnames"
import { useDispatch, useSelector } from "react-redux"
import { ICON_SIZE, createRandomEvent } from "./utils"

interface Props {
  isDarkMode: boolean
}

export default function EventLeftPanelHeader({ isDarkMode }: Props) {
  const editEvent = useSelector((state: RootState) => state.events.editEvent)
  const dispatch = useDispatch()

  const handleCreateNewEvent = () => {
    const { newEvent, eventuuid } = createRandomEvent(new Date())
    dispatch(setEditEvent(newEvent))
    dispatch(setActiveEvent(eventuuid))
  }

  return (
    <TitleBar
      className={cn("text-carbon flex-none justify-between border-b-[0.5px] sm:hidden px-4 pt-1", {
        "border-typegray": !isDarkMode,
        "border-cloudy": isDarkMode,
      })}
        bgColor={isDarkMode ? "cobrowse" : "gradient-white"}
    >
      <span
        className={cn("text-22 font-bold", {
          "text-carbon": !isDarkMode,
          "text-smoke": isDarkMode,
        })}
      >
        Calendar
      </span>
      {!editEvent && (
        <a
          className="flex justify-center items-center h-[20px] w-[20px] cursor-pointer overflow-hidden"
          onClick={() => handleCreateNewEvent()}
        >
          <Icon
            className={cn({
              "fill-carbon": !isDarkMode,
              "fill-ghostgray hover:fill-white": isDarkMode,
            })}
            iconName={IconNameProp.Add}
            size={ICON_SIZE}
          />
        </a>
      )}
    </TitleBar>
  )
}
