import type { RootState } from "@/TYPES/redux"
import { useSelector } from "react-redux"
import CanvasVideoOwner from "@/components/Call/CanvasVideo/CanvasVideoOwner"
import CanvasVideoUser from "@/components/Call/CanvasVideo/CanvasVideoUser"
import io from "socket.io-client"
import { useEffect, useState, useContext } from "react"
import { SocketContext } from "@/context/socket"

export default function CanvasVideo() {
  const socket = useContext(SocketContext)
  const [vidSocket, setVidSocket] = useState<any>(null)
  const isPresenterMode = useSelector(
    (state: RootState) => state.space.isPresenterMode,
  )
  const room_id = useSelector(
    (state: RootState) => state.room.data?._id || state.space.spaceId,
  )
  const user_id = useSelector((state: RootState) => state.user.data?._id)
  // As of 1/23/2024, only oroiginal owner can "present" which means this video is only triggered by original owner
  const isOriginalOwner = room_id === user_id

  const env = useSelector((state: RootState) => state.env)
  const baseSocketUrl =
    window._env_.REACT_APP_SOCKET_SERVER ||
    env.REACT_APP_SOCKET_SERVER ||
    import.meta.env.REACT_APP_SOCKET_SERVER
  const subPath = (() => {
    try {
      const urlObj = new URL(baseSocketUrl)
      const pathname =
        urlObj.pathname ||
        env.REACT_APP_SOCKET_SUBPATH ||
        import.meta.env.REACT_APP_SOCKET_SUBPATH ||
        ""
      return pathname
    } catch (error) {
      console.error(error)
      return (
        env.REACT_APP_SOCKET_SUBPATH || import.meta.env.REACT_APP_SOCKET_SUBPATH
      )
    }
  })()
  const vsocketURL = `${baseSocketUrl}/presenter-video-${room_id}`

  useEffect(() => {
    const vsock = io(vsocketURL, {
      path: subPath,
      transports: ["websocket"],
    })
    vsock.on("connect", () => {
      console.log("vidSocket connected")
    })
    vsock.on("disconnect", () => {
      console.log("vidSocket disconnected")
    })
    setVidSocket(vsock)
    return () => {
      // vsock.close()
      vsock.disconnect()
    }
    //  socket.on("init-presenter-video", (payload: any) => {
    //    console.log("init-presenter-video", payload)
    //    setVidSocket(
    //      io(baseSocketUrl, {
    //        path: subPath,
    //        transports: ["websocket"],
    //      }),
    //    )
    //  })
    //  return () => {
    //    socket.off("init-presenter-video")
    //    vidSocket?.close()
    //  }
  }, [])

  if (!isPresenterMode) return <></>
  if (!vidSocket) return <div>Could not connect to socket</div>
  if (isOriginalOwner) {
    return (
      <>
        <CanvasVideoOwner socket={vidSocket} />
      </>
    )
  }
  return (
    <>
      <CanvasVideoUser socket={vidSocket} />
    </>
  )
}
