import { RootState } from "@/TYPES/redux"
import Icon, { IconNameProp } from "@/components/Icon"
import ConfirmationModal from "@/components/Modal/ConfirmationModal"
import { getTileFaviconURL, getIconNameProp, getPopperProps } from "@/helpers"
import { capitalize } from "@mui/material"
import Tooltip from "@mui/material/Tooltip"
import cn from "classnames"
import { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"

export default function SmallTileHeader({
  app,
  isMain,
  isFile = false,
  isRDP = false,
  onRemove,
}) {
  const [faviconURL, setFaviconURL] = useState<string | null>(null)
  const [confirmingToRemove, setConfirmingToRemove] = useState(false)

  const isRoomOwner = useSelector(
    (state: RootState) => state.permission.level === "owner",
  )
  const isSmallSidebar = useSelector(
    (state: RootState) => state.space.isSmallSidebar,
  )

  const handleRemove = useCallback(() => {
    setConfirmingToRemove(false)
    onRemove(app)
  }, [onRemove, app])

  const title = app.title || app.fileName

  const getFavURL = async (app) => {
    try {
      const url = await getTileFaviconURL(app)
      setFaviconURL(url)
    } catch (err) {
      return null
    }
  }

  const normalizeSrc = (url) => {
    let finalUrl = url
    if (!/^https?:\/\//i.test(url)) {
      finalUrl = `https://${url}`
    }
    return finalUrl
  }

  useEffect(() => {
    const needsFavicon = !faviconURL && type === "cobrowse"
    if (needsFavicon) {
      const cobrowseSrc = normalizeSrc(app.title)
      getFavURL({ ...app, cobrowseSrc })
    }
  }, [app._id, faviconURL])

  const type = app.type
  const fileName = app.fileName
  const iconName = getIconNameProp(fileName)
  const resolvedType = (() => {
    if (type === "iframe" || fileName === "iframe") return "Embed"
    if (fileName?.endsWith(".pdf")) return "PDF"
    if (fileName?.endsWith(".rdp")) return "RDP"
    if (
      fileName?.endsWith(".png") ||
      fileName?.endsWith(".jpg") ||
      fileName?.endsWith(".jpeg") ||
      fileName?.endsWith(".gif")
    ) {
      return `Image (${fileName?.split(".").pop()?.toUpperCase()})`
    }

    return capitalize(type || "")
  })()

  const TooltipTitle = (
    <div className="descriptor-value-grid">
      <div className="descriptor-value-pair">
        <div className="descriptor">TITLE:</div>
        <div className="value">{title}</div>
      </div>
      <div className="descriptor-value-pair">
        <div className="descriptor">TYPE:</div>
        <div className="value">{resolvedType}</div>
      </div>
      {app.browser && (
        <div className="descriptor-value-pair">
          <div className="descriptor">BROWSER:</div>
          <div className="value">{capitalize(app.browser)}</div>
        </div>
      )}
    </div>
  )

  return (
    <div className="w-full h-[36px] flex items-center justify-between px-[7px]">
      {!isSmallSidebar && (
        <Tooltip
          title={TooltipTitle}
          placement="bottom"
          PopperProps={popperProps}
          enterDelay={1000}
        >
          <div
            className="flex items-center cursor-default"
            style={{
              width: "max-content",
              height: "100%",
              marginRight: "5px",
            }}
          >
            {!isFile && faviconURL ? (
              <img
                className="w-[16px] h-[16px] mr-[5px] rounded min-w-4"
                src={faviconURL}
                alt="favicon"
                style={{
                  objectFit: "contain",
                }}
              />
            ) : (
              <Icon
                iconName={iconName}
                wrapperClassName="!w-full h-full"
                className="w-full h-full fill-accentyellow"
              />
            )}
          </div>
        </Tooltip>
      )}

      <div
        className={cn("text-13 truncate w-full", {
          "text-lavender": !isMain,
          "text-accentyellow": isMain,
        })}
      >
        {title}
      </div>
      {isRoomOwner && (
        <div
          className="group w-[32px] min-w-[32px] h-[32px] flex-center cursor-pointer transform translate-x-1"
          onClick={(evt) => {
            evt.stopPropagation()
            setConfirmingToRemove(true)
          }}
        >
          <Icon
            className="fill-lavender group-hover:fill-accentred transition-color duration-300"
            iconName={IconNameProp.Close}
            size={12}
          />
        </div>
      )}

      {confirmingToRemove && (
        <ConfirmationModal
          message={`Are you sure you want to remove ${title}?`}
          onConfirm={handleRemove}
          onClose={() => setConfirmingToRemove(false)}
        />
      )}
    </div>
  )
}

const popperProps = getPopperProps({ offset: [0, -10] })
