import { Event, RootState } from "@/TYPES/redux"
import { useMyUser } from "@/helpers/user"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import $store from "@/store"
import { v4 as uuidv4 } from "uuid"
import { socketConnection as socket } from "@/context/socket"

export const useUpdateCalendar = () => {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    window.env?.REACT_APP_API_SERVER ||
    window._env_?.REACT_APP_API_SERVER ||
    env.REACT_APP_API_SERVER ||
    import.meta.env.REACT_APP_API_SERVER
  const calendarApi = REACT_APP_API_SERVER + `/v2/calendar/event`

  const { data } = useMyUser()
  const userId = data?._id

  const savedEvents = useSelector((state: RootState) => state.events.events)
  const user = useSelector((state: RootState) => state.user)
  // const userCalendarId = useSelector((state: RootState) => state.user.calendar_id)
  const userCalendarId = user.calendar_id

  const addEventToCalendar = (newEvent: Event) => {
    const locationSpaceURL = `${window.location.origin}/space/${newEvent.spaceId}`
    const enhanceEvent: Event = {
      ...newEvent,
      start: new Date(newEvent.startTime).getTime(),
      end: new Date(newEvent.endTime).getTime(),
      location: `Cloudcom Space: ${locationSpaceURL}`,
      url: locationSpaceURL,
      organizer: {
        //   _id: userId,
        email: data?.email,
        //   orgId: data?.orgId,
        name: data?.name,
      },
      description: newEvent.agenda,
      // attendees: newEvent.participants,
      attendees: newEvent.participants.map((p) => ({
        name: p.name,
        email: p.email,
      })),
      new: true,
    }

    const { organizerId: organizer_id, ...rest } = enhanceEvent
    const eventPropertyNamesForBackend = { organizer_id, ...rest }
    eventPropertyNamesForBackend.user_id = userId
    console.log('EVENT FOR UPDATE', eventPropertyNamesForBackend)
    // const sampleCalId = `${Math.floor(Math.random() * 10000000000)}` // to test if this field is holding up update cal in backend


    const newCalendarData = [
      ...savedEvents.filter((ev) => ev.eventUUID !== enhanceEvent.eventUUID),
      // newEvent,
      enhanceEvent,
    ]
    const editingExistingEvent = savedEvents.some(
      (ev) => ev.eventUUID === enhanceEvent.eventUUID,
    )

    if (editingExistingEvent) {

      fetch(calendarApi, {
        method: "PATCH",
        body: JSON.stringify({
          event: eventPropertyNamesForBackend,
          userId,
          calendar_id: userCalendarId,
          event_id: eventPropertyNamesForBackend._id
        }),
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            toast.success(
              `Event ${editingExistingEvent ? "updated" : "added"}!`,
            )
          } else {
            toast.error(
              `Error ${editingExistingEvent ? "updating" : "adding"} event.`,
            )
          }
        })
        .finally(() => {
          socket.emit("sync-calendar", {})
        })
    } else {

      fetch(calendarApi, {
        method: "POST",
        body: JSON.stringify({ event: eventPropertyNamesForBackend, userId }),
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            toast.success(
              `Event ${editingExistingEvent ? "updated" : "added"}!`,
            )
          } else {
            toast.error(
              `Error ${editingExistingEvent ? "updating" : "adding"} event.`,
            )
          }
        })
        .finally(() => {
          socket.emit("sync-calendar", { })
        })
    }
  }


  const deleteEventFromCalendar = (deleteEventId: string) => {
    const eventToRemove = savedEvents.find(
      (ev) => ev.eventUUID === deleteEventId,
    )
    console.log('CALENDAR EVENT TO REMOVE', eventToRemove)

    fetch(calendarApi, {
      method: "DELETE",
      body: JSON.stringify({
        event_id: eventToRemove?._id,
        user_id: userId,
        calendar_id: userCalendarId,
      }), 
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          toast.success(`Event deleted!`)
        } else {
          toast.error(`Error deleting event.`)
        }
      })
      .finally(() => {
        socket.emit("sync-calendar", {})
      })
  }


  const updateEventTimeInCalendar = (
    newAllDay: boolean,
    newStartDate: Date | null,
    newEndDate: Date | null,
    updateEventId: string,
  ) => {
    const newCalendarData = savedEvents.map((ev) => {
      return ev.eventUUID === updateEventId
        ? {
            ...ev,
            allDay: newAllDay,
            startTime: newStartDate ? newStartDate : ev.startTime,
            endTime: newEndDate ? newEndDate : ev.endTime,
          }
        : ev
    })

    fetch(calendarApi, {
      method: "POST",
      body: JSON.stringify({
        calendarData: newCalendarData,
        userId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          toast.success(`Event time updated!`)
        } else {
          toast.error(`Error updating event time.`)
        }
      })
  }

  return {
    addEventToCalendar,
    deleteEventFromCalendar,
    updateEventTimeInCalendar,
  }
}
