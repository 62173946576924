import { RoomState } from "@/TYPES/redux"
import { createSlice } from "@reduxjs/toolkit"
import { getMainTileType } from "@/helpers"

const initialState: RoomState = {
  data: null,
  mainTileType: null,
  mainTileTitleMsg: null,
}

export const roomSlice = createSlice({
  name: "room",
  initialState,
  reducers: {
    updateRoom(state, action) {
      const roomData = action.payload
      const roomDataCloneDeep = JSON.parse(JSON.stringify(roomData))
      return {
        ...state,
        data: {
          ...roomDataCloneDeep,
        },
        mainTileType: getMainTileType(roomData),
      }
    },
    assignScene2d(state, action) {
      const payload = action.payload
      const roomData: any = state.data
      if (!roomData || !payload || !payload.dataTile00 || !payload.dataTile01)
        return state

      const newScene2d = {
        dataTile00: payload.dataTile00,
        dataTile01: payload.dataTile01,
      }

      const newData = {
        ...roomData,
        scene2d: newScene2d,
      }

      return {
        ...state,
        data: newData,
        mainTileType: getMainTileType(newData),
      }
    },
    assignLargeTile(state: RoomState, action) {
      const payload = action.payload
      const roomData = state.data

      if (!roomData || !roomData.scene2d) return state

      const currentSmallTiles = JSON.parse(
        JSON.stringify(roomData.scene2d.dataTile01),
      )

      const newLargeTile = currentSmallTiles.find(
        (item) => item._id === payload,
      )

      if (!newLargeTile) {
        return state
      }

      // Determine the current large tile
      // const currentLargeTile =
      //   currentLargeTiles?.length > 0 ? currentLargeTiles[0] : null

      // Add the current large tile to the small tile position
      // const newSmallTiles = currentLargeTile
      //   ? currentSmallTiles.map((item) =>
      //       item._id !== payload ? item : currentLargeTile,
      //     )
      //   : currentSmallTiles.filter((item) => item._id !== newLargeTile._id)

      const newData = {
        ...roomData,
        scene2d: {
          ...roomData.scene2d,
          dataTile00: [newLargeTile],
          // dataTile01: newSmallTiles,
        },
      }

      // Return the new state with updates
      return {
        ...state,
        data: newData,
        mainTileType: getMainTileType(newData),
      }
    },
    removeLargeTile(state: RoomState) {
      const roomData = state.data

      if (!roomData || !roomData.scene2d) return state

      // const currentLargeTiles = JSON.parse(
      //   JSON.stringify(roomData.scene2d.dataTile00),
      // )
      // const currentSmallTiles = JSON.parse(
      //   JSON.stringify(roomData.scene2d.dataTile01),
      // )

      return {
        ...state,
        data: {
          ...roomData,
          scene2d: {
            ...roomData.scene2d,
            dataTile00: [],
            // dataTile01: [...currentSmallTiles, ...currentLargeTiles],
          },
        },
        mainTileType: null,
      }
    },
    changeSmallTilesOrder(state: RoomState, action) {
      const payload = action.payload
      const roomData: any = state.data

      if (!roomData || !roomData.scene2d) return state

      const currentSmallTiles = roomData.scene2d.dataTile01
      const fromTile = currentSmallTiles.find(
        (item) => item._id === payload.from,
      )
      const toTile = currentSmallTiles.find((item) => item._id === payload.to)
      if (!fromTile || !toTile) {
        return state
      }

      const newSmallTiles = currentSmallTiles.map((item) => {
        if (item._id === payload.from) {
          return toTile
        } else if (item._id === payload.to) {
          return fromTile
        }

        return item
      })

      const newData = {
        ...roomData,
        scene2d: {
          ...roomData.scene2d,
          dataTile01: newSmallTiles,
        },
      }

      return {
        ...state,
        data: newData,
        mainTileType: getMainTileType(newData),
      }
    },
    clearRoom(state: RoomState) {
      return {
        ...state,
        data: null,
        mainTileType: null,
      }
    },
    assignMainTileType(state: RoomState, action) {
      return {
        ...state,
        mainTileType: action.payload,
      }
    },
    assignMainTileTitleMsg(state: RoomState, action) {
      const payload = action.payload
      return {
        ...state,
        mainTileTitleMsg: payload,
      }
    },
  },
})

export const {
  updateRoom,
  assignLargeTile,
  changeSmallTilesOrder,
  assignScene2d,
  clearRoom,
  removeLargeTile,
  assignMainTileType,
  assignMainTileTitleMsg,
} = roomSlice.actions
export default roomSlice.reducer
