import { useMyUser } from "@/helpers"
import User from "./User"
import UserList from "./UserList"
import { Event } from "@/TYPES/redux"
import cn from "classnames"

interface Props {
  event?: Event
  isDarkMode: boolean
}

export default function GuestInformation({ event, isDarkMode }: Props) {
  const { data } = useMyUser()

  if (!event) {
    return null
  }

  return (
    <div className="flex flex-col pt-[44px] gap-[12px]">
      <div className="flex items-center">
        <div
          className={cn("text-[12pt]", {
            "text-gray3": !isDarkMode,
            "text-gray2": isDarkMode,
          })}
        >{`${event.participants?.length} ${
          event.participants?.length === 1 ? "guest" : "guests"
        }`}</div>
      </div>
      <div className="flex flex-col gap-3">
        {event.spaceName && (
          <User
            textClassName={cn("font-bold text-[18pt]", {
              "text-carbon": !isDarkMode,
              "text-smoke": isDarkMode,
            })}
            bgColor={isDarkMode ? "smoke" : "carbon"}
            name={event.spaceName}
          />
        )}
        <UserList
          users={event.participants}
          organizer={event.organizerId}
          isDarkMode={isDarkMode}
        />
        <User
          textClassName={cn("font-bold text-[12pt]", {
            "text-carbon": !isDarkMode,
            "text-smoke": isDarkMode,
          })}
          bgColor={isDarkMode ? "smoke" : "carbon"}
          name={data.name}
        />
      </div>
    </div>
  )
}
