import { RootState } from "@/TYPES/redux"
import MMChatGuest from "@/components/Chat/MMChatGuest"
import MMChatUser from "@/components/Chat/MMChatUser"
import $store from "@/store"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import validator from "validator"

const { isURL } = validator

export default function ChatComp() {
  const isGuest = useSelector(
    (state: RootState) => state.permission.level === "guest",
  )
  const [isPreLoading, setIsPreLoading] = useState(true)
  const [chatServerIsDown, setChatServerIsDown] = useState(false)

  async function preLoadChat() {
    setIsPreLoading(true)
    await mmApiLogout()

    try {
      setMattermostLandingPreferences()
    } catch (error) {
      console.error(error)
    } finally {
      setIsPreLoading(false)
    }
  }

  useEffect(() => {
    //__ JUST TO LOG # TIMES THIS COMPONENT GETS MOUNTED __//
    if (!window.__chat_comp_mount_counts) window.__chat_comp_mount_counts = 0
    window.__chat_comp_mount_counts++
    log(`<ChatComp> mounted()`, "highlight", {
      counts: window.__chat_comp_mount_counts,
    })
    //________________________________________________//

    preLoadChat()
  }, [])

  if (chatServerIsDown)
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          background: "black",
          color: "red",
          zIndex: 1,
        }}
      >
        Chat server is down
      </div>
    )

  if (isPreLoading)
    return (
      <>
        <div
          style={{
            width: "100%",
            height: "100%",
            background: "black",
          }}
        >
          Chat pre-loading..
        </div>
      </>
    )

  return (
    <>
      <div className="relative flex flex-1 h-full">
        {isGuest ? <MMChatGuest /> : <MMChatUser />}
      </div>
    </>
  )
}

/////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////

export function log(msg?: any, type: any = "info", data?: any) {
  const logStyles = {
    info: `background:black;color:#87ceeb;border:1px solid #87ceeb;font-size:18px;padding:10px;`,
    error: `background:black;color:red;border:1px solid red;font-size:18px;padding:10px;`,
    warn: `background:black;color:orange;border:1px solid orange;font-size:18px;padding:10px;`,
    highlight: `background:black;color:#bada55;border:1px solid #bada55;font-size:18px;padding:10px;`,
  }
  if (data) return console.log(`%c[cc-frontend]: ${msg}`, logStyles[type], data)
  return console.log(`%c[cc-frontend]: ${msg}`, logStyles[type])
}

function setMattermostLandingPreferences() {
  log(`<setMattermostLandingPreferences()>`, "info")

  const storeState = $store.getState()
  const env = storeState.env
  const MATTERMOST_URL =
    env.REACT_APP_MATTERMOST_URL || import.meta.env.REACT_APP_MATTERMOST_URL

  const MM_LOCAL_STORAGE_VALS = {
    [`landing-preference__${MATTERMOST_URL}`]: "browser",
    [`__landingPageSeen__`]: "true",
  }

  // Check if localStorage already has the values set
  let isAlreadySet = true
  for (const key in MM_LOCAL_STORAGE_VALS) {
    const val = MM_LOCAL_STORAGE_VALS[key]
    const localStorageVal = window.localStorage.getItem(key)
    if (localStorageVal !== val) {
      isAlreadySet = false
      break
    }
  }

  if (isAlreadySet) return
  for (const key in MM_LOCAL_STORAGE_VALS) {
    const val = MM_LOCAL_STORAGE_VALS[key]
    window.localStorage.setItem(key, val)
  }
}

function mmApiLogout() {
  log(`<mmApiLogout()>`, "info")
  const storeState = $store.getState()
  const env = storeState.env
  const MATTERMOST_API_URL =
    env.REACT_APP_MATTERMOST_API_URL || import.meta.env.REACT_APP_MATTERMOST_API_URL

  return new Promise(async (resolve, reject) => {
    try {
      const apiLogoutRes = await fetch(`${MATTERMOST_API_URL}/users/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "include",
      })
      const apiLogoutData = await apiLogoutRes.json()
      log(`<mmApiLogout()> apiLogoutData`, "info", apiLogoutData)
      resolve(apiLogoutData)
    } catch (err) {
      console.error(err)
      resolve(err)
    }
  })
}
