import FullCalendar from "@fullcalendar/react"
import Icon, { IconNameProp } from "@/components/Icon"
import { MutableRefObject, useEffect, useState, useRef } from "react"
import moment from "moment"
import cn from "classnames"
import { EViewTypes } from "../types"
import { useSelectViewport } from "@/utils/hooks/viewport"
import TitleBar from "@/components/TitleBar"
import DarkModeSwitch from "./DarkModeSwitch"

const menuOptions = [
  {
    key: "day",
    title: "Day",
    view: EViewTypes.Day,
  },
  {
    key: "week",
    title: "Week",
    view: EViewTypes.Week,
  },
  {
    key: "month",
    title: "Month",
    view: EViewTypes.Month,
  },
]

interface Props {
  calendarRef: MutableRefObject<FullCalendar | null>
  isDarkMode: boolean
  setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>
}

export default function CalendarHeader({
  calendarRef,
  isDarkMode,
  setIsDarkMode,
}: Props) {
  const { isPhone } = useSelectViewport()
  const [date, setDate] = useState<Date | undefined>(undefined)
  const [viewType, setViewType] = useState<string | null>(EViewTypes.Week)
  const [optionMenuOpen, setOptionMenuOpen] = useState(false)
  const optionMenuRef = useRef<HTMLDivElement | null>(null)
  const ICON_SIZE = isPhone ? 15 : 20
  const MONTH_FORMAT = isPhone ? "MMM" : "MMMM"

  useEffect(() => {
    const handleClickOutsideMenu = (e: MouseEvent) => {
      if (
        optionMenuRef.current &&
        !optionMenuRef.current.contains(e.target as Node)
      ) {
        setOptionMenuOpen(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutsideMenu)

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideMenu)
    }
  }, [])

  useEffect(() => {
    const calApi = calendarRef?.current?.getApi()
    if (calApi) {
      setDate(calendarRef.current?.getApi().getDate())
    }
  }, [])

  const getDay = (d: Date) => {
    const day = moment(d).format("DD")
    const month = moment(d).format(MONTH_FORMAT)
    return `${month} ${day}`
  }

  const getWeekRange = (d: Date) => {
    const startWeek = moment(d).week()
    const startMonth = moment(d).format(MONTH_FORMAT)
    const year = d.getFullYear()
    // fullCalendar weeks start on Sunday
    const firstDate = moment()
      .day("Sunday")
      .year(year)
      .week(startWeek)
      .format("YYYY-MM-DD")
    // add 6 days to get last day of week
    const lastDate = moment(firstDate).add(6, "days").format("YYYY-MM-DD")
    const endMonth = moment(lastDate).format(MONTH_FORMAT)

    return `${startMonth} ${moment(firstDate).format("D")}-${
      startMonth === endMonth
        ? moment(lastDate).format("D")
        : `${endMonth} ${moment(lastDate).format("D")}`
    }`
  }

  const getMonth = (d: Date) => {
    return moment(d).format(MONTH_FORMAT)
  }

  const getHeaderTitle = (d: Date) => {
    switch (viewType) {
      case EViewTypes.Day:
        return getDay(d)
      case EViewTypes.Week:
        return getWeekRange(d)
      case EViewTypes.Month:
        return getMonth(d)
    }
  }

  const getViewButtonTitle = () => {
    const view = calendarRef.current?.getApi().view
    if (!view) return ""

    switch (view.type) {
      case EViewTypes.Day:
        return "Day"
      case EViewTypes.Week:
        return "Week"
      case EViewTypes.Month:
        return "Month"
    }
  }

  const handleNavigate = (direction: "prev" | "next") => {
    const calendarApi = calendarRef.current?.getApi()
    if (calendarApi) {
      if (direction === "prev") {
        calendarApi?.prev()
      } else if (direction === "next") {
        calendarApi?.next()
      }
      setDate(calendarApi.getDate())
    }
  }

  const changeView = (view: string) => {
    const calendarApi = calendarRef.current?.getApi()

    if (calendarApi) {
      calendarApi.changeView(view)
      setViewType(view)
      setDate(calendarApi.getDate())
    }
    setOptionMenuOpen(false)
  }

  if (!date) {
    return null
  }

  return (
    <TitleBar
      className={cn("justify-between border-b-[0.5px] pt-1", {
        "text-carbon border-typegray": !isDarkMode,
        "text-smoke border-cloudy": isDarkMode,
      })}
      bgColor={isDarkMode ? "cobrowse" : "gradient-white"}
    >
      <div className="flex items-center gap-3">
        <Icon
          iconName={IconNameProp.Calendar}
          className={cn("mb-1", {
            "fill-carbon": !isDarkMode,
            "fill-smoke": isDarkMode,
          })}
          size={ICON_SIZE}
        />
        <span
          className={cn("font-bold whitespace-nowrap", {
            "text-[1.75vw]": !isPhone,
            "text-[5vw]": isPhone,
          })}
        >
          {getHeaderTitle(date)}
        </span>
        <div className="flex gap-4">
          <button onClick={() => handleNavigate("prev")}>
            <Icon
              className={cn("rotate-180", {
                "fill-carbon": !isDarkMode,
                "fill-smoke": isDarkMode,
              })}
              iconName={IconNameProp.Chevron}
              size={ICON_SIZE}
            />
          </button>
          <button onClick={() => handleNavigate("next")}>
            <Icon
              className={cn({
                "fill-carbon": !isDarkMode,
                "fill-smoke": isDarkMode,
              })}
              iconName={IconNameProp.Chevron}
              size={ICON_SIZE}
            />
          </button>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <div className="relative" ref={optionMenuRef}>
          <button
            className={cn(
              "relative flex items-center justify-between border-[1px] py-1 gap-2 rounded-sm",
              {
                "hover:border-cloudy border-carbon": !isDarkMode,
                "hover:border-white border-smoke": isDarkMode,
                "px-4": !isPhone,
                "text-[14px] px-2": isPhone,
              },
            )}
            onClick={() => setOptionMenuOpen(!optionMenuOpen)}
          >
            {getViewButtonTitle()}
            <Icon
              className={cn("transition-transform duration-300", {
                "fill-carbon": !isDarkMode,
                "fill-smoke": isDarkMode,
                "rotate-90": !optionMenuOpen,
                "rotate-[270deg]": optionMenuOpen,
              })}
              iconName={IconNameProp.Chevron}
              size={15}
            />
          </button>
          <div
            className={cn(
              "absolute flex flex-col items-center justify-center overflow-hidden rounded-sm px-4 z-50 gap-1 w-full transition-all",
              {
                "bg-smoke text-carbon border-carbon": !isDarkMode,
                "bg-cobrowsegray text-smoke border-smoke": isDarkMode,
                "h-0 border-0": !optionMenuOpen,
                "h-[85px] border-[1px]": optionMenuOpen,
              },
            )}
          >
            {menuOptions.map((option) => (
              <button
                key={option.key}
                className={cn("transition-colors duration-300", {
                  "hover:text-cloudy": !isDarkMode,
                  "hover:text-white": isDarkMode,
                })}
                onClick={() => changeView(option.view)}
              >
                {option.title}
              </button>
            ))}
          </div>
        </div>
        <DarkModeSwitch isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
      </div>
    </TitleBar>
  )
}
