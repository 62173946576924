import { AppType, DraggingType } from "@/TYPES/common"
import { DragZone } from "@/components/DragAndDrop"
import Icon, { IconNameProp } from "@/components/Icon"
import TileHeader from "@/components/TileHeader"
import cn from "classnames"
import { useEffect, useState } from "react"
import Tile from "./Tile"

type Props = {
  apps: AppType[]
  isFile: boolean
}

export default function PhoneTileList({ apps }: Props) {
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  useEffect(() => {
    if (selectedIndex !== 0) {
      setSelectedIndex(0)
    }
  }, [apps])

  const handlePrev = () => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1)
    }
  }

  const handleNext = () => {
    if (selectedIndex < apps.length - 1) {
      setSelectedIndex(selectedIndex + 1)
    }
  }

  const app = apps[selectedIndex]

  if (!app) {
    return null
  }

  return (
    <div
      className={cn(
        "relative overflow-hidden w-full flex flex-col",
        "inactive-iframes",
      )}
    >
      <TileHeader className="justify-between !pr-0 !border-t-0">
        <h3 className="flex-1 text-11 truncate mr-4">
          {app?.title || app?.fileName}
        </h3>
        <div className="flex items-center">
          <a
            className={cn(
              "w-[36px] h-[36px] flex-center",
              "border-x border-darkgray",
            )}
            onClick={handlePrev}
          >
            <Icon
              className={cn(
                "rotate-180",
                selectedIndex === 0 ? "fill-carbon" : "fill-typegray",
              )}
              iconName={IconNameProp.Chevron}
              size={16}
            />
          </a>
          <a className="w-[36px] h-[36px] flex-center" onClick={handleNext}>
            <Icon
              className={cn(
                selectedIndex === apps.length - 1
                  ? "fill-carbon"
                  : "fill-typegray",
              )}
              iconName={IconNameProp.Chevron}
              size={16}
            />
          </a>
        </div>
      </TileHeader>

      <DragZone type={DraggingType.App} item={app}>
        <Tile app={app} />
      </DragZone>
    </div>
  )
}
