import { RootState } from "@/TYPES/redux"
import LoaderComp from "@/components/Loader/LoaderComp"
import { getMyUser } from "@/helpers"
import $store from "@/store"
import { assignChatUser } from "@/store/slices/chatSlice"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import validator from "validator"
import { appendUrlPath } from "@/helpers"

const { isURL } = validator
const storeState = $store.getState()
const env = storeState.env

export default function MMChatUser() {
  const REACT_APP_MATTERMOST_RESTRICT_UI = useSelector(
    (state: RootState) => state.env.REACT_APP_MATTERMOST_RESTRICT_UI,
  )
  const RESTRICT_UI = (() => {
    if (typeof REACT_APP_MATTERMOST_RESTRICT_UI === "boolean")
      return REACT_APP_MATTERMOST_RESTRICT_UI
    if (typeof REACT_APP_MATTERMOST_RESTRICT_UI === "string") {
      const val = REACT_APP_MATTERMOST_RESTRICT_UI.trim().toLowerCase()
      if (val === "true") return true
      if (val === "false") return false
      return true
    }
    if (REACT_APP_MATTERMOST_RESTRICT_UI === "true") return true
    if (REACT_APP_MATTERMOST_RESTRICT_UI === "false") return false

    const localVal = import.meta.env.REACT_APP_MATTERMOST_RESTRICT_UI
    if (localVal === "false") return false
    return true
  })()
  const RESTRICT_UI_PARAM = RESTRICT_UI ? "?restrict=true" : ""
  const REACT_APP_MATTERMOST_URL = useSelector(
    (state: RootState) => state.env.REACT_APP_MATTERMOST_URL,
  )
  const MATTERMOST_URL =
    REACT_APP_MATTERMOST_URL || import.meta.env.REACT_APP_MATTERMOST_URL
  const REACT_APP_MATTERMOST_API_URL = useSelector((state: RootState) => {
    return state.env.REACT_APP_MATTERMOST_API_URL
  })
  const MATTERMOST_API_URL =
    REACT_APP_MATTERMOST_API_URL || import.meta.env.REACT_APP_MATTERMOST_API_URL
  const REACT_APP_NEW_BACKEND_URL = useSelector(
    (state: RootState) => state.env.REACT_APP_NEW_BACKEND_URL,
  )
  const CLOUDCOM_BACKEND_URL =
    REACT_APP_NEW_BACKEND_URL || import.meta.env.REACT_APP_NEW_BACKEND_URL
  const BACKEND_MM_URL = appendUrlPath(CLOUDCOM_BACKEND_URL, "mattermost")
  //   const BACKEND_MM_URL = appendSubpathToURL(CLOUDCOM_BACKEND_URL, "mattermost")

  const REACT_APP_SOCKET_SERVER = useSelector(
    (state: RootState) =>
      state.env.REACT_APP_SOCKET_SERVER ||
      import.meta.env.REACT_APP_SOCKET_SERVER,
  )
  const SOCKET_SERVER = appendUrlPath(REACT_APP_SOCKET_SERVER, "mattermost")

  const REACT_APP_MATTERMOST_DECODE_PASSPHRASE = useSelector(
    (state: RootState) => state.env.REACT_APP_MATTERMOST_DECODE_PASSPHRASE,
  )
  const MATTERMOST_DECODE_PASSPHRASE =
    REACT_APP_MATTERMOST_DECODE_PASSPHRASE ||
    import.meta.env.REACT_APP_MATTERMOST_DECODE_PASSPHRASE

  const dispatch = useDispatch()
  const mm_iframe_ref = useRef(null)
  const roomData = useSelector((state: RootState) => state.room.data)
  const roomId = useSelector((state: RootState) => {
    if (!state.room.data) {
      if (!state.space.spaceId) return
      return state.space.spaceId
    }
    const roomData = state.room.data
    const roomId =
      roomData._id || roomData.id || roomData.roomId || roomData.room_id
    return roomId
  })
  const userData = useSelector((state: RootState) => state.user.data)
  const userId = useSelector((state: RootState) => {
    if (!state.user.data) return
    const userData = state.user.data
    const userId =
      userData._id || userData.id || userData.userId || userData.user_id
    return userId
  })
  const mmTeamName = userData?.mmTeamName
  const [isLoading, setIsLoading] = useState(true)
  const [isMattermostFailed, setIsMattermostFailed] = useState(false)

  function addMessageListener() {
    if (window.__mattermost_message_listener_registered) return
    window.addEventListener("message", messageListener)
    window.__mattermost_message_listener_registered = true
  }

  function removeMessageListener() {
    window.removeEventListener("message", messageListener)
    window.__mattermost_message_listener_registered = false
  }

  useEffect(() => {
    //__ JUST TO LOG # TIMES THIS COMPONENT GETS MOUNTED __//
    if (!window.__chat_comp_mount_counts)
      window.__chat_user_comp_mount_counts = 0
    window.__chat_user_comp_mount_counts++
    log(`<MMChatUser> mounted()`, "highlight", {
      counts: window.__chat_user_comp_mount_counts,
    })
    //________________________________________________//
    return () => {
      removeMessageListener()
    }
  }, [])

  //(( mmLoginCheckDirty() ))//
  function mmLoginCheckDirty(repeat: boolean = false) {
    //@@ Will check if mm iframe has login input field @@//
    const iframeEl: any = mm_iframe_ref.current
    const iframeWindow = iframeEl?.contentWindow
    const iframeDocument = iframeEl?.contentDocument
    log(`<mmLoginCheckDirty()>`, "info", {
      iframeEl,
      iframeWindow,
      iframeDocument,
    })
    if (!iframeWindow || !iframeDocument) {
      log(
        `<mmLoginCheckDirty()> -- No iframeWindow or iframeDocument found..`,
        "warn",
      )
      return
    }
    const loginIdInputEl = iframeDocument.getElementById("input_loginId")
    const loginPasswordInputEl = iframeDocument.getElementById(
      "input_password-input",
    )

    if (!loginIdInputEl && !loginPasswordInputEl) {
      log(
        `<mmLoginCheckDirty()> -- No login input field found (which means logged in)`,
        "info",
      )
      return
    }

    toggleIsMattermostInited(false)
  }

  function keepMMLoggedIn(register: boolean = true) {
    if (!register) {
      log(`<keepMMLoggedIn()> Unregistering dirty checke interval...`, "warn")
      clearInterval(window.__keepMMLoggedInIntervalId)
      return
    }
    window.__keepMMLoggedInIntervalId = setInterval(() => {
      mmLoginCheckDirty()
      if (window.__isMattermostInited) return
      setTimeout(() => {
        initMattermost()
      }, 1000)
    }, 6000)
  }

  //(( onLoad() ))//
  function onLoad() {
    setIsLoading(false)
    console.log("onload")
    initMattermost()
  }

  //(( initMattermost() ))//
  async function initMattermost() {
    log(`<initMattermost()>`)
    //  setIsMattermostFailed(false)

    setIsLoading(true)

    mmLoginCheckDirty()

    if (window.__isMattermostInited) {
      log(
        `initMattermost() -- Mattermost is already logged in and initialized!`,
        "info",
      )
      setIsLoading(false)
      toggleMattermostGlobalLoader(false)
      return
    }

    addMessageListener()

    const iframeEl: any = mm_iframe_ref.current
    const iframeWindow = iframeEl.contentWindow
    const userDataPayload = {
      _id: userData._id || userData.id,
      name: userData.name,
      email: userData.email,
      isGuest: false,
      isOwner: roomId === userId,
      password: userData.mmPassword,
      teamName: userData.mmTeamName,
    }
    const roomDataPayload = {
      _id: roomId,
      teamId: roomData?.teamId,
      name: roomData?.name,
    }
    const envData = {
      BASE_URL: MATTERMOST_URL,
      API_URL: MATTERMOST_API_URL,
      BACKEND_MM_URL,
      SOCKET_URL: SOCKET_SERVER,
      DECODE_PASSPHRASE: MATTERMOST_DECODE_PASSPHRASE,
      // COOKIE_PROPS: [`MMAUTHTOKEN`, `MMUSERID`, `MMCSRF`],
      RESTRICT_UI,
    }

    const msgPayload = {
      user: userDataPayload,
      room: roomDataPayload,
      env: envData,
    }
    const data = {
      type: `init:mattermost`,
      payload: msgPayload,
    }

    // log(`<initMattermost()> postMessage`, "info", data)
    iframeWindow.postMessage(data, "*")
    setIsLoading(false)
  }

  //(( messageListener() ))//
  function messageListener(evt) {
    const type = evt.data?.type
    const payload = evt.data?.payload
    if (!type || !payload) return

    const iframeEl: any = mm_iframe_ref.current
    const iframeWindow = iframeEl.contentWindow

    //@@ If user init success @@//
    if (type === "init:mattermost::success") {
      // toggleMattermostGlobalLoader(false);
      log(`messageListener() -- init:mattermost::success`, "info", { payload })
      setIsMattermostFailed(false)

      const loggedInMMUser = payload?.mm?.user
      if (!loggedInMMUser || !loggedInMMUser.id) {
        log(
          `[init:mattermost::success] No loggedInMMUser..?? Terminating Mattermost..`,
          "error",
          { evt },
        )
        iframeWindow.postMessage(
          { type: "term:mattermost", payload: null },
          "*",
        )
        toggleMattermostGlobalLoader(false)
        toggleIsMattermostInited(false)
        return
      }

      dispatch(assignChatUser(loggedInMMUser))

      toggleIsMattermostInited(true)
      iframeWindow.location.reload()

      window.setTimeout(keepMMLoggedIn, 5000)
      return
    }

    //@@ If user init fails @@//
    if (type === "init:mattermost::fail") {
      setIsLoading(false)
      // setIsLoading(true)
      toggleMattermostGlobalLoader(false)
      log(`[init:mattermost::fail]`, "error", { payload })
      // setIsMattermostFailed(true)
      const reinitTime = 50000
      log(
        `<init:mattermost::fail> Reloading iframe window and re-triggering initialization in ${reinitTime}ms..`,
        "error",
        { payload },
      )
      setTimeout(async () => {
        //   getMyUser()
        //   await getRoom(roomId)
        if (window.__isMattermostInited) return
        iframeWindow.location.reload()
      }, reinitTime)

      return
    }
  }

  async function attemptAutoCreateMMUser() {
    //  const preLogin = async () => {
    setIsLoading(true)
    try {
      log(`attemptAutoCreateMMUser()`, "info", {
        BACKEND_MM_URL,
      })
      if (!BACKEND_MM_URL) return
      const fetchURL = appendUrlPath(BACKEND_MM_URL, "pre-login")
      const response = await fetch(fetchURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: userData.email,
          _roomId: roomId,
          _userType: "regular",
        }),
      })
      const data = await response.json()
      if (!data) return
      // getMyUser(() => initMattermost())
      getMyUser()
      const iframeEl: any = mm_iframe_ref.current
      if (!iframeEl) return
      const iframeWindow = iframeEl.contentWindow
      iframeWindow.location.reload()
    } catch (error) {
      log(error, "error")
    } finally {
      setIsLoading(false)
    }
    //  }
  }

  //(( toggleMattermostGlobalLoader ))//
  function toggleMattermostGlobalLoader(show) {
    const iframeEl: any = mm_iframe_ref.current
    if (!iframeEl) return
    const iframeWindow = iframeEl.contentWindow
    const data = {
      type: `loader:${show ? "show" : "hide"}`,
      payload: null,
    }
    iframeWindow.postMessage(data, "*")
  }

  if (!isURL(MATTERMOST_URL)) return <div>CHAT_URL not found</div>

  //   if (!mmTeamName) {
  //     attemptAutoCreateMMUser()
  //   }

  const iframeSRC =
    MATTERMOST_URL &&
    roomId &&
    mmTeamName &&
    //  `${MATTERMOST_URL}/${mmTeamName}/channels/${roomId}`
    `${MATTERMOST_URL}/${mmTeamName}/channels/${roomId}${RESTRICT_UI_PARAM}`

  if (!iframeSRC) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          color: "red",
          fontFamily: "sans-serif",
        }}
      >
        <div>- You have no access to chat -</div>
        {/* <div>- Please contact administrator for more info -</div> */}

        <button
          onClick={attemptAutoCreateMMUser}
          style={{
            border: "1px solid grey",
            cursor: "pointer",
            padding: "2px",
          }}
        >
          CLICK HERE TO GET ACCESS
        </button>
      </div>
    )
  }

  if (isMattermostFailed) {
    return (
      <>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            color: "red",
            fontFamily: "sans-serif",
          }}
        >
          <div>Chat failed to connect</div>
          <button
            onClick={initMattermost}
            style={{
              border: "1px solid grey",
              cursor: "pointer",
              padding: "2px",
            }}
          >
            TRY AGAIN
          </button>
        </div>
      </>
    )
  }

  return (
    <div
      className="mm-iframe-wrapper"
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          display: isLoading ? "flex" : "none",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 100,
          background: "#2e2e2e",
        }}
      >
        <LoaderComp show={true} size="100px" msg={false} />
      </div>

      <iframe
        id="mm_iframe"
        ref={mm_iframe_ref}
        title="Cloudcom Chat"
        src={iframeSRC}
        allowFullScreen
        height="100%"
        width="100%"
        key={iframeSRC}
        onLoad={onLoad}
      ></iframe>
    </div>
  )
}

/////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////////////////////

export function log(msg?: any, type: any = "info", data?: any) {
  const logStyles = {
    info: `background:black;color:#87ceeb;border:1px solid #87ceeb;font-size:18px;padding:10px;`,
    error: `background:black;color:red;border:1px solid red;font-size:18px;padding:10px;`,
    warn: `background:black;color:orange;border:1px solid orange;font-size:18px;padding:10px;`,
    highlight: `background:black;color:#bada55;border:1px solid #bada55;font-size:18px;padding:10px;`,
  }
  if (data) return console.log(`%c[theia]: ${msg}`, logStyles[type], data)
  return console.log(`%c[theia]: ${msg}`, logStyles[type])
}

function toggleIsMattermostInited(force) {
  if (force === true || force === false) {
    window.__isMattermostInited = force
    return
  }
  window.__isMattermostInited = !window.__isMattermostInited
}

// function appendUrlPath(baseUrl: string, paths: string) {
//   // Trim slashes at the ends of baseUrl and at the start of paths
//   const sanitizedBaseUrl = baseUrl.replace(/\/+$/, "")
//   const sanitizedPaths = paths.replace(/^\/+/, "")

//   return `${sanitizedBaseUrl}/${sanitizedPaths}`
// }
