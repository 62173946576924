import { Event } from "@/TYPES/redux"
import { SingleValue } from "react-select"
import Select from "@/components/Select"
import SectionHeader from "../components/SectionHeader"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { setEditEvent } from "@/store/slices/eventsSlice"
import $store from "@/store"

interface Props {
  editEvent: Event
  isDarkMode: boolean
}

interface Location {
  value: string
  label: string
}

// const locationOptions: Location[] = [
//   { value: "1", label: "Space 1" },
//   { value: "2", label: "Space 2" },
//   { value: "3", label: "Space 3" },
//   { value: "4", label: "Space 4" },
//   { value: "5", label: "Space 5" },
//   { value: "6", label: "Space 6" },
//   { value: "7", label: "Space 7" },
//   { value: "8", label: "Space 8" },
//   { value: "9", label: "Space 9" },
//   { value: "10", label: "Space 10" },
// ]

export default function LocationSelect({ editEvent, isDarkMode }: Props) {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    window.env?.REACT_APP_API_SERVER ||
    window._env_?.REACT_APP_API_SERVER ||
    env.REACT_APP_API_SERVER ||
    import.meta.env.REACT_APP_API_SERVER
  const locationsApi = `${REACT_APP_API_SERVER}/v1/room`

  const initialLocation: Location | undefined = editEvent.spaceId
    ? { value: editEvent.spaceId, label: `Space ${editEvent.spaceId}` }
    : undefined
  const [location, setLocation] = useState<Location | undefined>(
    initialLocation,
  )
  const [locationsOptions, setLocationsOptions] = useState<Location[]>([])
  const dispatch = useDispatch()

  useEffect(() => {
    fetch(locationsApi)
      .then((res) => res.json())
      .then(({ data }) => {
        const locationsList: Location[] = data.map((room) => {
          return {
            value: room._id,
            label: room.name,
          }
        })
        setLocationsOptions(locationsList)
      })
  }, [])

  const handleLocationSelect = (selectedLocation: SingleValue<Location>) => {
    if (selectedLocation) {
      dispatch(
        setEditEvent({
          ...editEvent,
          spaceId: selectedLocation.value,
          spaceName: selectedLocation.label,
        }),
      )
      setLocation(selectedLocation)
    }
  }

  return (
    <div className="w-full">
      <SectionHeader title="Location" isDarkMode={isDarkMode} />
      <Select
        placeholder="Add location"
        value={location}
        isMulti={false}
        options={locationsOptions}
        onChange={handleLocationSelect}
      />
    </div>
  )
}
