import { useMyUser } from "@/helpers"
import { useSelectViewport } from "@/utils/hooks/viewport"
import cn from "classnames"
import Controls from "./Controls"
import { NavItems } from "./NavItems"
import VisitAdmin from "./VisitAdmin"

export default function Navigation() {
  const { isDesktop } = useSelectViewport()
  const { data } = useMyUser()

  return (
    <div
      className={cn("h-[45px] w-fit flex select-none justify-center gap-x-2", {
        "px-2 pt-2 mx-auto": !isDesktop,
      })}
    >
      {/* {data?.role === "super-admin" && <VisitAdmin />} */}

      <NavItems />

      {data?.role === "super-admin" && <VisitAdmin />}

      {/* <Controls /> */}
    </div>
  )
}
