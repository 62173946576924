import { RootState } from "@/TYPES/redux"
import TitleBar from "@/components/TitleBar"
import GuestQueue from "@/components/Call/GuestQueue"
import { useSelector } from "react-redux"
import cn from "classnames"

export default function QueueComp() {
  const show = useSelector(
    (state: RootState) => !!state.space.guestQueue.length,
  )

  return (
    <div
      className={cn(
        "absolute flex flex-col",
        "w-[395px] max-h-full bottom-4 right-1",
        "bg-cobrowsegray",
        "transition-all duration-300 ease-in-out",
        {
          "z-[101] rounded-sm shadow shadow-secondarygray shadow-md": show,
          "w-0 h-0 z-0 overflow-hidden": !show,
        },
        "sm:top-8 sm:right-0 sm:z-[101] sm:pt-[2px] sm:h-min sm:w-full",
        "md:right-0 md:z-[101]",
      )}
    >
      <TitleBar
        className="justify-center !p-2 text-14 sm:hidden"
        bgColor="cobrowse"
      >
        Meeting Lobby
      </TitleBar>
      <GuestQueue />
    </div>
  )
}
