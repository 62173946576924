import { DeviceType } from "@/TYPES/common"
import { RootState } from "@/TYPES/redux"
import { assignAudioMuted, assignVideoMuted } from "@/store/slices/callSlice"
import { IconNameProp } from "@/components/Icon"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useDevices } from "@/utils/hooks/useDevices"
import ControlItem from "@/pages/Space/Navigation/ControlItem"

export default function CallControls({ showTooltips = true }) {
  const dispatch = useDispatch()
  const {
    videoDevice,
    audioDevice,
    filterDevicesByType,
    onChangeAudioDevice,
    onChangeVideoDevice,
  } = useDevices()
  const { videoMuted, audioMuted } = useSelector(
    (state: RootState) => state.call,
  )
  const isPresenterMode = useSelector(
    (state: RootState) => state.space.isPresenterMode,
  )

  const handleSelectAudioDevice = (device) => {
    onChangeAudioDevice(device)
    window.jitsi?.setAudioInputDevice(device.label, device.id)
  }

  const handleSelectVideoDevice = (device) => {
    onChangeVideoDevice(device)
    window.jitsi?.setVideoInputDevice(device.label, device.id)
  }

  const toggleVideo = () => {
    dispatch(assignVideoMuted(!videoMuted))
  }

  const toggleAudio = () => {
    dispatch(assignAudioMuted(!audioMuted))
  }

  const buildDeviceOptions = (devicetype: string, selectedDevice: DeviceType) =>
    filterDevicesByType(devicetype).map((item) => ({
      id: item.id!,
      label: item.label!,
      selected: item.id === selectedDevice.id,
    }))

  const videoOptions = buildDeviceOptions("videoinput", videoDevice)
  const audioOptions = buildDeviceOptions("audioinput", audioDevice)

  async function updateAudioVideoMuted() {
    const jitsi = window.jitsi
    if (!jitsi) return
    try {
      const jitsiVideoMuted = await jitsi.isVideoMuted()
      if (jitsiVideoMuted !== videoMuted) {
        jitsi.executeCommand("toggleVideo")
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    updateAudioVideoMuted()
  }, [videoMuted, audioMuted])

  useEffect(() => {
    if (videoOptions.length === 0 && !videoMuted) {
      toggleVideo()
    }
    if (audioOptions.length === 0 && !audioMuted) {
      toggleAudio()
    }
  }, [videoOptions.length, audioOptions.length])

  // Props for MUI Menu rendered by DropdownMenu component //
  const anchorProps = {
    vertical: -20,
    horizontal: "center",
  }
  const transformProps = {
    vertical: "bottom",
    horizontal: "center",
  }

  return (
    <>
      <ControlItem
        tooltip={showTooltips ? `${videoMuted ? "Start" : "Stop"} Video` : ""}
        iconName={videoMuted ? IconNameProp.Video : IconNameProp.VideoOn}
        iconSize={19}
        highlighted={isPresenterMode}
        selected={!videoMuted}
        disabled={videoOptions.length === 0}
        options={videoOptions}
        onClick={toggleVideo}
        onSelectOption={handleSelectVideoDevice}
        anchorProps={anchorProps}
        transformProps={transformProps}
      />

      <ControlItem
        tooltip={showTooltips ? `${audioMuted ? "Unmute" : "Mute"} Audio` : ""}
        iconName={audioMuted ? IconNameProp.Mute : IconNameProp.Unmute}
        iconSize={21}
        highlighted={isPresenterMode}
        selected={!audioMuted}
        disabled={audioOptions.length === 0}
        options={audioOptions}
        onClick={toggleAudio}
        onSelectOption={handleSelectAudioDevice}
        anchorProps={anchorProps}
        transformProps={transformProps}
      />
    </>
  )
}
