import DatePicker from "react-datepicker"
import moment from "moment"
import SectionHeader from "../components/SectionHeader"
import Select from "@/components/Select"
import { Time, timeOptions } from "./constants"
import { getDateFromTime } from "../utils"

interface Props {
  title: string
  date: Date
  setDate: (d: Date) => void
  timeOptions: Time[]
  isDarkMode: boolean
}

export default function DateInput({
  title,
  date,
  setDate,
  timeOptions,
  isDarkMode,
}: Props) {
  const handleSelectInput = (input: Time) => {
    const newTime = input.value
    const newDate = getDateFromTime(newTime, date.toISOString())
    setDate(newDate)
  }

  return (
    <div className="flex flex-row w-full ">
      <div className="flex flex-col w-full">
        <SectionHeader title={title} isDarkMode={isDarkMode} />
        <div className="flex flex-row gap-1 w-full">
          <div className="flex flex-1">
            <DatePicker
              className="flex text-carbon border-carbon border-[1px] rounded-sm px-2 py-1 outline-none w-full h-[38px]"
              selected={date}
              onChange={(date: Date) => setDate(date)}
            />
          </div>
          <div className="flex flex-1">
            <Select
              className="w-full"
              value={
                timeOptions.filter(
                  (option) => option.label === moment(date).format("h:mm A"),
                )[0]
              }
              options={timeOptions}
              onChange={handleSelectInput}
              isMulti={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
