import { SocketMessageType } from "@/TYPES/common"
import { RootState } from "@/TYPES/redux"
import CenteredLoader from "@/components/CenteredLoader"
import { SocketContext } from "@/context/socket"
import {
  CSS_COLOR_NAMES,
  getCobrowseBackendURL,
  getCobrowseIDFromURL,
  getCursorId,
  setCobrowsePodPhase,
  toggleCursorVisibility,
  debounce,
} from "@/helpers"
import cn from "classnames"
import { useContext, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { assignMainCanvasRect } from "@/store/slices/cobrowseSlice"
import { useResizeDetector } from "react-resize-detector"
import { assignTippy } from "@/store/slices/tippySlice"
import { assignMainTileTitleMsg } from "@/store/slices/roomSlice"

const randomColor =
  CSS_COLOR_NAMES[Math.floor(Math.random() * CSS_COLOR_NAMES.length)]

// const COBROWSE_CANVAS_EL_ID = "canvas_wrapper"
const COBROWSE_CANVAS_EL_ID = "noVNC_canvas"

export default function VNCTile(props: any) {
  //   const iframe_wrapper_ref = useRef(null)
  const {
    width: compWidth,
    height: compHeight,
    ref: iframe_wrapper_ref,
  } = useResizeDetector({
    refreshMode: "debounce",
    refreshRate: 100,
  })
  console.log({ compWidth, compHeight })
  const dispatch = useDispatch()
  const checkerRef = useRef<number>()
  const socket = useContext(SocketContext)
  const iframe_ref = useRef(null)
  const [cobrowseInited, setCobrowseInited] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [errMsg, setErrMsg] = useState("")

  const item = props?.tile || props?.app
  const src = item?.src
  const id = item?._id
  const iframeElId = `cobrowse_iframe__${id}`
  const podPhase = item?.phase
  const podIsAlreadyRunning = podPhase?.trim?.().toLowerCase() === "running"
  const isMain = props?.isMain // Passed directly from <MainPanel /> to distinguish what shoul REALLY be the main tile.

  const REACT_APP_SOCKET_SERVER = useSelector((state: RootState) =>
      state.env.REACT_APP_SOCKET_SERVER ||
      import.meta.env.REACT_APP_SOCKET_SERVER
  )
  const userData = useSelector((state: RootState) => {
    const userdata = state.user.data || {}
    return JSON.parse(JSON.stringify(userdata))
  })
  const guestData = useSelector((state: RootState) => {
    const guestData = state.socket?.socketJoinPayload?.data || {}
    return JSON.parse(JSON.stringify(guestData))
  })
  const roomData = useSelector((state: RootState) => state.room.data)
  const isLarge = useSelector((state: RootState) => {
    const largeTiles = state.room.data?.scene2d?.dataTile00
    if (!largeTiles || !item) return false
    const found = largeTiles.find((tile: any) => tile._id === item._id)
    return !!found
  })
  const permissionLevel = useSelector(
    (state: RootState) => state.permission.level,
  )
  const userCursorColor = useSelector(
    (state: RootState) => state.user.cursorColor,
  )
  const cursors = useSelector((state: RootState) => state.participants.cursors)
  const cursorsRef = useRef(cursors)
  const views = useSelector((state: RootState) => state.space.views)
  const mainTileRect = useSelector(
    (state: RootState) => state.space.mainTileRect,
  )

  const isGuest = permissionLevel === "guest"
  const allowCobrowse = useSelector((state: RootState) => {
    if (!isLarge) return false
    const permissionLevel = state.permission.level
    if (permissionLevel === "owner") return true
    // if (permissionLevel === "guest") return false

    const cobrowseTileAccesses = item?.access
    if (!cobrowseTileAccesses || !Array.isArray(cobrowseTileAccesses))
      return false
    const foundAccess = cobrowseTileAccesses.find((obj) => {
      return obj.userId === (userData._id || guestData?.guestId)
    })
    if (!foundAccess || !foundAccess.action) return false
    const isAllowed = foundAccess.action === "control"
    return isAllowed
  })
  const tippyState = useSelector((state: RootState) => state.tippy)
  const showAnnotation = useSelector(
    (state: RootState) => state.annotation.showAnnotation,
  )
  const enableAnnotation = views.includes("annotation") && showAnnotation


  const getUserDataPayload = () => {
    // const guestUUID = window.crypto.randomUUID()
    // const guestUserId = `guest-${guestUUID}`
    const guestUserId = guestData?.guestId
    const guestFullName = window.sessionStorage.getItem("guestName")
    const _id = isGuest ? guestUserId : userData?._id
    const email = userData?.email
    const name = isGuest ? guestFullName : userData?.name
    const guest = isGuest
    const owner = permissionLevel === "owner"
    const cursorColor = userCursorColor || userData?.cursorColor || randomColor
    const displayName = isGuest ? guestFullName : name
    const color = userCursorColor || userData?.color || randomColor
    return {
      _id,
      email,
      name,
      guest,
      owner,
      cursorColor,
      displayName,
      color,
    }
  }

  const userDataPayload = getUserDataPayload()

  const iframeMessagePayload = {
    user: userDataPayload,
    room: roomData,
    // readonly: isGuest && !allowCobrowse,
    password: "password", // Can be any value but I'm thinking to set this as room._id (implementation also needed from api-server).
  }

  async function vncStateChecker(url: string, intervalId: any = null) {
    if (podIsAlreadyRunning) {
      clearInterval(intervalId)
      return
    }
    try {
      setIsLoading(true)
      const cobrowseID = getCobrowseIDFromURL(url)
      const backendURL = getCobrowseBackendURL()
      const fetchURL = new URL(backendURL)
      fetchURL.searchParams.append("check", cobrowseID)
      const response = await fetch(fetchURL)
      const result = await response.json()
      const notFound = result.type === "not-found"
      if (notFound) {
        clearInterval(intervalId)
        setCobrowsePodPhase({
          tileId: id,
          phase: result.type, // 'not-found'
          roomId: roomData?._id,
        })
        return true
      }
      const data = result.data
      const dataTrimmed = data?.trim?.()
      const isRunning =
        dataTrimmed === "Running" || dataTrimmed.toLowerCase?.() === "running"

      if (isRunning) {
        clearInterval(intervalId)
        setIsLoading(false)
        setCobrowsePodPhase({
          tileId: id,
          phase: data,
          roomId: roomData?._id,
        })
        return true
      }
      setIsLoading(true)
      return false
    } catch (error) {
      setIsLoading(true)
      return false
    }
  }

  function registerSocketEvents(onMounted = true) {
    if (onMounted) {
      if (!isLarge || !isMain) return
      socket.on(SocketMessageType.HideCursor, (payload) => {
        const participantId = payload._id
        if (!participantId || participantId === userData._id) return
        toggleCursorVisibility(participantId, false)
      })
      socket.on(SocketMessageType.ShowCursor, (payload) => {
        const participantId = payload._id
        if (!participantId || participantId === userData._id) return
        toggleCursorVisibility(participantId, true)
      })
      return
    }

    if (!isLarge || !isMain) return
    socket.off(SocketMessageType.HideCursor)
    socket.off(SocketMessageType.ShowCursor)
  }

  function registerWindowEvents(onMounted = true) {
    if (!isLarge || !isMain) return
    // Without debouncing, React virtual DOM will handle things with previous state //
    //  const debouncedOnWindowResize = debounce(onWindowResize, 200)
    if (onMounted) {
      if (window.__registered_cobrowse_main_tile_window_events) return
      window.addEventListener("message", messageListener)
      // window.addEventListener("resize", onWindowResize)
      // window.addEventListener("resize", debouncedOnWindowResize)
      window.__registered_cobrowse_main_tile_window_events = true
      return
    }

    termCobrowse()
    //!! wtf doesnt work.. might need to switch to tracking mainTileRect instead of window resize event listener !!//
    console.log("----- remove evt listener")
    window.removeEventListener("message", messageListener)
    //  window.removeEventListener("resize", onWindowResize)
    //  window.removeEventListener("resize", debouncedOnWindowResize)
    window.__registered_cobrowse_main_tile_window_events = false
  }

  function onBlockerMouseEnter(evt) {
    console.log('onBlockerMouseEnter()', { isMain, allowCobrowse, enableAnnotation })
    // if (!isMain || allowCobrowse || enableAnnotation) {
    //   dispatch(assignMainTileTitleMsg(''))
    //   return
    // }
    // const msg = `Owner must permit control`
    // dispatch(assignMainTileTitleMsg(msg))
  }

  function onBlockerMouseLeave(evt) {
    if (!isMain || allowCobrowse || enableAnnotation) return
    dispatch(assignMainTileTitleMsg(""))
  }

  //@@ Check if VNC is up and running @@//
  useEffect(() => {
    if (!src) return
    registerWindowEvents(true)
    registerSocketEvents(true)

    if (podIsAlreadyRunning) {
      // if (podIsReady) {
      window.log(
        `Pod is already running and ready. No need to check if VNC is up and running.`,
        "info",
      )
      setIsLoading(false)
      return
    }

    const time = 2000
    const intervalId = setInterval(() => vncStateChecker(src, intervalId), time)

    return () => {
      registerSocketEvents(false)
      registerWindowEvents(false)
      clearInterval(intervalId)
      // dispatch(assignMainCanvasRect(null))
    }
  }, [])

  //   useEffect(() => {
  //     if (isMain) {
  //       console.log("hi world")
  //       if (allowCobrowse) {
  //         dispatch(assignTippy({ visible: false, content: "" }))
  //       } else {
  //         const tippyContent = (() => {
  //           if (isGuest) return `Guests are not allowed to control Cobrowse`
  //           return `Owner must permit you to control Cobrowse`
  //         })()
  //         dispatch(assignTippy({ visible: true, content: tippyContent }))
  //       }
  //     }

  //     return () => {
  //       dispatch(assignTippy({ visible: false, content: "" }))
  //     }
  //   }, [allowCobrowse, isGuest, isMain])

  useEffect(() => {
    if (!isMain) {
      registerWindowEvents(false)
    }
  }, [isMain])

  useEffect(() => {
    cursorsRef.current = cursors
  }, [cursors])

  useEffect(() => {
    cobrowseRectHandler(COBROWSE_CANVAS_EL_ID)
    onMainTileResize()
  }, [mainTileRect])

  //## initCobrowse() ##//
  function initCobrowse() {
    window.log(`<initCobrowse()>: Triggered on iframe load for tile ${item?.title}`)
    // if (isGuest) return window.log('User is guest. Not initializing cobrowse.', 'warn');
    if (isGuest) {
      window.log(
        `User is guest -- Initializing cobrowse but not allowing interaction.`,
        "warn",
      )
    }
    if (!isLarge || !isMain) {
      window.log(
        "<initCobrowse()>: Not initializing cobrowse because tile is not large.",
        "warn",
      )
      if (cobrowseInited) return termCobrowse()
      return
    }
    const iframeEl: any = iframe_ref.current
    const iframeWindow = iframeEl.contentWindow

    const data = {
      type: "init:cobrowse",
      payload: iframeMessagePayload,
    }
    window.log(`<initCobrowse()>: postMessage to iframe`, "info", {
      data,
      iframeWindow,
    })
    //  iframeWindow.postMessage(data, iframeWindow.origin)
    iframeWindow.postMessage(data, "*")
    window.log(
      `<initCobrowse()>: Okay now we wait for response from cobrowse instance`,
      "info",
      { data, iframeWindow },
    )
  }

  //## termCobrowse() ##//
  function termCobrowse() {
    window.log("termCobrowse()")
    if (!iframe_ref || !iframe_ref.current) {
      // window.log(
      //   `termCobrowse() -- iframe_ref or iframe_ref.current is null, maybe 'termCobrowse()' is triggered after <iframe> is gone?`,
      //   "error",
      // )
      return
    }
    const iframeEl: any = iframe_ref.current
    if (!iframeEl) {
      window.log(`termCobrowse() -- iframeEl is null`, "error")
    }
    const iframeWindow = iframeEl.contentWindow

    const data = {
      type: "term:cobrowse",
      payload: iframeMessagePayload,
    }
    //  iframeWindow.postMessage(data, iframeWindow.origin)
    iframeWindow.postMessage(data, "*")
  }

  //## messageListener() ##//
  function messageListener(evt) {
    const type = evt?.data?.type
    if (!type) return
    // const payload = evt.data.payload;
    if (type === "init:cobrowse:succeeded") {
      window.log(`messageListener() -- init:cobrowse:succeeded`)
      setCobrowseInited(true)
      const manualSucceeded = cobrowseRectHandler(COBROWSE_CANVAS_EL_ID)
      if (manualSucceeded) return
      const iframeEl: any = iframe_ref.current
      const iframeWindow = iframeEl?.contentWindow
      if (!iframeWindow) return window.log(`cant be`)
      cobrowseRectHandler(COBROWSE_CANVAS_EL_ID, true)
      return
    }

    if (type === "init:cobrowse:failed") {
      window.log(`messageListener() -- init:cobrowse:failed`)
      setCobrowseInited(false)
      const delay = 5000
      const msg = `Cobrowse failed to initialize.. Re-trying in ${
        delay / 1000
      } seconds..`
      console.error(msg)
      setTimeout(messageListener, delay)
      return
    }

    if (type === "term:cobrowse:succeeded") {
      window.log(`messageListener() -- term:cobrowse:succeeded`)
      setCobrowseInited(false)
      toggleCursorVisibility(null, true)
      return
    }

    if (type === "get:element:rect") {
      window.log(`messageListener() -- get:element:rect`, "info", {
        evt,
      })
      const responsePayload = evt.data?.payload
      const elRect = responsePayload?.rect
      const elementId = responsePayload?.elementId
      if (!elRect || !elementId) return
      if (elementId === COBROWSE_CANVAS_EL_ID) {
        dispatch(assignMainCanvasRect(elRect))
      }
      // More element window.logic here..
      return
    }
  }

  //## cobrowseRectHandler() ##//
  function cobrowseRectHandler(elementId?: string, communicate = false) {
    if (!isLarge || !isMain) return
    const elId = elementId || COBROWSE_CANVAS_EL_ID
    const iframeEl: any = iframe_ref.current
    if (!iframeEl) {
      return false
    }

    const iframeWindow = iframeEl.contentWindow
    if (!iframeWindow) {
      return false
    }

    const postMessageData = {
      type: "get:element:rect",
      payload: {
        elementId: elId,
      },
    }

    try {
      const iframeDocument = iframeWindow.document

      if (communicate || !iframeDocument) {
        iframeWindow.postMessage(postMessageData, "*")
        //   window.log(
        //     `cobrowseRectHandler() -- Attempting to communicate with iframe`,
        //     "info",
        //   )
        return true
      }

      const selectedEl = iframeDocument?.getElementById(elId)

      if (!selectedEl) {
        window.log(
          `cobrowseRectHandler() -- No element found with element id: '${elId}'`,
          "error",
          { iframeDocument, selectedEl, iframeEl },
        )
        return false
      }

      const elRect = selectedEl.getBoundingClientRect()
      dispatch(assignMainCanvasRect(elRect))

      return elRect
    } catch (error: any) {
      // window.log(
      //   `[cobrowseRectHandler() - ERROR] Falling back to communication`,
      //   "error",
      //   { error },
      // )
      iframeWindow.postMessage(postMessageData, "*")
      return true
    }
  }

  //## onMouseEnter() ##//
  function onMouseEnter() {
    //@@ Emit socket event to hide theia cobrowse cursor @@//
    socket.emit(SocketMessageType.HideCursor, {
      _id: userData._id,
    })
  }

  //## onMouseLeave() ##//
  function onMouseLeave(evt) {
    //@@ Emit socket event to show theia cobrowse cursor @@//
    socket.emit(SocketMessageType.ShowCursor, {
      _id: userData._id,
    })
  }

  //## onWindowResize() ##//
  //   function onWindowResize(evt) {
  //     window.log(`<VNCTile> onWindowResize()`, "info", { evt })
  //     const cursors = cursorsRef.current

  //     const cursorsArr = Object.values(cursors)
  //     cursorsArr.forEach((cur: any) => {
  //       if (!cur.show) {
  //         // Going to trust that element is also hidden.
  //         window.log(
  //           `Cursor ${getCursorId(cur.id)} should already be hidden`,
  //           "info",
  //           {
  //             cur,
  //           },
  //         )
  //         return
  //       }
  //       const pid = cur._id || cur.id
  //       if (!pid) return
  //       const cursorId: string = getCursorId(pid)
  //       const cursorEl: any = document.querySelector(`#${cursorId}`)
  //       const iframeEl: any = iframe_ref.current
  //       if (!cursorEl || !iframeEl) {
  //         window.log(
  //           `onWindowResize() -- cursorEl or iframeEl is null`,
  //           "error",
  //           {
  //             cursorId,
  //           },
  //         )
  //         return
  //       }

  //       // Check if cursor is on top of iframe.
  //       const cursorElRect = cursorEl.getBoundingClientRect()
  //       const {
  //         top: topc,
  //         left: leftc,
  //         width: widthc,
  //         height: heightc,
  //       } = cursorElRect
  //       const bottomc = topc + heightc
  //       const rightc = leftc + widthc

  //       const iframeElRect = iframeEl.getBoundingClientRect()
  //       const {
  //         top: topi,
  //         left: lefti,
  //         width: widthi,
  //         height: heighti,
  //       } = iframeElRect
  //       const bottomi = topi + heighti
  //       const righti = lefti + widthi

  //       window.log(`Comparing cursorElRect to iframeElRect`, "info", {
  //         cursorElRect,
  //         iframeElRect,
  //       })

  //       // If cursor is on top of iframe, hide it.
  //       if (
  //         topc >= topi &&
  //         leftc >= lefti &&
  //         bottomc <= bottomi &&
  //         rightc <= righti
  //       ) {
  //         cursorEl.style.visibility = "hidden"
  //       }
  //     })

  //     console.log({ item })
  //     if (item && item.browser === "chromium") {
  //       setCanvasWrapperSizeForChromium()
  //     }

  //     cobrowseRectHandler(COBROWSE_CANVAS_EL_ID)
  //   }

  //## onMainTileResize() ##//
  function onMainTileResize() {
    window.log(`<VNCTile> onMainTileResize()`)
    const cursors = cursorsRef.current

    const cursorsArr = Object.values(cursors)
    cursorsArr.forEach((cur: any) => {
      if (!cur.show) {
        // Going to trust that element is also hidden.
        window.log(
          `Cursor ${getCursorId(cur.id)} should already be hidden`,
          "info",
          {
            cur,
          },
        )
        return
      }
      const pid = cur._id || cur.id
      if (!pid) return
      const cursorId: string = getCursorId(pid)
      const cursorEl: any = document.querySelector(`#${cursorId}`)
      const iframeEl: any = iframe_ref.current
      if (!cursorEl || !iframeEl) return

      // Check if cursor is on top of iframe.
      const cursorElRect = cursorEl.getBoundingClientRect()
      const {
        top: topc,
        left: leftc,
        width: widthc,
        height: heightc,
      } = cursorElRect
      const bottomc = topc + heightc
      const rightc = leftc + widthc

      const iframeElRect = iframeEl.getBoundingClientRect()
      const {
        top: topi,
        left: lefti,
        width: widthi,
        height: heighti,
      } = iframeElRect
      const bottomi = topi + heighti
      const righti = lefti + widthi

      // If cursor is on top of iframe, hide it.
      if (
        topc >= topi &&
        leftc >= lefti &&
        bottomc <= bottomi &&
        rightc <= righti
      ) {
        cursorEl.style.visibility = "hidden"
      }
    })

    console.log({ item })
    if (item && item.browser === "chromium") {
      setCanvasWrapperSizeForChromium()
    }
    if (item && item.browser === "chrome") {
      setCanvasWrapperSizeForChrome()
    }

    //  cobrowseRectHandler(COBROWSE_CANVAS_EL_ID)
    //!! Quick dirty fix !!//
    setTimeout(cobrowseRectHandler, 500, COBROWSE_CANVAS_EL_ID)
  }

  //## setCanvasWrapperSizeForChromium() ##//
  function setCanvasWrapperSizeForChromium() {
    const isChromium = item?.browser === "chromium"
    const iframeEl: any = iframe_ref.current
    const iframeWindow = iframeEl?.contentWindow
    const mainTileWidth = mainTileRect?.width
    const mainTileHeight = mainTileRect?.height

    window.log(`setCanvasWrapperSizeForChromium()`, "info", {
      isMain,
      iframeWindow,
      mainTileWidth,
      mainTileHeight,
      browser: item.browser,
    })

    if (
      !isMain ||
      !isChromium ||
      !iframeWindow ||
      !mainTileWidth ||
      !mainTileHeight
    ) {
      return
    }

    const data = {
      type: "set:canvas-wrapper-size",
      payload: {
        width: `${mainTileWidth}px`,
        height: `${mainTileHeight}px`,
      },
    }
    iframeWindow.postMessage(data, "*")
  }

  //## setCanvasWrapperSizeForChrome() ##//
  function setCanvasWrapperSizeForChrome() {
   const isChrome = item?.browser === "chrome"
   const iframeEl: any = iframe_ref.current
   const iframeWindow = iframeEl?.contentWindow
   const mainTileWidth = mainTileRect?.width
   const mainTileHeight = mainTileRect?.height

   window.log(`setCanvasWrapperSizeForChrome()`, "info", {
     isMain,
     iframeWindow,
     mainTileWidth,
     mainTileHeight,
     browser: item.browser,
   })

   if (
     !isMain ||
     !isChrome ||
     !iframeWindow ||
     !mainTileWidth ||
     !mainTileHeight
   ) {
     return
   }

   const data = {
     type: "set:canvas-wrapper-size",
     payload: {
       width: `${mainTileWidth}px`,
       height: `${mainTileHeight}px`,
     },
   }
   iframeWindow.postMessage(data, "*")
 }

  //## TEMPLATE ##//
  if (!item || !userData || isLoading) {
    return <CenteredLoader />
  }

  const finalSrc = (() => {
    const browser = item?.browser
    if (browser === "chromium") {
      return `${src}?resize=scale&autoconnect=1&clipboard_up=true&clipboard_down=true&clipboard_seamless=true&show_control_bar=true`
      // return `${src}?resize=scale&autoconnect=1&clipboard_up=true&clipboard_down=true&clipboard_seamless=true&show_control_bar=true&socketurl=http://localhost:8000/space-${roomData?._id}`
    }

    if (browser === "chrome") {
      // return `${src}?resize=scale&autoconnect=1&clipboard_up=true&clipboard_down=true&clipboard_seamless=true&show_control_bar=true`
      // return `${src}?resize=scale&autoconnect=1&clipboard_up=true&clipboard_down=true&clipboard_seamless=true&show_control_bar=true&socketurl=http://localhost:8000/space-${roomData?._id}`
      return `${src}?resize=scale&autoconnect=1&clipboard_up=true&clipboard_down=true&clipboard_seamless=true&show_control_bar=true&socketurl=${REACT_APP_SOCKET_SERVER}/space-${roomData?._id}`
    }

    return `${src}`
    //  return `${src}?socketurl=http://localhost:8000/space-${roomData?._id}`
  })()

  return (
    <div
      ref={iframe_wrapper_ref}
      className={cn(
        "cobrowse-tile-comp",
        "vnc-tile-comp",
        "iframe-wrapper",
        "relative w-full h-full",
        "flex justify-center items-center",
        "p-0 border-0 select-none",
      )}
      style={{
        boxSizing: "border-box",
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {!allowCobrowse && (
        <div
          className={cn(
            "iframe-blocker",
            "absolute inset-0",
            "bg-black/0 z-[5]",
          )}
          style={{
            cursor: "not-allowed",
          }}
          onMouseEnter={onBlockerMouseEnter}
          onMouseLeave={onBlockerMouseLeave}
        />
      )}
      <iframe
        ref={iframe_ref}
        id={iframeElId}
        className="border-0"
        src={finalSrc}
        height={"100%"}
        width={"100%"}
        title={"cobrowse iframe " + id}
        allowFullScreen
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-pointer-lock"
        onLoad={initCobrowse}
      />
    </div>
  )
}
