import { useMousePosition } from "@/utils/hooks/useMousePosition"
import cn from "classnames"
import { useEffect, useState } from "react"
import { XYCoord, useDragLayer } from "react-dnd"
import Tile from "../../TileList/Tile"

export default function DragLayer() {
  const [offset, setOffset] = useState<XYCoord>({ x: 0, y: 0 })

  const mousePosition = useMousePosition()
  const { isDragging, item, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  useEffect(() => {
    if (!isDragging || !currentOffset || !mousePosition) {
      return
    }

    setOffset({
      x: currentOffset.x - mousePosition.x,
      y: currentOffset.y - mousePosition.y,
    })
  }, [isDragging])

  if (!isDragging || !item || !currentOffset) {
    return null
  }

  return (
    <div
      className={cn(
        "fixed w-[400px] h-[250px] overflow-hidden rounded-lg pb-10 z-50",
        "flex flex-col items-center bg-gravel shadow shadow-cloudy",
      )}
      style={{
        top: currentOffset.y - offset.y,
        left: currentOffset.x - offset.x,
      }}
    >
      <div className="w-full h-[36px] flex items-center justify-between px-[7px]">
        <div className="text-14 text-lavender truncate">
          {item.title || item.fileName}
        </div>
      </div>
      <div className="w-[368px]">
        <Tile app={item} />
      </div>
    </div>
  )
}
