import { RootState } from "@/TYPES/redux"
import JitsiVideo from "@/components/Call/JitsiVideo"
import ChatComp from "@/components/Chat"
import { switchViews } from "@/store/slices/spaceSlice"
import cn from "classnames"
import { memo, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import DesktopLeftPanel from "./DesktopLeftPanel"
const ChatCompMemo = memo(ChatComp)
const JitsiVideoMemo = memo(JitsiVideo)
const REACT_APP_SHOW_CHAT = import.meta.env.REACT_APP_SHOW_CHAT
const forceHideChat =
  REACT_APP_SHOW_CHAT === "false" || REACT_APP_SHOW_CHAT === false

// Visible on desktop only
export default function DesktopRightPanel() {
  const dispatch = useDispatch()

  const views = useSelector((state: RootState) => state.space.views)
  // Show jitsi as fullscreen when someone is sharing screen
  const fullScreen = useSelector(
    (state: RootState) => state.call.shareScreenMode,
  )
  const isAddingTile = useSelector(
    (state: RootState) => state.space.isAddingTile || state.space.isAddingTile,
  )
  const isPresenterMode = useSelector(
    (state: RootState) => state.space.isPresenterMode,
  )

  // Hide chat and calendar on full-screen
  useEffect(() => {
    if (fullScreen) {
      if (views.includes("chat")) {
        dispatch(switchViews("chat"))
      }
      if (views.includes("calendar")) {
        dispatch(switchViews("calendar"))
      }
      if (views.includes("jitsi")) {
        dispatch(switchViews("jitsi"))
      }
    }
  }, [fullScreen])

  // const showApps = views.includes("apps")
  const showJitsi = fullScreen || views.includes("jitsi")
  const showChat = views.includes("chat")
  const isVisible = showJitsi || showChat
  // || showApps

  return (
    <div
      className={cn(
        "relative transition-width flex flex-col justify-center",
        "bg-cobrowsegray",
        {
          "w-[400px]": isVisible && !fullScreen,
          "flex-1 mb-[50px]": fullScreen,
          "w-0": !isVisible,
          "z-10": isAddingTile,
        },
      )}
    >
      <div
        className={cn(
          "absolute top-0 left-0 h-full",
          {
            "w-0": !fullScreen && !showJitsi,
            "w-[400px]": !fullScreen && showJitsi,
            "w-full": fullScreen,
          },
          "flex flex-col",
        )}
      >
        <JitsiVideoMemo />
      </div>

      <div
        className={cn(
          "absolute h-full right-0 overflow-hidden bg-cobrowsegray z-10",
          {
            "w-[400px] transition-width z-[100]": fullScreen && showChat,
            "w-0": !showChat,
            "w-[400px]": !fullScreen && showChat,
          },
        )}
      >
        {forceHideChat ? (
          <div>Hiding chat for development</div>
        ) : (
          <ChatCompMemo />
        )}
      </div>
    </div>
  )
}
