import { RootState } from "@/TYPES/redux"
import { Cursor } from "@/components/Cursor"
import ImgTile from "@/pages/Space/AppTile/ImgTile"
import PDFTile from "@/pages/Space/AppTile/PDFTile"
import RDPTile from "@/pages/Space/AppTile/RDPTile"
import VNCTile from "@/pages/Space/AppTile/VNCTile"
import { useSelector } from "react-redux"
import EmbedTile from "./EmdbedTile"
import IconTile from "./IconTile"
import { useEffect, useState } from "react"

const allowed = ["cobrowse", "iframe", "file"]

type Props = {
  isMain?: boolean
  tile: any
  active?: boolean
}

export default function AppTile({
  isMain = false,
  tile,
  active = false,
}: Props) {
  const [faviconURL, setFaviconURL] = useState<string | undefined | null>(null)

  const isSmallSidebar = useSelector(
    (state: RootState) => state.space.isSmallSidebar,
  )

  const type = tile.type
  const fileName = tile.fileName

  const isFile = type === "file" || fileName?.endsWith(".pdf")
  const isImg =
    fileName?.endsWith(".png") ||
    fileName?.endsWith(".jpg") ||
    fileName?.endsWith(".jpeg") ||
    fileName?.endsWith(".gif")
  const isIframe = type === "iframe" || fileName === "iframe"
  const isRDP = fileName?.endsWith(".rdp")

  const iconTile = isSmallSidebar && !isMain

  return (
    <>
      {!iconTile && (
        <>
          {isMain && isFile && <Cursor tile={tile}/>}
          {type === "cobrowse" && (
            <VNCTile app={tile} tile={tile} active={active} isMain={isMain} />
          )}
          {isIframe && <EmbedTile app={tile} active={active} isMain={isMain} />}
          {isImg && <ImgTile app={tile} tile={tile} active={active} />}
          {type === "file" && !isRDP && !isImg && (
            <PDFTile
              isMain={isMain}
              tile={tile}
              key={tile._id}
              active={active}
            />
          )}
          {isRDP && (
            <RDPTile app={tile} tile={tile} active={active} isMain={isMain} />
          )}
        </>
      )}
      {iconTile && <IconTile app={tile} />}
      {!allowed.includes(type) && !isFile && !isImg && !isIframe && !isRDP && (
        <pre>{JSON.stringify(tile, null, 3)}</pre>
      )}
    </>
  )
}
