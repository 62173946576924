import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cn from "classnames"

type Props = {
  value?: string
  onChange: (value: string) => void
}

export default function Searchbox({ value, onChange }: Props) {
  return (
    <div className={cn("flex", "sm:flex-1 sm:justify-end sm:pr-4")}>
      <div
        className={cn(
          "relative h-[40px] w-[400px]",
          "flex bg-smoke rounded-[5px]",
          "sm:w-fit sm:justify-end sm:bg-transparent",
        )}
      >
        <FontAwesomeIcon
          className="absolute top-1/2 left-[8px] transform -translate-y-1/2"
          icon="search"
        />
        <input
          className={cn(
            "flex-1 h-full w-full bg-transparent outline-none pl-8 text-carbon z-[1]",
            "sm:transition-width sm:text-typegray sm:max-w-[30px] sm:focus:max-w-[150px]",
          )}
          value={value ?? ""}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
    </div>
  )
}
