import { useDynamicSvgImport } from "@/utils/hooks/useDynamicSvgImport"
import cn from "classnames"
import { SVGProps } from "react"
import { IconNameProp } from "./types"

interface IconProps {
  iconName: IconNameProp
  size?: number
  className?: string
  wrapperClassName?: string
  svgProps?: SVGProps<SVGSVGElement>
  onClick?: (e: any) => void
}

export { IconNameProp }

export default function Icon({
  iconName,
  size = 20,
  className = "fill-typegray",
  wrapperClassName,
  svgProps,
  onClick,
}: IconProps) {
  const { loading, SvgIcon } = useDynamicSvgImport(iconName)

  if (loading) {
    return <div className="rounded-full bg-gravel animate-pulse h-8 w-8"></div>
  }

  if (!SvgIcon) {
    return null
  }

  return (
    <span
      className={cn("flex items-center justify-center", wrapperClassName)}
      style={{ width: size, height: size }}
      onClick={onClick}
    >
      <SvgIcon className={className} width={size} height={size} {...svgProps} />
    </span>
  )
}
