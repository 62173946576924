import cn from "classnames"

interface Props {
  title: string
  isDarkMode: boolean
}

export default function SectionHeader({ title, isDarkMode }: Props) {
  return (
    <div
      className={cn("font-bold text-[14pt]", {
        "text-carbon": !isDarkMode,
        "text-smoke": isDarkMode,
      })}
    >
      {title}
    </div>
  )
}
