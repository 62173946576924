import $store from "@/store"
import useSWR, { mutate } from "swr"
import swrHook from "swr"
import { fetcher, poster, throwError } from "./fetcher"
import { TEMP_SHAREPOINT_TILE_ID, updateTiles } from "@/helpers"
export interface FetchedData<T> {
  data: T
  isLoading: boolean
  isError: boolean
}

const isValidFileName = (filename: string) => {
  const regex = new RegExp(
    /^(?!\.)(?!com[0-9]$)(?!con$)(?!lpt[0-9]$)(?!nul$)(?!prn$)[^\|\*\?\\:<>/#$"]*[^\.\|\*\?\\:<>/#$"]+$/,
  )
  return regex.test(filename)
}

// Holds all assets associated with space
export function useAssets(roomId: string): FetchedData<any> {
  const { data: roomData } = swrHook(`/api/rooms/${roomId}`, fetcher)
  const { data, error } = swrHook(
    `/api/teams/assetlocker/${roomData?.teamId}/${roomId}/listAssets`,
    fetcher,
  )

  const files = data?.data;

  return {
    data: files,
    isLoading: !error && !data,
    isError: error,
  }
}

// export async function uploadAsset(spaceId: string, file: File) {
//   const url = `${REACT_APP_API_SERVER}/api/teams/${spaceId}/addAsset`
//   const body = new FormData()
//   body.append("", file, file.name) // Add file to form object w/o specifying a field name (only filename--used on server side)

//   try {
//     const response = await fetch(url, {
//       method: "POST",
//       body,
//       credentials: "include",
//     })
//     if (!response.ok) throw response
//     // Need to add logic to notify user that upload failed
//     const data = await response.json()
//     return data
//   } catch (err) {
//     throwError("Failed to upload asset", err)
//   }
// }

function isFormDataEmpty(formData) {
  // Convert the FormData entries iterator to an array and check its length
  return Array.from(formData.entries()).length === 0
}

export function uploadAsset(teamId, file) {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER
  const formData = new FormData()
  formData.append("", file, file.name)
  console.log("uploadAsset()", formData)
  if (isFormDataEmpty(formData)) {
    console.error("uploadAsset() formData is empty")
    return
  }

  const options: any = {
    method: "POST",
    body: formData,
    credentials: "include",
  }

  const URL = `${REACT_APP_API_SERVER}/api/teams/${teamId}/add-asset`
  //   const URL = `${
  //     import.meta.env.REACT_APP_API_SERVER
  //   }/api/teams/${teamId}/addAsset`

  return new Promise((resolve, reject) => {
    fetch(URL, options)
      .then((res) => {
        res.json().then((data) => {
          if (res.ok) resolve(data)
          //  snackbarQueue.notify({
          //    title: "Server Error",
          //    body: data.msg || data.errorMsg,
          //    icon: "warning",
          //  })
          reject(data)
        })
      })
      .catch((e) => {
        console.error(e.message || e)
        reject(e.message)
      })
  })
}

const replaceTempSharepointTile = (createdRDPAsset, currSmallTiles) => {
  createdRDPAsset.title = 'SharePoint'
  const newSmallTiles = currSmallTiles.map(tile => (
    tile._id === TEMP_SHAREPOINT_TILE_ID ? createdRDPAsset : tile
  ))
  return newSmallTiles
}

export async function uploadRDPAsset(teamId?: string, assetData?: any) {
  teamId = teamId || $store.getState().room.data?.teamId
  console.log("uploadRDPAsset()", { teamId, assetData })
  if (!teamId || !assetData) {
    console.error("uploadRDPAsset() missing teamId or assetData")
    return
  }

  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER
  const url = `${REACT_APP_API_SERVER}/api/teams/${teamId}/addRdpAsset`

  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(assetData),
      credentials: "include",
    })

    if (!response.ok) return Promise.reject(response)

    const roomId = $store.getState().room.data?._id
    if (!roomId) return Promise.reject("Missing roomId")

    const depositAssetLockerResult: any = await depositAssetLocker(
      teamId,
      roomId,
      `${assetData.name}.rdp`,
    )
    const createdRDPAssets = depositAssetLockerResult.data
    const createdRDPAsset = createdRDPAssets[0]
    if (!createdRDPAsset) return Promise.reject("Missing createdRDPAsset")

    const isConfiguringSharepoint = assetData.isSharepoint
    const currentSmallTiles = $store.getState().room.data?.scene2d?.dataTile01
    const currentLargeTiles = $store.getState().room.data?.scene2d?.dataTile00

    if (!currentSmallTiles)
      return Promise.reject("Missing currentSmallTiles??????")

    const newSmallTiles = isConfiguringSharepoint
      ? replaceTempSharepointTile(createdRDPAsset, currentSmallTiles)
      : [createdRDPAsset, ...currentSmallTiles]
    const newLargeTiles = isConfiguringSharepoint
      ? [createdRDPAsset]
      : currentLargeTiles

    await updateTiles(roomId, newSmallTiles, newLargeTiles)
  } catch (err) {
    return Promise.reject(err)
  }
}

// export async function depositAssetLocker(
//   spaceId: string,
//   roomId: string,
//   filename: string,
// ) {
//   const url = `${REACT_APP_API_SERVER}/api/teams/assetLocker/${spaceId}/${roomId}/${filename}/deposit`

//   try {
//     const response = await fetch(url, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       credentials: "include",
//     })
//     if (!response.ok) throw response
//     const data = response.json()
//     return data
//   } catch (err) {
//     throwError("Failed to deposit to asset locker", err)
//   }
// }

export function depositAssetLocker(teamId, roomId, fileName) {
  console.log("depositAssetLocker()", { teamId, roomId, fileName })
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER

  const options: any = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  }

  const URL = `${REACT_APP_API_SERVER}/api/teams/assetLocker/${teamId}/${roomId}/${fileName}/deposit`

  return new Promise((resolve, reject) => {
    fetch(URL, options)
      .then((res) => {
        res.json().then((data) => {
          if (res.ok) resolve(data)
          //  snackbarQueue.notify({
          //    title: "Server Error",
          //    body: data.msg || data.errorMsg,
          //    icon: "warning",
          //  })
          reject(data)
        })
      })
      .catch((e) => {
        reject(e)
      })
  })
}

// export async function unlinkAsset(
//   spaceId: string,
//   roomId: string,
//   filename: string,
// ) {
//   const url = `${REACT_APP_API_SERVER}/api/teams/assetLocker/${spaceId}/${roomId}/${filename}/remove`

//   try {
//     const response = await fetch(url, {
//       method: "DELETE",
//       credentials: "include",
//     })
//     if (!response.ok) throw response
//     const data = await response.json()
//     return data
//   } catch (err) {
//     throwError("Failed to unlink asset", err)
//   }
// }

export function unlinkAsset(teamId: string, roomId: string, fileName: string) {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER

  const options: any = {
    method: "DELETE",
    credentials: "include",
  }

  const URL = `${REACT_APP_API_SERVER}/api/teams/assetLocker/${teamId}/${roomId}/${fileName}/remove2`

  return new Promise((resolve, reject) => {
    fetch(URL, options)
      .then((res) => {
        const data = res.json()
        if (res.ok) resolve(data)
        reject(data)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export async function updateAssetName(
  spaceId: string,
  roomId: string,
  filename: string,
  newFilename: string,
) {
  const url = `/api/teams/assetLocker/${spaceId}/${roomId}/${filename}/updateFileNameLive`
  const newNameWithExtension = `${newFilename}.${filename.split(".").pop()}` // Example output: NewName.pdf
  const body = { newName: newNameWithExtension }

  if (!isValidFileName(newNameWithExtension))
    throw new Error("Invalid filename")

  try {
    const response: any = await poster(url, body)
    if (!response.ok) throw response
    mutate("/api/rooms") // Update rooms w/ SWR
    return response
  } catch (err) {
    throwError("Failed to update asset name", err)
  }
}

export async function updateVirtualAssetName(
  roomId: string,
  assetId: string,
  newFilename: string,
) {
  const url = `/api/me/tiles/${roomId}/${assetId}/updateVirtualName`
  const body = { newName: newFilename }

  try {
    const response: any = await poster(url, body)
    if (!response.ok) throw response
    return response
  } catch (err) {
    throwError("Failed to update virtual asset name", err)
  }
}

interface GetAssetDataParams {
  teamId: string
  roomId: string
  fileName: string
}

export function getAssetData(param: GetAssetDataParams) {
  const teamId = param.teamId || $store.getState().room.data?.teamId
  const roomId = param.roomId || $store.getState().room.data?._id
  const fileName = param.fileName

  if (!teamId || !roomId || !fileName) {
    const msg = `Missing teamId, roomId, or fileName`
    return Promise.reject(msg)
  }

  const fetchEndpoint = `/api/teams/assetlocker/${teamId}/${roomId}/${fileName}/withdraw`

  return new Promise(async (resolve, reject) => {
    try {
      const result = await fetcher(fetchEndpoint)
      resolve(result)
    } catch (err) {
      console.error(err)
      reject(err)
    }
  })
}
