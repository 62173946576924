import { RootState } from "@/TYPES/redux"
import cn from "classnames"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

type Props = {
  spaceId: string
  videoOff: boolean
  audioOff: boolean
}

export default function GuestJoinSpace({ spaceId, videoOff, audioOff }: Props) {
  const navigate = useNavigate()

  const serverError = useSelector((state: RootState) => state.init.serverError)
  const user = useSelector((state: RootState) => state.user)

  const displayName = user.data?.firstName || user.data?.name
  const rejoiningGuestName = window.sessionStorage.guestName ?? ""

  const handleSubmit = (e: any) => {
    e.preventDefault()

    const queryParams = new URLSearchParams({
      audio: (!audioOff).toString(),
      video: (!videoOff).toString(),
      audioSource: user.audioDevice.id ?? "default",
      videoSource: user.videoDevice.id ?? "default",
    })

    navigate(
      {
        pathname: `/space/${spaceId}`,
        search: queryParams.toString(),
      },
      { state: { skipRedirect: true } },
    )
    // window.location.href = `/space/${spaceId}?${queryParams.toString()}`
  }

  return (
    <form className="w-full flex flex-col" onSubmit={handleSubmit}>
      <h3 className="text-28 text-center font-light mb-8">
        {displayName
          ? `Welcome, ${displayName}`
          : `Welcome, ${rejoiningGuestName}`}
      </h3>

      <button
        type="submit"
        className={cn(
          "h-[54px] text-20 rounded-sm px-16 py-2",
          "sm:h-[46px] sm:text-16",
          {
            "text-carbon bg-accentyellow hover:opacity-80": !serverError,
            "text-ghostgray bg-accentred cursor-not-allowed opacity-80":
              serverError,
          },
        )}
      >
        Join
      </button>
      {serverError && (
        <div className="self-center text-center pt-4">
          Internal Server Error: Try refreshing the page.
          <br />
          If the problem persists, contact your administrator.
        </div>
      )}
    </form>
  )
}
