import { useEffect, useState } from "react"

interface XYCord {
  x: number
  y: number
}

export const useMousePosition = () => {
  const [position, setPosition] = useState<XYCord>()

  useEffect(() => {
    const updateMousePosition = (ev) => {
      setPosition({ x: ev.clientX, y: ev.clientY })
    }

    window.addEventListener("mousemove", updateMousePosition)

    return () => {
      window.removeEventListener("mousemove", updateMousePosition)
    }
  }, [])

  return position
}
