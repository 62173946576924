import { Slide, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

interface Props {}

export default function ToastNotification() {
  return (
    <ToastContainer
      position="top-center"
      limit={3}
      hideProgressBar={true}
      pauseOnFocusLoss={false}
      autoClose={850}
      theme="dark"
      transition={Slide}
      icon={false}
      draggable={false}
    />
  )
}
