import { Event } from "@/TYPES/redux"

import { useState, useEffect } from "react"
import "react-datepicker/dist/react-datepicker.css"
import cn from "classnames"
import { getEventIsValid } from "./utils"
import { useDispatch, useSelector } from "react-redux"
import {
  addEvent,
  setActiveEvent,
  setEditEvent,
} from "@/store/slices/eventsSlice"
import ParticipantSelect from "./ParticipantSelect"
import DatesInput from "./DatesInput"
import TitleInput from "./TitleInput"
import Header from "./Header"
import LocationSelect from "./LocationSelect"
import AgendaInput from "./AgendaInput"
import { useUpdateCalendar } from "../../hooks/useUpdateCalendar"
import { RootState } from "@/TYPES/redux"

interface Props {
  editEvent: Event
  isDarkMode: boolean
}

export default function EditPanel({ editEvent, isDarkMode }: Props) {
  const [eventIsValid, setEventIsValid] = useState(getEventIsValid(editEvent))
  const savedEvents = useSelector((state: RootState) => state.events.events)
  const dispatch = useDispatch()
  const { addEventToCalendar } = useUpdateCalendar()
  const isEditingExistingEvent = savedEvents.some(
    (ev) => ev.eventUUID === editEvent.eventUUID,
  )

  const validateForm = (newForm: Event) => {
    if (getEventIsValid(newForm)) {
      setEventIsValid(true)
    } else {
      setEventIsValid(false)
    }
  }

  useEffect(() => {
    validateForm(editEvent)
  }, [editEvent])

  const handleCreateEventClick = () => {
    dispatch(addEvent(editEvent))
    dispatch(setEditEvent(undefined))
    dispatch(setActiveEvent(editEvent.eventUUID))

    addEventToCalendar(editEvent)
  }

  return (
    <>
      <div
        className={cn("flex flex-col flex-1 gap-4 overflow-y-auto p-4", {
          "bg-white": !isDarkMode,
          "bg-cobrowsegray": isDarkMode,
        })}
      >
        <Header isDarkMode={isDarkMode} />
        <TitleInput editEvent={editEvent} isDarkMode={isDarkMode} />
        <DatesInput editEvent={editEvent} isDarkMode={isDarkMode} />
        <ParticipantSelect editEvent={editEvent} isDarkMode={isDarkMode} />
        <LocationSelect editEvent={editEvent} isDarkMode={isDarkMode} />
        <AgendaInput editEvent={editEvent} isDarkMode={isDarkMode} />
      </div>
      <div className="flex h-[70px] w-full">
        <button
          className={cn(
            "flex w-full p-3 font-bold text-carbon text-[20pt] text-center justify-center rounded-sm border-2 transition-color duration-300",
            {
              "bg-accentyellow border-transparent hover:bg-white hover:border-accentyellow":
                eventIsValid,
              "cursor-not-allowed": !eventIsValid,
              "bg-typegray border-typegray": !eventIsValid && !isDarkMode,
              "text-smoke bg-cobrowsegray border-[0.5px] border-cloudy": !eventIsValid && isDarkMode,
            },
          )}
          disabled={!eventIsValid}
          onClick={() => handleCreateEventClick()}
        >
          {`${isEditingExistingEvent ? "Edit" : "Create"} Event`}
        </button>
      </div>
    </>
  )
}
