import { RootState } from "@/TYPES/redux"
import { selectFolder } from "@/store/slices/filesSlice"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import FileActionbar from "./FileActionbar"
import FileHeader from "./FileHeader"
import FileItem from "./FileItem"
import UpItem from "./UpItem"

type Props = {
  narrow?: boolean
  darkMode?: boolean
  managable?: boolean
}

export default function FileManager({
  narrow = false,
  darkMode = false,
  managable = false,
}: Props) {
  const dispatch = useDispatch()

  const { folders, selectedIds } = useSelector(
    (state: RootState) => state.files,
  )

  const [selectedFiles, setSelectedFiles] = useState<string[]>([])
  const [sortValue, setSortValue] = useState("")
  const [sortedFiles, setSortedFiles] = useState<any[]>([])

  useEffect(() => {
    setSelectedFiles([])
  }, [selectedIds])

  const currentFolder =
    selectedIds.length === 0
      ? null
      : selectedIds.reduce(
          (res, id) => {
            if (!res) {
              return null
            }
            return res.files.find((item) => item.id === id)
          },
          { files: folders },
        )

  const handleSelect = (id: string) => {
    if (selectedFiles.includes(id)) {
      setSelectedFiles(selectedFiles.filter((item) => item !== id))
    } else {
      setSelectedFiles([...selectedFiles, id])
    }
  }

  const handleOpen = (id: string) => {
    const file = currentFolder?.files.find((item) => item.id === id)

    if (file?.type === "folder") {
      dispatch(selectFolder([...selectedIds, id]))
    }
  }

  const handleSort = (sortBy) => {
    setSortValue(sortBy)

    const sortedFiles = [...currentFolder.files].sort((a, b) => {
      if (sortBy === "last_modified") {
        return parseInt(b.modifiedAt, 10) - parseInt(a.modifiedAt, 10)
      }
      if (sortBy === "name") {
        return a.name.localeCompare(b.name)
      }
    })

    // console.log('sortedFiles:', sortedFiles)
    if (sortedFiles.length) setSortedFiles([...sortedFiles])
  }

  return (
    // Everything under My Files header when inside folder
    <div className="w-full h-full flex flex-col">
      {managable && <FileActionbar selectedFiles={selectedFiles} />}

      <div className="flex flex-col">
        <FileHeader narrow={narrow} onSelect={handleSort} />
        {currentFolder && <UpItem narrow={narrow} darkMode={darkMode} />}
        {sortedFiles.length
          ? sortedFiles.map((item: any) => (
              <FileItem
                key={item.id}
                file={item}
                selected={selectedFiles.includes(item.id)}
                onSelect={() => handleSelect(item.id)}
                onOpen={() => handleOpen(item.id)}
                narrow={narrow}
                darkMode={darkMode}
              />
            ))
          : null}
        {currentFolder &&
          !sortValue &&
          currentFolder.files.map((item) => (
            <FileItem
              key={item.id}
              file={item}
              selected={selectedFiles.includes(item.id)}
              onSelect={() => handleSelect(item.id)}
              onOpen={() => handleOpen(item.id)}
              narrow={narrow}
              darkMode={darkMode}
            />
          ))}
      </div>
    </div>
  )
}
