import { RootState } from "@/TYPES/redux"
import CalendarLeftPanel from "./CalendarLeftPanel"
import CalendarBody from "./CalendarBody"
import { useGetCalendar, getCalendar } from "./hooks/useGetCalendar"
import { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SocketContext } from "@/context/socket"
import { setEvents } from "@/store/slices/eventsSlice"

export default function Calendar() {
  const socket = useContext(SocketContext)
  const dispatch = useDispatch()
  const [isDarkMode, setIsDarkMode] = useState(true)
  useGetCalendar()
  const userData = useSelector((state: RootState) => state.user.data)
  const user_id = userData?._id
  const calendarEvents = useSelector((state: RootState) => state.events.events)

  function socketEvtHandler() {
    if (!user_id) {
      socket.off("sync-calendar")
      return
    }
    socket.on("sync-calendar", async (payload) => {
      console.log("SOCKET ON RECEIVING PAYLOAD", payload)
      const payloadUser_id = payload?.user_id
      if (payloadUser_id === user_id) return
      try {
        const updatedCalendarData = await getCalendar()
        console.log("updated calendar data", updatedCalendarData)
        const updatedEvents = updatedCalendarData.events || []
        dispatch(setEvents(updatedEvents))
      } catch (err) {
        console.error(err)
      }
    })
  }

  useEffect(() => {
    socketEvtHandler()
  }, [user_id])

  return (
    <div className="flex h-full w-full">
      <CalendarLeftPanel
        isDarkMode={isDarkMode}
        calendarEvents={calendarEvents}
      />
      <CalendarBody
        isDarkMode={isDarkMode}
        setIsDarkMode={setIsDarkMode}
        calendarEvents={calendarEvents}
      />
    </div>
  )
}
