import { RootState } from "@/TYPES/redux"
import $store from "@/store"
import {
  assignLoggedInMMUser,
  assignMMConnectGuest,
  assignMMTeamName,
} from "@/store/slices/mmSlice"
import cn from "classnames"
import { useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import validator from "validator"
import { appendUrlPath } from "@/helpers"

const { isURL } = validator

export default function MMChatGuest() {
  const RESTRICT_UI = true
  const RESTRICT_UI_PARAM = RESTRICT_UI ? "?restrict=true" : ""
  //   const storeState = $store.getState()
  //   const env = storeState.env
  //   const MATTERMOST_URL =
  //  env.REACT_APP_MATTERMOST_URL || import.meta.env.REACT_APP_MATTERMOST_URL
  const REACT_APP_MATTERMOST_URL = useSelector((state: RootState) => {
    return state.env.REACT_APP_MATTERMOST_URL
  })
  const MATTERMOST_URL =
    REACT_APP_MATTERMOST_URL || import.meta.env.REACT_APP_MATTERMOST_URL
  const REACT_APP_MATTERMOST_API_URL = useSelector((state: RootState) => {
    return state.env.REACT_APP_MATTERMOST_API_URL
  })
  const MATTERMOST_API_URL =
    REACT_APP_MATTERMOST_API_URL || import.meta.env.REACT_APP_MATTERMOST_API_URL
  const REACT_APP_NEW_BACKEND_URL = useSelector((state: RootState) => {
    return state.env.REACT_APP_NEW_BACKEND_URL
  })
  const BACKEND_MM_URL = appendUrlPath(
    REACT_APP_NEW_BACKEND_URL || import.meta.env.REACT_APP_NEW_BACKEND_URL,
    `/mattermost`,
  )
  //  (REACT_APP_NEW_BACKEND_URL || import.meta.env.REACT_APP_NEW_BACKEND_URL) +
  //  "/mattermost"
  const REACT_APP_SOCKET_SERVER = useSelector((state: RootState) => {
    return state.env.REACT_APP_SOCKET_SERVER
  })
  const SOCKET_SERVER =
    (REACT_APP_SOCKET_SERVER || import.meta.env.REACT_APP_SOCKET_SERVER) +
    `/mattermost`
  const REACT_APP_MATTERMOST_DECODE_PASSPHRASE = useSelector(
    (state: RootState) => {
      return state.env.REACT_APP_MATTERMOST_DECODE_PASSPHRASE
    },
  )
  const MATTERMOST_DECODE_PASSPHRASE =
    REACT_APP_MATTERMOST_DECODE_PASSPHRASE ||
    import.meta.env.REACT_APP_MATTERMOST_DECODE_PASSPHRASE

  const dispatch = useDispatch()
  const guestFirstName = window.sessionStorage.getItem("guestFirstName")
  const guestLastName = window.sessionStorage.getItem("guestLastName")
  const mm_iframe_ref = useRef(null)
  const roomData = useSelector((state: RootState) => state.room.data)
  const roomId = roomData?._id

  const [initTriggered, setInitTriggered] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")

  //(( onLoad() ))//
  //   function onLoad() {
  //     console.warn("onload")
  //     setMmIframeLoaded(true)
  //   }

  //(( initMattermostGuest() ))//
  async function initMattermostGuest() {
    log(`<initMattermostGuest()>`)
    setInitTriggered(true)

    if (window.__isMattermostInited) {
      log(
        `initMattermostGuest() -- Mattermost is already logged in and initialized!`,
      )
      toggleMattermostGlobalLoader(false)
      return
    }

    window.addEventListener("message", messageListener)

    toggleMattermostGlobalLoader(true)

    const iframeRef: any = mm_iframe_ref.current
    const iframeWindow = iframeRef.contentWindow

    const guestData = {
      isOwner: false,
      isGuest: true,
      first_name: guestFirstName,
      last_name: guestLastName,
      // email: mmGuestDesiredEmail,
    }
    const roomDataPayload = {
      _id: roomId,
      teamId: roomData?.teamId,
      name: roomData?.name,
    }
    const envData = {
      BASE_URL: MATTERMOST_URL,
      API_URL: MATTERMOST_API_URL,
      BACKEND_MM_URL,
      SOCKET_URL: SOCKET_SERVER,
      DECODE_PASSPHRASE: MATTERMOST_DECODE_PASSPHRASE,
      // COOKIE_PROPS: [`MMAUTHTOKEN`, `MMUSERID`, `MMCSRF`],
      RESTRICT_UI,
    }

    const msgPayload = {
      user: guestData,
      room: roomDataPayload,
      env: envData,
    }
    const data = {
      type: `init:mattermost:guest`,
      payload: msgPayload,
    }

    log(`<initMattermostGuest()> postMessage`, "info", data)
    //  iframeWindow.postMessage(data, iframeWindow.origin)
    iframeWindow.postMessage(data, "*")
    dispatch(assignMMConnectGuest(true))
  }

  //(( toggleMattermostGlobalLoader() ))//
  function toggleMattermostGlobalLoader(show) {
    const iframeEl: any = mm_iframe_ref.current
    const iframeWindow = iframeEl.contentWindow
    const data = {
      type: `loader:${show ? "show" : "hide"}`,
      payload: null,
    }
    //  iframeWindow.postMessage(data, iframeWindow.origin)
    iframeWindow.postMessage(data, "*")
  }

  //(( messageListener() ))//
  function messageListener(evt) {
    const type = evt.data?.type
    const payload = evt.data?.payload
    if (!type || !payload) return

    const iframeEl: any = mm_iframe_ref.current
    const iframeWindow = iframeEl.contentWindow

    //@@ If guest init success @@//
    if (type === "init:mattermost:guest::success") {
      log(`messageListener() -- init:mattermost:guest::success`, "info", {
        payload,
      })

      const loggedInMMGuest = payload?.mm?.user
      if (
        !loggedInMMGuest ||
        !loggedInMMGuest.id ||
        !loggedInMMGuest.props ||
        !loggedInMMGuest.props.mattermostTeamName
      ) {
        log(
          `messageListener() -- ERROR: No loggedInMMGuest??????????? wtf?????`,
          "error",
          { evt },
        )
        iframeWindow.postMessage(
          { type: "term:mattermost", payload: null },
          iframeWindow.origin,
        )
        toggleMattermostGlobalLoader(false)
        return
      }

      dispatch(assignLoggedInMMUser(loggedInMMGuest))

      const mmTeamName = loggedInMMGuest.props.mattermostTeamName
      dispatch(assignMMTeamName(mmTeamName))

      window.__isMattermostInited = true
      // iframeWindow.location.reload()
      iframeWindow.location.href = `${MATTERMOST_URL}/${mmTeamName}/channels/${roomId}${RESTRICT_UI_PARAM}`
      return
    }

    //@@ If guest init fail @@//
    if (type === "init:mattermost:guest::fail") {
      log(`messageListener() -- init:mattermost:guest::fail`, "error", {
        payload,
      })
      toggleMattermostGlobalLoader(false)
      setErrorMsg(payload?.msg || "Something went wrong")
      return
    }
  }

  //   const iframeSRC =
  //     CHAT_URL &&
  //     roomId &&
  //     mmTeamName &&
  //     `${CHAT_URL}/${mmTeamName}/channels/${roomId}`
  function retryInitMattermost() {
    setInitTriggered(false)
    setErrorMsg("")
    toggleMattermostGlobalLoader(false)
    window.__isMattermostInited = false
    const iframeEl: any = mm_iframe_ref.current
    //  iframeEl?.contentWindow?.location.reload()
  }

  const iframeSRC = `${MATTERMOST_URL}${RESTRICT_UI_PARAM}`
  //   const iframeSRC =
  //     MATTERMOST_URL &&
  //     roomId &&
  //     mmTeamName &&
  //     `${MATTERMOST_URL}/${mmTeamName}/channels/${roomId}`

  if (!iframeSRC) return <div>Chat not found</div>

  if (errorMsg)
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          color: "red",
          fontFamily: "sans-serif",
        }}
      >
        <div>{errorMsg}</div>
        <br />
        <div
          onClick={retryInitMattermost}
          style={{
            cursor: "pointer",
            color: "yellow",
            border: "1px solid yellow",
            padding: "5px",
          }}
        >
          RETRY
        </div>
      </div>
    )

  return (
    <div className="relative mm-iframe-wrapper-guest w-full h-full">
      {/* <div
        style={{
          width: "100%",
          overflow: "auto",
        }}
      >
        asldkfjsldfjdddddddddddddddddddddddddddddddddddddddddddddddddddllllllllsddaaaaaaaaaaaaaaaaaaaaaaaaaaa
      </div>
      <pre style={{ overflow: "auto", width: "100%" }}>
        {JSON.stringify({ CHAT_URL, roomId, mmTeamName, iframeSRC })}
      </pre> */}
      {/* <div
        style={{
          display: mmIframeLoaded ? "none" : "flex",
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "blue",
          color: "white",
        }}
      >
        Loading..
      </div> */}
      <div
        className="flex flex-col w-full h-full justify-center items-center text-white bg-gray chat-join-as-guest"
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          display: initTriggered ? "none" : "flex",
          zIndex: 1,
          backgroundColor: "rgba(16, 14, 14, 1)",
          userSelect: "none",
        }}
      >
        <div className="flex flex-col items-start gap-1">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >{`${guestFirstName} ${guestLastName}`}</div>
          <button
            className={cn(
              "border-[1px] border-accentyellow rounded-sm p-2 group transition-color duration-300",
              "hover:border-transparent hover:bg-accentyellow",
            )}
            onClick={initMattermostGuest}
          >
            <div
              className={cn(
                "text-accentyellow transition-color duration-300",
                "group-hover:text-gravel",
              )}
            >
              JOIN CHAT
            </div>
          </button>
        </div>
      </div>
      <iframe
        id="mm_iframe_guest"
        ref={mm_iframe_ref}
        title="Cloudcom Guest Chat"
        src={iframeSRC}
        allowFullScreen
        height="100%"
        width="100%"
        key={iframeSRC}
      ></iframe>
    </div>
  )
}

///////////////////////////////////////////////////
///////////////////////////////////////////////////
///////////////////////////////////////////////////
///////////////////////////////////////////////////

export function log(msg?: any, type: any = "info", data?: any) {
  const logStyles = {
    info: `background:black;color:#87ceeb;border:1px solid #87ceeb;font-size:18px;padding:10px;`,
    error: `background:black;color:red;border:1px solid red;font-size:18px;padding:10px;`,
    warn: `background:black;color:orange;border:1px solid orange;font-size:18px;padding:10px;`,
    highlight: `background:black;color:#bada55;border:1px solid #bada55;font-size:18px;padding:10px;`,
  }
  if (data) return console.log(`%c[theia]: ${msg}`, logStyles[type], data)
  return console.log(`%c[theia]: ${msg}`, logStyles[type])
}
