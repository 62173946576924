import { RootState } from "@/TYPES/redux"
import JitsiVideo from "@/components/Call/JitsiVideo"
import Chat from "@/components/Chat"
import TileHeader from "@/components/TileHeader"
import cn from "classnames"
import { memo } from "react"
import { useSelector } from "react-redux"

const JitsiVideoMemo = memo(JitsiVideo)
const ChatMemo = memo(Chat)

export default function PhoneSecondaryPanel() {
  const isPresenterMode = useSelector(
    (state: RootState) => state.space.isPresenterMode,
  )
  const views = useSelector((state: RootState) => state.space.views)

  let currentView = ""
  if (views.includes("jitsi")) {
    currentView = "jitsi"
  } else if (views.includes("chat")) {
    currentView = "chat"
  }

  const showChat = currentView === "chat"
  const showJitsi = currentView === "jitsi"

  return (
    <div
      className={cn("relative overflow-hidden w-full flex flex-col", {
        "h-0": !currentView,
        "h-[280px]": !!currentView,
      })}
    >
      <div
        className={cn(
          "absolute left-0 top-0 w-full flex flex-col overflow-hidden",
          {
            "h-full": showChat,
            "h-0": !showChat,
          },
        )}
      >
        <TileHeader className="justify-between !pr-0 !border-t-0">
          <h3>Chat</h3>
        </TileHeader>
        <ChatMemo />
      </div>

      <div
        className={cn("absolute left-0 top-0 w-full overflow-hidden", {
         //  "h-full": showJitsi,
          "h-full": showJitsi && !isPresenterMode,
         //  "h-0": !showJitsi,
          "h-0": !showJitsi || isPresenterMode,
        })}
      >
        <JitsiVideoMemo />
      </div>
    </div>
  )
}
