import { createSlice } from "@reduxjs/toolkit"

export const rdpSlice = createSlice({
  name: "rdp",
  initialState: {
    rdpIsAvailable: false,
    guacToken: null,
    guacTokenExp: null,
    guacConnections: null,
    fetching: false,
    loading: false,
    guacConnInfoCache: {},
    rdpAsset: null,
    guacShareLink: "",
  },
  reducers: {
    assignRdpIsAvailable: (state, action) => ({
      ...state,
      rdpIsAvailable: !!action.payload,
    }),
    assignGuacToken: (state, action) => ({
      ...state,
      guacToken: action.payload,
    }),
    assignGuacTokenExp: (state, action) => ({
      ...state,
      guacTokenExp: action.payload,
    }),
    assignGuacConnections: (state, action) => ({
      ...state,
      guacConnections: action.payload,
    }),
    assignFetching: (state, action) => ({
      ...state,
      fetching: !!action.payload,
    }),
    assignLoading: (state, action) => ({
      ...state,
      loading: !!action.payload,
    }),
    addGuacConnInfo: (state, action) => {
      const payload = action.payload
      if (!payload || !payload.name) return state
      const name = payload.name
      return {
        ...state,
        guacConnInfoCache: {
          ...state.guacConnInfoCache,
          [name]: payload,
        },
      }
    },
    assignRdpAsset: (state, action) => {
      return {
        ...state,
        rdpAsset: action.payload,
      }
    },
    assignGuacShareLink: (state, action) => {
      return {
        ...state,
        guacShareLink: action.payload,
      }
    },
  },
})

export const {
  assignRdpIsAvailable,
  assignGuacToken,
  assignGuacTokenExp,
  assignGuacConnections,
  assignFetching,
  assignLoading,
  addGuacConnInfo,
  assignRdpAsset,
  assignGuacShareLink,
} = rdpSlice.actions
export default rdpSlice.reducer
