import { RootState } from "@/TYPES/redux"
import { SocketContext } from "@/context/socket"
import {
  assignPenColor,
  assignPenTimeoutMS,
} from "@/store/slices/annotationSlice"
import { getPopperProps } from "@/helpers"
import Icon, { IconNameProp } from "@/components/Icon"
import AnnotateButton from "@/components/MainTileToolbar/Annotate"
import FullscreenButton from "@/components/MainTileToolbar/Fullscreen"
import PermitCobrowse from "@/components/PermitCobrowse"
import SpaceDetails from "@/components/MainTileToolbar/SpaceDetails"
import cn from "classnames"
import { useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { MuiColorInput } from "mui-color-input"
import Tooltip from "@mui/material/Tooltip"

export default function MainTileToolbar() {
  const socket = useContext(SocketContext)
  const dispatch = useDispatch()
  const user_id = useSelector((state: RootState) => state.user.data?._id)
  const myName = useSelector((state: RootState) => state.user.data?.name)
  const myEmail = useSelector((state: RootState) => state.user.data?.email)
  const permissionLevel = useSelector((state: any) => state.permission.level)
  const penColor = useSelector((state: RootState) => state.annotation.penColor)
  const penTimeoutMS = useSelector(
    (state: RootState) => state.annotation.penTimeoutMS,
  )
  const mainApp = useSelector((state: RootState) => {
    const roomData = state.room.data
    if (!roomData) return null

    const dataTile00 = roomData.scene2d?.dataTile00
    const app = Array.isArray(dataTile00) ? dataTile00[0] : null
    return app
  })
  const views = useSelector((state: RootState) => state.space.views)
  const isAddingTile = useSelector(
    (state: RootState) => state.space.isAddingTile || state.space.isAddingTile,
  )
  const showAnnotate = views.includes("annotate")
  const showMenu = useSelector((state: RootState) => state.annotation.showMenu)
  const tooltipPos = "right"

  const annotationMenu = (
    <div
      className={cn("flex flex-col items-center justify-center gap-4", {
        invisible: !showMenu || !showAnnotate || permissionLevel === "guest",
        "h-auto": showMenu && showAnnotate && permissionLevel !== "guest",
      })}
      style={{
        userSelect: "none",
      }}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <div
        className="rounded-full"
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Tooltip
          title={`Pen Color: ${penColor}`}
          placement={tooltipPos}
          PopperProps={popperProps}
        >
          <MuiColorInput
            sx={{
              width: "20px",
              "& .MuiInputBase-root": {
                height: "20px",
              },
              "& .MuiInputBase-input": {
                fontSize: "12px",
              },
              "& .MuiButtonBase-root": {
                width: "20px !important",
                height: "20px",
                borderRadius: "50%",
              },
            }}
            value={penColor}
            onChange={(color) => dispatch(assignPenColor(color))}
            size="medium"
            variant="standard"
            format="hex"
            isAlphaHidden={true}
            InputProps={{
              disableUnderline: true,
              margin: "none",
            }}
          />
        </Tooltip>
      </div>
      <div
        className="rounded-full"
        style={{
          display: showAnnotate ? "flex" : "none",
        }}
      >
        <Tooltip
          title="Magic Marker"
          placement={tooltipPos}
          PopperProps={menuOptionPopperProps}
        >
          <button
            onClick={() =>
              dispatch(assignPenTimeoutMS(penTimeoutMS ? 0 : 5000))
            }
          >
            <Icon
              iconName={IconNameProp.Magic}
              className={cn("cursor-pointer -mb-1", {
                "fill-smoke hover:fill-accentgreen": !penTimeoutMS,
                "fill-accentyellow hover:opacity-60": penTimeoutMS,
              })}
              size={24}
            />
          </button>
        </Tooltip>
      </div>
      <div
        className="rounded-full"
        style={{
          display:
            permissionLevel === "owner" && showAnnotate ? "flex" : "none",
        }}
      >
        <Tooltip
          title="Clear All Drawings"
          placement={tooltipPos}
          PopperProps={menuOptionPopperProps}
        >
          <button
            onClick={() => {
              socket.emit("magic-marker-clear", {
                user_id,
                name: myName || myEmail || "Someone",
              })
            }}
          >
            <Icon
              iconName={IconNameProp.Eraser}
              className={cn("fill-smoke hover:fill-accentgreen cursor-pointer")}
              size={25}
            />
          </button>
        </Tooltip>
      </div>
    </div>
  )

  return (
    <div
      className={cn(
        "absolute flex flex-col top-1/2 left-0 -translate-y-2/3 h-auto",
        "gap-4 items-center bg-cobrowsegray bg-opacity-80 z-[102] rounded-r-lg pt-2 px-1 pr-2",
        {
          "!z-20": isAddingTile,
        },
      )}
    >
      <div>
        <SpaceDetails />
      </div>
      <div>
        <FullscreenButton />
      </div>
      <div>
        <PermitCobrowse tile={mainApp} />
      </div>
      <div className="-mb-1">
        <AnnotateButton mainApp={mainApp} />
      </div>
      <div>
        <div
          className={cn("transition-height", {
            "h-0": !showMenu || !showAnnotate || permissionLevel === "guest",
            "h-[7.25rem] pt-1":
              showMenu && showAnnotate && permissionLevel === "owner",
            "h-[4.75rem] pt-1":
              showMenu && showAnnotate && permissionLevel === "standard",
          })}
        >
          {annotationMenu}
        </div>
      </div>
    </div>
  )
}

const popperProps = getPopperProps({ offset: [0, -2] })
const menuOptionPopperProps = getPopperProps({ offset: [0, -4] })
