import Icon, { IconNameProp } from "@/components/Icon"
import { ICON_SIZE } from "../utils"
import { Event } from "@/TYPES/redux"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/free-solid-svg-icons"
import { useDispatch } from "react-redux"
import {
  deleteEvent,
  setActiveEvent,
  setEditEvent,
} from "@/store/slices/eventsSlice"
import { Link } from "react-router-dom"
import { useUpdateCalendar } from "../../hooks/useUpdateCalendar"
import { toast } from "react-toastify"
import cn from "classnames"

interface Props {
  event: Event
  isDarkMode: boolean
}

export default function EventInformation({ event, isDarkMode }: Props) {
  const dispatch = useDispatch()
  const { deleteEventFromCalendar } = useUpdateCalendar()

  const getEventTimeTitle = (startTime: string, endTime: string) => {
    const dayMonthDate = `${moment(startTime).format("dddd, MMMM D")}`
    const timeRange = `${moment(startTime).format("h:mma")} - ${moment(
      endTime,
    ).format("h:mma")}`

    return event.allDay ? dayMonthDate : `${dayMonthDate} | ${timeRange}`
  }

  const handleCopyClick = (spaceId: string | undefined) => {
    if (spaceId) {
      navigator.clipboard.writeText(`${window.location.host}/space/${spaceId}`)
    }
    toast.success("Copied to clipboard!")
  }

  const handleExitClick = () => {
    dispatch(setActiveEvent(undefined))
    dispatch(setEditEvent(undefined))
  }

  const handleDeleteClick = () => {
    dispatch(deleteEvent(event.eventUUID))
    deleteEventFromCalendar(event.eventUUID)
  }

  const spaceDetailsButtons = [
    {
      key: IconNameProp.Edit,
      iconName: IconNameProp.Edit,
      onClick: () => dispatch(setEditEvent(event)),
    },
    {
      key: IconNameProp.Trash,
      iconName: IconNameProp.Trash,
      onClick: () => handleDeleteClick(),
    },
  ]

  if (!event) {
    return null
  }

  return (
    <div className="flex flex-1 flex-col gap-4">
      <div className="flex justify-between items-center">
        <div
          className={cn("font-bold text-[12pt]", {
            "text-cloudy": !isDarkMode,
            "text-ghostgray": isDarkMode,
          })}
        >
          Space Details
        </div>
        <div className="flex flex-row items-center gap-4">
          {spaceDetailsButtons.map((buttonInfo) => (
            <button
              key={buttonInfo.key}
              className="flex items-center justify-center w-5 h-5 overflow-hidden"
              onClick={() => buttonInfo.onClick()}
            >
              <Icon
                className={isDarkMode ? "fill-ghostgray hover:fill-white" : "fill-cloudy"}
                iconName={buttonInfo.iconName}
                size={ICON_SIZE}
              />
            </button>
          ))}
          {/** temporary until we get our own exit icon */}
          <button
            className="flex items-center justify-center w-5 h-5 overflow-hidden"
            onClick={() => handleExitClick()}
          >
            <FontAwesomeIcon
              icon={faXmark}
              className={cn({
                "text-carbon": !isDarkMode,
                "text-ghostgray hover:text-white": isDarkMode,
              })}
              size="xl"
            />
          </button>
        </div>
      </div>
      <div className="leading-6">
        <div
          className={cn("text-[16pt] font-bold", {
            "text-carbon": !isDarkMode,
            "text-smoke": isDarkMode,
          })}
        >
          {event.title}
        </div>
        <div
          className={cn("text-[12pt]", {
            "text-cloudy": !isDarkMode,
            "text-ghostgray": isDarkMode,
          })}
        >
          {getEventTimeTitle(event.startTime, event.endTime)}
        </div>
      </div>

      {event.agenda?.length > 0 && (
        <div className={cn("text-wrap", {
          "text-carbon": !isDarkMode,
          "text-smoke": isDarkMode,
        })}>{event.agenda}</div>
      )}

      <div className="flex items-center justify-between">
        <Link
          to={`/space/${event.spaceId}`}
          className={cn(
            "flex justify-center items-center w-[170px] h-[38px] rounded-sm text-carbon font-bold transition-color duration-300",
            {
              "pointer-events-none bg-typegray": !event.spaceId,
              "pointer-events-auto bg-accentyellow border-2 border-accentyellow hover:opacity-80":
                event.spaceId,
            },
          )}
        >
          Join Space
        </Link>
        {event.spaceId && (
          <button
            className="flex items-center justify-center w-5 h-5 overflow-hidden"
            onClick={() => handleCopyClick(event.spaceId)}
          >
            <Icon
              className={cn({
                "fill-carbon": !isDarkMode,
                "fill-ghostgray hover:fill-white": isDarkMode,
              })}
              iconName={IconNameProp.Copy}
              size={ICON_SIZE}
            />
          </button>
        )}
      </div>
    </div>
  )
}
