import { useSelectViewport } from "@/utils/hooks/viewport"
import { useState } from "react"
import PlatformLayout from "../PlatformLayout"
import MainPanel from "./MainPanel"
import PhonePanel from "./PhonePanel"
import { Sidebar } from "./Sidebar"

export default function Files() {
  const [query, setQuery] = useState("")

  const { isPhone } = useSelectViewport()

  return (
    <PlatformLayout query={query} title="Files" onSearch={setQuery}>
      {!isPhone && (
        <>
          <Sidebar />
          <MainPanel />
        </>
      )}
      {isPhone && <PhonePanel />}
    </PlatformLayout>
  )
}
