import {
  AnnotationModeEnum,
  DraggingType,
  SocketMessageType,
} from "@/TYPES/common"
import { RootState } from "@/TYPES/redux"
import AnnotationCanvas from "@/components/Annotation/AnnotationCanvas"
import AnnotationComp from "@/components/Annotation/AnnotationComp"
import Calendar from "@/components/Calendar"
import { DropZone } from "@/components/DragAndDrop"
import MainTileToolbar from "@/components/MainTileToolbar"
import { SocketContext } from "@/context/socket"
import { TEMP_SHAREPOINT_TILE_ID, updateTiles } from "@/helpers"
import $store from "@/store"
import { assignShowAnnotation } from "@/store/slices/annotationSlice"
import {
  assignLargeTile,
  assignMainTileTitleMsg,
  removeLargeTile,
} from "@/store/slices/roomSlice"
import {
  assignMainTileRect,
  removeView,
  toggleHotkeysEnabled,
} from "@/store/slices/spaceSlice"
import cn from "classnames"
import { useContext, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useResizeDetector } from "react-resize-detector"
import MainTile from "./MainTile"

export default function MainPanel({ annotationNotify }) {
  //   const main_tile_body_ref = useRef<HTMLDivElement>(null)
  const {
    width: mainTileBodyWidth,
    height: mainTileBodyHeight,
    ref: main_tile_body_ref,
  } = useResizeDetector({
    refreshMode: "debounce",
    refreshRate: 100,
    refreshOptions: { leading: true },
  })
  const socket = useContext(SocketContext)
  const dispatch = useDispatch()
  const views = useSelector((state: RootState) => state.space.views)
  const showAnnotation = useSelector(
    (state: RootState) => state.annotation.showAnnotation,
  )
  const annotationMode = useSelector(
    (state: RootState) => state.annotation.mode,
  )

  const mainApp = useSelector((state: RootState) => {
    const roomData = state.room.data
    if (!roomData) return null

    const dataTile00 = roomData.scene2d?.dataTile00
    const app = Array.isArray(dataTile00) ? dataTile00[0] : null
    return app
  })
  const roomId = useSelector(
    (state: RootState) => state.room.data?._id || state.space.spaceId,
  )
  const userId = useSelector((state: RootState) => state.user.data?._id)
  const user_id = userId
  const myName = useSelector((state: RootState) => state.user.data?.name)
  const myEmail = useSelector((state: RootState) => state.user.data?.email)
  const isGuest = useSelector(
    (state: RootState) => state.permission.level === "guest",
  )
  const volatileMarker = useSelector(
    (state: RootState) => state.annotation.volatileMarker,
  )
  const isVolatileMarker = volatileMarker.enabled
  const isAddingTile = useSelector(
    (state: RootState) => state.space.isAddingTile,
  )
  const isPresenterMode = useSelector(
    (state: RootState) => state.space.isPresenterMode,
  )
  const isFullScreenMode = useSelector(
    (state: RootState) => state.call.shareScreenMode,
  )

  const isOriginalOwner = roomId === userId

  const onDropMainAppHandler = (app: any) => {
    if (!app._id || !roomId) return
    if (app._id === TEMP_SHAREPOINT_TILE_ID) return

    dispatch(assignLargeTile(app._id))
    const roomData = $store.getState().room.data
    const scene2d = roomData?.scene2d
    if (!scene2d) return
    const newSmallTiles = scene2d.dataTile01
    const newLargeTiles = scene2d.dataTile00

    updateTiles(
      roomId,
      JSON.parse(JSON.stringify(newSmallTiles)),
      JSON.parse(JSON.stringify(newLargeTiles)),
    )
  }

  const hotkeysAllowed = () => {
    const currentlyEnabled = $store.getState().space?.hotkeysEnabled
    const views = $store.getState().space?.views
    const annotationShowing = $store.getState().annotation?.showAnnotation
    // Only enable when apps or files panel open
    const appsPanelOpen = ["apps", "files"].some((view) => views.includes(view))
    // Always disable when calendar or annotations active
    const conflictingViews =
      ["calendar", "annotate"].some((view) => views.includes(view)) ||
      annotationShowing
    // ...or when add app modal is open
    const isAddingTile = $store.getState().space?.isAddingTile
    const shouldAllow = appsPanelOpen && !isAddingTile && !conflictingViews
    if (currentlyEnabled !== shouldAllow)
      dispatch(toggleHotkeysEnabled(shouldAllow))
    return shouldAllow
  }

  useEffect(() => {
    const checkIfHotkeysAllowed = hotkeysAllowed
    checkIfHotkeysAllowed()
  }, [views, showAnnotation, isAddingTile])

  useEffect(() => {
    const handleDemoKeyPress = () => {
      if (!isOriginalOwner) return

      const isDemoMode = $store.getState().space?.isDemoMode
      const onOrOff = !isDemoMode ? "ON" : "OFF"

      const triggerToastPayload: TriggerToastPayload = {
        type: !isDemoMode ? "success" : "warning",
        msg: `Demo mode is ${onOrOff}`,
        options: {
          toastId: "demo-mode-toast",
          autoClose: 3000,
          closeOnClick: true,
          draggable: true,
          theme: "dark",
        },
      }

      socket.emit(SocketMessageType.DemoMode, !isDemoMode)
      socket.emit(SocketMessageType.TriggerToast, triggerToastPayload)
    }

    const handleKeyPress = async (e) => {
      if (!hotkeysAllowed()) return

      const { key, ctrlKey, metaKey } = e
      console.log(`HOTKEY PRESSED: ${key}`)

      const demoModeShortcutPressed = (ctrlKey || metaKey) && key === "/"
      if (demoModeShortcutPressed) {
        handleDemoKeyPress()
        return
      }

      const keyPressed = Number(key)
      const isValidNumber = !isNaN(keyPressed)
      if (!isValidNumber) return

      const roomData = $store.getState().room?.data
      const scene2d = roomData?.scene2d
      if (!scene2d || !roomId) return

      const tileList = scene2d.dataTile01

      // Determine index of new main app
      const newMainAppIndex = keyPressed - 1
      // Use index to grab app's ID and assign it as large tile
      const newMainAppId = tileList[newMainAppIndex]._id
      const oldMainAppId = scene2d.dataTile00[0]?._id
      if (newMainAppId === TEMP_SHAREPOINT_TILE_ID) return

      newMainAppId === oldMainAppId
        ? dispatch(removeLargeTile())
        : dispatch(assignLargeTile(newMainAppId))
      // Ensure we get updated values from store after dispatching action
      const updatedScene2d = $store.getState().room.data?.scene2d
      if (!updatedScene2d) return
      const newSmallTiles = updatedScene2d.dataTile01
      const newLargeTiles = updatedScene2d.dataTile00

      updateTiles(
        roomId,
        JSON.parse(JSON.stringify(newSmallTiles)),
        JSON.parse(JSON.stringify(newLargeTiles)),
      )
    }

    const handleFocus = () => {
      if (!hotkeysAllowed()) return
      dispatch(toggleHotkeysEnabled(true))
    }

    const handleBlur = () => {
      const { body, activeElement } = document
      if (activeElement === body) return
      dispatch(toggleHotkeysEnabled(false))
      console.log("HOTKEYS DISABLED - Focus switched to:", activeElement)
    }

    window.addEventListener("keydown", handleKeyPress)
    window.addEventListener("focus", handleFocus)
    window.addEventListener("blur", handleBlur)

    return () => {
      window.removeEventListener("keydown", handleKeyPress)
      window.removeEventListener("focus", handleFocus)
      window.removeEventListener("blur", handleBlur)
    }
  }, [])

  useEffect(() => {
    const observedElement = main_tile_body_ref?.current
    if (!mainApp || !observedElement) return

    const rect = observedElement.getBoundingClientRect()
    dispatch(
      assignMainTileRect({
        x: rect.x,
        y: rect.y,
        width: rect.width,
        height: rect.height,
        top: rect.top,
        left: rect.left,
        right: rect.right,
        bottom: rect.bottom,
      }),
    )
  }, [mainApp, mainTileBodyWidth, mainTileBodyHeight])

  const endAnnotationSession = () => {
    dispatch(assignShowAnnotation(false))
    dispatch(removeView("annotate"))
    socket.emit("magic-marker-toggle", {
      user_id,
      show: false,
      name: myName || myEmail || "Someone",
    })
  }

  //@@ Stop annotation mode @@//
  useEffect(() => {
    if (isAddingTile) {
      endAnnotationSession()
    }
  }, [isAddingTile])

  useEffect(() => {
    // const msg = showAnnotation ? "Magic Marker Mode" : ""
    const msg = ""
    dispatch(assignMainTileTitleMsg(msg))
  }, [showAnnotation])

  if (!roomId) return <div>No Room ID</div>

  const showCalendar = views.includes("calendar")
  const activeTile = mainApp?.type !== "file" || isOriginalOwner

  return (
    <div
      className={cn("relative flex", "mb-[50px]", {
        "flex-1 overflow-auto": !isFullScreenMode,
        "w-0 overflow-hidden": isFullScreenMode,
      })}
    >
      <div
        className={cn(
          "absolute flex w-full overflow-hidden transition-height",
          {
            "h-full z-20": showCalendar,
            "h-0": !showCalendar,
          },
        )}
      >
        <Calendar />
      </div>
      <div
        className={cn(
          "absolute flex w-full z-50 overflow-hidden transition-all",
          {
            "h-full":
              views.includes("annotate") &&
              annotationMode === AnnotationModeEnum.WHITEBOARD,
            "h-0": !views.includes("annotate"),
          },
        )}
      >
        <AnnotationCanvas annotationNotify={annotationNotify} />
      </div>
      <DropZone
        className="relative flex flex-col h-full w-full"
        type={DraggingType.App}
        onDrop={onDropMainAppHandler}
      >
        <>
          {mainApp && (
            <div className="relative flex flex-col h-full w-full">
              <div
                ref={main_tile_body_ref}
                className={cn(
                  "main-tile-body large-tile-body main-tile-body-ref relative flex h-full w-full overflow-auto hide-scrollbar",
                  {
                    "z-50": isPresenterMode,
                  },
                )}
                id="main-tile"
                style={{
                  position: "relative",
                  boxSizing: "border-box",
                }}
              >
                {!showCalendar && <MainTileToolbar />}
                <MainTile active={activeTile} tile={mainApp} />
                <AnnotationComp tile={mainApp} />
              </div>
            </div>
          )}
          {!mainApp && (
            <div
              ref={main_tile_body_ref}
              className={cn(
                "relative h-full w-full flex items-center justify-center p-[24px] select-none bg-cobrowsegray",
                "bg-center bg-cover bg-[url(/assets/images/bg-wallpaper.jpg)]",
              )}
            >
              <div className="absolute top-0 w-full h-auto flex items-center justify-center bg-accentgreen text-white font-bold">
                UNCLASSIFIED
              </div>
              <img
                src="/assets/images/logo-mission-command.png"
                draggable="false"
                width="200"
                alt="wallpaper logo"
              />
            </div>
          )}
        </>
      </DropZone>
    </div>
  )
}
