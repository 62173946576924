import { createSlice } from "@reduxjs/toolkit"

const initialState: any = {
  mainCanvasRect: null,
}

export const cobrowseSlice = createSlice({
  name: "cobrowse",
  initialState,
  reducers: {
    assignMainCanvasRect: (state, action) => {
      return {
        ...state,
        mainCanvasRect: action.payload,
      }
    },
  },
})

export const { assignMainCanvasRect } = cobrowseSlice.actions
export default cobrowseSlice.reducer
