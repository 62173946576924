import { RootState } from "@/TYPES/redux"
import { useMyUser } from "@/helpers/user"
import cn from "classnames"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

export default function Login() {
  const navigate = useNavigate()
  const { data } = useMyUser()
  const REACT_APP_API_SERVER =
    useSelector((state: RootState) => state.env.REACT_APP_API_SERVER) ||
    import.meta.env.REACT_APP_API_SERVER

  const [email, setEmail] = useState("")
  const query = new URLSearchParams(window.location.search)

  useEffect(() => {
    if (data !== undefined && data.role !== undefined) {
      // navigate(`/space/${data._id}`, { replace: false })
      //## Must refresh the page for socket connection to re-establish. If not, socket connection will remain connected to undefined room ID! ##//
      window.location.href = `${window.location.origin}/lobby/${data._id}`
    }
  }, [data])

  const handleSubmit = (e: any) => {
    e.preventDefault()

    const redirect = query.get("redirectTo") || window.location.href
    window.location.href = `${REACT_APP_API_SERVER}/api/auth/loginRouterPost?email=${email}&redirectTo=${redirect}`
  }

  return (
    <form
      className="flex-1 flex flex-col justify-center items-center bg-cobrowsegray"
      onSubmit={handleSubmit}
    >
      <img
        className={cn("w-[290px]", "sm:w-[200px]")}
        src="/assets/logo.svg"
        alt="clouldcom"
      />
      <div
        className={cn(
          "w-[470px] flex flex-col p-[50px]",
          "sm:w-[350px] sm:p-8",
        )}
      >
        <input
          className={cn(
            "h-[54px] outline-none mb-8 px-4 font-light text-20 text-white opacity-80 border-b-[0.5px] border-carbon bg-transparent focus:border-white focus:placeholder-white",
            "sm:h-[46px] sm:text-16",
          )}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />

        <button
          className={cn(
            "h-[54px] text-20 text-white rounded-sm px-16 py-2 hover:opacity-80",
            "border-[0.5px] border-white bg-transparent hover:border-0 hover:bg-accentyellow",
            "sm:h-[46px] sm:text-16",
          )}
          type="submit"
        >
          Sign In
        </button>
      </div>
    </form>
  )
}
