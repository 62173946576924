import { RootState } from "@/TYPES/redux"
import Icon, { IconNameProp } from "@/components/Icon"
import Modal from "@/components/Modal"
import { createRDPConnection } from "@/helpers"
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom"
import cn from "classnames"
import { useState } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"

type Props = {
  addDisabled?: boolean
  isSharepoint?: boolean
  onClose: () => void
}

export default function AddRDPModal({
  addDisabled,
  isSharepoint,
  onClose,
}: Props) {
  const [name, setName] = useState("")
  const [host, setHost] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [domain, setDomain] = useState("")

  const [adding, setAdding] = useState(false)

  const disabled =
    addDisabled || (!isSharepoint && !name) || !host || !username || !password

  const teamId = useSelector((state: RootState) => state.room.data?.teamId)
  const userId = useSelector((state: RootState) => state.user.data?._id)

  async function handleAddConn() {
    const missingReqdFields = !host || !isSharepoint ? !name : !userId

    if (missingReqdFields) return

    const addingToast = toast(
      `Adding ${!isSharepoint ? `RDP: ${name}` : "SharePoint"}`,
      {
        toastId: `adding-rdp-${name || userId}`,
        autoClose: false,
        closeOnClick: false,
        theme: "dark",
      },
    )

    try {
      setAdding(true)
      const payload = {
        username,
        password,
        host,
        name: !isSharepoint ? name : `SharePoint-${userId}`,
        domain,
        isSharepoint,
      }

      createRDPConnection(payload, () => {
        toast.dismiss(addingToast)
        setAdding(false)
        onClose()
      })
    } catch (err) {
      toast.dismiss(addingToast)
    }
  }

  if (adding) {
    return null
  }

  return (
    <Modal
      className="w-[500px] flex flex-col"
      titleClassName={
        isSharepoint
          ? "bg-[#3272c3] bg-[url(/assets/images/logo-sharepoint.png)] bg-no-repeat bg-center bg-contain"
          : undefined
      }
      title={isSharepoint ? "" : "Add Remote Desktop Session"}
      onClose={onClose}
      info={
        isSharepoint
          ? ""
          : "Add a remote desktop session. The information you enter will be used to create a connection to another computer, allowing you remote control of that system from within the space."
      }
    >
      {!isSharepoint && (
        <span className="text-typegray text-xs text-center">
          Please Note: RDP is not available for Demo Users.
        </span>
      )}
      <br />
      {!isSharepoint && (
        <input
          className={cn("flex-1 p-2 outline-none rounded-sm w-full mb-3", {
            "bg-smoke text-carbon": !disabled,
            "bg-typegray cursor-not-allowed": disabled,
          })}
          placeholder="Tile Name *"
          value={name}
          onChange={(e) => setName(e.target.value)}
          disabled={adding || addDisabled}
        />
      )}
      <input
        className={cn("flex-1 p-2 outline-none rounded-sm w-full mb-3", {
          "bg-smoke text-carbon": !disabled,
          "bg-typegray cursor-not-allowed": disabled,
        })}
        placeholder="Host IP *"
        value={host}
        onChange={(e) => setHost(e.target.value)}
        disabled={adding || addDisabled}
      />
      <input
        className={cn("flex-1 p-2 outline-none rounded-sm w-full mb-3", {
          "bg-smoke text-carbon": !disabled,
          "bg-typegray cursor-not-allowed": disabled,
        })}
        placeholder="Domain"
        value={domain}
        onChange={(e) => setDomain(e.target.value)}
        disabled={adding || addDisabled}
      />
      <input
        className={cn("flex-1 p-2 outline-none rounded-sm w-full mb-3", {
          "bg-smoke text-carbon": !disabled,
          "bg-typegray cursor-not-allowed": disabled,
        })}
        placeholder="Username *"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        disabled={adding || addDisabled}
        autoComplete="off"
      />
      <input
        type="password"
        className={cn("flex-1 p-2 outline-none rounded-sm w-full mb-3", {
          "bg-smoke text-carbon": !disabled,
          "bg-typegray cursor-not-allowed": disabled,
        })}
        placeholder="Password *"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        disabled={adding || addDisabled}
        autoComplete="off"
      />
      <div className="flex justify-center">
        <button
          className={cn(
            "h-[40px] flex-center rounded-sm box-shadow py-2 px-4 ml-2",
            {
              "bg-accentgreen text-white hover:opacity-70": !disabled,
              "bg-secondarygray cursor-not-allowed": disabled,
            },
          )}
          onClick={handleAddConn}
          disabled={disabled}
        >
          {adding ? (
            <HourglassBottomIcon />
          ) : (
            <Icon
              className={cn({
                "fill-cloudy": disabled,
                "fill-lavender hover:fill-accentgreen": !disabled,
              })}
              iconName={IconNameProp.Add}
              size={16}
            />
          )}
        </button>
      </div>
    </Modal>
  )
}
