import { SocketContext } from "@/context/socket"
import {
  getCursorColor,
  getPermission,
  getRoom,
  getSyncableUsers,
  toggleCursorVisibility,
  usePermission,
} from "@/helpers"
import {
  addCurrentJoin,
  assignMySocketId,
  assignSocketJoinPayload,
  removeCurrentJoin,
} from "@/store/slices/socketSlice"
import {
  assignIsLocked,
  setSyncableUserCount,
  syncSpace,
  toggleAppsPanel,
  toggleDemoMode,
  togglePresenterMode,
  addView,
  removeView,
} from "@/store/slices/spaceSlice"
import {
  assignCursorColor,
  assignEmail,
  assignGuest,
  assignId,
  assignMMPassword,
  assignMMTeamName,
  assignName,
  assignOrgId,
  assignOwner,
  assignTeams,
  updateIsOwner,
} from "@/store/slices/userSlice"
import { PermissionLevel, RootState } from "@/TYPES/redux"

import { TheaterOverlay } from "@/components/PresenterMode"
import { updateJitsiKey, updateShareScreenMode } from "@/store/slices/callSlice"
import { assignParticipants } from "@/store/slices/participantsSlice"
import { assignPdfPage } from "@/store/slices/viewSlice"
import { AnnotationModeEnum, SocketMessageType } from "@/TYPES/common"
import { useSelectViewport } from "@/utils/hooks/viewport"
import cn from "classnames"
import { useContext, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast, ToastOptions } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import DesktopLeftPanel from "./panels/DesktopLeftPanel"
import DesktopRightPanel from "./panels/DesktopRightPanel"
import Layout from "./panels/Layout"
import MainPanel from "./panels/MainPanel"
import Navbar from "@/pages/Space/Navbar"
import PhoneSecondaryPanel from "./panels/PhoneSecondaryPanel"
import QueueComp from "@/components/Call/GuestQueue/QueueComp"
import {
  assignShowAnnotation,
  assignCurrentDrawer,
  assignAnnotationMode,
  clearChunkData,
  assignShowMenu,
} from "@/store/slices/annotationSlice"

const annotationNotify = (name: string) =>
  toast.info(`${name} is annotating`, {
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "dark",
    toastId: name,
  })

const presentationNotify = (name: string, toggle: Boolean) => {
  const msg = toggle ? "is presenting" : "ended the presentation"

  toast.info(`${name} ${msg}`, {
    autoClose: 1500,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "dark",
    toastId: name,
  })
}

interface JoinPersonHandlerParam {
  permissionLevel: PermissionLevel
}

interface SocketJoinEventPayload {
  socketId: string
  roomId: string
  permissionLevel: PermissionLevel
  data: any
}

type Props = {
  roomId: string
  authedUser: any
}

// Entire space section under navbar
export default function SpaceRoom({ roomId, authedUser }: Props) {
  const socket = useContext(SocketContext)
  const { isPhone } = useSelectViewport()
  const dispatch = useDispatch()
  const permission = usePermission(roomId, true)

  const REACT_APP_API_SERVER =
    useSelector((state: any) => state.env.REACT_APP_API_SERVER) ||
    import.meta.env.REACT_APP_API_SERVER

  const hasRoomData = useSelector(
    (state: RootState) => state.room.data !== null,
  )
  const permissionLevel = useSelector(
    (state: RootState) => state.permission.level,
  )
  const { shareScreenMode, shareScreenModeSelf } = useSelector(
    (state: any) => state.call,
  )
  const isOwner = useSelector((state: RootState) => state.user.owner)
  const isPresenterMode = useSelector(
    (state: RootState) => state.space.isPresenterMode,
  )

  const showAnnotation = useSelector(
    (state: RootState) => state.annotation.showAnnotation,
  )

  useEffect(() => {
    checkPermission()
    if (authedUser.data?.role !== undefined) {
      dispatch(assignGuest(false))
    }
    if (authedUser.data?.role === "super-admin") {
      checkSyncableUsers()
      dispatch(assignOwner(true))
      dispatch(updateIsOwner(true))
    }

    const cursorColor = getCursorColor(authedUser.data?.name)
    dispatch(assignCursorColor(cursorColor))
    dispatch(assignId(authedUser.data?._id))
    dispatch(assignEmail(authedUser.data?.email))
    dispatch(assignName(authedUser.data?.name))
    dispatch(assignMMPassword(authedUser.data?.mmPassword))
    dispatch(assignMMTeamName(authedUser.data?.mmTeamName))
    dispatch(assignOrgId(authedUser.data?.orgId))
    dispatch(assignTeams(authedUser.data?.teams))
  }, [authedUser.data])

  useEffect(() => {
    let keepAliveInt
    if (socket.connected && authedUser.data) {
      keepAliveInt = setInterval(() => {
        const keepAlivePayload = {
          name: authedUser.data?.name,
          id: authedUser.data?._id,
          _id: authedUser.data?._id,
          time: new Date().getTime(),
          role: authedUser.data?.role,
          email: authedUser.data?.email,
          roomPermissionLevel: permission,
        }
        socket.emit(SocketMessageType.KeepAlive, keepAlivePayload)
      }, 5000)
    }

    return () => {
      clearInterval(keepAliveInt)
    }
  }, [socket.connected, authedUser.data])

  //## SOCKET EVENTS ##//
  useEffect(() => {
    socket.on(
      SocketMessageType.TriggerToast,
      (payload: TriggerToastPayload) => {
        if (!payload || !payload.msg || !payload.options) return
        const type = payload.type
        const msg = payload.msg
        const options: ToastOptions = payload.options
        const excludeSocketIds = payload.excludeSocketIds
        if (
          Array.isArray(excludeSocketIds) &&
          excludeSocketIds.includes(socket.id)
        )
          return

        const DEBOUNCE_TOAST_IDS = ["annotation-start", "annotation-end"] // To prevent spamming.
        const toastId = options?.toastId
        if (toastId && DEBOUNCE_TOAST_IDS.filter((item) => item === toastId)) {
          //!! incomplete !!//
          //  toast.dismiss(toastId)
          toast.clearWaitingQueue()
        }

        if (!type || type === "default") return toast(msg, options)
        toast[type]?.(msg, options)
      },
    )
    socket.on(SocketMessageType.SyncSpace, (payload: SyncSpacePayload) => {
      if (!payload || payload.spaceId !== roomId) return // Just in case.
      dispatch(syncSpace(payload))

      if (payload.magicMarker) {
        const show = payload.magicMarker.show
        const mode = payload.magicMarker.mode
        const currentDrawer = payload.magicMarker.currentDrawer
        dispatch(assignShowAnnotation(show || false))

        if (mode) {
          dispatch(assignAnnotationMode(mode))
        }
        if (show) {
          dispatch(addView("annotate"))
          dispatch(assignShowMenu(show))
        }
        if (show === false) {
          dispatch(removeView("annotate"))
        }
        if (currentDrawer) {
          console.log("currentDrawer", currentDrawer)
          dispatch(assignCurrentDrawer(currentDrawer))
        }
      }
    })
    socket.on(SocketMessageType.JoinUser, (payload) => {
      if (payload.socketId === socket.id) return
      dispatch(addCurrentJoin(payload))
    })
    socket.on(SocketMessageType.JoinGuest, (payload) => {
      if (payload.socketId === socket.id) return
      dispatch(addCurrentJoin(payload))
    })
    socket.on(SocketMessageType.LeavePerson, (payload) => {
      dispatch(removeCurrentJoin(payload))
    })

    socket.on(SocketMessageType.ToggleIsLocked, (payload: boolean) => {
      dispatch(assignIsLocked(payload))
    })

    socket.on(
      SocketMessageType.ToogleAppsPanel,
      (payload: boolean | { user_id?: string; show?: boolean }) => {
        console.log({ payload, _id: authedUser.data?._id })
        if (typeof payload === "boolean") {
          dispatch(toggleAppsPanel(payload))
          return
        }
        const show = payload?.show
        if (typeof show !== "boolean") return

        const triggering_user_id = payload?.user_id
        if (!triggering_user_id) {
          dispatch(toggleAppsPanel(show))
          return
        }
        const myUser_id = authedUser.data?._id
        if (triggering_user_id === myUser_id) return
        dispatch(toggleAppsPanel(show))
      },
    )

    socket.on(SocketMessageType.KeepAlive, (payload: any) => {
      if (authedUser.data?._id !== payload.message.id) {
        dispatch(assignParticipants(payload.message))
      }
    })

    socket.on(SocketMessageType.SceneString, (payload: any) => {
      const scene2d = payload.message
      if (!scene2d) return
    })

    socket.on(SocketMessageType.GetRoom, (payload: any) => {
      console.log("get-room", payload)
      const payloadRoomId = payload?.roomId
      const payloadSenderId = payload?.senderId
      // if (!payloadRoomId || !payloadSenderId) return
      if (!payloadRoomId || roomId !== payloadRoomId) {
        const msg = `get-room roomId mismatch: ${roomId} !== ${payloadRoomId}`
        console.error(msg)
        return
      }
      // if (roomId !== payloadRoomId || authedUser._id === payloadSenderId) return
      //@@ If senderId exists, and if sender is self, return @@//
      if (payloadSenderId && authedUser.data?._id === payloadSenderId) return

      console.log("get-room TRIGGERING ---- ")
      getRoom(roomId)
    })

    socket.on(SocketMessageType.DemoMode, (payload) => {
      dispatch(toggleDemoMode(payload))
      if (!payload) dispatch(toggleAppsPanel(payload)) // just to be sure...
    })

    socket.on(SocketMessageType.PresenterMode, (payload) => {
      const toggle = payload.toggle // true --> start presenting, false --> stop
      const name = payload.name || "Room owner"
      const shouldTriggerNotif = payload.triggerNotif || false

      if (shouldTriggerNotif) presentationNotify(name, toggle) // Emit toast notif
      dispatch(togglePresenterMode(toggle)) // Toggle side panels for all users
    })

    socket.on(SocketMessageType.HideCursor, (payload) => {
      const participantId = payload._id
      const userData = authedUser?.data
      if (!participantId || participantId === userData?._id) return
      toggleCursorVisibility(participantId, false)
    })

    socket.on(SocketMessageType.ShowCursor, (payload) => {
      const participantId = payload._id
      const userData = authedUser?.data
      if (!participantId || participantId === userData?._id) return
      toggleCursorVisibility(participantId, true)
    })

    socket.on(SocketMessageType.VideShareScreen, (payload) => {
      const event = payload.event
      // const userInfo = payload.userInfo;
      // console.log("sharing user info", payload)

      const isSharing = event.on
      if (shareScreenMode === isSharing) return

      dispatch(updateShareScreenMode(isSharing))
      // const displayName = userInfo.displayName;
      // const email = userInfo.email;
      forceUpdateJitsiKey()

      if (!isSharing) {
        console.warn("NOT SHARING SCREEN ANYMORE")
        window.clearInterval(window.screenShareInterval)
        return
      }
      const INTERVAL_FREQUENCY = 500

      //!! DISABLING TEMPORARILY !!//
      // window.screenShareInterval = window.setInterval(() => {
      //   console.warn("UPDATING JITSI DURING SCREEN SHARE")
      //   dispatch(updateJitsiKey(null))
      // }, INTERVAL_FREQUENCY)
    })

    socket.on(SocketMessageType.PdfPageChange, (payload) => {
      dispatch(
        assignPdfPage({
          id: payload.message.pdfId,
          page: payload.message.newPage,
        }),
      )
    })

    socket.on("magic-marker-toggle", (payload) => {
      console.log("magic-marker-toggle", payload)
      if (!payload) return
      const user_id = payload.user_id
      const show = payload.show
      const mode = payload.mode
      if (user_id && user_id === authedUser.data?._id) return
      // if (showAnnotation !== show) {
      //   toast.info(
      //     `${payload.name || payload.email || "Someone"} ${
      //       show ? "started" : "ended"
      //     } annotation session`,
      //     {
      //       autoClose: 1000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: false,
      //       theme: "dark",
      //       toastId: payload.name,
      //     },
      //   )
      // }
      show && mode && dispatch(assignAnnotationMode(mode))
      dispatch(assignShowAnnotation(show))
      dispatch(assignShowMenu(show))
      dispatch(show ? addView("annotate") : removeView("annotate"))
    })

    socket.on("magic-marker-clear", (payload) => {
      dispatch(clearChunkData())
    })

    socket.on("magic-marker-current-drawer", (payload) => {
      dispatch(assignCurrentDrawer(payload))
    })

    return () => {
      socket.off(SocketMessageType.TriggerToast)
      socket.off(SocketMessageType.SyncSpace)
      socket.off(SocketMessageType.JoinUser)
      socket.off(SocketMessageType.JoinGuest)
      socket.off(SocketMessageType.LeavePerson)
      socket.off(SocketMessageType.KeepAlive)
      socket.off(SocketMessageType.GetRoom)
      socket.off(SocketMessageType.DemoMode)
      socket.off(SocketMessageType.PresenterMode)
      socket.off(SocketMessageType.ToggleIsLocked)
      socket.off(SocketMessageType.ToogleAppsPanel)
      socket.off(SocketMessageType.ShowCursor)
      socket.off(SocketMessageType.HideCursor)
      socket.off(SocketMessageType.VideShareScreen)
      socket.off(SocketMessageType.SceneString)
      socket.off(SocketMessageType.PdfPageChange)
      socket.off("connect-guac")
      socket.off("magic-marker-toggle")
      socket.off("magic-marker-clear")
      socket.off("magic-marker-current-drawer")
    }
  }, [socket, dispatch, permissionLevel])

  function forceUpdateJitsiKey(timeout = 50) {
    if (window.forceUpdateJitsiKeyTimeoutId) {
      window.clearTimeout(window.forceUpdateJitsiKeyTimeoutId)
    }

    const forceUpdateJitsiKeyTimeoutId = setTimeout(() => {
      dispatch(updateJitsiKey(null))
    }, timeout)

    window.forceUpdateJitsiKeyTimeoutId = forceUpdateJitsiKeyTimeoutId
  }

  function roomJoinedHandler(param: JoinPersonHandlerParam) {
    if (!socket || !socket.connected || !authedUser || !authedUser.data) {
      console.error("wtf??? this shouldn't happen.....", {
        socket: Boolean(socket),
        connected: socket?.connected,
        authedUser: authedUser,
        authedUserData: authedUser?.data,
      })
      return
    }
    if (!param || !param.permissionLevel) {
      return
    }

    const permissionLevel = param.permissionLevel
    const authedUserData = authedUser.data
    dispatch(assignMySocketId(socket.id))
    const isGuest = !permissionLevel || permissionLevel === "guest"

    const payload: SocketJoinEventPayload = {
      socketId: socket.id,
      roomId,
      permissionLevel,
      data: {
        ...authedUserData,
        name: isGuest
          ? window.sessionStorage.getItem("guestName")
          : authedUserData.name,
      },
    }

    dispatch(assignSocketJoinPayload(payload))
    isGuest
      ? socket.emit(SocketMessageType.JoinGuest, payload)
      : socket.emit(SocketMessageType.JoinUser, payload)
  }

  // Set accesslevel and listen to socket message
  async function checkPermission() {
    try {
      const myPermission = await getPermission(roomId)

      roomJoinedHandler({ permissionLevel: myPermission })
    } catch (error) {
      console.error(
        "checkPermission() error!!!!!!!!!!!!!! -- Re-triggering!",
        error,
      )
      setTimeout(() => {
        checkPermission()
      }, 1000)
      // navigate(`/lobby/${roomId}`, { replace: true })
    }
  }

  async function checkSyncableUsers() {
    if (roomId !== authedUser.data?._id) return

    try {
      const syncableUsers = await getSyncableUsers()
      const noOfSyncableUsers = syncableUsers?.length
      dispatch(setSyncableUserCount(noOfSyncableUsers))
    } catch (err) {
      console.error("checkSyncableUsers() ERROR:", err)
    }
  }

  //   if (!socket.connected) {
  //    return <div>Connecting...</div>
  //   }

  return (
    <Layout>
      <TheaterOverlay />

      <div
        className={cn(
          "relative flex h-full w-full items-stretch gap-[1pt] overflow-hidden",
          "sm:flex-col",
          { "z-50": isPresenterMode },
        )}
      >
        {hasRoomData && !isPhone && <DesktopLeftPanel />}
       
        {hasRoomData && (
          //  <div className="flex flex-col ">
          <>
          {/* <div className="relative flex"> */}
            <MainPanel annotationNotify={annotationNotify} />
            {/* </div> */}
            {/* <Navbar /> */}
            <QueueComp />
          </>
          // </div>
        )}
       

        {isPhone ? <PhoneSecondaryPanel /> : <DesktopRightPanel />}
      </div>
      <Navbar />
    </Layout>
  )
}
