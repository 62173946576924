import { useDispatch } from "react-redux"
import DateInput from "./DateInput"
import { Event } from "@/TYPES/redux"
import { setEditEvent } from "@/store/slices/eventsSlice"
import { timeOptions } from "./constants"
import { getDateFromTime } from "../utils"
import moment from "moment"
import AllDaySwitch from "./AllDaySwitch"

interface Props {
  editEvent: Event
  isDarkMode: boolean
}

export default function DatesInput({ editEvent, isDarkMode }: Props) {
  const dispatch = useDispatch()
  const startTimeOptions = timeOptions
  const endTimeOptions = timeOptions.filter((option) => {
    const endTime = getDateFromTime(option.value, editEvent.endTime)
    return endTime > moment(editEvent.startTime).toDate()
  })
  const handleStartTimeSelect = (newStartDate: Date) => {
    const oldEndTime = moment(editEvent.endTime).toDate()
    const newForm: Event = {
      ...editEvent,
      startTime: newStartDate.toISOString(),
      endTime:
        newStartDate >= oldEndTime
          ? moment(newStartDate).add(30, "minutes").toDate().toISOString()
          : editEvent.endTime,
    }
    dispatch(setEditEvent(newForm))
  }
  const handleEndTimeSelect = (newEndDate: Date) => {
    const newForm: Event = { ...editEvent, endTime: newEndDate.toISOString() }
    dispatch(setEditEvent(newForm))
  }

  const handleAllDaySwitchClick = () => {
    dispatch(setEditEvent({ ...editEvent, allDay: !editEvent.allDay }))
  }

  return (
    <div className="flex flex-col">
      <AllDaySwitch
        checked={editEvent.allDay}
        onChange={handleAllDaySwitchClick}
        isDarkMode={isDarkMode}
      />
      {!editEvent.allDay && (
        <div className="flex flex-col gap-1">
          <DateInput
            title="Start Date"
            date={moment(editEvent.startTime).toDate()}
            setDate={handleStartTimeSelect}
            timeOptions={startTimeOptions}
            isDarkMode={isDarkMode}
          />
          <DateInput
            title="End Date"
            date={moment(editEvent.endTime).toDate()}
            setDate={handleEndTimeSelect}
            timeOptions={endTimeOptions}
            isDarkMode={isDarkMode}
          />
        </div>
      )}
    </div>
  )
}
