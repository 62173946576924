import { SocketContext, socketConnection } from "@/context/socket"
import { useAssets, useMyUser, useRoom } from "@/helpers"
import { assignSpaceId, setInitialApps } from "@/store/slices/spaceSlice"

import { RootState } from "@/TYPES/redux"
import { updateFiles } from "@/store/slices/filesSlice"
import { clearRoom, updateRoom } from "@/store/slices/roomSlice"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import "react-toastify/dist/ReactToastify.css"
import SpaceRoom from "./SpaceRoom"
import CanvasVideo from "@/components/Call/CanvasVideo"
import {
  startUserSessionValidationLoop,
  endUserSessionValidationLoop,
} from "@/helpers"

// Entire space section under navbar
export default function Space() {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const { id } = useParams()
  const room = useRoom(id)
  const files = useAssets(id!)
  const user = useMyUser(true)

  const isPresenterMode = useSelector(
    (state: RootState) => state.space.isPresenterMode,
  )

  // Set data to room / space store
  useEffect(() => {
    if (room.data && !room.isError) {
      dispatch(updateRoom(room.data))
    }
  }, [room.data, room.isError])

  useEffect(() => {
    if (files.data && !files.isError) {
      dispatch(updateFiles(files.data))
    }
  }, [files.data, files.isError])

  // Clear room store on dismount
  useEffect(() => {
    return () => {
      dispatch(clearRoom())
    }
  }, [])

  useEffect(() => {
    dispatch(assignSpaceId(id))
  }, [id])

  useEffect(() => {
    // Redirect to lobby on first attempt to access space
    const redirect = !location.state?.skipRedirect
    if (redirect) navigate(`/lobby/${id}`, { replace: true })

    // Start user session validation loop !THIS IS A QUICK SOLUTION! //

    startUserSessionValidationLoop()
    return () => {
      endUserSessionValidationLoop()
    }
  }, [user])

  return (
    <SocketContext.Provider value={socketConnection}>
      <SpaceRoom roomId={id!} authedUser={user} />
      {isPresenterMode ? (
        <div
          className="presenter-video-wrapper"
          style={{
            display: isPresenterMode ? "flex" : "none",
            position: "fixed",
            bottom: "0",
            right: "0",
            width: "min-content",
            height: "min-content",
            zIndex: 1000,
          }}
        >
          <CanvasVideo />
        </div>
      ) : null}
    </SocketContext.Provider>
  )
}
