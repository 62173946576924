import { FileFolderManager, FileFolderMenu } from "@/components/File"
import PlatformSidebar from "@/components/PlatformSidebar"

export function Sidebar() {
  return (
    <PlatformSidebar title="Files" headerActions={<FileFolderMenu />}>
      <FileFolderManager />
    </PlatformSidebar>
  )
}
