import { createSlice } from "@reduxjs/toolkit"

export const initSlice = createSlice({
  name: "init",
  initialState: {
    isInited: false,
    serverError: false,
  },
  reducers: {
    assignIsInited: (state, action) => ({
      ...state,
      isInited: !!action.payload,
    }),
    assignServerError: (state, action) => ({
      ...state,
      serverError: action.payload,
    })
  },
})

export const { assignIsInited, assignServerError } = initSlice.actions
export default initSlice.reducer
