import { RootState } from "@/TYPES/redux"
import { assignIsSmallSidebar } from "@/store/slices/spaceSlice"
import cn from "classnames"
import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import TileList from "../TileList"

const minWidth = 150
const avgWidth = 250
const maxWidth = 400
const offset = 10

export default function DesktopLeftPanel() {
  const dispatch = useDispatch()

  const views = useSelector((state: RootState) => state.space.views)
  const isVisible = views.includes("apps") || views.includes("files")

  const [width, setWidth] = useState(300)
  const [resizing, setResizing] = useState(false)

  // useEffect(() => {
  //   if (resizing) {
  //     document.addEventListener("mouseup", handleMouseUp, true)
  //     document.addEventListener("mousemove", handleMouseMove, true)
  //   } else {
  //     document.removeEventListener("mouseup", handleMouseUp, true)
  //     document.removeEventListener("mousemove", handleMouseMove, true)
  //   }
  // }, [resizing])

  // const handleMouseDown = useCallback(() => setResizing(true), [])

  // const handleMouseUp = useCallback(() => setResizing(false), [])

  // const handleMouseMove = useCallback((e) => {
  //   let newWidth = e.clientX - document.body.offsetLeft
  //   if (newWidth > minWidth - offset && newWidth < maxWidth + offset) {
  //     newWidth = Math.max(newWidth, minWidth)
  //     newWidth = Math.min(newWidth, maxWidth)

  //     setWidth(newWidth)
  //     dispatch(assignIsSmallSidebar(newWidth < avgWidth))
  //   }
  // }, [])

  return (
    <>
      <div
        className={cn(
          "relative h-full flex items-stretch border-r-cloudy overflow-auto z-30 select-none",
          {
            "transition-width": !resizing,
            "select-none": resizing,
            "pr-1": isVisible,
          },
        )}
        style={{ width: isVisible ? width + 4 : 0 }}
      >
        <div
          // className="absolute top-0 left-0 h-full flex flex-col bg-charcoal"
          style={{ width }}
        >
          <TileList />
        </div>
        </div>
        {/* <div
          className={cn(
            "absolute top-0 right-0 h-full w-[4px]",
            "cursor-ew-resize",
            "hover:bg-accentgreen",
            { "bg-accentgreen": resizing },
          )}
          onMouseDown={handleMouseDown}
        />
      </div>
      {resizing && <div className="fixed inset-0 z-20"></div>} */}
    </>
  )
}
