import { SocketMessageType } from "@/TYPES/common"
import { RootState } from "@/TYPES/redux"
import Icon, { IconNameProp } from "@/components/Icon"
import Modal from "@/components/Modal"
import { SocketContext } from "@/context/socket"
import { resolveToValidHttpURL } from "@/helpers"
import { updateTiles } from "@/helpers/tiles"
import { Tooltip } from "@mui/material"
import cn from "classnames"
import { useContext, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"

type Props = {
  onClose: () => void
}

export default function AddAppModal({ onClose }: Props) {
  const socket = useContext(SocketContext)
  const dispatch = useDispatch()
  const roomId = useSelector((state: RootState) => state.space.spaceId!)
  //   const apps = useSelector((state: RootState) => state.space.apps)
  const smallTiles = useSelector(
    (state: RootState) => state.room.data?.scene2d?.dataTile01,
  )
  const largeTiles = useSelector(
    (state: RootState) => state.room.data?.scene2d?.dataTile00,
  )
  const [title, setTitle] = useState("")
  const [url, setUrl] = useState("")
  const [adding, setAdding] = useState(false)

  const resolvedUrl = resolveToValidHttpURL(url)
  const disabled = !resolvedUrl

  const addWebApp = async (e: any) => {
    if (!resolvedUrl) {
      window.alert("Please provide valid URL")
      return
    }

    setAdding(true)
    const addingToast = toast(`Adding embed app, ${resolvedUrl}`, {
      toastId: `adding-app-${resolvedUrl}`,
      autoClose: false,
      closeOnClick: false,
      theme: "dark",
    })

    const { hostname } = new URL(resolvedUrl)
    const newApp: any = {
      title: title || hostname,
      fileName: "iframe",
      type: "iframe",
      src: resolvedUrl,
    }

    const newSmallTiles = [newApp, ...(smallTiles || [])]
    await updateTiles(roomId, newSmallTiles, largeTiles)
      // This catch block is technically unnecessary, but is being used to display a more accurate error msg for users:
      // When updateTiles errors, it will display toast notif 'Failed to update tiles: ...'
      // The below code will conditionally override that message to say 'Failed to add app: ...' instead
      .catch((err) => {
        toast.update("update-tiles", {
          render: `Failed to add app: ${
            err.msg || err.message || "Server Error"
          }`,
        })
      })
    socket.emit(SocketMessageType.SceneString, {
      dataTile01: newSmallTiles,
      dataTile00: largeTiles,
    })

    setAdding(false)
    toast.dismiss(addingToast)
    onClose()
  }

  //   const disabled = !resolveToValidHttpURL(url)

  if (adding) {
    return null
  }

  return (
    <Modal
      className="w-[400px] flex flex-col"
      title="Add App"
      onClose={onClose}
      info="Embed website without live collaboration features. Some websites may not display. Please use Cobrowse for guaranteed access and interactive collaboration."
    >
      <div className="flex flex-col justify-center items-center">
        <input
          className="flex-1 text-carbon bg-smoke p-2 outline-none rounded-sm w-full"
          placeholder="App URL"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />

        <br />
        <Tooltip
          enterDelay={500}
          title={disabled ? "Please provide valid URL" : `Add "${url}"`}
          disableInteractive={disabled}
        >
          <button
            className={cn(
              "w-[40px] h-[40px] flex-center rounded-sm box-shadow button-effect",
              {
                "bg-accentgreen hover:opacity-70": !disabled,
                "bg-carbon": disabled,
              },
            )}
            onClick={addWebApp}
            disabled={!resolvedUrl}
          >
            <Icon
              className={cn({
                "fill-typegray": disabled,
                "fill-white": !disabled,
                "opacity-70": disabled,
              })}
              iconName={IconNameProp.Add}
              size={16}
            />
          </button>
        </Tooltip>
      </div>
    </Modal>
  )
}
