import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin, {
  DateClickArg,
  EventResizeDoneArg,
} from "@fullcalendar/interaction"
import FullCalendar from "@fullcalendar/react"
import rrulePlugin from "@fullcalendar/rrule"
import timeGridPlugin from "@fullcalendar/timegrid"
import {
  EventClickArg,
  EventDropArg,
  DayHeaderContentArg,
} from "@fullcalendar/core"
// It is recommended to override base fullCalendar styles by using a style sheet. https://fullcalendar.io/docs/v5/css-customization
import "@/assets/styles/calendar.css"
import { useEffect, useRef } from "react"
import CalendarHeader from "./CalendarHeader"
import moment from "moment"
import { EViewTypes } from "./types"
import { useSelectViewport } from "@/utils/hooks/viewport"
import cn from "classnames"
import { useDispatch, useSelector } from "react-redux"
import { Event, RootState } from "@/TYPES/redux"
import {
  setActiveEvent,
  setEditEvent,
  updateDraggedEventTime,
} from "@/store/slices/eventsSlice"
import { createRandomEvent } from "./CalendarLeftPanel/utils"
import { useUpdateCalendar } from "./hooks/useUpdateCalendar"

interface Props {
  isDarkMode: boolean
  setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>
  calendarEvents?: any
}

export default function CalendarBody({ isDarkMode, setIsDarkMode }: Props) {
  const { isPhone } = useSelectViewport()
  const dispatch = useDispatch()
  const divRef = useRef<HTMLDivElement | null>(null)
  const calendarRef = useRef<FullCalendar | null>(null)
  const { updateEventTimeInCalendar } = useUpdateCalendar()

  const activeEventId = useSelector((state: RootState) => state.events.activeId)
  const editEvent = useSelector((state: RootState) => state.events.editEvent)
  const savedEvents = useSelector((state: RootState) => state.events.events)
  const events = editEvent ? [...savedEvents, editEvent] : savedEvents

  useEffect(() => {
    // resize calendar when parent div size changes
    const calApi = calendarRef.current?.getApi()
    if (!divRef.current || !calApi) {
      return
    }
    const resizeObserver = new ResizeObserver(() => {
      calApi.updateSize()
    })
    resizeObserver.observe(divRef.current)

    return () => resizeObserver.disconnect()
  }, [])

  useEffect(() => {}, [savedEvents, savedEvents.length])

  const handleEventClick = (info: EventClickArg) => {
    if (editEvent) {
      return
    }
    dispatch(setActiveEvent(info.event._def.publicId))
  }

  const handleEventDrag = (info: EventResizeDoneArg | EventDropArg) => {
    const draggedEventId = info.event._def.publicId
    dispatch(
      updateDraggedEventTime({
        newAllDay: info.event.allDay,
        newStartDate: info.event.start?.toISOString(),
        newEndDate:
          info.event.end?.toISOString() ||
          moment(info.event.start).add(30, "minutes").toDate().toISOString(),
        draggedEventId,
      }),
    )
    updateEventTimeInCalendar(
      info.event.allDay,
      info.event.start,
      info.event.end,
      draggedEventId,
    )
  }

  const handleEventResize = (info: EventResizeDoneArg) => {
    if (!info.event.end) {
      return
    }

    if (editEvent) {
      const newEvent: Event = {
        ...editEvent,
        endTime: info.event.end.toISOString(),
      }
      dispatch(setEditEvent(newEvent))
    } else {
      handleEventDrag(info)
    }
  }

  const handleEventDrop = (info: EventDropArg) => {
    if (!info.event.start) {
      return
    }

    if (editEvent) {
      const newEvent: Event = {
        ...editEvent,
        allDay: info.event.allDay,
        startTime: info.event.start.toISOString(),
        endTime:
          info?.event?.end?.toISOString() ||
          moment(info.event.start).add(30, "minutes").toISOString(),
      }
      dispatch(setEditEvent(newEvent))
    } else {
      handleEventDrag(info)
    }
  }

  const handleDateClick = (e: DateClickArg) => {
    const { newEvent, eventuuid } = createRandomEvent(e.date)
    dispatch(setEditEvent(newEvent))
    dispatch(setActiveEvent(eventuuid))
  }

  const renderDayHeader = (content: DayHeaderContentArg) => {
    const weekday = moment(content.date).format("ddd")
    const dayDate = content.date.getDate()

    if (content.view.type === EViewTypes.Month) {
      return <div>{weekday}</div>
    }

    return (
      <div className="flex flex-col items-start p-1">
        <span
          className={cn("font-bold justify-start hover:none", {
            "text-[3vw]": !isPhone,
            "text-[5.5vw]": isPhone,
          })}
        >
          {dayDate}
        </span>
        <span
          className={cn({ "text-[3.5vw]": isPhone, "text-[1vw]": !isPhone })}
        >
          {weekday}
        </span>
      </div>
    )
  }

  return (
    <div
      ref={divRef}
      className={cn("relative flex flex-col w-full h-full", {
        "bg-smoke": !isDarkMode,
        "bg-cobrowsegray": isDarkMode,
      })}
    >
      <CalendarHeader
        calendarRef={calendarRef}
        isDarkMode={isDarkMode}
        setIsDarkMode={setIsDarkMode}
      />
      <div
        className={cn("flex flex-1 flex-col", {
          "calendar-darkmode": isDarkMode,
        })}
      >
        <FullCalendar
          ref={calendarRef}
          height="100%"
          viewClassNames={cn("w-full", {
            "bg-smoke text-cloudy": !isDarkMode,
            "bg-cobrowsegray text-smoke": isDarkMode,
          })}
          headerToolbar={{
            left: "",
            center: "",
            right: "",
          }}
          titleFormat={{ month: "long", day: "numeric" }}
          eventClassNames={cn(
            "border-l-accentyellow border-l-4 rounded-[3px] font-bold cursor-pointer",
            {
              "bg-white border-white text-carbon": !isDarkMode,
              "text-smoke hover:opacity-80": isDarkMode,
              "text-[16px] px-2": !isPhone,
              "text-[12px] px-0": isPhone,
            },
          )}
          eventTextColor="#5b5b5b"
          eventClick={handleEventClick}
          displayEventTime={false}
          slotLabelFormat={{ hour: "numeric", minute: "2-digit" }}
          events={
            (events &&
              Array.isArray(events) &&
              events.map((event) => {
                return {
                  id: event.eventUUID,
                  title: event.title,
                  start: event.startTime,
                  end: event.endTime,
                  textColor:
                    event.eventUUID === activeEventId ? "#5b5b5b" : !isDarkMode ? "#4d4d50" : "#ebebef",
                  backgroundColor:
                    event.eventUUID === activeEventId ? "#fdd100" : !isDarkMode ? "#fff" : "#282828",
                  borderColor:
                    event.eventUUID === activeEventId ? "#fdd100" : !isDarkMode ? "#fff" : "#888989",
                  editable: activeEventId === event.eventUUID,
                  allDay: event.allDay,
                }
              })) ||
            []
          }
          dayHeaderContent={renderDayHeader}
          nowIndicator={true}
          navLinks={false}
          expandRows={true}
          droppable={true}
          initialView="timeGridWeek"
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            rrulePlugin,
            interactionPlugin,
          ]}
          eventDrop={handleEventDrop}
          eventResize={handleEventResize}
          dateClick={handleDateClick}
        />
      </div>
    </div>
  )
}
