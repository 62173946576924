import { createContext } from "react"
import io from "socket.io-client"
import $store from "@/store"
if (!window._env_) window._env_ = {}
const storeState = $store.getState()
const env = storeState.env
console.warn({env})
const roomId = window.location.pathname.split("/")[2]
const baseSocketUrl =
  window._env_.REACT_APP_SOCKET_SERVER ||
  env.REACT_APP_SOCKET_SERVER ||
  import.meta.env.REACT_APP_SOCKET_SERVER
// const subPath =
//   env.REACT_APP_SOCKET_SUBPATH || import.meta.env.REACT_APP_SOCKET_SUBPATH || ""
const subPath = (() => {
  try {
    const urlObj = new URL(baseSocketUrl)
    const pathname =
      urlObj.pathname ||
      env.REACT_APP_SOCKET_SUBPATH ||
      import.meta.env.REACT_APP_SOCKET_SUBPATH ||
      ""
    return pathname
  } catch (error) {
    console.error(error)
    return (
      env.REACT_APP_SOCKET_SUBPATH || import.meta.env.REACT_APP_SOCKET_SUBPATH
    )
  }
})()
const roomPath = `/space-${roomId}`
const socketURL = baseSocketUrl + roomPath

export const socketConnection = io(socketURL, {
  path: subPath,
  transports: ["websocket", "polling"],
  auth: {},
  query: {},
})

socketConnection.on("connect", () => {
  const logStyle = `background:black;color:white;border:1px solid white;font-size:18px;padding:10px;`
  console.log(
    `%c[connect] | Socket Connected:\n - URL: ${socketURL}\n - PATH: ${subPath}`,
    logStyle,
  )
})

socketConnection.on("connect_error", () => {
  const logStyle = `background:black;color:red;border:1px solid red;font-size:18px;padding:10px;width:100%;`
  console.log(
    `%c[connect_error] | Socket Connection Error:\n - URL: ${socketURL}\n - PATH: ${subPath}`,
    logStyle,
  )
})

// socketConnection.io.on("reconnection_attempt", () => {
//   const logStyle = `background:black;color:orange;border:1px solid orange;font-size:18px;padding:10px;`
//   console.log(
//     `%c[reconnection_attempt] | Socket Reconnect Attempt:\n - URL: ${socketURL}\n - PATH: ${subPath}`,
//     logStyle,
//   )
// })

// socketConnection.on("disconnect", () => {
//   const logStyle = `background:black;color:red;border:1px solid red;font-size:18px;padding:10px;`
//   console.log(`%c[disconnect] | Socket Disconnected`, logStyle)
// })(window as any)._socket_ = socketConnection

window._socket_ = socketConnection

export const SocketContext = createContext(socketConnection)

/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////
// import { createContext } from "react"
// import io from "socket.io-client"
// import $store from "@/store"
// export function connectSocket() {
//   const storeState = $store.getState()
//   const env = storeState.env
//   const roomId = window.location.pathname.split("/")[2]
//   const baseSocketUrl =
//     env.REACT_APP_SOCKET_SERVER || import.meta.env.REACT_APP_SOCKET_SERVER
//   const subPath =
//     env.REACT_APP_SOCKET_SUBPATH ||
//     import.meta.env.REACT_APP_SOCKET_SUBPATH ||
//     ""
//   const roomPath = `/space-${roomId}`
//   const socketURL = baseSocketUrl + roomPath

//   const socketConnection = io(socketURL, {
//     path: subPath,
//     transports: ["websocket", "polling"],
//     auth: {},
//     query: {},
//   })

//   socketConnection.on("connect", () => {
//     const logStyle = `background:black;color:white;border:1px solid white;font-size:18px;padding:10px;`
//     console.log(
//       `%c[connect] | Socket Connected:\n - URL: ${socketURL}\n - PATH: ${subPath}`,
//       logStyle,
//     )
//   })

//   socketConnection.on("connect_error", () => {
//     const logStyle = `background:black;color:red;border:1px solid red;font-size:18px;padding:10px;width:100%;`
//     console.log(
//       `%c[connect_error] | Socket Connection Error:\n - URL: ${socketURL}\n - PATH: ${subPath}`,
//       logStyle,
//     )
//   })

//   window._socket_ = socketConnection

//   return {
//     socket: socketConnection,
//     context: createContext(socketConnection),
//   }
// }
