import { ChildrenType } from "@/TYPES/common"
import Icon, { IconNameProp } from "@/components/Icon"
import InfoIcon from "@mui/icons-material/Info"
import Tooltip from "@mui/material/Tooltip"
import cn from "classnames"

type Props = {
  className?: string
  titleClassName?: string
  title?: string
  noCloseButton?: boolean
  onClose: () => void
  children: ChildrenType
  info?: string
  bgColor?: string
}

export default function Modal({
  className,
  bgColor = "bg-secondarygray",
  titleClassName = "bg-secondarygray",
  title,
  onClose,
  noCloseButton = false,
  children,
  info,
}: Props) {
  const handleClose = (e: any) => {
    e.stopPropagation()
    onClose()
  }

  const handlePreventClose = (e: any) => e.stopPropagation()

  return (
    <div
      className="fixed inset-0 bg-black/50 z-[9999] flex-center"
      onClick={handleClose}
    >
      <div
        className={cn(
          "relative rounded-sm overflow-hidden shadow-2xl",
          bgColor,
        )}
        onClick={handlePreventClose}
      >
        {(title || !noCloseButton) && (
          <div
            className={cn(
              "h-[40px] flex items-center justify-between",
              titleClassName,
            )}
          >
            {info ? (
              <>
                <Tooltip title={info} placement="top">
                  <div
                    className="info-hover"
                    style={{
                      width: "max-content",
                      marginLeft: "5px",
                    }}
                  >
                    <InfoIcon />
                  </div>
                </Tooltip>

                <div
                  style={{
                    flexGrow: 1,
                    marginLeft: "5px",
                  }}
                >
                  {title}
                </div>
              </>
            ) : (
              <h3 className="ml-3">{title}</h3>
            )}

            {!noCloseButton && (
              <div
                className="group w-[32px] h-[32px] flex-center cursor-pointer m-1"
                onClick={handleClose}
              >
                <Icon
                  className="fill-lavender group-hover:fill-accentred transition-color duration-300"
                  iconName={IconNameProp.Close}
                  size={12}
                />
              </div>
            )}
          </div>
        )}
        <div className={cn(className, "relative p-8")}>{children}</div>
      </div>
    </div>
  )
}
