import $store from "@/store"

export function getCursorId(pid) {
  return `cursor_${pid}`
}

const CURSOR_COLORS = [
  '#6A50D3',
  '#FF9B42',
  '#DF486F',
  '#73348C',
  '#B23683',
  '#F96E57',
  '#4380E2',
  '#238561',
  '#00A8B3'
];

export function getCursorColor(userName: string | undefined) {
  const state = $store.getState()
  const displayName = state.user?.displayName
  const guestName = displayName ? `${displayName} (Guest)` : ""
  const name = userName ?? guestName

  if (!name) return CURSOR_COLORS[0]

  const nameHash = name
    .split('')
    .reduce((hash, char) => hash + char.charCodeAt(0), 0)

  return CURSOR_COLORS[nameHash % CURSOR_COLORS.length]
}

export function toggleCursorVisibility(pid, show) {
  const cursors = $store.getState()?.participants?.cursors
  if (!cursors || !Object.keys(cursors).length) return
  if (!pid) {
    const cursorsArr = Object.values(cursors)
    cursorsArr.forEach((cur: any) => {
      const particiId = cur?._id
      const cursorId = getCursorId(particiId)
      const cursorEl: any = document.querySelector(`#${cursorId}`)
      if (!cursorEl) return
      //>> Unless intentionally hide all cursors, show all cursors <<//
      if (show !== false) show = true
      cursorEl.style.display = show ? "block" : "none"
    })
    return
  }

  const cursorId = getCursorId(pid)
  const cursorEl: any = document.querySelector(`#${cursorId}`)
  if (!cursorEl) return
  cursorEl.style.display = show ? "block" : "none"
  $store.dispatch({
    type: "participants/modifyCursor",
    payload: { _id: pid, show },
  })
}
