import swrHook from "swr"

export const SWRHelper = (
  endpoint: string,
  fetchFunc: (endpoint: string, body?: {}) => Promise<any>,
) => {
  const { data, error } = swrHook(endpoint, fetchFunc)
  return {
    data,
    isLoading: !data && !error,
    isError: error,
  }
}
