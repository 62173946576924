import Icon, { IconNameProp } from "@/components/Icon"
import cn from "classnames"

const options = [
  { label: "Last Modified", value: "last_modified" },
  { label: "Name", value: "name" },
]

type Props = {
  narrow: boolean
  onSelect: (key: any) => void
}

export default function Header({ narrow, onSelect }: Props) {
  const sortContent = (
    <select
      className={cn(
        "w-[175px] h-[38px] px-2",
        "text-concord text-18 text-center",
        "outline-none border border-ghostgray bg-transparent rounded-sm",
      )}
      onChange={(e) => onSelect(e.target.value)}
    >
      {options.map((item) => (
        <option key={item.label} value={item.value}>
          {item.label}
        </option>
      ))}
    </select>
  )

  return (
    <>
      {!narrow && (
        <div className="h-[70px] flex items-stretch py-4 shadow bg-smoke text-carbon text-20">
          <div className="min-w-[5%] max-w-[5%] flex items-center justify-center px-2">
            <Icon
              className="fill-carbon"
              iconName={IconNameProp.Check}
              size={18}
            />
          </div>
          <div className="min-w-[8%] max-w-[8%] flex items-center px-2 border-l border-ghostgray">
            Type
          </div>
          <div className="min-w-[35%] max-w-[35%] flex items-center px-2 border-l border-ghostgray">
            Name
          </div>
          <div className="min-w-[25%] max-w-[25%] flex items-center px-2 border-l border-ghostgray">
            Modified
          </div>
          <div className="flex-1 flex items-center justify-between px-2 border-l border-ghostgray">
            Modified by
            {sortContent}
          </div>
        </div>
      )}

      {narrow && (
        <div className="flex justify-between p-[10px]">{sortContent}</div>
      )}
    </>
  )
}
