import { ChildrenType } from "@/TYPES/common"
import { RootState } from "@/TYPES/redux"
import Icon, { IconNameProp } from "@/components/Icon"
import Topbar from "@/components/Topbar"
import { useSelectViewport } from "@/utils/hooks/viewport"
import { getPopperProps } from "@/helpers"
import { Tooltip } from "@mui/material"
import cn from "classnames"
import { useCallback } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import Navigation from "../../Navigation"
import DragLayer from "./DragLayer"
import Navbar from "../../Navbar"

type Props = {
  children: ChildrenType
}

// Top bar (Cloudcom logo, space name, user icon)
export default function Layout({ children }: Props) {
  const { isPhone, isPad, isDesktop } = useSelectViewport()

  return (
    <DndProvider backend={HTML5Backend}>
      <DragLayer />

      <div className="flex flex-col flex-1 overflow-hidden">{children}</div>

      {!isPad && !isDesktop && (
        <div className="relative overflow-x-auto overflow-y-visible">
          <Navigation />
        </div>
      )}
      {/* {(isPad || isDesktop) && (
        <div>
          <Navbar />
        </div>
      )} */}
    </DndProvider>
  )
}
