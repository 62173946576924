import { createSlice } from "@reduxjs/toolkit"

export const callSlice = createSlice({
  name: "call",
  initialState: {
    resizable: false, // Static value (cannot be changed) that determines if jitsi element should be resizable or not.
    show: true,
    showBeforeFullscreen: true,
    hovering: false,
    fullscreen: false,
    // width: window ? window.innerWidth * 0.4 : 500,
    width: 550,
    minWidth: 200,
    maxWidth: 800,
    resize: false,
    initialX: null,
    jitsiAPI: null,
    showParticipants: false,
    shareScreenModeSelf: false,
    shareScreenMode: false,
    recording: false,
    jitsiKey: 0,
    videoMuted: false,
    audioMuted: false,
  },
  reducers: {
    updateShow: (state, action) => {
      return {
        ...state,
        show: action.payload,
      }
    },
    updateShowBeforeFullscreen: (state, action) => {
      return {
        ...state,
        showBeforeFullscreen: action.payload,
      }
    },
    updateHovering: (state, action) => {
      return {
        ...state,
        hovering: action.payload,
      }
    },
    updateFullscreen: (state, action) => {
      return {
        ...state,
        fullscreen: action.payload,
      }
    },
    updateWidth: (state, action) => {
      return {
        ...state,
        width:
          state.width +
          (() => {
            const mousemoveX = action.payload
            if (mousemoveX < 0) return Math.abs(mousemoveX)
            return -Math.abs(mousemoveX)
          })(),
      }
    },
    updateResize: (state, action) => {
      return {
        ...state,
        resize: action.payload,
      }
    },
    updateRecording: (state, action) => {
      return {
        ...state,
        recording: action.payload,
      }
    },
    updateInitialX: (state, action) => {
      return {
        ...state,
        initialX: action.payload,
      }
    },
    updateJitsi: (state, action) => {
      return {
        ...state,
        jitsi: action.payload,
      }
    },
    updateShowParticipants: (state, action) => {
      return {
        ...state,
        showParticipants: action.payload,
      }
    },
    updateShareScreenModeSelf: (state, action) => {
      return {
        ...state,
        shareScreenModeSelf: action.payload,
      }
    },
    updateShareScreenMode: (state, action) => {
      return {
        ...state,
        shareScreenMode: action.payload,
      }
    },
    updateJitsiKey: (state, action) => {
      return {
        ...state,
        jitsiKey: state.jitsiKey + 1,
      }
    },
    assignVideoMuted: (state, action) => {
      return {
        ...state,
        videoMuted: action.payload,
      }
    },
    assignAudioMuted: (state, action) => {
      return {
        ...state,
        audioMuted: action.payload,
      }
    },
    assignJitsiAPI: (state, action) => {
      return {
        ...state,
        jitsiAPI: action.payload,
      }
    },
  },
})

export const {
  updateShow,
  updateHovering,
  updateFullscreen,
  updateWidth,
  updateResize,
  updateRecording,
  updateInitialX,
  updateJitsi,
  updateShowParticipants,
  updateShareScreenModeSelf,
  updateShareScreenMode,
  updateJitsiKey,
  updateShowBeforeFullscreen,
  assignVideoMuted,
  assignAudioMuted,
  assignJitsiAPI,
} = callSlice.actions
export default callSlice.reducer
