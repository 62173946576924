import { useCallback, useContext, useEffect, useState } from "react"
import { SessionContext } from "../context"
import { ViewportType } from "@/TYPES/common"

const breakpoints = {
  sm: 768,
  md: 1024,
  lg: 1280,
}

const generateViewPort = (): ViewportType => ({
  isPhone: window.innerWidth <= breakpoints.sm,
  isPad:
    window.innerWidth > breakpoints.sm && window.innerWidth <= breakpoints.md,
  isDesktop: window.innerWidth > breakpoints.md,
})

export const useViewport = (): ViewportType => {
  const [viewport, setViewport] = useState<ViewportType>(generateViewPort())

  const resizeEvent = useCallback(() => {
    const newViewport = generateViewPort()
    if (
      viewport.isPhone !== newViewport.isPhone ||
      viewport.isPad !== newViewport.isPad ||
      viewport.isDesktop !== newViewport.isDesktop
    ) {
      setViewport(newViewport)
    }
  }, [viewport, setViewport])

  useEffect(() => {
    window.addEventListener("resize", resizeEvent, { passive: true })

    return () => window.removeEventListener("resize", resizeEvent)
  }, [viewport])

  return viewport
}

export const useSelectViewport = (): ViewportType => {
  return useContext(SessionContext)?.viewport
}
