import { RootState } from "@/TYPES/redux"
import Icon, { IconNameProp } from "@/components/Icon"
import Modal from "@/components/Modal"
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom"
import cn from "classnames"
import { useState } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"

type Props = {
  addDisabled?: boolean
  onClose: () => void
}

export default function AddVNCModal({ addDisabled, onClose }: Props) {
  const [name, setName] = useState("")
  const [host, setHost] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [domain, setDomain] = useState("")

  const [adding, setAdding] = useState(false)

  const disabled = addDisabled || !host || !username || !password

  const teamId = useSelector((state: RootState) => state.room.data?.teamId)
  const userId = useSelector((state: RootState) => state.user.data?._id)

  async function handleAddConn() {
    const missingReqdFields = !host ? !name : !userId

    if (missingReqdFields) return

    const addingToast = toast(`Adding VNC: ${name}`, {
      toastId: `adding-vnc-${name || userId}`,
      autoClose: false,
      closeOnClick: false,
      theme: "dark",
    })

    try {
      // TODO: Implement this feature or remove it
      setAdding(true)
      console.log("Adding VNC")
    } catch (err) {
      toast.dismiss(addingToast)
    }
  }

  if (adding) {
    return null
  }

  return (
    <Modal
      className="w-[500px] flex flex-col"
      title="Add VNC Session"
      onClose={onClose}
    >
      <span className="text-typegray text-xs text-center">
        Please Note: VNC is not available for Demo Users.
      </span>
      <br />
      <input
        className={cn("flex-1 p-2 outline-none rounded-sm w-full mb-3", {
          "bg-smoke text-carbon": !disabled,
          "bg-typegray cursor-not-allowed": disabled,
        })}
        placeholder="Tile Name *"
        value={name}
        onChange={(e) => setName(e.target.value)}
        disabled={adding || addDisabled}
      />
      <input
        className={cn("flex-1 p-2 outline-none rounded-sm w-full mb-3", {
          "bg-smoke text-carbon": !disabled,
          "bg-typegray cursor-not-allowed": disabled,
        })}
        placeholder="Host IP *"
        value={host}
        onChange={(e) => setHost(e.target.value)}
        disabled={adding || addDisabled}
      />
      <input
        className={cn("flex-1 p-2 outline-none rounded-sm w-full mb-3", {
          "bg-smoke text-carbon": !disabled,
          "bg-typegray cursor-not-allowed": disabled,
        })}
        placeholder="Domain"
        value={domain}
        onChange={(e) => setDomain(e.target.value)}
        disabled={adding || addDisabled}
      />
      <input
        className={cn("flex-1 p-2 outline-none rounded-sm w-full mb-3", {
          "bg-smoke text-carbon": !disabled,
          "bg-typegray cursor-not-allowed": disabled,
        })}
        placeholder="Username *"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        disabled={adding || addDisabled}
        autoComplete="off"
      />
      <input
        type="password"
        className={cn("flex-1 p-2 outline-none rounded-sm w-full mb-3", {
          "bg-smoke text-carbon": !disabled,
          "bg-typegray cursor-not-allowed": disabled,
        })}
        placeholder="Password *"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        disabled={adding || addDisabled}
        autoComplete="off"
      />
      <div className="flex justify-center">
        <button
          className={cn(
            "h-[40px] flex-center rounded-sm box-shadow py-2 px-4 ml-2",
            {
              "bg-accentgreen text-white hover:opacity-70": !disabled,
              "bg-secondarygray cursor-not-allowed": disabled,
            },
          )}
          onClick={handleAddConn}
          disabled={disabled}
        >
          {adding ? (
            <HourglassBottomIcon />
          ) : (
            <Icon
              className={cn({
                "fill-cloudy": disabled,
                "fill-lavender hover:fill-accentgreen": !disabled,
              })}
              iconName={IconNameProp.Add}
              size={16}
            />
          )}
        </button>
      </div>
    </Modal>
  )
}
