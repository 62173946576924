import { createSlice } from "@reduxjs/toolkit"

const samples = [
  {
    id: "default",
    name: "Default",
    type: "folder",
    files: [
      { id: "0.1", name: "PDF.pdf", type: "pdf" },
      { id: "0.2", name: "RBG.png", type: "image" },
      { id: "0.3", name: "Test.jpg", type: "image" },
      { id: "0.4", name: "Dance.gif", type: "image" },
    ],
  },
  {
    id: "1",
    name: "Folder 1",
    type: "folder",
    files: [
      { id: "1.1", name: "Sub folder 1-1", type: "folder", files: [] },
      { id: "1.2", name: "Sub folder 1-2", type: "folder", files: [] },
      { id: "1.3", name: "Sub folder 1-3", type: "folder", files: [] },
      { id: "1.4", name: "PDF.pdf", type: "pdf" },
      { id: "1.5", name: "RBG.png", type: "image" },
      { id: "1.6", name: "test.csv", type: "csv" },
      { id: "1.7", name: "model.glb", type: "glb" },
    ],
  },
  {
    id: "2",
    name: "Folder 2",
    type: "folder",
    files: [
      { id: "2.4", name: "PDF.pdf", type: "pdf" },
      { id: "2.5", name: "RBG.png", type: "image" },
      { id: "2.6", name: "test.csv", type: "csv" },
      { id: "2.7", name: "model.glb", type: "glb" },
    ],
  },
  {
    id: "3",
    name: "Folder 3",
    type: "folder",
    files: [
      {
        id: "3.1",
        name: "Sub folder 3-1",
        type: "folder",
        files: [
          {
            id: "3.1.0",
            name: "Sub folder 3-1-1",
            type: "folder",
            files: [
              {
                id: "3.1.1.1",
                name: "Sub folder 3-1-1-1 Test",
                type: "folder",
                files: [],
              },
              {
                id: "3.1.1.2",
                name: "Sub folder 3-1-1-2",
                type: "folder",
                files: [],
              },
            ],
          },
          { id: "3.1.1", name: "PDF.pdf", type: "pdf" },
          { id: "3.1.2", name: "RBG.png", type: "image" },
        ],
      },
      { id: "3.2", name: "Sub folder 3-2", type: "folder", files: [] },
    ],
  },
  { id: "4", name: "Folder 4", type: "folder", files: [] },
]

const getFileType = ({ fileName, type }) => {
  const ext = fileName.split('.')[1];
  const imgExtensions = ['png', 'jpg', 'jpeg', 'gif'];

  return (ext === 'pdf' ? ext
    : imgExtensions.includes(ext) ? 'image'
      : type === 'folder' ? type
        : 'default')
}

export const filesSlice = createSlice({
  name: "files",
  initialState: {
    allFiles: [] as any[],
    folders: samples as any[],
    openFolderIds: [] as string[],
    selectedIds: [] as string[],
  },
  reducers: {
    updateFiles: (state, action) => {
      const transformedFiles = action.payload.map(file => {
        return { id: file._id, name: file.fileName, type: getFileType(file), modifiedAt: file.modifiedAt || Date.now() }
        // ^ All newly created files will have a 'modifiedAt' property
        // '|| Date.now()' is to cover files that were added to the db before 'modifiedAt' existed
      })

      return {
        ...state,
        allFiles: transformedFiles,
        folders: [{ id: '1', name: 'All Files', files: transformedFiles }]
      }
    },
    setFolders: (state, action) => {
      return {
        ...state,
        folders: action.payload,
        openFolderIds: [],
        selectedIds: [],
      }
    },
    openFolder: (state, action) => {
      return {
        ...state,
        openFolderIds: [...state.openFolderIds, action.payload],
      }
    },
    closeFolder: (state, action) => {
      return {
        ...state,
        openFolderIds: state.openFolderIds.filter(
          (id) => id !== action.payload,
        ),
      }
    },
    selectFolder: (state, action) => {
      return {
        ...state,
        selectedIds: action.payload,
      }
    },
  },
})

export const { updateFiles, openFolder, closeFolder, selectFolder } = filesSlice.actions
export default filesSlice.reducer
