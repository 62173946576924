import { PDFState } from "@/TYPES/redux"
import { createSlice } from "@reduxjs/toolkit"

const initialState: PDFState = {
  mainPDFRect: null,
}

export const pdfSlice = createSlice({
  name: "pdf",
  initialState,
  reducers: {
    assignMainPDFRect: (state, action) => {
      const payload = action.payload
      return {
        ...state,
        mainPDFRect: {
          ...state.mainPDFRect,
          ...payload,
        },
      }
    },
  },
})

export const { assignMainPDFRect } = pdfSlice.actions
export default pdfSlice.reducer
