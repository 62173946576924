import { ChildrenType } from "@/TYPES/common"
import { logoutUser, useMyUser } from "@/helpers"
import { useSelectViewport } from "@/utils/hooks/viewport"
import cn from "classnames"
import { Link, useNavigate } from "react-router-dom"

interface Props {
  redirectToAfterSignIn?: string
  children: ChildrenType
  rightContent?: ChildrenType
}

export default function Topbar({
  children,
  redirectToAfterSignIn,
  rightContent,
}: Props) {
  const { isPhone } = useSelectViewport()
  const navigate = useNavigate()
  const { data } = useMyUser()

  const redirectTo = redirectToAfterSignIn || window.location.href

  const handleLogout = async () => {
    await logoutUser()
    navigate("/")
  }

  return (
    <div
      className={cn(
        "h-[45px] flex flex-none items-center justify-between px-3 py-8 select-none",
        "sm:h-[45px] sm:px-[10px]",
      )}
    >
      <div className="flex items-center w-[650px] shrink-1">
        <img
          className={cn("h-[32px] mr-4", "sm:hidden")}
          src="/assets/images/logo-3data-box.svg"
          alt="clouldcom"
        />
      </div>

      <div className="w-fit shrink-0 flex items-center px-4 sm:flex-1 sm:justify-center">
        {children}
      </div>

      <div className="flex items-center justify-end w-[650px]">
        {rightContent}
        {!rightContent &&
          (!data ? (
            <Link
              className="hover:text-white"
              to={{ pathname: "/", search: `?redirectTo=${redirectTo}` }}
            >
              Sign In
            </Link>
          ) : (
            <a
              className="hover:text-white cursor-pointer"
              onClick={handleLogout}
            >
              <span className="sm:whitespace-nowrap">Log out</span>
            </a>
          ))}
      </div>
    </div>
  )
}
