import { ChildrenType } from "@/TYPES/common"
import cn from "classnames"

type Props = {
  className?: string
  children?: ChildrenType
}

export default function TileBody({ className, children }: Props) {
  return (
    <div className="relative pt-[56.25%]">
      <div
        className={cn(className, "absolute inset-0 bg-cobrowsegray overflow-hidden")}
      >
        {children}
      </div>
    </div>
  )
}
