import { AppType } from "@/TYPES/common"
import Loader from "@/components/Loader"
import { useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { toast } from "react-toastify"
import AppList from "./AppList"

type Props = {
  apps: AppType[]
  uploading: boolean
  onClickHandler: (event: any, app: AppType) => void
  onRemove: (app: AppType) => void
  onUpload: (files: any[]) => void
}

export default function TileList({
  apps,
  uploading,
  onClickHandler,
  onRemove,
  onUpload,
}: Props) {
  const onDrop = useCallback(
    async (files) => {
      try {
        await onUpload(files)
      } catch (e: any) {
        if (e.message !== "500") {
          toast.error(e.message)
        }
      }
    },
    [onUpload],
  )

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    onDrop,
    noClick: true,
  })

  return (
    <div
      className="relative w-full h-full flex flex-col gap-[10px]"
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {isDragAccept && (
        <div className="absolute inset-0 flex-center z-10 bg-secondarygray/50 text-22 rounded-lg">
          Drop a file here...
        </div>
      )}
      {uploading && (
        <Loader
          className="z-10 bg-gradient-gray2/60"
          message="Uploading..."
          size={80}
        />
      )}

      <AppList
        apps={apps}
        isFile
        onClickHandler={onClickHandler}
        onRemove={onRemove}
      />
    </div>
  )
}
