import Icon, { IconNameProp } from "@/components/Icon"
import cn from "classnames"

type Props = {
  active: boolean
  activeIcon: IconNameProp
  inactiveIcon: IconNameProp
  onClick: () => void
}

export default function ToggleButton({
  active,
  activeIcon,
  inactiveIcon,
  onClick,
}: Props) {
  return (
    <button
      className={cn("w-[60px] h-[60px] flex-center rounded-full", {
        "border border-white": active,
        "bg-orangyred": !active,
      })}
      onClick={onClick}
    >
      <Icon
        className="fill-white"
        iconName={active ? activeIcon : inactiveIcon}
        size={30}
      />
    </button>
  )
}
