import SectionHeader from "../components/SectionHeader"
import { MultiValue } from "react-select"
import Select from "@/components/Select"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { setEditEvent } from "@/store/slices/eventsSlice"
import { Event } from "@/TYPES/redux"
import $store from "@/store"

interface Participant {
  value: string
  label: string
}

// const participantOptions: MultiValue<Participant> = [
//   {
//     value: "User 1",
//     label: "User 1",
//   },
//   {
//     value: "User 2",
//     label: "User 2",
//   },
//   {
//     value: "User 3",
//     label: "User 3",
//   },
//   {
//     value: "User 4",
//     label: "User 4",
//   },
//   {
//     value: "User 5",
//     label: "User 5",
//   },
//   {
//     value: "User 6",
//     label: "User 6",
//   },
//   {
//     value: "User 7",
//     label: "User 7",
//   },
//   {
//     value: "User 8",
//     label: "User 8",
//   },
//   {
//     value: "User 9",
//     label: "User 9",
//   },
//   {
//     value: "User 10",
//     label: "User 10",
//   },
// ]

interface Props {
  editEvent: Event
  isDarkMode: boolean
}

export default function ParticipantSelect({ editEvent, isDarkMode }: Props) {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    window.env?.REACT_APP_API_SERVER ||
    window._env_?.REACT_APP_API_SERVER ||
    env.REACT_APP_API_SERVER ||
    import.meta.env.REACT_APP_API_SERVER

  const participantsApi = `${REACT_APP_API_SERVER}/user-list`
  // const initialParticipants: MultiValue<Participant> =
  //   editEvent.participants.map((participant) => {
  //     return { value: participant, label: participant }
  //   })
  const initialParticipants = editEvent.participants?.map((person) => {
    return {
      label: `${person.name} (${person.email})`,
      value: person,
    }
  })

  const [participants, setParticipants] = useState<MultiValue<Participant>>([])
  const [participantsOptions, setParticipantsOptions] = useState<
    MultiValue<Participant>
  >([])
  const dispatch = useDispatch()

  useEffect(() => {
    fetch(participantsApi)
      .then((res) => res.json())
      .then(({ data }) => {
        console.warn("data", data)
        const participantsList: MultiValue<Participant> = data.map((person) => {
          return {
            // value: person.name,
            value: person,
            // label: person.name,
            label: `${person.name} (${person.email})`,
          }
        })
        setParticipantsOptions(participantsList)
        setParticipants(initialParticipants)
      })
  }, [])

  const handleParticipantSelect = (
    selectedParticipants: MultiValue<Participant>,
  ) => {
    const newPartipants = selectedParticipants.map((p) => p.value)
    const newForm = { ...editEvent, participants: newPartipants }

    setParticipants(selectedParticipants)
    dispatch(setEditEvent(newForm))
  }
  console.log("participants array?", participants)
  return (
    <div className="w-full">
      <SectionHeader title="Participants" isDarkMode={isDarkMode} />
      <Select
        placeholder="Add required attendees"
        value={participants}
        isMulti={true}
        closeMenuOnSelect={true}
        options={participantsOptions}
        onChange={handleParticipantSelect}
      />
    </div>
  )
}
