import { ChildrenType, DraggingType } from "@/TYPES/common"
import cn from "classnames"
import { useEffect } from "react"
import { useDrag } from "react-dnd"
import { getEmptyImage } from "react-dnd-html5-backend"

type Props = {
  className?: string
  type: DraggingType
  item: any
  hidePreview?: boolean
  children?: ChildrenType
  disableDrag?: boolean
}

export default function DragZone({
  className,
  type,
  item,
  hidePreview = false,
  children,
  disableDrag = false,
}: Props) {
  const [{ isDragging }, ref, preview] = useDrag(
    () => ({
      type,
      item,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      //  canDrag: () => {
      //    if (typeof disableDrag !== "boolean") return true
      //    return !disableDrag
      //  },
      canDrag: !disableDrag,
    }),
    [disableDrag],
  )

  useEffect(() => {
    if (hidePreview) {
      preview(getEmptyImage(), { captureDraggingState: true })
    }
  }, [])

  return (
    <div
      ref={ref}
      className={cn(className, {
        "cursor-pointer": !disableDrag,
        "opacity-10": isDragging,
      })}
    >
      {children}
    </div>
  )
}
