import { Event } from "@/TYPES/redux"
import moment from "moment"
import $store from "@/store"
import {v4 as uuidv4 } from "uuid"

console.log({state: $store.getState()});

export const ICON_SIZE = 20

export const createRandomEvent = (date: Date) => {
  const uniqueId = `${Math.floor(Math.random() * 10000000000)}` // until proper backend is implemented
  const eventuuid = uuidv4()
  const userId = $store.getState().user.data?._id
  const endTime = moment(date).add(30, "minutes").toDate().toISOString()

  const newEvent: Event = {
    title: "",
    spaceId: undefined,
    spaceName: undefined,
    eventId: eventuuid,
    eventUUID: eventuuid,
    //  organizerId: "User 1",
    organizerId: userId,
    startTime: date.toISOString(),
   //  start: new Date(date).getTime(),
   //  end: new Date(endTime).getTime(),
    endTime,
    participants: [],
    agenda: "",
    allDay: false,
  }

  return { newEvent, eventuuid }
}
