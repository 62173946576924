import PlatformMain from "@/components/PlatformMain"
import { useSelectViewport } from "@/utils/hooks/viewport"
import cn from "classnames"
import { useState } from "react"
import PlatformLayout from "../PlatformLayout"
import AppList from "./AppList"
import MainTitle from "./MainTitle"
import Sidebar from "./Sidebar"
import { apps, sorts } from "./utils"

export default function Apps() {
  const { isPhone } = useSelectViewport()

  const [query, setQuery] = useState("")
  const [sort, setSort] = useState(sorts[0])

  return (
    <PlatformLayout query={query} title="Apps" onSearch={setQuery}>
      {!isPhone && <Sidebar />}
      <PlatformMain className="flex flex-col bg-smoke">
        {!isPhone && <MainTitle selected={0} />}
        <div className="relative flex-1">
          <div
            className={cn(
              "absolute inset-0 overflow-x-hidden overflow-y-auto flex flex-col p-[28px] gap-[28px]",
              "sm:p-[10px] sm:gap-[10px]",
            )}
          >
            <AppList title="Apps" apps={apps}>
              <select
                className={cn(
                  "w-[175px] h-[38px] px-2",
                  "text-concord text-18 text-center",
                  "outline-none border border-ghostgray bg-transparent rounded-sm",
                )}
                value={sort.label}
                onChange={(e) => {
                  const selectedItem = sorts.find(
                    (item) => item.label === e.target.value,
                  )

                  setSort(selectedItem ?? sorts[0])
                }}
              >
                {sorts.map((item) => (
                  <option key={item.label} value={item.label}>
                    {item.label}
                  </option>
                ))}
              </select>
            </AppList>
          </div>
        </div>
      </PlatformMain>
    </PlatformLayout>
  )
}
