import { SocketMessageType } from "@/TYPES/common"
import { RootState } from "@/TYPES/redux"
import CenteredLoader from "@/components/CenteredLoader"
import { SocketContext } from "@/context/socket"
import { updatePdfPage, usePortableDocumentAsset } from "@/helpers"
import { assignMainPDFRect } from "@/store/slices/pdfSlice"
import { useSelectViewport } from "@/utils/hooks/viewport"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { IconButton } from "@mui/material"
import cn from "classnames"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { Document, Page } from "react-pdf"
import { useDispatch, useSelector } from "react-redux"
import { useResizeDetector } from "react-resize-detector"
interface Props {
  isMain: boolean
  active?: boolean
  tile: any
}

export default function PDFTile({ isMain, active, tile }: Props) {
  const socket = useContext(SocketContext)
  const dispatch = useDispatch()
  const [pdfData, setPdfData] = useState<any>()
  const [numPages, setNumPages] = useState<number>()
  const [src, setSrc] = useState<any>()
  // const [retryCount, setRetryCount] = useState<number>(0)
  // const maxRetries = 5
  const pdf_canvas_ref = useRef<HTMLCanvasElement>(null)

  const [loading, setLoading] = useState(true)
  const [pageWidth, setPageWidth] = useState(0)
  const [pageHeight, setPageHeight] = useState(0)
  const {
    width: wrapperWidth,
    height: wrapperHeight,
    ref: wrapperRef,
  } = useResizeDetector()

  const tileId = tile?._id
  const teamId = useSelector((state: RootState) => state.room.data?.teamId)
  const roomId = useSelector((state: RootState) => state.room.data?._id)
  const { isPhone } = useSelectViewport()

  const isLarge = useSelector((state: RootState) => {
    const largeTiles = state.room.data?.scene2d?.dataTile00
    const item = tile
    if (!largeTiles || !Array.isArray(largeTiles) || !item) return false
    const found = largeTiles.find((tile: any) => tile._id === item._id)
    return !!found
  })
  const pageNum = useSelector((state: RootState) => {
    const pdfTracker = state.view.pdfTracker
    if (!tile) return 1
    if (!pdfTracker || !tileId) return tile.currPage
    const tracker = pdfTracker[tileId]
    if (!tracker) return tile.currPage
    return tracker.page
  })
  const permissionLevel = useSelector(
    (state: RootState) => state.permission.level,
  )
  const isOwner = permissionLevel === "owner"

  const pdf = usePortableDocumentAsset(tile.teamId, tile.roomId, tile.fileName)

  const handleResize = useCallback(
    (tmpWidth, tmpHeight) => {
      // calculated pdf page width/height based on current wrapper size and page ratio
      if (!tmpHeight || !wrapperHeight) {
        const width = wrapperWidth || 0
        const height = 0
        setPageWidth(width)
        setPageHeight(height)
        return
      } else if (!tmpWidth || !wrapperWidth) {
        const height = wrapperHeight || 0
        const width = 0
        setPageHeight(height)
        setPageWidth(width)
        return
      }

      const pageRatio = tmpWidth / tmpHeight
      if (wrapperWidth / wrapperHeight > pageRatio) {
        setPageHeight(wrapperHeight)
        const newWidth = wrapperHeight * pageRatio
        setPageWidth(newWidth)
      } else {
        setPageWidth(wrapperWidth)
        const newHeight = wrapperWidth / pageRatio
        setPageHeight(newHeight)
      }
    },
    [wrapperHeight, wrapperWidth],
  )

  useEffect(() => {
    // detect wrapper resize, recalculate pdf page size
    handleResize(pageWidth, pageHeight)
  }, [wrapperWidth, wrapperHeight])

  useEffect(() => {
    // For magic marker sizing //
    const pdfCanvasEl = pdf_canvas_ref.current
    if (isMain && pdfCanvasEl) {
      const rect = pdfCanvasEl.getBoundingClientRect()
      const rectSerialized = JSON.parse(JSON.stringify(rect))
      dispatch(assignMainPDFRect(rectSerialized))
    }
  }, [pageWidth, pageHeight, wrapperHeight, wrapperWidth])

  let title = tile.fileName.split(".")
  // remove file extension
  title.pop()
  title = title.join("")

  const onDocumentLoadSuccess = (pdf) => {
    setNumPages(pdf.numPages)
  }

  const onPageLoadSuccess = (page) => {
    if (loading) {
      handleResize(page.width, page.height)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (pdf?.data && !pdfData) {
      if ((pdf.data as any).detached) {
        // Somehow this fixes detach arraybuffer error.
        return
      }
      // Clone PDF data before setting to state
      // For some reason, setting state to pdf.data directly (without making a copy)
      // ...will occasionally result in the ArrayBuffer becoming detached
      const pdfDataClone = pdf.data.slice(0)
      setPdfData(pdfDataClone)
    }
  }, [pdf])

  const getErrorComp = () => (
    <div>
      <pre>{JSON.stringify(pdf, null, 3)}</pre>
      <hr />
      <div>src</div>
      <pre>{JSON.stringify(src, null, 3)}</pre>
    </div>
  )

  const changePage = (offset) => {
    const newPageNum = pageNum + offset

    socket.emit(SocketMessageType.PdfPageChange, {
      newPage: newPageNum,
      pdfId: tile._id,
      fileName: tile.fileName,
    })
    updatePdfPage(teamId, roomId, tile.fileName, newPageNum)
  }

  if (!pdfData) return <CenteredLoader />

  return (
    <div
      ref={wrapperRef}
      id={getPDFTileElId(tile._id)}
      className={cn("relative h-full w-full flex items-center justify-center", {
        "overflow-auto": isMain,
        "overflow-hidden": !isMain,
      })}
    >
      <Document
        className="bg-transparent"
        file={pdfData}
        onLoadSuccess={onDocumentLoadSuccess}
        error={getErrorComp()}
        loading={<CenteredLoader />}
      >
        <Page
          className="!bg-transparent"
          pageNumber={pageNum}
          loading={<CenteredLoader />}
          width={pageWidth}
          onLoadSuccess={onPageLoadSuccess}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          canvasRef={pdf_canvas_ref}
        />
        {isOwner && active && (numPages as number) > 1 && (
          <div
            className={cn(
              "absolute inset-0 flex flex-row justify-between items-center z-[1] select-none",
              {
                "opacity-0 hover:opacity-100": !isPhone,
                "transition-opacity delay-[3s] hover:delay-0": !isPhone,
              },
            )}
          >
            <IconButton
              className="!bg-white/50"
              disabled={pageNum <= 1}
              onClick={() => changePage(-1)}
              aira-label="previous"
              size="large"
              color="default"
            >
              <ArrowBackIosIcon
                className="translate-x-[6px]"
                fontSize="large"
              />
            </IconButton>
            <IconButton
              className="!bg-white/50"
              disabled={pageNum >= (numPages as number)}
              onClick={() => changePage(1)}
              aira-label="next"
              size="large"
              color="default"
            >
              <ArrowForwardIosIcon fontSize="large" />
            </IconButton>
          </div>
        )}
      </Document>
    </div>
  )
}

function getPDFTileElId(tileId: string) {
  if (!tileId) return
  return `pdf-tile-${tileId}`
}
