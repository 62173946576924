import $store from "@/store"
import useSWR from "swr"
import { fetcher } from "./fetcher"

export function useRoom(id: any, autoUpdateState = false) {
  const result = useSWR(`/api/rooms/${id}`, fetcher, {
    revalidateOnFocus: false,
  })
  const { data, error } = result

  const scene2d = data?.scene2d

  if (id && data && scene2d) {
    const largeTiles = scene2d.dataTile00
    const largeTile = Array.isArray(largeTiles) ? largeTiles[0] : null
    const smallTiles = scene2d.dataTile01
    const smTilesValid = smallTiles.filter(Boolean)
    scene2d.dataTile01 = smTilesValid
    if (autoUpdateState) {
      $store.dispatch({
        type: "room/updateRoom",
        payload: data,
      })

      $store.dispatch({
        type: "space/assignMainApp",
        payload: largeTile,
      })
    }
  }

  return {
    data,
    isLoading: !data && !error,
    isError: error,
  }
}

export async function getRoom(id: string) {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER
  //   if (!$store || !$store.getState)
  //     return Promise.reject("Redux store not initiated")
  //   const state = $store.getState()
  //   const roomData = state.room?.data
  //   if (!roomData || !roomData._id) return Promise.reject("No room data")
  //   const roomId = id || roomData._id
  const roomId = id

  try {
    const response = await fetch(
      `${REACT_APP_API_SERVER}/api/rooms/${roomId}`,
      {
        method: "GET",
        credentials: "include",
      },
    )
    const data = await response.json()
    if (!data || !data._id || !data.scene2d) {
      const msg = `WTF?? No room data found for roomId: ${roomId}`
      console.error(msg)
      return Promise.reject(msg)
    }

    $store.dispatch({
      type: "room/updateRoom",
      payload: data,
    })
    return Promise.resolve(data)
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export function getMainTileType(largeTileOrData?: any) {
  //   const storeState = $store.getState()
  //   const largeTile = largeTileOrData?.scene2d
  //     ? largeTileOrData.scene2d.dataTile00?.[0]
  //     : storeState.room?.data?.scene2d?.dataTile00?.[0]
  const largeTile = largeTileOrData?.scene2d?.dataTile00?.[0]

  if (!largeTile) return null

  const type = largeTile.type
  const fileName = largeTile.fileName

  let detectedType = type

  const isFile = type === "file" || fileName?.endsWith(".pdf")
  const isImg =
    fileName?.endsWith(".png") ||
    fileName?.endsWith(".jpg") ||
    fileName?.endsWith(".jpeg") ||
    fileName?.endsWith(".gif")
  const isIframe = type === "iframe" || fileName === "iframe"
  const isRDP = fileName?.endsWith(".rdp")
  const isPDF = isFile && !isImg && !isRDP

  isIframe && (detectedType = "iframe")
  isFile && (detectedType = "file")
  isImg && (detectedType = "img")
  isRDP && (detectedType = "rdp")
  isPDF && (detectedType = "pdf") // Must be last after `isImg`, `isFile`, and `isRDP`.

  return detectedType
}
