import { AppType } from "@/TYPES/common"
import Icon from "@/components/Icon"
import { getIconNameProp } from "@/helpers"

type Props = {
  app: AppType
}

export default function IconTile({ app }: Props) {
  const fileName = app.fileName
  const iconName = getIconNameProp(fileName)

  return (
    <Icon
      iconName={iconName}
      wrapperClassName="!w-full !h-full"
      className="!w-full !h-full fill-accentyellow"
    />
  )
}
