import { Event } from "@/TYPES/redux"
import SectionHeader from "../components/SectionHeader"
import { useDispatch } from "react-redux"
import { setEditEvent } from "@/store/slices/eventsSlice"
import cn from "classnames"

interface Props {
  editEvent: Event
  isDarkMode: boolean
}

export default function TitleInput({ editEvent, isDarkMode }: Props) {
  const dispatch = useDispatch()

  const handleTitleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newForm: Event = { ...editEvent, title: e.target.value }
    dispatch(setEditEvent(newForm))
  }

  return (
    <div className="w-full">
      <SectionHeader title="Title" isDarkMode={isDarkMode} />
      <input
        className="w-full h-[38px] border-carbon border-[1px] rounded-sm outline-none px-2 py-1 text-carbon"
        value={editEvent.title}
        onChange={handleTitleInput}
        placeholder="Add Title"
      />
    </div>
  )
}
