import CenteredLoader from "@/components/CenteredLoader"
import { useImageAsset } from "@/helpers"

export default function ImgTile(props: any) {
  const item = props?.app || props?.tile

  const img: any = useImageAsset(item.teamId, item.roomId, item.fileName)

  if (!img || !img.data) return <CenteredLoader />

  const src = URL.createObjectURL(img.data)

  return (
    <div className="img-tile-wrapper">
      <img src={src} alt={item.title} />
    </div>
  )
}
