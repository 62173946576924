import { SocketMessageType } from "@/TYPES/common"
import { RootState } from "@/TYPES/redux"
import { getPopperProps } from "@/helpers"
import { NavItemType } from "@/pages/Space/Navigation/NavItems"
import { SocketContext } from "@/context/socket"
import { IconNameProp } from "@/components/Icon"
import NavItem from "@/pages/Space/Navigation/NavItem"
import {
  assignShowAnnotation,
  assignShowMenu,
} from "@/store/slices/annotationSlice"
import { switchViews } from "@/store/slices/spaceSlice"
import cn from "classnames"
import Tooltip from "@mui/material/Tooltip"
import { useContext, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

export default function AnnotateButton({ mainApp }) {
  const socket = useContext(SocketContext)
  const dispatch = useDispatch()
  const views = useSelector((state: RootState) => state.space.views)
  const user_id = useSelector((state: RootState) => state.user.data?._id)
  const myName = useSelector((state: RootState) => state.user.data?.name)
  const myEmail = useSelector((state: RootState) => state.user.data?.email)
  const permissionLevel = useSelector(
    (state: RootState) => state.permission.level,
  )
  const showAnnotation = useSelector(
    (state: RootState) => state.annotation.showAnnotation,
  )
  const annotationMode = useSelector((state: any) => state.annotation.mode)
  const shareScreenMode = useSelector(
    (state: RootState) => state.call.shareScreenMode,
  )

  const navItem = {
    key: "annotate",
    tooltip: `${
      showAnnotation || views.includes("calendar")
        ? "Stop Annotating"
        : "Annotate"
    }`,
    iconName: IconNameProp.Annotate,
    iconSize: 21,
    adminOnly: true,
    invisibleOnPhone: true,
    disabledOnFullScreen: true,
    customHandler: () => {
      const newShow = !showAnnotation
      console.log("customHandler()", {
        newShow: !showAnnotation,
        currentViews: views,
      })
      dispatch(switchViews("annotate"))
      dispatch(assignShowAnnotation(newShow))
      dispatch(assignShowMenu(newShow))
      socket.emit("magic-marker-toggle", {
        user_id,
        show: newShow,
        name: myName || myEmail || "Someone",
        mode: annotationMode,
      })
      const startOrEnd = newShow ? "started" : "ended"
      const triggerToastPayload: TriggerToastPayload = {
        type: "default",
        msg: `${myName || myEmail || "Someone"} ${startOrEnd} ${
          annotationMode === "magic" ? "annotation" : "whiteboard"
        }`,
        options: {
          toastId: `annotation-${startOrEnd}`,
          autoClose: 800,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "dark",
        },
      }
      socket.emit(SocketMessageType.TriggerToast, triggerToastPayload)
    },
    additionalDisabledCheck: () => {
      return views.includes("calendar") || !mainApp
    },
  }

  const isDisabled = (navItem: NavItemType) => {
    const disabled =
      (shareScreenMode && navItem.disabledOnFullScreen) ||
      (permissionLevel === "guest" && navItem.adminOnly)

    if (!disabled && typeof navItem.additionalDisabledCheck === "function") {
      const additionalChecker = navItem.additionalDisabledCheck
      const shouldDisable = additionalChecker()
      return shouldDisable ?? disabled
    }

    return disabled
  }
  let selected = views.includes(navItem.key)
  const itemDisabled = isDisabled(navItem)
  const tooltip = itemDisabled ? "" : navItem.tooltip

  return (
    <div>
      <Tooltip title={tooltip} placement="right" PopperProps={popperProps}>
        <span>
          <NavItem
            key={navItem.key}
            className="min-w-fit"
            iconName={navItem.iconName}
            iconSize={navItem.iconSize}
            iconClassName={cn("transition-color duration-300 mb-1", {
              "fill-smoke hover:fill-white cursor-pointer":
                !selected && !itemDisabled,
              "fill-accentyellow hover:opacity-60 cursor-pointer":
                selected && !itemDisabled,
              "!fill-accentyellow cursor-not-allowed": selected && itemDisabled,
              "fill-smoke cursor-not-allowed": !selected && itemDisabled,
            })}
            selected={selected}
            disabled={itemDisabled}
            onClick={() => {
              const customHandler = navItem?.customHandler
              if (customHandler && typeof customHandler === "function") {
                customHandler()
                return
              }
            }}
            navItem={navItem}
          />
        </span>
      </Tooltip>
    </div>
  )
}

const popperProps = getPopperProps({ offset: [0, -2] })
