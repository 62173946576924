import DropdownMenu from "@/components/DropdownMenu"
import Icon, { IconNameProp } from "@/components/Icon"
import { useSelectViewport } from "@/utils/hooks/viewport"
import cn from "classnames"

type Props = {
  selectedFiles: string[]
}

export default function FileActionbar({ selectedFiles }: Props) {
  const { isPhone } = useSelectViewport()

  const active = selectedFiles.length > 0

  return (
    <div className="h-[70px] flex items-center justify-between px-[12px] bg-smoke text-concord shadow border-b border-ghostgray">
      <div className="flex items-center">
        <span className="text-16 font-bold">
          {selectedFiles.length} files selected
        </span>
      </div>
      <div className="flex items-center">
        {!isPhone && (
          <>
            <div className="w-[50px] h-[50px] flex-center cursor-pointer">
              <Icon
                className={cn({
                  "fill-carbon": active,
                  "fill-concord": !active,
                })}
                iconName={IconNameProp.Edit}
                size={21}
              />
            </div>
            <div className="w-[50px] h-[50px] flex-center cursor-pointer">
              <Icon
                className={cn({
                  "fill-carbon": active,
                  "fill-concord": !active,
                })}
                iconName={IconNameProp.Trash}
                size={22}
              />
            </div>
            <div className="w-[50px] h-[50px] flex-center cursor-pointer">
              <Icon
                className={cn({
                  "fill-carbon": active,
                  "fill-concord": !active,
                })}
                iconName={IconNameProp.Send}
                size={24}
              />
            </div>
          </>
        )}

        {isPhone && (
          <DropdownMenu
            className="p-3"
            menuItems={[
              { label: "Edit", onClick: () => {} },
              { label: "Delete", onClick: () => {} },
              { label: "Send", onClick: () => {} },
            ]}
          >
            <Icon
              className={cn({
                "fill-carbon": active,
                "fill-concord": !active,
              })}
              iconName={IconNameProp.Options}
              size={21}
            />
          </DropdownMenu>
        )}
      </div>
    </div>
  )
}
