import { AppType, DraggingType } from "@/TYPES/common"
import { RootState } from "@/TYPES/redux"
import { DragZone, DropZone } from "@/components/DragAndDrop"
import { updateTiles } from "@/helpers"
import $store from "@/store"
import { changeSmallTilesOrder } from "@/store/slices/roomSlice"
import cn from "classnames"
import { useDispatch, useSelector } from "react-redux"
import SmallTileHeader from "./SmallTileHeader"
import Tile from "./Tile"

type Props = {
  apps: AppType[]
  isFile?: boolean
  onClickHandler: (event: any, app: AppType) => void
  onRemove: (app: AppType) => void
}

export default function AppList({
  apps,
  isFile = false,
  onClickHandler,
  onRemove,
}: Props) {
  const dispatch = useDispatch()
  const roomId = useSelector(
    (state: RootState) => state.room.data?._id || state.space.spaceId,
  )
  const hotkeysEnabled = useSelector(
    (state: RootState) => state.space.hotkeysEnabled,
  )
  const mainAppId = useSelector((state: RootState) => {
    const dataTile00 = state.room.data?.scene2d?.dataTile00
    const app = Array.isArray(dataTile00) ? dataTile00[0] : null
    return app?._id
  })

  const handleDrop = (source: any, target: any) => {
    if (!source?._id || !roomId) return
    if (source._id === target._id) return

    dispatch(changeSmallTilesOrder({ from: source._id, to: target._id }))

    const scene2d = $store.getState().room.data?.scene2d
    if (!scene2d) return

    const newSmallTiles = scene2d.dataTile01
    const newLargeTiles = scene2d.dataTile00

    updateTiles(
      roomId,
      JSON.parse(JSON.stringify(newSmallTiles)),
      JSON.parse(JSON.stringify(newLargeTiles)),
    )
  }

  const appAndFileList = apps.map((app) => {
    const fileName = app.fileName

    const isFile =
      fileName?.endsWith(".pdf") ||
      fileName?.endsWith(".png") ||
      fileName?.endsWith(".jpg") ||
      fileName?.endsWith(".jpeg") ||
      fileName?.endsWith(".gif")
    const isRDP = !!fileName?.endsWith(".rdp")

    return { ...app, isFile, isRDP }
  })

  return (
    <>
      {appAndFileList.map((app: any, i) => (
        <div
          key={app._id}
          className="relative flex items-stretch pl-5"
          onClick={(event) => onClickHandler(event, app)}
        >
          <div
            className={cn(
              "absolute top-0 left-0 h-[36px] flex items-center text-13",
              "transition-opacity",
              {
                "text-accentyellow opacity-100": hotkeysEnabled,
                "opacity-50": !hotkeysEnabled,
              },
            )}
          >
            {i + 1}
          </div>

          <DropZone
            className={cn(
              "flex-1 overflow-hidden shrink-0",
              "bg-cobrowsegray border-[0.5px] rounded-sm",
              "transition duration-500",
              {
                "border-cloudy hover:border-white hover:shadow-sm hover:shadow-white":
                  app._id !== mainAppId,
                "border-accentyellow shadow-tile shadow-sm shadow-accentyellow":
                  app._id === mainAppId,
              },
            )}
            type={DraggingType.App}
            onDrop={(item) => handleDrop(item, app)}
          >
            <DragZone
              className="flex flex-col items-center"
              type={DraggingType.App}
              item={app}
              hidePreview
            >
              <SmallTileHeader
                app={app}
                isMain={mainAppId === app._id}
                isFile={app.isFile}
                isRDP={app.isRDP}
                onRemove={onRemove}
              />
              <div className="w-full flex pb-[10px] px-[7px]">
                <div className="w-full">
                  <Tile app={app} />
                </div>
              </div>
            </DragZone>
          </DropZone>
        </div>
      ))}
    </>
  )
}
