import Tippy from "@tippyjs/react"
import { followCursor } from "tippy.js"
import { RootState } from "@/TYPES/redux"
import { useContext, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

export default function TippyComp() {
  const { visible, content, placement } = useSelector(
    (state: RootState) => state.tippy,
  )

  return visible ? (
    <Tippy
      visible={visible}
      content={content}
      followCursor={true}
      plugins={[followCursor]}
      placement={placement}
      animation="shift-away"
    ></Tippy>
  ) : null
}
