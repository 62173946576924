import { Switch } from "@mui/material"
import cn from "classnames"

interface Props {
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  isDarkMode: boolean
}

export default function AllDaySwitch({ checked, onChange, isDarkMode }: Props) {
  return (
    <div className="flex gap-1 items-center">
      <Switch checked={checked} onChange={onChange} />
      <div
        className={cn({
          "text-carbon": !isDarkMode,
          "text-smoke": isDarkMode,
        })}
      >
        All Day
      </div>
    </div>
  )
}
