import { SocketContext } from "@/context/socket"
import { memo, useContext, useEffect, useState } from "react"
import { useSelector } from "react-redux"
// import { fetchExternalApi } from '@jitsi/web-sdk';
import { JitsiMeeting } from "@jitsi/react-sdk"
// import { fetchExternalApi } from '@jitsi/react-sdk';
import { SocketMessageType } from "@/TYPES/common"
import { RootState } from "@/TYPES/redux"
import {
  assignAudioMuted,
  assignVideoMuted,
  updateJitsiKey,
  updateRecording,
  updateShareScreenMode,
  updateShareScreenModeSelf,
  updateShowParticipants,
} from "@/store/slices/callSlice"
import { useSelectViewport } from "@/utils/hooks/viewport"
import { useDispatch } from "react-redux"

export default function JitsiVideo(props: any) {
  const dispatch = useDispatch()
  const socket: any = useContext(SocketContext)
  const { isPhone } = useSelectViewport()
  const user = useSelector((state: RootState) => state.user.data)
  const showVid = useSelector((state: RootState) => state.call.show)
  const { shareScreenMode, shareScreenModeSelf } = useSelector(
    (state: RootState) => state.call,
  )
  const views = useSelector((state: RootState) => state.space.views)
  const broadUser = useSelector((state: RootState) => state.user)
  const broadGuest = useSelector((state: RootState) => state.guest)
  const userJoins = useSelector((state: RootState) => state.socket)
  const roomOwner_id = useSelector((state: RootState) => state.room.data?._id)

  // const videoStyle = props.videoStyle;
  // const jitsiKey = useSelector(state => state.video.jitsiKey);

  const jitsiDomain =
    useSelector((state: RootState) => state.env.REACT_APP_JITSI_DOMAIN) ||
    import.meta.env.REACT_APP_JITSI_DOMAIN
  const defaultMode =
    useSelector((state: RootState) => state.env.REACT_APP_JITSI_DEFAULT) ||
    import.meta.env.REACT_APP_JITSI_DEFAULT

  const videoState = useSelector((state: RootState) => state.call)
  const videoResizable = useSelector((state: RootState) => state.call.resizable)
  const {
    show,
    hovering,
    fullscreen,
    width: videoWidth,
    minWidth: videoMinWidth,
    maxWidth: videoMaxWidth,
    resize,
  } = videoState

  const isGuest = useSelector(
    (state: RootState) => state.permission.level === "guest",
  )

  const queryParameters = new URLSearchParams(window.location.search)
  const videoParam = queryParameters.get("video")
  //   const videoEnabled = videoParam === "true"
  const audioParam = queryParameters.get("audio")
  //   const audioEnabled = audioParam === "true"
  const videoEnabled = useSelector((state: RootState) => !state.call.videoMuted)
  const audioEnabled = useSelector((state: RootState) => !state.call.audioMuted)

  const videoMuted = useSelector((state: RootState) => state.call.videoMuted)
  const audioMuted = useSelector((state: RootState) => state.call.audioMuted)

  const [style, setStyle] = useState({})

  const email = user?.email || ""
  const guestFullName = window.sessionStorage.getItem("guestName")

  // TODO: a workaround for now with respect to name displays, a bit safer and more consistent
  const displayName = user?.name ? user.name : `${guestFullName} (Guest)`
  const pathArray = window.location.pathname.split("/")
  const roomID = pathArray[2]
  const roomName = roomID
  const userInfo = { email, displayName }

  async function resolveJitsiAudio(jApi?: any) {
    console.log("resolveJitsiAudio()")
    const api = jApi || window.jitsi
    if (!api) return
    try {
      const actualJitsiAudioState = await api.isAudioMuted()
      if (actualJitsiAudioState === audioMuted) return
      api.executeCommand("toggleAudio")
    } catch (err) {
      console.error("Error accessing media devices.", err)
    }
  }

  useEffect(() => {
    resolveJitsiAudio()
  }, [audioMuted])

  useEffect(() => {
    const interval = setInterval(() => {
      window.jitsi?.getContentSharingParticipants().then((res) => {
        // console.warn("***RES", res)
        const sharingScreen = res.sharingParticipantIds.length > 0
        if (sharingScreen !== shareScreenMode) {
          dispatch(updateShareScreenMode(sharingScreen))
        }
      })
    }, 500)
    return () => clearInterval(interval)
  }, [shareScreenMode])

  useEffect(() => {
    window.addEventListener("resize", forceUpdateJitsiKey)

    socket.on(SocketMessageType.VideShareScreen, (payload) => {
      console.log("video-share-screen", payload)
      const event = payload.event
      // const userInfo = payload.userInfo;

      const isSharing = event.on
      console.log("sharing event", event)
      if (shareScreenMode === isSharing) return

      dispatch(updateShareScreenMode(isSharing))
      // const displayName = userInfo.displayName;
      // const email = userInfo.email;
      forceUpdateJitsiKey()

      if (!isSharing) {
        console.warn("NOT SHARING SCREEN ANYMORE")
        window.clearInterval(window.screenShareInterval)
        return
      }
      const INTERVAL_FREQUENCY = 500
      window.screenShareInterval = window.setInterval(() => {
        console.warn("UPDATING JITSI DURING SCREEN SHARE")
        dispatch(updateJitsiKey(null))
      }, INTERVAL_FREQUENCY)
    })

    return () => {
      socket.off(SocketMessageType.VideShareScreen)
    }
  }, [shareScreenMode])

  function jitsiOnApiReady(api) {
    window.jitsi = api
    //!! TEST !!//
    ;(async () => {
      function getAllPropertyNames(o) {
        let propertyNames = []

        for (
          let proto = o;
          proto !== null;
          proto = Object.getPrototypeOf(proto)
        ) {
          propertyNames = propertyNames.concat(
            // @ts-ignore
            Object.getOwnPropertyNames(proto),
          )
        }

        return propertyNames
      }
      try {
        console.log("------------------------")
        console.dir(api)
        console.log("api reflect own keys", Reflect.ownKeys(api))
        console.log("own props", Object.getOwnPropertyNames(api))
        console.log("own props", getAllPropertyNames(api))
        //   const supportedCommands = await api.getSupportedCommands()
        //   console.log("supportedCommands", supportedCommands)
        const roomsInfo = await api.getRoomsInfo()
        console.log("roomsInfo", roomsInfo)
        const participantsInfo = await api.getParticipantsInfo()
        console.log("participantsInfo", participantsInfo)
        const contentSharingParticipants =
          await api.getContentSharingParticipants()
        console.log("contentSharingParticipants", contentSharingParticipants)
        const deviceChangeInfo = await api.isDeviceChangeAvailable()
        console.log("isDeviceChangeAvailable", deviceChangeInfo)
        const isAudioAvailable = await api.isAudioAvailable()
        console.log("isAudioAvailable", isAudioAvailable)
        const isAudioMuted = await api.isAudioMuted()
        console.log("isAudioMuted", isAudioMuted)
        const listeners = await api.listeners()
        console.log("api.listeners", listeners)
        const iframe = api.getIFrame()
        console.log("iframe", iframe)
        const deploymentInfo = await api.getDeploymentInfo()
        console.log("deploymentInfo", deploymentInfo)
      } catch (error) {
        console.error(error)
      }
    })()
    //!!  !!//

    api.addListener("videoMuteStatusChanged", (evt) => {
      console.warn("videoMuteStatusChanged", evt)
      dispatch(assignVideoMuted(evt.muted))
    })
    api.addListener("audioMuteStatusChanged", async (evt) => {
      console.log("audioMuteStatusChanged", evt)
    })
    //  dispatch(assignJitsiAPI(api)) // Doesn't work!
    //  dispatch(updateJitsi(api))
    // if (window.jitsi) return; // Just to prevent multiple calls.

    // https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-iframe-commands/
    // api.executeCommand('toggleLobby', false);
    // api.executeCommand('toggleTileView');
    api.addListener("recordingStatusChanged", (evt) => {
      console.log("recordingStatusChanged", evt)
      dispatch(updateRecording(evt.on))
    })

    api.executeCommand("setTileView", true)
    //!! Temp fix, retain jitsi tile view upon loading for couple of seconds because it keeps exiting tile view mode !!//
    window.retainTileViewOnLoadTemp = true
    window.setTimeout(() => (window.retainTileViewOnLoadTemp = false), 5000)

    // api.executeCommand('setTileView', true);
    // https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-iframe-events#screensharingstatuschanged
    api.addListener("screenSharingStatusChanged", (event) => {
      dispatch(updateShareScreenModeSelf(event.on))
      socket.emit(SocketMessageType.VideShareScreen, {
        userInfo,
        event,
      })
    })
    api.addListener("participantsPaneToggled", (event) => {
      const open = event.open
      dispatch(updateShowParticipants(open))
    })
    api.addListener("tileViewChanged", (event) => {
      const enabled = event.enabled

      if (window.retainTileViewOnLoadTemp && !enabled) {
        api.executeCommand("setTileView", true)
      }
    })
    api.addListener("audioAvailabilityChanged", (event) => {
      console.log("audioAvailabilityChanged", event)
    })
    api.addListener("participantJoined", (event) => {
      console.log("participantJoined", event)
    })
    api.addListener("p2pStatusChanged", (event) => {
      console.log("p2pStatusChanged", event)
    })
    api.addListener("peerConnectionFailure", (event) => {
      console.log("peerConnectionFailure", event)
    })
    api.addListener("suspendDetected", (event) => {
      console.log("suspendDetected", event)
    })

    // if (defaultMode === "participants") {
    //   api.executeCommand("toggleParticipantsPane", true)
    // }
  }

  function forceUpdateJitsiKey(timeout: any = 50) {
    if (!videoResizable) return
    if (window.forceUpdateJitsiKeyTimeoutId) {
      window.clearTimeout(window.forceUpdateJitsiKeyTimeoutId)
    }

    const forceUpdateJitsiKeyTimeoutId = setTimeout(() => {
      dispatch(updateJitsiKey(null))
    }, timeout)

    window.forceUpdateJitsiKeyTimeoutId = forceUpdateJitsiKeyTimeoutId
  }

  return (
    <div
      // id="webexVideoTile"
      className="relative block w-full h-full transition-width z-[100]"
    >
      {videoResizable && (
        <div
          id="webex_video_resize_bar"
          className="absolute left-0 top-0 h-full w-[6px] ml-[-3px] z-[1] cursor-e-resize"
          // onMouseDown={onMouseDown}
          // onMouseUp={onMouseUp}
        />
      )}
      {/* https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-iframe/#creating-the-jitsi-meet-api-object */}
      <JitsiMeeting
        domain={jitsiDomain}
        roomName={roomName}
        configOverwrite={{
          connectionIndicators: {
            disabled: true,
          },
          // ...jitsi_config,
          apiLogLevels: ["error"],
          logging: {
            defaultLogLevel: "error",
          },
          breakoutRooms: {
            hideAddRoomButton: true,
          },
          hideConferenceSubject: true,
          hideParticipantsStats: true,
          prejoinConfig: {
            enabled: false,
          },
          p2p: {
            enabled: false,
          },
          watermark: false,
          defaultLogoUrl: null,
          disableTileView: true,
          startWithVideoMuted: !videoEnabled,
          startWithAudioMuted: !audioEnabled,
          // toolbarButtons: [
          //   // "camera",
          //      'chat',
          //   // "closedcaptions",
          //   // "desktop", // screen share
          //   // "download",
          //   //    'embedmeeting',
          //   // "etherpad",
          //   //    'feedback',
          //   //    'filmstrip',
          //   // "fullscreen",
          //   //    'hangup',
          //   // 'help',
          //   // "highlight",
          //   // 'invite',
          //   //    'linktosalesforce',
          //   //    'livestreaming',
          //   // "microphone",
          //   // "noisesuppression",
          //   // 'profile',
          //   // "raisehand",
          //   // "recording",
          //   //    'security',
          //   // "select-background",
          //   // "settings",
          //   //    'shareaudio',
          //   //    'sharedvideo',
          //   // "shortcuts",
          //   // "stats",
          //   "tileview",
          //   // "toggle-camera",
          //   // "videoquality",
          //   // "whiteboard",
          //   // ...(isPhone ? ["participants-pane"] : []),
          //   "participants-pane",
          //   "__end",
          // ],
          toolbarButtons: [],
          //  whiteboard: {
          //    enabled: true,
          //    collabServerBaseUrl: "http://localhost:8282",
          //  },
          remoteVideoMenu: {
            disableGrantModerator: false,
          },
          disableDeepLinking: true,
          deeplinking: {
            disabled: true,
          },
        }}
        interfaceConfigOverwrite={{
          // ...jitsi_interface_config,
          TILE_VIEW_MAX_COLUMNS: 5,
          SHOW_JITSI_WATERMARK: false,
          JITSI_WATERMARK_LINK: "",
          DEFAULT_BACKGROUND: "#282828",
          TOOLBAR_TIMEOUT: 1000,
        }}
        userInfo={userInfo}
        // @ts-ignore
        prejoinPageEnabled={false}
        style={{ width: "100%", height: "100%" }}
        onApiReady={jitsiOnApiReady}
      />

      {!resize && (
        <div
          id="video_resize_overlay"
          className="opacity-10 fixed insect-0 bg-gray z-[100]"
          // onMouseUpCapture={onMouseUp}
          // onMouseMoveCapture={onMouseMove}
        />
      )}
    </div>
  )
}

export const JitsiVideoMemo = memo(JitsiVideo)
