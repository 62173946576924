import { RootState } from "@/TYPES/redux"
import { getPopperProps } from "@/helpers"
import Icon, { IconNameProp } from "@/components/Icon"
import Tooltip from "@mui/material/Tooltip"
import { toast } from "react-toastify"
import { useCallback } from "react"
import { useSelector } from "react-redux"

export default function SpaceDetails() {
  const roomData = useSelector((state: RootState) => state.room.data)
  const host = roomData?.name?.replace(/'s\s+\w+$/, "")
  const spaceUrl = window.location.origin + window.location.pathname

  const copyUrl = useCallback(() => {
    navigator.clipboard.writeText(spaceUrl)
    toast.success("Copied to clipboard!")
  }, [])

  const title = (
    <>
      <div className="text-14 text-smoke mb-2">{roomData?.name}</div>
      <div className="flex space-x-8 text-sm">
        <div className="w-1/5 text-typegray whitespace-nowrap">
          <div className="flex flex-col gap-y-2">
            <p>Encryption</p>
            <p>Host</p>
            <p>Invite Link</p>
          </div>
        </div>
        <div className="w-4/5 text-smoke">
          <div className="flex flex-col gap-y-2">
            <p className="whitespace-nowrap">Secure 256 Bit SSL Encryption</p>
            <p>{host}</p>
            <div className="flex justify-center items-center">
              <div className="truncate">{spaceUrl}</div>
              <div
                className="group cursor-pointer flex gap-1 -mt-1 text-accentyellow px-2 pt-1"
                onClick={copyUrl}
              >
                <Icon
                  className="fill-accentyellow group-hover:fill-smoke mt-1"
                  iconName={IconNameProp.Link}
                  size={14}
                />
                <span className="group-hover:text-white text-xs">Copy</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

  if (!roomData) return null
  return (
    <div>
      <Tooltip
        title={title}
        placement="right-start"
        leaveDelay={500}
        PopperProps={popperProps}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "rgb(40, 40, 40, 0.8) !important",
              color: "#ffffff",
              boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.3)",
              borderRadius: "0.125rem",
              "& .MuiTooltip-arrow": {
                color: "#282828",
              },
              maxWidth: "max-content",
              padding: "1rem",
            },
          },
        }}
      >
        <div>
          <Icon
            className="fill-accentgreen cursor-pointer opacity-100 hover:opacity-80"
            iconName={IconNameProp.Shield}
            size={26}
          />
        </div>
      </Tooltip>
    </div>
  )
}

const popperProps = getPopperProps({ offset: [-9, -4] })
