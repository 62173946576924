import { SocketMessageType } from "@/TYPES/common"
import { RootState } from "@/TYPES/redux"
import { SocketContext } from "@/context/socket"
import { togglePresenterMode } from "@/store/slices/spaceSlice"
import Icon, { IconNameProp } from "@/components/Icon"
import { NavItems } from "@/pages/Space/Navigation/NavItems"
import VisitAdmin from "@/pages/Space/Navigation/VisitAdmin"
import CallControls from "@/pages/Space/Navbar/CallControls"
import { getPopperProps } from "@/helpers"
import { leaveCall } from "@/utils/webex-controls"
import { Tooltip } from "@mui/material"
import cn from "classnames"
import { useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

export default function NavContent() {
  const socket = useContext(SocketContext)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const views = useSelector((state: RootState) => state.space.views)
  const spaceId = useSelector((state: RootState) => state.space.spaceId)
  const isOwner = useSelector((state: RootState) => state.user.owner)
  const name = useSelector((state: RootState) => state.user.name)
  const role = useSelector((state: RootState) => state.user.data?.role)

  // Emit event when owner leaves
  const cleanUp = async () => {
    const toggle = false
    if (isOwner) {
      const payload = {
        toggle,
        name,
        presenter_id: spaceId,
      }
      socket.emit(SocketMessageType.PresenterMode, payload)
      socket.emit(SocketMessageType.DemoMode, toggle)
    }
    dispatch(togglePresenterMode(toggle))
  }

  const handleLeave = async () => {
    await cleanUp()
    await leaveCall(spaceId, "")
    navigate(`/lobby/${spaceId}`)
  }

  return (
    <div className={cn("flex h-full w-full justify-between items-center")}>
      <div className="flex items-center gap-1 mb-1 mr-3">
        <CallControls />
      </div>
      <div className="flex items-center gap-2">
        <NavItems />
      </div>
      <div className="flex items-center">
        <Tooltip title="Leave Space" PopperProps={popperProps}>
          <button
            className={cn(
              "overflow-hidden w-[30px] h-[30px] flex-center bg-accentred text-white rounded-sm mb-2 hover:opacity-60",
            )}
            onClick={handleLeave}
          >
            <Icon
              className="fill-white"
              iconName={IconNameProp.HangUp}
              size={22}
            />
          </button>
        </Tooltip>
      </div>
    </div>
  )
}

const popperProps = getPopperProps({ offset: [0, -8] })
