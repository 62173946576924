import DropdownMenu from "@/components/DropdownMenu"
import Icon, { IconNameProp } from "@/components/Icon"
import { useSelectViewport } from "@/utils/hooks/viewport"
import { defaultPopperProps } from "@/helpers"
import { Tooltip } from "@mui/material"
import cn from "classnames"

export type PropsType = {
  label?: string
  tooltip?: string
  iconName: IconNameProp
  iconSize?: number
  highlighted?: boolean
  selected: boolean
  disabled?: boolean
  options: { id: string; label: string; selected: boolean }[]
  anchorProps?: any
  transformProps?: any
  onClick: () => void
  onSelectOption: (value: any) => void
}

export default function ControlItem({
  label,
  tooltip = "",
  iconName,
  iconSize = 20,
  highlighted,
  selected,
  disabled,
  options,
  anchorProps,
  transformProps,
  onClick,
  onSelectOption,
}: PropsType) {
  const { isPhone } = useSelectViewport()

  const handleClick = () => !disabled && onClick()

  return (
    <div className="relative flex items-center">
      <Tooltip title={tooltip} PopperProps={defaultPopperProps}>
        <div
          className={cn(
            "group cursor-pointer",
            "relative w-[45px] h-full",
            "flex flex-col items-center justify-between pt-[4px]",
            {
              "z-50 opacity-80": highlighted,
            },
          )}
          onClick={handleClick}
        >
          <Icon
            className={cn("mb-3", {
              "fill-typegray group-hover:fill-white": selected && !disabled,
              "fill-accentred group-hover:opacity-60": !selected && !disabled,
              "fill-cloudy": disabled,
            })}
            iconName={iconName}
            size={iconSize}
          />
          <span
            className={cn(
              "w-full text-center text-9 leading-none whitespace-nowrap",
              "sm:text-9",
              {
                "group-hover:text-white": selected && !disabled,
                "text-accentred": !selected && !disabled,
                "text-cloudy": disabled,
              },
            )}
          >
            {label}
          </span>
        </div>
      </Tooltip>
      {options.length > 1 && !isPhone && (
        <div className="group absolute top-0 -right-[8px] h-full flex items-center">
          <DropdownMenu
            className="p-1 mb-1"
            menuItems={options.map((op) => ({
              label: (
                <div className="w-full flex items-center justify-between">
                  <span className="mr-4">{op.label}</span>
                  {op.selected && (
                    <Icon
                      className="fill-carbon"
                      iconName={IconNameProp.Check}
                      size={12}
                    />
                  )}
                </div>
              ),
              onClick: () => onSelectOption!(op),
            }))}
            anchorProps={anchorProps}
            transformProps={transformProps}
            styleOverrides={{
              "& .MuiButtonBase-root:hover": {
                color: "#fff",
              },
            }}
          >
            <Icon
              className={cn("rotate-90", {
                "fill-ghostgray group-hover:fill-white": options.length > 0,
                "fill-darkgray": options.length === 0,
              })}
              iconName={IconNameProp.Chevron}
              size={10}
            />
          </DropdownMenu>
        </div>
      )}
    </div>
  )
}
