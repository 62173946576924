import { useMyUser } from "@/helpers/user"
import { setEvents } from "@/store/slices/eventsSlice"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import $store from "@/store"
import { fetcher } from "@/helpers"
import { assignCalendarId } from "@/store/slices/userSlice"

export const useGetCalendar = () => {
  const { data } = useMyUser()
  const userId = data?._id

  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    window.env?.REACT_APP_API_SERVER ||
    window._env_?.REACT_APP_API_SERVER ||
    env.REACT_APP_API_SERVER ||
    import.meta.env.REACT_APP_API_SERVER
  const calendarApi = REACT_APP_API_SERVER + `/v2/calendar`

  const dispatch = useDispatch()

  useEffect(() => {
    if (!userId) {
      return
    }

    fetcher(`/v2/calendar?userId=${userId}`)
      .then((data) => {
        console.log('GET CAL IF NO DATA', data)
        if (!data.data || !data.data.events) {
          // initialize new calendar array
          // fetch(calendarApi, {
          //   method: "POST",
          //   body: JSON.stringify({ calendarData: [], userId }),
          // })
          dispatch(setEvents([]))
        } else {
          console.log('GET CAL DATA', data)
          // set database calendar events in redux store
          dispatch(setEvents(data.data.events))
          dispatch(assignCalendarId(data.data._id))
        }
      })
  }, [userId])
}

export async function getCalendar() {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    window.env?.REACT_APP_API_SERVER ||
    window._env_?.REACT_APP_API_SERVER ||
    env.REACT_APP_API_SERVER ||
    import.meta.env.REACT_APP_API_SERVER
  const calendarApi = REACT_APP_API_SERVER + `/v2/calendar`
  const permissionLevel = $store.getState().permission.level
  const isGuest = permissionLevel === "guest"
  if (isGuest) return Promise.reject("Guests cannot access calendar")

  const userId = $store.getState().user.data?._id
  if (!userId) return Promise.reject("Missing user _id")

  try {
    const response = await fetch(`${calendarApi}?user_id=${userId}`, {
      method: "GET",
      credentials: "include",
    })
    const result = await response.json()
    const calendarData = result?.data
    const calendarEvents = calendarData?.events || []
    $store.dispatch({
      type: "calendar/setEvents",
      payload: calendarEvents,
    })
    return Promise.resolve(calendarData)
  } catch (error: any) {
    console.error(error)
    return Promise.reject(error.message || error)
  }
}
