import { SocketMessageType } from "@/TYPES/common"
import { socketConnection as socket } from "@/context/socket"
import { getRoom } from "@/helpers"
import $store from "@/store"

export function getCobrowseBackendURL() {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_NEW_BACKEND_URL =
    env.REACT_APP_NEW_BACKEND_URL || import.meta.env.REACT_APP_NEW_BACKEND_URL

  if (!REACT_APP_NEW_BACKEND_URL) {
    const msg = "REACT_APP_BACKEND_URL not set"
    throw new Error(msg)
  }
  const backendURL = (
    REACT_APP_NEW_BACKEND_URL.endsWith("/")
      ? REACT_APP_NEW_BACKEND_URL
      : `${REACT_APP_NEW_BACKEND_URL}/`
  ).trim()
  const cobrowseURL = new URL("cobrowse", backendURL)
  return cobrowseURL.href
}

export function getCobrowseIDFromURL(urlString: string) {
  const url = new URL(urlString)
  const pathSegments = url.pathname.split("/").filter((segment) => segment)
  return pathSegments[pathSegments.length - 1]
}

// In theia, the func 'addVNC_old' was here, but it doesn't appear to be used anywhere so leaving it out for now

// export async function addVNC(param: {
//   roomId: string
//   fileName: string
//   title: string
//   url: string
// }) {
//   console.log("addVNC() called with param:", param)
//   try {
//     const { roomId, fileName, title, url } = param

//     // Create cobrowse in K8
//     const addURL = getCobrowseBackendURL()
//     const addBody = JSON.stringify({ url })
//     const addResponse = await fetch(addURL, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: addBody,
//     })
//     const addResult = await addResponse.json()
//    //  const { data: addData, status: addStatus, cobrowseURL } = addResult
//    const addStatus = addResult.status
//    const addData = addResult.data
//    const cobrowseURL = addData.cobrowseURL

//     if (!addStatus) {
//       throwError("addVNC() failed - status is FALSE", addResult)
//       throw addResult
//     }
//     if (!addData || !cobrowseURL) {
//       throwError("addVNC() failed - invalid data", addData)
//       throw addData
//     }

//     console.log("addVNC() successfully added to K8:", addData)

//     // Add cobrowse to db
//     const fetchURL = `${REACT_APP_API_SERVER}/cobrowse-data`
//     const body = JSON.stringify({
//       roomId,
//       src: cobrowseURL,
//       title,
//       fileName,
//     })

//     const dbResponse = await fetch(fetchURL, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body,
//     })
//     const dbResult = await dbResponse.json()
//     const {
//       data,
//       data: { value: newRoom },
//       status,
//     } = dbResult
//     if (!data || !status) {
//       throwError("addVNC() failed on 2nd fetch", dbResult)
//       throw dbResult
//     }
//     console.log("addVNC() successfully added to db:", newRoom)
//     return newRoom
//   } catch (err) {
//     throwError("addVNC() failed", err)
//   }
// }

interface AddVNCResult {
  status: boolean
  msg: string
  data?: {
    cobrowseURL: string
    deployment: any
    configmap: any
    service: any
    ingressroute: any
    middleware: any
  }
}

interface AddVNCParam {
  roomId: string
  fileName: string
  title: string
  url: string
  browser?: string
}

export function addVNC(param: AddVNCParam, callbacks?: any) {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER

  const browser = param.browser || "firefox"

  return new Promise(async (resolve, reject) => {
    if (!param || !param.roomId || !param.url) {
      console.error("addVNC failed - invalid param", param)
      reject("addVNC failed - invalid param")
      return
    }
    try {
      //@@ First create cobrowse in k8 @@//
      const addURL = getCobrowseBackendURL()
      const addResponse = await fetch(addURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          url: param.url,
          browser,
        }),
      })
      const addResult: AddVNCResult = await addResponse.json()
      if (!addResult.status) {
        reject(addResult)
        console.error("addVNC() failed - status is false", addResult)
        return
      }

      const addData = addResult.data
      if (!addData || !addData.cobrowseURL) {
        reject(addData)
        console.error("addVNC failed - invalid data", addData)
        return
      }
      console.log("addVNC add cobrowse - success", addData)
      const { cobrowseURL } = addData

      //@@ Then add cobrowse in DB @@//
      const dbResponse = await fetch(`${REACT_APP_API_SERVER}/cobrowse-data`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          roomId: param.roomId,
          src: cobrowseURL,
          title: param.title,
          fileName: param.fileName,
          browser,
        }),
      })
      const dbResult = await dbResponse.json()
      if (!dbResult.status || !dbResult.data) {
        reject(dbResult)
        console.error("addVNC failed on 2nd fetch result2", dbResult)
        return
      }
      const dbData = dbResult.data
      const newRoom = dbData.value
      console.log("addVNC() add database - success", newRoom)

      callbacks?.onSuccess?.()

      resolve(newRoom)
    } catch (error) {
      reject(error)
      console.error("addVNC() failed", error)
    } finally {
      getRoom(param.roomId)
      callbacks?.onFinally?.()
    }
  })
}

// export async function removeVNC(
//   param: {
//     roomId: string
//     tileId: string
//     url: string
//   },
//   cbs?: { [key: string]: () => void },
// ) {
//   try {
//     // Add something here to notify user re: VNC removal
//     // In theia, this is handled via SnackbarQueue
//     const { roomId, tileId, url } = param
//     const fetchURL = getCobrowseBackendURL()
//     const fetchBody = JSON.stringify({
//       url,
//       cobrowseId: tileId,
//     })

//     // Delete K8 resources
//     const response = await fetch(fetchURL, {
//       method: "DELETE",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: fetchBody,
//     })

//     const removeResourceResult = await response.json()
//     const { type, status } = removeResourceResult
//     // If resource exists but couldn't be deleted...
//     if (type !== "not-found") {
//       if (!response.ok || !status) {
//         // Add logic to notify user
//         throwError("removeVNC() failed", removeResourceResult)
//         throw removeResourceResult
//       }
//     }

//     console.log(
//       `VNC deployment successfully removed (...or never existed): ${removeResourceResult}`,
//     )

//     // Delete db data
//     const dbFetchURL = `${REACT_APP_API_SERVER}/cobrowse-data`
//     const dbBody = JSON.stringify({
//       tileId,
//       roomId,
//     })

//     const dbResponse = await fetch(dbFetchURL, {
//       method: "DELETE",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: dbBody,
//     })

//     if (!dbResponse.ok) {
//       throwError("removeVNC() failed to remove tile from db", dbResponse)
//       throw dbResponse
//     }

//     const dbResult = await dbResponse.json()
//     const { data, status: dbStatus } = dbResult
//     if (!data || !dbStatus) {
//       throwError(
//         "removeVNC() failed to remove tile from db, but fetch req was successful",
//         dbResult,
//       )
//       throw dbResult
//     }

//     console.log(`Successfully removed tile from db: ${dbResult}`)

//     // Add logic to notify user of successful removal

//     // Emit socket event (closeTile)
//     cbs?.onSuccess?.()
//   } catch (err) {
//     throwError("removeVNC() failed", err)
//     // Add logic to notify user of error (cobrowse removal failed)
//   }
// }

interface RemoveVNCParam {
  roomId: string
  tileId: string
  url: string
}

export async function removeVNC(param: RemoveVNCParam, callbacks?: any) {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER

  console.log("removeVNC()", param)
  if (!param || !param.url || !param.roomId || !param.tileId) {
    console.error("removeVNC failed - invalid param", param)
    return
  }
  try {
    //  $store.dispatch({
    //    type: "snackbar/queueNotify",
    //    payload: {
    //      title: "Removing VNC..",
    //      // body: 'Please wait..',
    //      icon: "info",
    //      timeout: 3000,
    //    },
    //  })

    const fetchURL = getCobrowseBackendURL()

    //@@ Deleting K8 resources @@//
    const fetchHandle = fetch(`${fetchURL}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        url: param.url,
        cobrowseId: param.tileId,
      }),
    })

    const response = await fetchHandle
    const removeResourceResult = await response.json()

    //@@ If resource exists but was unable to delete it @@//
    if (removeResourceResult.type !== "not-found") {
      if (!removeResourceResult.status || !response.ok) {
        console.error("removeVNC failed!", removeResourceResult)
        //   $store.dispatch({
        //     type: "snackbar/queueNotify",
        //     payload: {
        //       title: "Server Error",
        //       body: removeResourceResult.msg || "Unable to remove Cobrowse",
        //       icon: "error",
        //       timeout: 3000,
        //     },
        //   })
        return
      }
    }

    console.log(
      "Removing vnc deployment succeeded or does not exist",
      removeResourceResult,
    )

    //@@ Deleting DB data @@//
    const response2 = await fetch(`${REACT_APP_API_SERVER}/cobrowse-data`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tileId: param.tileId,
        roomId: param.roomId,
      }),
    })

    if (!response2.ok) {
      console.error("removeVNC failed to remove tile from DB", response2)
      return
    }
    const result2 = await response2.json()
    if (!result2.status || !result2.data) {
      console.error(
        "removeVNC failed to remove tile from DB but fetch was ok",
        result2,
      )
      return
    }

    console.log(`successfully removed tile from DB`, result2)

    //  $store.dispatch({
    //    type: "snackbar/queueNotify",
    //    payload: {
    //      title: "Successfully removed Cobrowse",
    //      icon: "success",
    //      timeout: 3000,
    //    },
    //  })
    callbacks?.onSuccess?.()

    return result2
  } catch (error: any) {
    console.error("removeVNC failed;;;;;;;;;;;;", error)
    callbacks?.onError?.({
      msg: error?.message || "Removing cobrowse failed",
    })
    //  $store.dispatch({
    //    type: "snackbar/queueNotify",
    //    payload: {
    //      title: "Server Error",
    //      // body: error.message || 'Removing cobrowse fetch failed',
    //      body: "Removing cobrowse failed",
    //      icon: "error",
    //      timeout: 3000,
    //    },
    //  })
    return error
  }
}

// Set VNC (cobrowse) tile state to avoid showing loading UI when tile refreshes

// export async function setCobrowsePodPhase(param: {
//   phase: string
//   tileId: string
//   roomId: string
// }) {
//   const storeState = $store.getState()
//   const env = storeState.env
//   const REACT_APP_API_SERVER =
//     env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER
//   const { phase, tileId, roomId } = param
//   const url = `${REACT_APP_API_SERVER}/cobrowse-data`
//   const body = JSON.stringify({
//     phase,
//     tileId,
//     roomId,
//   })

//   try {
//     const response = await fetch(url, {
//       method: "PATCH",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body,
//     })

//     if (!response.ok) throw response

//     const result = await response.json()
//     const { data, status } = result

//     if (!status) throw result

//     console.log("setCobrowsePodPhase() succeeded:", data)
//     return data
//   } catch (err) {
//     throwError("setCobrowsePodPhase() error", err)
//   }
// }

//@@ Sets VNC (cobrowse) tile state so that so that it does not show loading UI anytime cobrowse tile refreshes @@//
export async function setCobrowsePodPhase(param) {
  const phase = param?.phase
  const tileId = param?.tileId
  const roomId = param?.roomId

  if (!phase || !tileId || !roomId) {
    console.error("setCobrowsePodPhase() not triggered - invalid param:", param)
    return
  }

  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER

  const fetchURL = `${REACT_APP_API_SERVER}/cobrowse-data`
  try {
    const fetchHandle = fetch(fetchURL, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phase,
        tileId,
        roomId,
      }),
    })

    const response = await fetchHandle
    if (!response.ok) {
      console.error("setCobrowsePodPhase() failed..", response)
      return
    }
    const result = await response.json()
    if (!result.status) {
      console.error("setCobrowsePodPhase() failed!", result)
      return
    }
    const data = result.data
    console.log("setCobrowsePodPhase() - success", data)
    return data
  } catch (error) {
    console.error("setCobrowsePodPhase() catch error:", error)
    return error
  }
}

export interface SetCobrowseTileAccessParam {
  action: string
  tileId: string
  targetUserId: string
  isGuest?: boolean
  grantedAt?: string
}
export function setCobrowseTileAccessAsync(param: SetCobrowseTileAccessParam) {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER
  const roomId = storeState.room?.data?._id || storeState.space?.spaceId
  if (!roomId || !REACT_APP_API_SERVER) {
    const msg = "No roomId or REACT_APP_API_SERVER"
    console.error(msg)
    return Promise.reject(msg)
  }

  const fetchURL = `${REACT_APP_API_SERVER}/v1/room/cobrowse/access`
  const action = param.action
  const targetUserId = param.targetUserId

  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(fetchURL, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(param),
      })
      const result = await response.json()

      resolve(result)

      if (socket && socket.connected) {
        socket.emit(SocketMessageType.GetRoom, {
          roomId,
        })
      }
    } catch (err: any) {
      console.error(err)
      reject(err.message || err)
    }
  })
}
