import { SocketMessageType } from "@/TYPES/common"
import { RootState } from "@/TYPES/redux"
import { SocketContext } from "@/context/socket"
import { setCobrowseTileAccessAsync } from "@/helpers"
import Checkbox from "@mui/material/Checkbox"
import { useContext, useState } from "react"
import { useSelector } from "react-redux"

/**
 * SHOULD ONLY BE USED IN MAIN TILE
 * For now, main tile is the only tile that can modify cobrowsing access
 **/
export default function PermitCheck({ user, tile }) {
  //   const [checked, setChecked] = useState(false)
  const socket = useContext(SocketContext)
  const [isLoading, setIsLoading] = useState(false)

  const mainApp = useSelector((state: RootState) => {
    const roomData = state.room.data
    if (!roomData) return null
    const scene2d = roomData.scene2d
    const dataTile00 = scene2d?.dataTile00
    const app = Array.isArray(dataTile00) && dataTile00[0]
    return app
  })

  const targetTile = tile || mainApp
  const targetUserData = user?.data

  const controlGranted = useSelector((state: RootState) => {
    if (!targetTile) return false
    const cobrowseAccesses = targetTile.access
    if (!cobrowseAccesses) return false
    const foundAccess = cobrowseAccesses.find((obj) => {
      return obj.userId === (targetUserData._id || targetUserData.guestId)
    })
    console.warn("foundAccess", foundAccess)
    if (!foundAccess || !foundAccess.action) return false

    const isAllowedControl = foundAccess.action === "control"
    return isAllowedControl
  })

  async function onChange(e) {
    if (!targetUserData) return

    setIsLoading(true)
    try {
      const action = controlGranted ? "block" : "control"
      const result = await setCobrowseTileAccessAsync({
        tileId: targetTile._id,
        targetUserId: targetUserData._id || targetUserData.guestId,
        action,
        isGuest: !!targetUserData.guestId,
        grantedAt: new Date().toISOString(),
      })

      const cobrowseName = targetTile.title

      const triggerToastPayload: TriggerToastPayload = {
        type: "default",
        msg: `${
          targetUserData.name
        } now has ${action.toUpperCase()} access for tile ${cobrowseName}`,
        options: {
          toastId: `cobrowse-access-change--${action}`,
          autoClose: 1500,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
        },
        //   excludeSocketIds: [socket.id],
      }
      socket.emit(SocketMessageType.TriggerToast, triggerToastPayload)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <div
        className="cobrowse-permit-check-loader"
        style={{
          display: isLoading ? "block" : "none",
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          zIndex: 1,
          backgroundColor: "rgba(255,255,255,0.5)",
        }}
      ></div>
      <Checkbox checked={controlGranted} onChange={onChange} />
    </>
  )
}
