import Modal from "@/components/Modal"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import Button from "@mui/material/Button"
import { useRef, useState } from "react"
import AddFileWrapper from "./AddFileWrapper"

export default function AddFileModal({ onClose }: any) {
  const file_input_ref = useRef<HTMLInputElement>(null)
  const [errorMessage, setErrorMessage] = useState("")

  async function handleFileUpload(upload: (files: any[]) => void) {
    setErrorMessage("")

    const fileInput = file_input_ref.current
    if (!fileInput) {
      setErrorMessage("No file input found")
      return
    }

    const files: any = fileInput.files
    try {
      await upload(files)
      onClose()
    } catch (e: any) {
      if (e.message !== "500") {
        setErrorMessage(e.message)
      }
    }
  }

  return (
    <AddFileWrapper>
      {(upload, uploading) => {
        if (uploading) {
          return <></>
        }

        return (
          <Modal
            className="w-[400px] flex flex-col"
            title="Add File"
            onClose={() => onClose(file_input_ref)}
          >
            <div className="relative w-full flex justify-center">
              <Button
                component="label"
                variant="text"
                startIcon={<CloudUploadIcon />}
                sx={{
                  width: "33%",
                  color: "#ebebef",
                  backgroundColor: "#1e1e1e",
                  border: "0.5px solid #888889",
                  borderRadius: "0.125rem",
                  "&:hover": {
                    color: "#fff",
                    backgroundColor: "#1e1e1e",
                    border: "0.5px solid #fff",
                  },
                }}
              >
                Upload
                <input
                  ref={file_input_ref}
                  className="absolute bottom-0 left-0 w-px h-px overflow-hidden whitespace-nowrap"
                  type="file"
                  onChange={() => handleFileUpload(upload)}
                  accept=".pdf,.png,.jpg,.jpeg,.gif"
                />
              </Button>
            </div>

            <br />
            {errorMessage && <p className="text-red-500">{errorMessage}</p>}
          </Modal>
        )
      }}
    </AddFileWrapper>
  )
}
