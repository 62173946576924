import callSlice from "@/store/slices/callSlice"
import chatSlice from "@/store/slices/chatSlice"
import envSlice from "@/store/slices/envSlice"
import initSlice from "@/store/slices/initSlice"
import mmSlice from "@/store/slices/mmSlice"
import rdpSlice from "@/store/slices/rdpSlice"
import roomSlice from "@/store/slices/roomSlice"
import socketSlice from "@/store/slices/socketSlice"
import viewSlice from "@/store/slices/viewSlice"
import { RootState } from "@/TYPES/redux"
import { configureStore, Store } from "@reduxjs/toolkit"
import appSlice from "./slices/appSlice"
import eventsSlice from "./slices/eventsSlice"
import filesSlice from "./slices/filesSlice"
import guestSlice from "./slices/guestSlice"
import participantsSlice from "./slices/participantsSlice"
import permissionSlice from "./slices/permissionSlice"
import spaceSlice from "./slices/spaceSlice"
import userSlice from "./slices/userSlice"
import annotationSlice from "@/store/slices/annotationSlice"
import cobrowseSlice from "@/store/slices/cobrowseSlice"
import tippySlice from "@/store/slices/tippySlice"
import pdfSlice from "@/store/slices/pdfSlice"

const store: Store<RootState> = configureStore({
  reducer: {
    space: spaceSlice,
    participants: participantsSlice,
    events: eventsSlice,
    user: userSlice,
    room: roomSlice,
    call: callSlice,
    chat: chatSlice,
    app: appSlice,
    guest: guestSlice,
    permission: permissionSlice,
    init: initSlice,
    mm: mmSlice,
    view: viewSlice,
    files: filesSlice,
    env: envSlice,
    socket: socketSlice,
    rdp: rdpSlice,
    annotation: annotationSlice,
    cobrowse: cobrowseSlice,
    tippy: tippySlice,
    pdf: pdfSlice,
  },
})

export default store
