import { ChildrenType } from "@/TYPES/common"
import cn from "classnames"

type Props = {
  className?: string
  children?: ChildrenType
}

export default function PlatformMain({ className, children }: Props) {
  return <div className={cn(className, "flex-1")}>{children}</div>
}
