import { SocketMessageType } from "@/TYPES/common"
import { RootState } from "@/TYPES/redux"
import { IconNameProp } from "@/components/Icon"
import { SocketContext } from "@/context/socket"
import $store from "@/store"
import {
  assignShowAnnotation,
  assignShowMenu,
} from "@/store/slices/annotationSlice"
import { updateShowParticipants } from "@/store/slices/callSlice"
import {
  removeView,
  switchViews,
  toggleAppsPanel,
  togglePresenterMode,
} from "@/store/slices/spaceSlice"
import { useSelectViewport } from "@/utils/hooks/viewport"
import { useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { toast } from "react-toastify"
import NavItem from "./NavItem"
import MenuItem from "@mui/material/MenuItem"

export interface DropdownMenuContent {
  id: string
  label: string
  selected: boolean
  onSelect: Function
}

export interface NavItemType {
  key: string
  label?: string
  tooltip?: string
  className?: string
  iconName: IconNameProp
  iconSize: number
  iconClassName?: string
  adminOnly: boolean
  hidden?: boolean
  selected?: boolean
  invisibleOnPhone?: boolean
  disabledInDemoMode?: boolean
  disabledInPresenterMode?: boolean
  disabledOnFullScreen: boolean
  dropdown?: {
    enabled: boolean
    //  menuContents: DropdownMenuContent[]
    menuContentJSX: JSX.Element
    isOpen?: boolean
  }
  customJSX?: JSX.Element
  customHandler?: Function
  additionalDisabledCheck?: Function
}

function isRealOwner(pSpaceId) {
  const userData = $store.getState()?.user?.data
  const userId = userData?._id

  if (userId === pSpaceId) return true
  return false
}

export const NavItems = () => {
  const socket = useContext(SocketContext)
  const views = useSelector((state: RootState) => state.space.views)
  const dispatch = useDispatch()
  const { isPhone, isPad } = useSelectViewport()

  const params = useParams()
  const spaceId = params.id
  const userRole = $store.getState()?.user?.data?.role
  const isOwner = $store.getState()?.user?.isOwner
  const permissionLevel = useSelector(
    (state: RootState) => state.permission.level,
  )
  const { recording, shareScreenMode, shareScreenModeSelf, showParticipants } =
    useSelector((state: RootState) => state.call)
  const userData = useSelector((state: RootState) => state.user.data)

  const isDemoMode = useSelector((state: RootState) => state.space.isDemoMode)
  const isLocked = useSelector((state: RootState) => state.space.isLocked)
  const isPresenterMode = useSelector(
    (state: RootState) => state.space.isPresenterMode,
  )
  const isPresenting = views.includes("presentation") && isOwner
  const showAnnotation = useSelector(
    (state: RootState) => state.annotation.showAnnotation,
  )
  const syncableUserCount = useSelector(
    (state: RootState) => state.space.syncableUserCount,
  )
  const user_id = useSelector((state: RootState) => state.user.data?._id)
  const myName = useSelector((state: RootState) => state.user.data?.name)
  const myEmail = useSelector((state: RootState) => state.user.data?.email)

  const filesSelected = views.includes("files")
  const appsSelected = views.includes("apps") && !filesSelected
  const calendarSelected = views.includes("calendar")

  const navItems: NavItemType[] = [
    {
      key: "apps",
      tooltip: `${appsSelected ? "Hide" : "Show"} Tiles`,
      iconName: IconNameProp.Apps,
      iconSize: 20,
      iconClassName: "mb-4",
      adminOnly: true,
      invisibleOnPhone: true,
      disabledInDemoMode: true,
      disabledOnFullScreen: false,
    },
    {
      key: "presentation",
      tooltip: `${isPresenting ? "Stop" : "Start"} Presenting`,
      iconName: IconNameProp.Presentation,
      iconSize: 24,
      adminOnly: true,
      selected: isPresenting,
      invisibleOnPhone: true,
      disabledOnFullScreen: false,
      customHandler: () => {
        const toggle = !isPresenting
        const payload = {
          toggle,
          name: myName,
          triggerNotif: true,
          presenter_id: user_id,
        }
        console.log("handlePresenterMode()", payload)
        //!! <Experimental> For immediate animation loop termination for owner !!//
        dispatch(togglePresenterMode(toggle))
        //!! !!//
        socket.emit(SocketMessageType.PresenterMode, payload)
      },
    },
    {
      key: "share-screen",
      tooltip: "Share Screen",
      iconName: IconNameProp.Share,
      iconClassName: "mb-4",
      iconSize: 21,
      adminOnly: false,
      selected: shareScreenModeSelf,
      disabledOnFullScreen: false,
      invisibleOnPhone: true,
      customHandler: () => {
        window.jitsi?.executeCommand("toggleShareScreen")
      },
    },
    {
      key: "record",
      tooltip: `${recording ? "Stop" : "Start"} Recording`,
      iconName: IconNameProp.Record,
      iconSize: 20,
      adminOnly: true,
      selected: recording,
      disabledOnFullScreen: false,
      customHandler: () => {
        const command = (() =>
          recording
            ? ["stopRecording", "local"]
            : ["startRecording", { mode: "local" }])()
        window.jitsi?.executeCommand(...command)
      },
    },
    {
      key: "chat",
      tooltip: `${views.includes("chat") ? "Hide" : "Show"} Chat`,
      iconName: IconNameProp.Chat,
      iconSize: 21,
      adminOnly: false,
      disabledOnFullScreen: false,
    },
    {
      key: "jitsi",
      tooltip: `${views.includes("jitsi") ? "Hide" : "Show"} People`,
      iconName: IconNameProp.User,
      iconSize: 20,
      adminOnly: false,
      disabledOnFullScreen: true,
    },
    {
      key: "more",
      tooltip: "More Options",
      iconName: IconNameProp.Options,
      iconSize: 21,
      adminOnly: true,
      disabledOnFullScreen: true,
      disabledInPresenterMode: true,
      invisibleOnPhone: true,
      dropdown: {
        enabled: true,
        menuContentJSX: (
          <div>
            <MenuItem
              key="calendar"
              onClick={() => {
                const currentlyShowing = views.includes("calendar")
                //   const newShow = !currentlyShowing

                //@@ If calendar WILL show @@//
                if (!currentlyShowing) {
                  //@@ Make sure to terminate annotation session @@//
                  if (showAnnotation) {
                    dispatch(removeView("annotate"))
                    dispatch(assignShowAnnotation(false))
                    dispatch(assignShowMenu(false))
                    socket.emit("magic-marker-toggle", {
                      user_id,
                      show: false,
                      name: myName || myEmail || "Someone",
                    })
                    toast.info(`Ending annotation session to use calendar`, {
                      autoClose: 800,
                      closeOnClick: true,
                      pauseOnHover: false,
                      theme: "dark",
                      toastId: myName,
                    })
                    socket.emit(SocketMessageType.TriggerToast, {
                      type: "default",
                      msg: `${myName} ended annotation`,
                      options: {
                        toastId: `annotation-end`,
                        autoClose: 800,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        theme: "dark",
                      },
                      excludeSocketIds: [socket.id],
                    })
                  }
                }

                //@@ Toggle calendar view @@//
                dispatch(switchViews("calendar"))
              }}
            >
              {calendarSelected ? "Hide Calendar" : "Show Calendar"}
            </MenuItem>
            <MenuItem
              key="participants"
              onClick={() => {
                dispatch(updateShowParticipants(!showParticipants))
                window.jitsi?.executeCommand(
                  "toggleParticipantsPane",
                  !showParticipants,
                )
                if (!views.includes("jitsi") && !shareScreenMode) {
                  dispatch(switchViews("jitsi"))
                }
              }}
            >
              {showParticipants ? "Show Video" : "Show Participants"}
            </MenuItem>
            {userRole === "super-admin" && (
              <MenuItem
                key="portal"
                onClick={() => {
                  const portalUrl = `${window.origin}/portal`
                  window.open(portalUrl, "_blank")
                }}
              >
                {`Open Admin Portal ${
                  syncableUserCount ? `(${Number(syncableUserCount)})` : ""
                }`}
              </MenuItem>
            )}
          </div>
        ),
      },
    },
    // {
    //   key: "calendar",
    //   tooltip: `${calendarSelected ? "" : "Show Calendar"}`,
    //   iconName: IconNameProp.Calendar,
    //   iconSize: 20,
    //   // className: "!min-w-[35px]",
    //   adminOnly: true,
    //   hidden: isPhone ? false : !views.includes("more") || shareScreenMode,
    //   invisibleOnPhone: false,
    //   disabledInPresenterMode: true,
    //   disabledOnFullScreen: true,
    //   customHandler: () => {
    //     const currentlyShowing = views.includes("calendar")
    //     //   const newShow = !currentlyShowing

    //     //@@ If calendar WILL show @@//
    //     if (!currentlyShowing) {
    //       //@@ Make sure to terminate annotation session @@//
    //       if (showAnnotation) {
    //         dispatch(removeView("annotate"))
    //         dispatch(assignShowAnnotation(false))
    //         dispatch(assignShowMenu(false))
    //         socket.emit("magic-marker-toggle", {
    //           user_id,
    //           show: false,
    //           name: myName || myEmail || "Someone",
    //         })
    //         toast.info(`Ending annotation session to use calendar`, {
    //           autoClose: 800,
    //           closeOnClick: true,
    //           pauseOnHover: false,
    //           theme: "dark",
    //           toastId: myName,
    //         })
    //         socket.emit(SocketMessageType.TriggerToast, {
    //           type: "default",
    //           msg: `${myName} ended annotation`,
    //           options: {
    //             toastId: `annotation-end`,
    //             autoClose: 800,
    //             hideProgressBar: true,
    //             closeOnClick: true,
    //             pauseOnHover: false,
    //             draggable: true,
    //             theme: "dark",
    //           },
    //           excludeSocketIds: [socket.id],
    //         })
    //       }
    //     }

    //     //@@ Toggle calendar view @@//
    //     dispatch(switchViews("calendar"))
    //   },
    // },
  ]

  const availableNavItems = navItems.filter((item) => {
    if (item.hidden) return false
    return item
    // if (permissionLevel !== "guest") {
    //   return item
    // } else {
    // if (!item.adminOnly) {
    //   return item
    // }
    // }
  })

  const filteredNavItems =
    !isPhone && !isPad
      ? availableNavItems
      : availableNavItems.filter((item) => !item.invisibleOnPhone)

  const handleViews = (key: string) => {
    if (key === "apps") {
      // Just deselect files if file and apps are selected
      if (views.includes("apps") && views.includes("files")) {
        dispatch(switchViews("files"))
        return
      }

      // check if this should be permitted
      console.log("ROLE: " + userRole)
      console.log("isOwner: " + isOwner)
      console.log("permissionLevel: " + permissionLevel)

      let accessLevel = ""
      if (isRealOwner(spaceId)) {
        console.log("This is the real owner")
        accessLevel = "owner"
      } else if (userRole === "super-admin") {
        if (isLocked) {
          console.log("Don't allow super-admin to act as owner")
          accessLevel = ""
        } else {
          console.log("Allow super-admin to act as owner")
          accessLevel = "owner"
        }
      } else {
        accessLevel = permissionLevel
      }

      const canToggleApps =
        accessLevel === "owner" || permissionLevel === "standard"

      if (canToggleApps) {
        const appsIsShowing = views.includes("apps")
        const show = !appsIsShowing

        dispatch(toggleAppsPanel(show))

        if (isOwner && isDemoMode) {
          socket.emit(SocketMessageType.ToogleAppsPanel, {
            user_id: userData?._id,
            show,
          })
        }
        return
      }
    }

    dispatch(switchViews(key))
  }

  const isDisabled = (navItem: NavItemType) => {
    const disabled =
      (shareScreenMode && navItem.disabledOnFullScreen) ||
      (!isOwner && isDemoMode && navItem.disabledInDemoMode) ||
      (isPresenterMode && navItem.disabledInPresenterMode) ||
      (permissionLevel === "guest" && navItem.adminOnly)

    if (!disabled && typeof navItem.additionalDisabledCheck === "function") {
      const additionalChecker = navItem.additionalDisabledCheck
      const shouldDisable = additionalChecker()
      return shouldDisable ?? disabled
    }

    return disabled
  }

  return (
    <>
      {filteredNavItems.map((navItem) => {
        let selected = navItem.selected || views.includes(navItem.key)
        if (selected && navItem.key === "apps") {
          selected = !views.includes("files")
        }

        const itemDisabled = isDisabled(navItem)
        const tooltip = itemDisabled ? "" : navItem.tooltip

        return (
          <NavItem
            key={navItem.key}
            label={navItem.label}
            tooltip={tooltip}
            className={navItem.className}
            iconClassName={navItem.iconClassName}
            iconName={navItem.iconName}
            iconSize={navItem.iconSize}
            selected={selected}
            disabled={itemDisabled}
            onClick={() => {
              const customHandler = navItem?.customHandler
              if (customHandler && typeof customHandler === "function") {
                customHandler()
                return
              }
              handleViews(navItem.key)
            }}
            navItem={navItem}
          />
        )
      })}
    </>
  )
}
