import { ChildrenType } from "@/TYPES/common"

type Props = {
  title: string
  actions?: ChildrenType
}

export default function PlatformSidebarHeader({ title, actions }: Props) {
  return (
    <div className="h-[70px] flex items-center justify-between px-[22px] shadow">
      <h5 className="font-bold text-30 text-carbon">{title}</h5>
      <div className="flex">{actions}</div>
    </div>
  )
}
