//>> https://github.com/ridvanaltun/guacamole-rest-api-documentation <<//
import { SocketMessageType } from "@/TYPES/common"
import { socketConnection as socket } from "@/context/socket"
import { getAsset, unlinkAsset, uploadRDPAsset } from "@/helpers"
import $store from "@/store"
import { toast } from "react-toastify"
import validator from "validator"
import { poster, throwError } from "./fetcher"
const { isBase64 } = validator

export const TEMP_SHAREPOINT_TILE_ID = 'temp_sharepoint'

export function getGuacURL(): string {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_GUACAMOLE_URL =
    env.REACT_APP_GUACAMOLE_URL || import.meta.env.REACT_APP_GUACAMOLE_URL
  const url = REACT_APP_GUACAMOLE_URL || ""
  return url
}

export function getGuacDataSrc(): string {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_GUACAMOLE_DATA_SOURCE =
    env.REACT_APP_GUACAMOLE_DATA_SOURCE ||
    import.meta.env.REACT_APP_GUACAMOLE_DATA_SOURCE
  const dataSrc = REACT_APP_GUACAMOLE_DATA_SOURCE
  return dataSrc || "postgresql"
}

export function encodeGuacIdBase64(id: string): string {
  if (!id) return ""
  if (isBase64(id)) return id
  const connId = id.toString()
  const append = "c"
  const dataSrc = getGuacDataSrc()

  const preArray = [connId, append, dataSrc]
  const preString = preArray.join("\0")

  const encoder = new TextEncoder()
  const encodedData = encoder.encode(preString)
  const encodedArr = Array.from(encodedData)

  // Convert the Uint8Array to base64
  let base64data = btoa(String.fromCharCode.apply(null, encodedArr))

  return base64data
}

// export async function startTileConnection(body: {}) {
//   const url = "/api/guac/startConnection"

//   try {
//     const response: any = await poster(url, body)
//     if (!response.ok) throw response
//     return response
//   } catch (err) {
//     throwError("Failed to start tile connection", err)
//   }
// }

export function startTileConnection(body) {
  return new Promise((resolve, reject) => {
    poster(`/api/guac/startConnection`, body)
      .then((data) => {
        resolve(data)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

// export async function killTileConnection(body: {}) {
//   const storeState = $store.getState()
//   const env = storeState.env
//   const REACT_APP_API_SERVER =
//     env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER
//   const url = `${REACT_APP_API_SERVER}/api/guac/killConnection`

//   try {
//     const response = await fetch(url, {
//       method: "PATCH",
//       body: JSON.stringify(body),
//       credentials: "include",
//     })
//     if (!response.ok) throw response
//     const data = await response.json()
//     return data
//   } catch (err) {
//     throwError("Failed to kill tile connection", err)
//   }
// }

export function killTileConnection(body) {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER
  const url = `${REACT_APP_API_SERVER}/api/guac/killConnection`
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "PATCH",
      body: JSON.stringify(body),
      credentials: "include",
    })
      .then((res) => {
        const data = res.json()
        if (res.ok) resolve(data)
        reject(data)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

interface GuacConns {
  [identifier: string]: {
    name: string
    identifier: string
    parentIdentifier: string
    protocol: string
    activeConnections: number
    lastActive: number
    attributes: {
      [key: string]: string
    }
  }
}

interface DeleteGuacConnectionParams {
  _id: string
  fileName: string // '*.rdp'
  roomId: string
  teamId: string
  type: string // 'file'
}
export function deleteGuacConnection(param: DeleteGuacConnectionParams) {
  console.log("deleteGuacConnection()", { param })
  if (!param) return Promise.reject("Missing param")

  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER

  return new Promise(async (resolve, reject) => {
    const teamId = param.teamId
    const roomId = param.roomId
    const fileName = param.fileName

    if (!teamId || !roomId || !fileName) {
      const msg = `Missing teamId, roomId, or fileName`
      console.error(msg)
      reject(msg)
    }

    try {
      // const { data, isError } = useDefaultAsset(teamId, roomId, fileName)
      // if (isError) {
      //   console.error("Failed to use default asset", isError)
      //   reject(isError)
      //   return
      // }

      // const assetData: any = await getAssetData({
      //   teamId,
      //   roomId,
      //   fileName,
      // })

      const guacConnsResult: GuacConns = await getGuacamoleConnections()
      const guacConns = guacConnsResult?.data
      if (!guacConns) {
        const msg = `No guac connections found`
        console.error(msg)
        reject(msg)
        return
      }
      let foundGuacConn, foundGuacConnId
      for (const iden in guacConns) {
        const conn = guacConns[iden]
        const cleanName = fileName.replace(".rdp", "")
        if (conn?.name === cleanName) {
          foundGuacConn = conn
          foundGuacConnId = conn.identifier
          break
        }
      }
      if (!foundGuacConn) {
        const msg = `No guac connection found for ${fileName}`
        console.error(msg)
        await unlinkAsset(teamId, roomId, fileName)
        reject(msg)
        return
      }

      console.log("deleteGuacConnection()", { foundGuacConn })

      // const url = `${REACT_APP_API_SERVER}/api/guac/deleteConnection`
      // const response = await fetch(url, {
      //   method: "DELETE",
      //   body: JSON.stringify(assetData),
      //   credentials: "include",
      // })

      const fetchURL = `${REACT_APP_API_SERVER}/v1/guacamole/connections`
      const response = await fetch(fetchURL, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          identifier: foundGuacConnId,
        }),
        credentials: "include",
      })

      const result = await response.json()
      resolve(result)
    } catch (error: any) {
      console.error(error)
      reject(error.message || error)
    } finally {
      await unlinkAsset(teamId, roomId, fileName)
      socket?.emit?.(SocketMessageType.GetRoom, {
        roomId: param.roomId,
      })
    }
  })
}

export function getGuacamoleConnections(): any {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER =
    env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER
  return new Promise(async (resolve, reject) => {
    const fetchURL = `${REACT_APP_API_SERVER}/v1/guacamole/connections`
    try {
      const response = await fetch(fetchURL, {
        method: "GET",
        credentials: "include",
      })
      const data = await response.json()
      resolve(data)
    } catch (error) {
      console.error(error)
      reject(error)
    }
  })
}

// export async function getShareString(body: {}) {
//   const url = "/api/guac/getConnectionShareString"

//   try {
//     const response: any = await poster(url, body)
//     if (!response.ok) throw response
//     return response
//   } catch (err) {
//     throwError("Failed to get share string", err)
//   }
// }

export function getShareString(body) {
  return new Promise((resolve, reject) => {
    poster(`/api/guac/getConnectionShareString`, body)
      .then((data) => {
        resolve(data)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export async function generateToken(param?: {
  baseURL?: string
}): Promise<any> {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_GUACAMOLE_URL =
    env.REACT_APP_GUACAMOLE_URL || import.meta.env.REACT_APP_GUACAMOLE_URL
  const endpoint = "/api/tokens"
  const baseURL = param?.baseURL || REACT_APP_GUACAMOLE_URL
  const REACT_APP_GUACAMOLE_DEFAULT_USERNAME =
    env.REACT_APP_GUACAMOLE_DEFAULT_USERNAME ||
    import.meta.env.REACT_APP_GUACAMOLE_DEFAULT_USERNAME
  const REACT_APP_GUACAMOLE_DEFAULT_PASSWORD =
    env.REACT_APP_GUACAMOLE_DEFAULT_PASSWORD ||
    import.meta.env.REACT_APP_GUACAMOLE_DEFAULT_PASSWORD

  if (!baseURL) {
    const msg = "No baseURL found for guac"
    //  return { status: false, msg }
    return Promise.reject({ status: false, msg })
  }

  const url = `${baseURL}${endpoint}`

  try {
    const guacAdminUsername =
      REACT_APP_GUACAMOLE_DEFAULT_USERNAME || "guacadmin"
    const guacAdminPassword =
      REACT_APP_GUACAMOLE_DEFAULT_PASSWORD || "guacadmin"
    const details: { [key: string]: string } = {
      username: guacAdminUsername,
      password: guacAdminPassword,
    }

    const formBody: string[] = []
    let encodedKey: string
    let encodedValue: string

    for (const property in details) {
      encodedKey = encodeURIComponent(property)
      encodedValue = encodeURIComponent(details[property])
      formBody.push(`${encodedKey}=${encodedValue}`)
    }

    const body = formBody.join("&")

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body,
    })
    if (!response.ok) return { status: false, msg: response.statusText }
    const tokenObj = await response.json()
    $store.dispatch({ type: "rdp/assignGuacToken", payload: tokenObj })
    window.localStorage.setItem("GUAC_AUTH", JSON.stringify(tokenObj))
    const tokenExp = Date.now() + 1000 * 60 * 15 // The last number is minutes (actual token expires in 60 mins)
    //>> THIS IS A CUSTOM CONFIG UNLIKE `GUAC_AUTH` <<//
    $store.dispatch({ type: "rdp/assignGuacTokenExp", payload: tokenExp })
    //  window.localStorage.setItem("GUAC_AUTH_EXP", tokenExp.toString())

    return Promise.resolve({ status: true, data: tokenObj })
  } catch (err) {
    console.error("Failed to generate guac token:", err)
    $store.dispatch({ type: "rdp/assignGuacToken", payload: null })
    //  return { status: false, msg: err }
    return Promise.reject({ status: false, msg: err })
  }
}

export function guacTokenIsValid(): boolean {
  //   const customExpTime = window.localStorage.getItem("GUAC_AUTH_EXP") // Must be set to less than default token expiration time which is 60 minutes!
  //   if (!customExpTime) return false
  //   const expTime = parseInt(customExpTime)
  //   const now = Date.now()
  //   const isValid = now < expTime
  //   return isValid
  // }
  const customExpTime = $store.getState().rdp.guacTokenExp
  const customExpTimeNum = parseInt(customExpTime || "")
  if (!customExpTime || Number.isNaN(customExpTimeNum)) return false

  const now = Date.now()
  const isValid = now < customExpTimeNum
  return isValid
}

interface GuacTokenObj {
  authToken: string
  username: string
  password: string
  dataSource: string
  availableDataSources: string[]
}

export async function getGuacTokenObj(): Promise<GuacTokenObj> {
  const storeState = $store.getState()
  const $rdp = storeState.rdp
  const storeGuacTokenObj = $rdp.guacToken
  const storeGuacAuthToken = storeGuacTokenObj?.authToken

  try {
    if (!storeGuacAuthToken) {
      const guacTokenObj = await generateToken()
      return Promise.resolve(guacTokenObj)
    }

    const isValid = guacTokenIsValid()
    if (isValid) {
      return Promise.resolve(storeGuacTokenObj)
    }

    window.log(`Guac token has expired, generating new token...`, `highlight`)
    const genTokenResult = await generateToken()
    if (!genTokenResult || !genTokenResult.data || !genTokenResult.status) {
      const msg = "Failed to generate guac token"
      console.error(msg)
      return Promise.reject(msg)
    }
    const tokenObj = genTokenResult.data
    return Promise.resolve(tokenObj)
  } catch (error: any) {
    console.error(error)
    return Promise.reject(error)
  }
}

export async function getGuacToken(): Promise<string> {
  console.log("[getGuacToken()]")
  try {
    const tokenObj = await getGuacTokenObj()
    console.log("getGuacToken() tokenObj:", tokenObj)
    const authToken = tokenObj?.authToken
    if (!authToken) {
      const msg = "Failed to get guac auth token"
      console.error(msg)
      return Promise.reject(msg)
    }
    return Promise.resolve(authToken)
  } catch (error: any) {
    console.error(`getGuacToken() ERROR - ${error}`)
    return Promise.reject(error)
  }
}

export async function getGuacConns(): Promise<any> {
  console.log("[getGuacConns()]")
  try {
    const token = await getGuacToken()
    console.log("getGuacConns() token:", token)
    const dataSrc = getGuacDataSrc()
    const baseURL = getGuacURL()
    console.log("getGuacConns() dataSrc:", dataSrc, "baseURL:", baseURL)
    const endpoint = `/api/session/data/${dataSrc}/connections`
    const fetchURL = `${baseURL}${endpoint}?token=${token}`

    const response = await fetch(fetchURL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Guacamole-Token": token,
      },
    })
    if (!response.ok) throw response
    const data = await response.json()
    console.log("getGuacConns data:", data)
    return Promise.resolve(data)
  } catch (error: any) {
    console.error(`getGuacConns() ERROR - ${error}`)
    return Promise.reject(error)
  }
}

export async function getGuacConnByName(name: string): Promise<any> {
  console.log("[getGuacConnByName()]")
  if (!name || typeof name !== "string")
    return Promise.reject("Missing guac conn name")

  const connName = name.toString().replaceAll(".rdp", "")
  try {
    console.log("getGuacConnByName() - getting allConns...")
    const allConns = await getGuacConns()
    const allConnsData = allConns || {}
    console.log("getGuacConnByName() - allConnsData:", allConnsData)
    const allConnsArr = Object.values(allConnsData)
    const foundConn = allConnsArr.find((conn: any) => conn.name === connName)
    console.log("getGuacConnByName() - foundConn:", foundConn)
    return Promise.resolve(foundConn)
  } catch (error: any) {
    console.error(`getGuacConnByName ERROR - ${error}`)
    return Promise.reject(error)
    //  return Promise.resolve(null)
  }
}

export async function getGuacIframeUrlById(id: String): Promise<string> {
  // let connectionString = `${guacUrl}/#/client/${connKey}?username=${username}&password=${password}`
  const connId = id?.toString()
  if (!connId) return Promise.reject("Missing connection id")

  try {
    const guacURL = getGuacURL()
    const idBase64 = encodeGuacIdBase64(connId)
    const token = await getGuacToken()
    const iframeURL = `${guacURL}/#/client/${idBase64}?token=${token}`
    return Promise.resolve(iframeURL)
  } catch (error: any) {
    console.error(error)
    return Promise.reject(error)
  }
}

export async function getGuacIframeUrlByName(name: string): Promise<string> {
  console.log("[getGuacIframeUrlByName()]", { name })
  try {
    const conn = await getGuacConnByName(name)
    const connId = conn?.identifier
    if (!connId) return Promise.resolve("")
    const iframeURL = await getGuacIframeUrlById(connId)
    return Promise.resolve(iframeURL)
  } catch (error: any) {
    console.error(error)
    return Promise.reject(error)
  }
}

const checkDataSrcAndToken = (dataSrc?: string, token?: string) => {
  const msg =
    !dataSrc && !token
      ? "Missing guac data source and auth token"
      : !dataSrc
      ? "Missing guac data source"
      : !token
      ? "Missing guac auth token"
      : ""
  console.error(msg)
  return { status: false, msg }
}

export async function getGuacConnections(param?: {
  token?: string
  baseURL?: string
}): Promise<any> {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_GUACAMOLE_DATA_SOURCE =
    env.REACT_APP_GUACAMOLE_DATA_SOURCE ||
    import.meta.env.REACT_APP_GUACAMOLE_DATA_SOURCE
  const REACT_APP_GUACAMOLE_URL =
    env.REACT_APP_GUACAMOLE_URL || import.meta.env.REACT_APP_GUACAMOLE_URL
  const data_src = REACT_APP_GUACAMOLE_DATA_SOURCE
  const paramToken = param?.token
  const storageToken = window?.localStorage.getItem("GUAC_AUTH")
  console.warn({
    storageToken,
  })
  const storageTokenJSON =
    storageToken && storageToken !== "undefined" && JSON.parse(storageToken)
  const token = paramToken || storageTokenJSON?.authToken

  if (!data_src || !token) {
    return Promise.reject(checkDataSrcAndToken(data_src, token))
  }

  const endpoint = `/api/session/data/${data_src}/connections?token=${token}`
  const baseURL = param?.baseURL || REACT_APP_GUACAMOLE_URL

  if (!baseURL) {
    const msg = "No baseURL found for guac"
    console.error(msg)
    //  return { status: false, msg }
    return Promise.reject({ status: false, msg })
  }

  const url = `${baseURL}${endpoint}`

  try {
    const response = await fetch(url)
    if (!response.ok) {
      const msg = response.statusText
      console.error(msg)
      console.warn(
        '$store.dispatch({ type: "rdp/assignGuacConnections", payload: null })',
      )
      $store.dispatch({ type: "rdp/assignGuacConnections", payload: null })
      // return { status: false, msg }
      return Promise.reject({ status: false, msg })
    }
    const data = await response.json()
    $store.dispatch({ type: "rdp/assignGuacConnections", payload: data })
    //  return { status: true, data }
    return Promise.resolve({ status: true, data })
  } catch (err) {
    console.warn(
      `$store.dispatch({ type: "rdp/assignGuacConnections", payload: [] })`,
    )
    $store.dispatch({ type: "rdp/assignGuacConnections", payload: [] })
    throwError("Failed to GET guac connections", err)
  }
}

export async function createRDPConnection(
  param?: {
    token?: string
    baseURL?: string
    name?: string
    connName?: string
    hostname?: string
    host?: string
    username?: string
    password?: string
    domain?: string
    isSharepoint?: boolean
  },
  callback?: () => void,
) {
  console.log("createRDPConnection()", { param })
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_GUACAMOLE_DATA_SOURCE =
    env.REACT_APP_GUACAMOLE_DATA_SOURCE ||
    import.meta.env.REACT_APP_GUACAMOLE_DATA_SOURCE
  const REACT_APP_GUACAMOLE_URL =
    env.REACT_APP_GUACAMOLE_URL || import.meta.env.REACT_APP_GUACAMOLE_URL
  const data_src = REACT_APP_GUACAMOLE_DATA_SOURCE
  const token = $store.getState().rdp.guacToken?.authToken || param?.token

  if (!data_src || !token) {
    const msg = checkDataSrcAndToken(data_src, token)
    window.alert(msg.msg)
    return msg
  }

  const endpoint = `/api/session/data/${data_src}/connections?token=${token}`
  const baseURL = param?.baseURL || REACT_APP_GUACAMOLE_URL

  if (!baseURL) {
    const msg = "No baseURL found for guac"
    console.error(msg)
    return { status: false, msg }
  }

  const url = `${baseURL}${endpoint}`

  const name = param?.name || param?.connName
  const hostname = param?.hostname || param?.host
  const username = param?.username || ""
  const password = param?.password || ""
  const domain = param?.domain || ""

  if (!name || !hostname) {
    const msg = "Missing guac connection or host name"
    return { status: false, msg }
  }

  const body = JSON.stringify({
    name: name,
    parentIdentifier: "ROOT",
    protocol: "rdp",
    parameters: {
      "color-depth": "",
      hostname: hostname,
      "ignore-cert": "true",
      password: password,
      domain: domain,
      port: "3389",
      // "resize-method": "string:display-update",
      "resize-method": "",
      security: "any",
      username: username,
      "disable-auth": "",
      "gateway-port": "",
      "server-layout": "",
      timezone: null,
      "enable-touch": "",
      console: "",
      width: "",
      height: "",
      dpi: "",
      "force-lossless": "",
      "read-only": "",
      "disable-copy": "",
      "disable-paste": "",
      "console-audio": "",
      "disable-audio": "",
      "enable-audio-input": "",
      "enable-printing": "",
      "enable-drive": "",
      "disable-download": "",
      "disable-upload": "",
      "create-drive-path": "",
      "enable-wallpaper": "",
      "enable-theming": "",
      "enable-font-smoothing": "",
      "enable-full-window-drag": "",
      "enable-desktop-composition": "",
      "enable-menu-animations": "",
      "disable-bitmap-caching": "",
      "disable-offscreen-caching": "",
      "disable-glyph-caching": "",
      "preconnection-id": "",
      "recording-exclude-output": "",
      "recording-exclude-mouse": "",
      "recording-exclude-touch": "",
      "recording-include-keys": "",
      "create-recording-path": "",
      "enable-sftp": "",
      "sftp-port": "",
      "sftp-server-alive-interval": "",
      "sftp-disable-download": "",
      "sftp-disable-upload": "",
      "wol-send-packet": "",
      "wol-wait-time": "",
    },
    attributes: {
      "guacd-encryption": null,
      "failover-only": null,
      weight: null,
      "max-connections": null,
      "guacd-hostname": null,
      "guacd-port": null,
      "max-connections-per-user": null,
    },
  })

  try {
    //@@ Creating connection directly via guac API @@//
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body,
    })
    console.log("createRDPConnection() response:", response)
    if (!response.ok) throw response
    const data = await response.json()
    console.log("createRDPConnection() data:", data)

    createGuacSharingProfileByName(name)

    const teamId = $store.getState().room.data?.teamId
    //@@ Creating txt file for guac connection info @@//
    await uploadRDPAsset(teamId, param)

    return { status: true, data }
  } catch (err: any) {
    console.error("Failed to POST RDP connection:", err)

    if (err.message === "authenticate token.") {
      err.message = `You don't have permission to add apps. Ask your administrator to add you to a team, then try again`
    }
    if (err.status === 400) {
      err.message = `A connection with this name already exists`
    }

    toast.error(
      `Failed to add RDP app: ${err.msg || err.message || "Server Error"}`,
      {
        autoClose: 4000,
        style: {
          width: "fit-content",
          whiteSpace: "pre-wrap",
        },
      },
    )

    return { status: false, msg: err }
  } finally {
    callback?.()
  }
}

async function createGuacSharingProfileByName(connName: string) {
  console.log("[createGuacSharingProfileByName()]", connName)
  try {
    const connInfo = await getGuacConnByName(connName)
    const connId = connInfo?.identifier
    if (!connId) {
      const msg = "Missing connection id"
      console.error(msg)
      return Promise.reject(msg)
    }
    const token = await getGuacToken()
    const guacURL = getGuacURL()
    const guacDataSrc = getGuacDataSrc()
    const endpoint = `/api/session/data/${guacDataSrc}/sharingProfiles`
    const fetchURL = `${guacURL}${endpoint}?token=${token}`
    const response = await fetch(fetchURL, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Guacamole-Token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: `${connName}_share`,
        primaryConnectionIdentifier: connId,
        parameters: {
          "read-only": "",
        },
        attributes: {},
      }),
    })

    console.log("createGuacSharingProfileByName() response:", response)
    return Promise.resolve(response)
  } catch (error: any) {
    console.error(`createGuacSharingProfileByName() ERROR - ${error}`)
    return Promise.reject(error)
  }
}

interface GetGuacAssetParam {
  teamId: string
  roomId: string
  fileName: string
}
export async function getGuacAsset(param: GetGuacAssetParam) {
  const teamId = param?.teamId
  const roomId = param?.roomId
  const fileName = param?.fileName
  if (!teamId || !roomId || !fileName) {
    const msg = "Missing teamId, roomId, or fileName"
    console.error(msg)
    return Promise.reject(msg)
  }

  try {
    const rdpData = await getAsset({
      teamId,
      roomId,
      fileName,
    })
    return Promise.resolve(rdpData)
  } catch (e: any) {
    console.error(e)
    return Promise.reject(e)
  }
}
interface GetGuacSharingProfilesParam {
  //   connId?: string
  //   id?: string
  name?: string
  guacName?: string
  connName?: string
}
export async function getGuacSharingProfileByName(
  //   param: GetGuacSharingProfilesParam,
  connName: string,
): Promise<any> {
  //   const guacName = param?.name || param?.guacName || param?.connName
  const guacName = connName
  if (!guacName) {
    const msg = "Missing guac connection name"
    console.error(msg)
    return Promise.reject(msg)
  }

  const connInfo = await getGuacConnByName(guacName)
  const connId = connInfo?.identifier
  if (!connId) {
    const msg = "Missing connection id"
    console.error(msg)
    return Promise.reject(msg)
  }

  try {
    const token = await getGuacToken()
    const guacURL = getGuacURL()
    const guacDataSrc = getGuacDataSrc()
    //  const endpoint = `/api/session/data/${guacDataSrc}/sharingProfiles`
    const endpoint = `/api/session/data/${guacDataSrc}/connections/${connId}/sharingProfiles`
    const fetchURL = `${guacURL}${endpoint}?token=${token}`
    const response = await fetch(fetchURL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Guacamole-Token": token,
      },
    })
    const result = await response.json()

    !result && (await createGuacSharingProfileByName(connName))

    const sharingProfilesArray = Object.values(result)
    const preferredProfileFound = sharingProfilesArray.find(
      (profile: any) => profile.name === `${connInfo.name}_share`,
    )
    const profile = preferredProfileFound || sharingProfilesArray[0]
    if (!profile) {
      const msg = "No sharing profiles found"
      console.error(msg)
      return Promise.reject(msg)
    }
    return Promise.resolve(profile)
  } catch (error: any) {
    console.error(error)
    return Promise.reject(error)
  }
}

export async function getGuacShareLinkByName(connName: string) {
  console.log("[getGuacShareLinkByName()]")
  if (!connName) {
    const msg = "Missing guac connection name"
    console.error(msg)
    return Promise.reject(msg)
  }
  try {
    const activeConn: any = await getGuacActiveConnectionByName(connName)
    const sharingProfile = await getGuacSharingProfileByName(connName)
    const sharingProfileId = sharingProfile?.identifier

    const activeConnId = activeConn?.identifier
    if (!activeConnId) {
      const msg = "No active connection found"
      console.error(msg)
      return Promise.reject(msg)
    }
    const token = await getGuacToken()
    const guacURL = getGuacURL()
    const endpoint = `/api/session/tunnels/${activeConnId}/activeConnection/sharingCredentials/${sharingProfileId}`
    const fetchURL = `${guacURL}${endpoint}?token=${token}`
    const response = await fetch(fetchURL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Guacamole-Token": token,
      },
    })
    const result = await response.json()
    const shareKey = result?.values?.key
    if (!shareKey) {
      const msg = "No sharing key found"
      console.error(msg)
      return Promise.reject(msg)
    }
    const shareLink = `${guacURL}/#/?key=${shareKey}`
    return Promise.resolve(shareLink)
  } catch (error: any) {
    console.error(error)
    return Promise.reject(error)
  }
}

export async function getGuacActiveConnections() {
  console.log("[getGuacActiveConnections()]")
  // url: `${guacUrl}/api/session/data/${dataSrc}/activeConnections?token=${token}`,
  try {
    console.log("getGuacActiveConnections() - getting token...")
    const token = await getGuacToken()
    console.log("getGuacActiveConnections() - token?", token)
    const guacURL = getGuacURL()
    const guacDataSrc = getGuacDataSrc()
    console.log(
      "getGuacActiveConnections() - guacURL?",
      guacURL,
      "guacDataSrc?",
      guacDataSrc,
    )
    const endpoint = `/api/session/data/${guacDataSrc}/activeConnections`
    const fetchURL = `${guacURL}${endpoint}?token=${token}`
    console.log(
      `getGuacActiveConnections() - fetching active conns from ${fetchURL}...`,
    )
    const response = await fetch(fetchURL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Guacamole-Token": token,
      },
    })
    const result = await response.json()
    console.log("getGuacActiveConnections() result:", result)
    return Promise.resolve(result)
  } catch (error: any) {
    console.error(`getGuacActiveConnections() ERROR - ${error}`)
    return Promise.reject(error)
  }
}

export async function getGuacActiveConnectionByName(connName: string) {
  console.log("[getGuacActiveConnectionByName()]", connName)
  if (!connName) {
    const msg = "Missing guac connection name"
    console.error(msg)
    return Promise.reject(msg)
  }
  try {
    console.log("getGuacActiveConnectionByName() - Getting connInfo...")
    const connInfo = await getGuacConnByName(connName)
    console.log("connInfo?", connInfo || null)
    console.log("getGuacActiveConnectionByName() - Getting activeConns...")
    const activeConns = await getGuacActiveConnections()
    console.log("activeConns?", activeConns || null)
    const activeConnsArr = Object.values(activeConns)
    const foundConn = activeConnsArr.find(
      (conn: any) => conn.connectionIdentifier === connInfo.identifier,
    )
    console.log(
      "getGuacActiveConnectionByName() - foundConn?",
      foundConn || null,
    )
    return Promise.resolve(foundConn)
  } catch (error: any) {
    console.error(`getGuacActiveConnectionByName() ERROR - ${error}`)
    return Promise.reject(error)
  }
}
