import { createSlice } from "@reduxjs/toolkit"

export const appSlice = createSlice({
  name: "env",
  initialState: {} as any,
  reducers: {
    assignBulkEnv: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
})

export const { assignBulkEnv } = appSlice.actions
export default appSlice.reducer
