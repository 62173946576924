import $store from '@/store'
import { fetcher } from "./fetcher"

/**
 * We want to generate a JWT that represents the 3Data user
 * so we can get them an access token for webex
 */
export function getJwt() {
  return new Promise((resolve, reject) => {
    fetcher("/api/webex/auth/jwt")
      .then((jwt) => {
        resolve(jwt)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * Exchanges JWT for access token. Every 3Data user is considered a guest user to webex.
 *
 * I don't use the fetcher here because it's a POST request and needs specific auth headers
 */
export function getAccessToken(guestToken) {
  return new Promise((resolve, reject) => {
    fetch(`https://webexapis.com/v1/jwt/login`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${guestToken}`,
      },
    })
      .then((res) => {
        const data = res.json()
        if (res.ok) {
          resolve(data)
        }
        reject(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * We want to get info associated with the webex guest user
 * that we created for the 3Data user.
 *
 * I'm not using fetcher because I need specific auth headers.
 */
export function getGuesUser(token) {
  return new Promise((resolve, reject) => {
    fetch(`https://webexapis.com/v1/people/me`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        const data = res.json()
        if (res.ok) {
          resolve(data)
        }
        reject(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * Use guest user ID to create membership with Webex Teams room
 */
export function createRoomMembership(guestId, roomId) {
  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_API_SERVER = env.REACT_APP_API_SERVER || import.meta.env.REACT_APP_API_SERVER

  return new Promise((resolve, reject) => {
    fetch(
      `${REACT_APP_API_SERVER
      }/api/webex/meeting/${roomId}/createMembership`,
      {
        method: "POST",
        body: JSON.stringify({
          guestId: guestId,
        }),
        credentials: "include",
      },
    )
      .then((res) => {
        const data = res.json()
        if (res.ok) {
          resolve(data)
        }
        reject(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
/**
 * We want meeting info so we can connect
 */
export function getMeetingInfo(roomId) {
  return new Promise((resolve, reject) => {
    fetcher(`/api/webex/meeting/${roomId}/meetingInfo`)
      .then((meeting) => {
        resolve(meeting)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * We want to remove any membership so we don't clutter webex
 */
export function deleteRoomMembership() {
  return new Promise<void>((resolve, reject) => {
    fetcher(`/api/webex/meeting/deleteMembership`)
      .then(() => {
        resolve()
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function cleanRoomMemberships(roomId) {
  return new Promise<void>((resolve, reject) => {
    fetcher(`/api/webex/meeting/${roomId}/cleanMemberships`)
      .then(() => {
        resolve()
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function getTokenSet(name) {
  return new Promise((resolve, reject) => {
    fetcher(`/api/webex/auth/${name}/getTokenSet`)
      .then((tokenSet) => {
        resolve(tokenSet)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
