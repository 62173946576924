import { ChildrenType, DraggingType } from "@/TYPES/common"
import cn from "classnames"
import { useDrop } from "react-dnd"

type Props = {
  className?: string
  type: DraggingType
  onDrop: (item: any) => void
  children: ChildrenType
}

export default function DropZone({ className, type, onDrop, children }: Props) {
  const [{ isOver, canDrop }, ref] = useDrop(() => ({
    accept: type,
    drop: (item) => onDrop(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }))

  return (
    <div
      ref={ref}
      className={cn(className, "relative", {
        "inactive-iframes": canDrop,
      })}
    >
      {children}
      {isOver && <div className="absolute inset-0 bg-smoke/30" />}
    </div>
  )
}
