import $store from "@/store"

export function getEnvOverride(key?: string) {
  if (!key || typeof key !== "string") {
    return window._env_ || {}
  }
  return window._env_?.[key]
}
export function getEnv(key?: string) {
  const viteEnvs = JSON.parse(JSON.stringify(import.meta.env || null))
  console.log("getEnv()", { viteEnvs })

  if (!key || typeof key !== "string") {
    const overrideEnvs = window._env_ || {}
    const env = { ...viteEnvs, ...overrideEnvs }
    return env
  }

  const overrideEnv = getEnvOverride(key)
  const final = overrideEnv || (viteEnvs && viteEnvs[key])
  console.log("getEnv()", { key, final })
  //   return overrideEnv || (import.meta.env && import.meta.env[key])
}

export function appendDocumentTitleVersion(desiredVersion?: string) {
  const currentTitle = document.title

  if (currentTitle.includes("| v")) return

  const storeState = $store.getState()
  const env = storeState.env
  const REACT_APP_VERSION =
    env.REACT_APP_VERSION || import.meta.env.REACT_APP_VERSION

  const setVersion = desiredVersion || REACT_APP_VERSION
  const version = setVersion.startsWith("v") ? setVersion : `v${setVersion}`
  const newTitle = `${currentTitle} | ${version}`

  document.title = newTitle
}
