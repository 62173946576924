import { useViewport } from "@/utils/hooks/viewport"
import cn from "classnames"
import { useSelector } from "react-redux"
import { RootState } from "@/TYPES/redux"
import ActivePanelInformation from "./ActivePanelInformation"
import EventLeftPanelHeader from "./EventLeftPanelHeader"
import EditPanel from "./EditPanel"
import { useEffect } from "react"

interface Props {
  isDarkMode: boolean
  calendarEvents?: any
}

export default function CalendarLeftPanel({ isDarkMode }: Props) {
  const { isPhone } = useViewport()
  const activeEventId = useSelector((state: RootState) => state.events.activeId)
  const editEvent = useSelector((state: RootState) => state.events.editEvent)
  const event = useSelector((state: RootState) => state.events.events)
  const activeEvent =
    (event &&
      Array.isArray(event) &&
      event.filter((ev) => ev.eventUUID === activeEventId)[0]) ||
    []

  const getContent = () => {
    if (editEvent) {
      return <EditPanel editEvent={editEvent} isDarkMode={isDarkMode} />
    } else if (activeEventId) {
      return (
        <ActivePanelInformation event={activeEvent} isDarkMode={isDarkMode} />
      )
    } else {
      return (
        <div
          className={cn("w-full h-full", {
            "bg-white": !isDarkMode,
            "bg-cobrowsegray": isDarkMode,
          })}
        />
      )
    }
  }

  useEffect(() => {}, [event, event.length])

  return (
    <div
      className={cn("flex flex-col h-full transition-width", {
        "w-0": !activeEventId && !editEvent,
        "w-[395px]": (activeEventId || editEvent) && !isPhone,
        "w-full": (activeEventId || editEvent) && isPhone,
      })}
    >
      <div
        className={cn("flex h-full flex-col border-r-[0.5px]", {
          "w-[395px]": !isPhone,
          "w-full": isPhone,
          "border-typegray": !isDarkMode,
          "border-cloudy": isDarkMode,
        })}
      >
        <EventLeftPanelHeader isDarkMode={isDarkMode} />
        {getContent()}
      </div>
    </div>
  )
}
