import { RootState } from "@/TYPES/redux"
import DropdownMenu from "@/components/DropdownMenu"
import Icon, { IconNameProp } from "@/components/Icon"
import TitleBar from "@/components/TitleBar"
import { getPopperProps } from "@/helpers"
import Tooltip from "@mui/material/Tooltip"
import cn from "classnames"
import { useSelector } from "react-redux"

const appMenus = [
  { key: "file", label: "Add File" },
  // { key: "app", label: "Embedded Content" },
  { key: "cobrowse", label: "Add Web App" },
  { key: "vnc", label: "Add VNC" },
  { key: "rdp", label: "Add RDP" },
]

type Props = {
  hotkeysEnabled: boolean
  isFile: boolean
  onAdd: (value: string) => void
}

export default function DesktopTitleBar({
  hotkeysEnabled,
  isFile,
  onAdd,
}: Props) {
  const isRoomOwner = useSelector(
    (state: RootState) => state.permission.level === "owner",
  )

  return (
    <TitleBar className="justify-between !px-2" bgColor="cobrowse">
      <div className="flex items-center">
        <Icon
          className={cn("transition-opacity duration-500", {
            "fill-accentyellow opacity-100": hotkeysEnabled,
            "fill-typegray opacity-0": !hotkeysEnabled,
          })}
          iconName={IconNameProp.Hotkeys}
          size={18}
        />
        {hotkeysEnabled && (
          <div className="relative flex items-center text-accentyellow text-9 ml-1 animation-fade-inout">
            Hotkeys Enabled
          </div>
        )}
      </div>

      {isRoomOwner && (
        <DropdownMenu
          menuItems={appMenus.map((item) => ({
            label: item.label,
            onClick: () => onAdd(item.key),
          }))}
          anchorProps={{ vertical: "bottom", horizontal: "center" }}
          transformProps={{ vertical: "top", horizontal: "center" }}
          styleOverrides={{
            "& .MuiButtonBase-root:hover": {
              color: "#fff",
            },
          }}
        >
          <Tooltip title="Add Tile" PopperProps={popperProps} placement="left">
            <div className="group flex p-[6px]">
              <Icon
                className="cursor-pointer fill-typegray group-hover:fill-accentgreen"
                iconName={IconNameProp.Add}
                size={16}
              />
            </div>
          </Tooltip>
        </DropdownMenu>
        // )
      )}
    </TitleBar>
  )
}

const popperProps = getPopperProps({ offset: [0, -10] })
